@font-face {
    font-family: 'cmab2';
    src:
        url('../fonts/cmab2.ttf?dku1vi') format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="cmab2-"], [class*=" cmab2-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cmab2' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cmab2-hat:before {
    content: "\e602";
}
.cmab2-question:before {
    content: "\e601";
}
.cmab2-bars:before {
    content: "\e600";
}
.cmab2-search:before {
    content: "\e900";
}
.cmab2-cheese-faq:before {
    content: "\e901";
}
.cmab2-cheese-bubble:before {
    content: "\e902";
}
.cmab2-cheese-101:before {
    content: "\e903";
}
.cmab2-cheese-glossary:before {
    content: "\e904";
}
.cmab2-cheese-types:before {
    content: "\e905";
}
.cmab2-cheese-history:before {
    content: "\e906";
}
.cmab2-cheese-producers:before {
    content: "\e907";
}
.cmab2-arrow-right:before {
    content: "\e908";
}
.cmab2-check:before {
    content: "\e909";
}

