.smart-pastures {
  .subheadline {
    font-family: $script;
    font-size: 35px;
    color: $yellow-ada;
    line-height: 1.7;
  }

  .headline {
    font-family: $sans;
    font-size: 97px;
    text-transform: uppercase;
    line-height: 70px;
  }

  .img-fluid {
    max-width: 250px;
    margin-top: -15px;
  }

  .content {
    margin-top: 24px;

    .description {
      font-family: $serif;
      font-size: 25px;
      line-height: 1.4;
      max-width: 360px;
      margin: auto;
    }
  }

  .cta-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 3em;

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      width: 195px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
    }
  }
}

@include bp(mediumWidth) {
  .smart-pastures {
    .subheadline {
      font-size: 17px;
    }

    .headline {
      font-size: 43px;
      line-height: 1;
    }

    .img-fluid {
      margin-top: -12px;
    }

    .content {
      .description {
        font-size: 4.8vw;
        line-height: 6.3vw;
      }
    }
  }
}
