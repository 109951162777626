.partnership-masthead {
  // Hispanic-dairy masthead
  &.hispanic-dairy {
    .topHeadline {
      font-size: 48px;
      @include break($mobile, true) {
        font-size: 24px;
      }
    }

    .bottomHeadline {
      max-width: 300px;
      @include break($mobile, true) {
        font-size: 20px;
      }
      @include break($tablet) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 712px;
      }
    }
  }

  // Nutrition Hub masthead
  &.nutrition {
    .bottomHeadline {
      font-family: gothic, sans-serif;
      padding-top: 0 !important;
      @include break($mobile, true) {
        font-size: 22px;
      }
      @include break($tablet) {
        max-width: 760px;
      }
    }
  }

  // Two column masthead
  &.two-column {
    .bg-container {
      /* we set transition to 'all' properies - but you can use it just for background image either */
      transition: linear all 1s;
      /* if you don't use delay , background will disapear and transition will start from a white background - you have to set the transition-delay the same as transition time , so there won't be any problems */
      -webkit-transition-delay: 1s;
      /* Safari */
      transition-delay: 1s;
    }

    .topHeadline {
      color: $yellow-ada;
    }

    .mediumHeadlineText {
      color: #080808;
    }

    .bottomHeadline {
      font-family: gothic, sans-serif;
      max-width: 551px;
      color: #080808;
    }

    @include break($mobile, true) {
      height: auto;
      .bg-container {
        height: 376px;
      }
      .health-masthead-copy {
        .topHeadline {
          font-size: 30px;
        }

        .mediumHeadlineText {
          font-size: 77px;
        }

        .bottomHeadline {
          font-size: 24px;
          max-width: 310px;
        }
      }
    }
  }

  &.health-wellness-everyone {
    .topHeadline {
      @include break($desktop-wide) {
        font-size: 52px;
      }
    }

    .mediumHeadlineText {
      @include break($desktop-wide) {
        font-size: 150px;
        line-height: 120px;
      }
    }

    .bottomHeadline {
      max-width: 230px;
      @include break($tablet) {
        max-width: 530px;
      }
    }
  }

  &.health-wellness-professionals {
    .bg-container {
      order: 2;
    }

    .health-masthead-copy {
      order: 1;

      .topHeadline {
        font-size: 23px;
        @include break($desktop) {
          font-size: 35px;
        }
      }

      .mediumHeadlineText {
        font-size: 64px;
        @include break($desktop) {
          font-size: 100px;
          line-height: 80px;
        }
      }

      .mediumHeadlineText,
      .bottomHeadline {
        color: #ffffff;
      }

      @include break($mobile, true) {
        order: 3;
      }
    }
  }

  margin-top: 50px;
  height: calc(100vh - 70px);
  max-height: 1080px;
  width: 100%;
  text-align: center;
  color: $white;
  background-size: cover;
  background-position: center center;

  @include break($mobile, true) {
    margin-top: 40px;
  }

  .topHeadline {
    font-family: "wisdom", cursive;
    font-size: 35px;
    @include break($mobile, true) {
      font-size: 20px;
    }
  }

  .mediumHeadlineImg {
    width: 85%;
    max-width: 900px;
  }

  .mediumHeadlineText {
    font-family: gothic, sans-serif;
    text-transform: uppercase;
    font-size: 100px;
    line-height: 80px;
    margin: auto;
    @include break($mobile, true) {
      font-size: 77px;
      line-height: 63px;
    }
  }

  .bottomHeadline {
    font-family: "hoefler", serif;
    font-size: 32px;
    max-width: 850px;
    margin: auto;
    @include break($mobile, true) {
      font-size: 16px;
    }
  }

  .arrow {
    height: 20px;
  }
}
