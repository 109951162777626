.featured-recipes {
  $spacer-width: 48px;
  $spacer-width-mobile: 10px;

  background-color: $yellow;
  margin: 0 auto;
  width: 100%;

  body#home &,
  body#partnership & {
    background-color: transparent;

    .prev,
    .slick-prev {
      @include prev_button(0, yellow, false);
    }

    .next,
    .slick-next {
      @include next_button(0, yellow, false);
    }

    .details {
      background-color: $yellow;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  body#partnership & {
    .header {
      @include break($tablet) {
        padding-bottom: 50px;
      }

      .subheadline {
        color: $black;
        font-size: 60px;

        @include break($tablet) {
          font-size: 72px;
        }
      }

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        font-size: 30px;

        @include bp(mediumWidth) {
          font-size: 30px;
        }
      }
    }

    .photo {
      height: 300px;

      @include break($desktop) {
        height: 422px;
      }

      &.video-player {
        iframe {
          height: 100%;
          width: 100%;
        }
      }
    }

    .slide {
      @include break($desktop) {
        padding: 0 60px;
      }
    }

    .slick-arrow {
      width: 69px;
      height: 69px;
    }

    .details {
      text-align: left;
      min-height: 320px;

      @include break($desktop) {
        padding: 48px;
      }

      .headline {
        color: $white;
      }

      .description {
        color: $white;
      }

      .cta {
        @include cta-no-border($black, $white, $white, $black, 160px);
        align-self: start;
      }
    }

    &.partnership-healthy-everyone {
      background-color: #f5b324;
      padding-top: 50px;
      padding-bottom: 50px;

      @include break($tablet) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      .header {
        width: auto;
        padding-bottom: 30px;
        @include break($tablet) {
          padding-bottom: 15px;
        }

        .heading {
          @include break($tablet) {
            padding-left: 45px;
          }
          @include break($desktop) {
            padding-left: 80px;
          }

          .subheadline {
            color: #323232;
            font-size: 56px;
            line-height: 50px;

            @include break($tablet) {
              font-size: 72px;
              line-height: 80px;
            }
          }
        }

        .cta-wrapper {
          display: none;
        }
      }

      .prev,
      .slick-prev {
        @include prev_button(0, white, false);
      }

      .next,
      .slick-next {
        @include next_button(0, white, false);
      }

      .slick-dots {
        li {
          button {
            &:before {
              color: #ffffff;
              opacity: 1;
            }
          }

          &.slick-active button:before {
            color: #d6d6d6;
          }
        }
      }

      .details {
        background-color: white;
        display: block;
        @include break($mobile, true) {
          display: flex;
          padding: 24px;
        }

        .headline {
          color: #232020;
        }

        .description {
          color: #000000;
          padding-bottom: 15px;
        }

        .cta {
          @include cta-no-border($black, $white, $white, $black, 160px);
        }
      }

      .more-recipes {
        padding-top: 40px;

        @include break($tablet) {
          padding-top: 80px;
        }

        a {
          @include cta-no-border($black, $white, $white, $black, 280px);
        }
      }
    }
  }

  body#food & {
    @include chevron("yellow");
    margin-top: -20px;
    padding-top: 210px;
    @include break($mobile) {
      padding-top: 50px;
    }
  }

  @include break($tablet) {
    padding: 114px 0 0;
  }

  a {
    text-decoration: none;
  }

  .slick-list {
    @include break($tablet) {
      overflow: initial;
    }
  }

  .slide-wrapper-outer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @include break($tablet) {
      width: 86%;
      max-width: 1100px;
    }

    @include bp(mediumWidth) {
      .slick-dotted.slick-slider {
        margin-bottom: 0 !important;
      }
    }
  }

  .heading {
    span {
      display: block;
    }

    .headline {
      @include headline-accent-3($white);
      font-size: 37px;
    }

    .subheadline {
      @include bold-headline-2;
    }
  }

  .header {
    .cta-wrapper {
      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
      }
    }
  }

  .prev,
  .slick-prev,
  .next,
  .slick-next {
    @include prev_next_buttons();
    @include break($tablet, true) {
      display: none !important;
    }

    &::before {
      content: "";
    }
  }

  .prev,
  .slick-prev {
    @include prev_button(0, white, false);
  }

  .next,
  .slick-next {
    @include next_button(0, white, false);
  }

  .slide {
    padding: 0 45px;

    @include break($mobile) {
      text-align: center;
      padding: 0 5px;
    }

    .panels {
      display: flex;
      width: 95%;
      margin: 0 auto;

      @include break($mobile) {
        flex-direction: column;
      }
    }

    .photo {
      display: block;
      height: 300px;
      background-size: cover;
      background-position: center;

      @include break($tablet) {
        min-height: 310px;
        height: auto;
        flex: 0 0 50%;
      }

      &[href*="ultimate-donut-milkshake"] {
        background-position: top;
      }

      &.video-player {
        position: relative;
        background-color: transparent;

        iframe {
          @include break($mobile) {
            display: none;
          }

          @media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
            display: none;
          }
        }

        img {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 30%;

          @include break($tablet) {
            display: none;
          }

          @media only screen and (max-device-width: $desktop) {
            display: block !important;
          }
        }

        span.has-video {
          position: absolute;
          top: 7px;
          right: 7px;
          width: 25px;
          height: 25px;
          background: url(../images/icons/play-button.png) no-repeat 0 2px;
          background-size: 23px;

          @include break($tablet) {
            display: none;
          }

          @media only screen and (max-device-width: $desktop) {
            display: block !important;
          }
        }

        .photo {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 310px;
          z-index: -1;

          @include break($mobile) {
            height: 100%;
          }
        }
      }

      .video-hit {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 310px;
      }
    }

    .details {
      $padding: 26px;
      $padding-mobile: 14px;
      padding: $padding;
      background-color: white;
      flex-basis: 50%;
      position: relative;

      body#campaign & {
        background-color: $yellow;
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      @include break($mobile) {
        height: 300px;
        min-height: 300px;
        padding: $padding-mobile;
      }

      .headline {
        line-height: 0.9;

        @include bold-headline-6;

        @include break($mobile) {
          font-size: 27px;
        }
      }

      .description {
        font-family: $serif;
        font-size: 1.4vw;
        line-height: 1.2;
        margin-top: 10px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 2;

        @include break($tablet) {
          font-size: 1.7vw;
        }

        @include break($desktop) {
          font-size: 18px;
        }

        @include break($mobile) {
          font-size: 17px;
        }
      }

      .cta {
        @include cta-no-border($black, $white, $yellow, $black, 160px);
        align-self: flex-start;

        body#home & {
          @include cta-no-border($black, $white, $white, $black, 160px);
        }

        @include break($mobile) {
          align-self: center;
        }
      }
    }
  }

  .cooking-tools {
    display: none;
    position: relative;
    width: 100%;
    height: 240px;
    background: url("../images/rolling-pin.png") no-repeat center;
    top: -30px;

    body#home.not-mobile & {
      display: block;
    }
  }
}
