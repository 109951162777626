.about-us {
  .about-us-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;

    p {
      @include paragraph-medium;
      text-align: center;
      max-width: 675px;
      width: 100%;
      padding-bottom: 50px;
    }

    .image {
      max-width: 420px;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .seal {
    padding: 60px 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    .headline {
      @include headline-accent-3($yellow-ada);
      position: relative;
      z-index: 0;
    }

    .subheadline {
      @include bold-headline-1;
      margin-bottom: 25px;
      position: relative;
      z-index: -1;
    }

    .content {
      max-width: 960px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      hr {
        width: 100%;
        margin-top: 40px;
      }

      .seal-description,
      .seal-look-up {
        @include paragraph-medium;
        width: 100%;
        text-align: center;
      }

      .seal-description {
        max-width: 650px;
      }

      .image {
        width: 100%;
        max-width: 375px;

        .seal-logo {
          width: 100%;
          margin-top: 40px;
        }
      }

      .seal-look-up {
        max-width: 600px;
        margin-top: 30px;
      }
    }

    .trademark {
      color: #000;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 600px;
      margin-top: 40px;

      img {
        min-width: 60px;
        width: 60px;
        margin-right: 10px;
      }

      p {
        text-align: left;
        margin: 0;
        font-size: 14px;
        color: $black;
        font-style: normal;
        line-height: 18px;
      }
    }
  }

  .look-for-seal-container {
    background-color: $gray_background;
    padding-bottom: 30px;
  }

  .chevron {
    position: relative;
    background-color: $gray_background;
  }

  .why-california {
    background-color: $yellow;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;

    .subheadline {
      @include bold-headline-2;
      font-size: 110px;
    }

    .description {
      font-family: "hoefler";
      color: $black_cmab;
      line-height: 1.42;
      letter-spacing: -0.36px;
      font-size: 2.4em;
      width: 100%;
      max-width: 1080px;
      text-align: center;
      padding: 15px;
      margin-top: 40px;
    }

    a {
      @include cta-no-border($black, $white, $white, $black, auto);
      margin-top: 30px;
    }

    img {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  .mission {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px;

    .headline {
      @include headline-accent-3($yellow-ada);
      padding-bottom: 5px;
    }

    .subheadline {
      @include bold-headline-2;
    }

    .container {
      max-width: 896px;
      width: 100%;

      .image {
        background: url(../images/about-us/bg-line.png) no-repeat center;
        margin-top: 30px;

        > .date {
          display: table;
          margin: 0 auto;
          padding: 0 15px;
          border-left: 1px solid $olive;
          border-right: 1px solid $olive;
          background-color: $white;
          @include paragraph-em-small;
        }
      }

      p {
        @include paragraph-em;
        border-bottom: 1px solid $olive;
        margin: 30px 0;
        padding-bottom: 30px;
        text-align: center;
      }
    }
  }

  .next-up {
    .image {
      max-width: 1080px;
      width: 100%;
      @include bp(mediumWidth) {
        width: 137%;
        position: relative;
        top: 11vw;
      }
    }
  }
}

@include bp(mediumWidth) {
  .about-us {
    .about-us-content {
      padding-top: 8vw;

      p {
        width: 80vw;
        font-size: 4.8vw;
        line-height: 6.3vw;
      }

      .image {
        max-width: 67vw;
      }
    }

    .seal {
      padding: 16vw 0;

      .headline {
        font-size: 4.6vw;
      }

      .subheadline {
        font-size: 11.5vw;
        margin-bottom: 3vw;
      }

      .content {
        width: 80vw;

        .seal-description {
          font-size: 4.8vw;
          line-height: 6.3vw;
        }

        .image {
          max-width: 51vw;
        }

        .seal-look-up {
          font-size: 4.8vw;
          line-height: 6.3vw;
        }
      }

      .trademark {
        width: 80vw;

        img {
          width: 18vw;
        }

        p {
          font-size: 3vw;
          line-height: 4vw;
        }
      }
    }

    .look-for-seal-container {
      padding-bottom: 9.3vw;
    }

    .why-california {
      .subheadline {
        font-size: 11.5vw;
      }

      .description {
        font-size: 4.8vw;
        line-height: 6.3vw;
        width: 80vw;
        margin-top: 6vw;
      }

      a {
        height: 11.3vw;
        font-size: 5.1vw;
        line-height: 10vw;
      }

      img {
        width: 40vw;
      }
    }

    .mission {
      padding: 18.7vw 10.7vw;

      .headline {
        font-size: 4.6vw;
      }

      .subheadline {
        font-size: 11.5vw;
      }

      .container {
        .image {
          height: 2vw;
          margin-top: 6vw;

          > .date {
            font-size: 2.8vw;
            height: inherit;
            line-height: 3vw;
            padding: 0 4vw;
          }
        }

        p {
          font-size: 4.8vw;
          line-height: 6.2vw;
          padding-bottom: 6vw;
          margin: 6vw 0;
        }
      }
    }
  }
}
