.foodservice {
  @mixin gist77upper() {
    font: 77px/1 "gistrough";
    text-transform: uppercase;
  }

  @mixin din22-din60upper() {
    > span {
      display: block;
    }

    > span:first-of-type {
      font: 22px/1 "dinbold";
      margin-bottom: 8px;

      @include break($mobile, true) {
        font-size: 15px;
      }
    }

    > span + span {
      font: 60px/1 "dinbold";
      text-transform: uppercase;

      @include break($mobile, true) {
        font-size: 29px;
      }
    }
  }

  @mixin din60upper-din22() {
    > span:first-of-type {
      font: 60px/1 "dinbold";
      margin-bottom: 8px;
      text-transform: uppercase;

      @include break($mobile, true) {
        font-size: 29px;
      }
    }

    > span + span {
      font: 22px/1 "dinbold";
      text-transform: none;

      @include break($mobile, true) {
        font-size: 15px;
      }
    }
  }

  @mixin gist86upper-din35upper() {
    > span {
      display: block;
      text-transform: uppercase;
    }

    > span:first-of-type {
      color: $yellow;
      font: 72px/1 "gistrough";
      margin-bottom: 8px;
      text-transform: uppercase;

      @include break($mobile, true) {
        font-size: 25px;
      }
    }

    > span + span {
      font: 35px/1 "dinbold";

      @include break($mobile, true) {
        font-size: 15px;
      }
    }
  }

  @mixin din35upper-gist86upper() {
    > span {
      display: block;
      text-transform: uppercase;
    }

    > span:first-of-type {
      font: 35px/1 "dinbold";
      margin-bottom: 8px;

      @include break($mobile, true) {
        font-size: 15px;
      }
    }

    > span + span {
      color: $white;
      font: 72px/1 "gistrough";

      @include break($mobile, true) {
        font-size: 25px;
      }
    }
  }

  @mixin din22upper-gist50 {
    > span {
      display: block;
    }

    > span:first-of-type {
      font: 22px/1 "dinbold";
      margin-bottom: 8px;

      @include break($mobile, true) {
        font-size: 15px;
      }
    }

    > span + span {
      font: 50px/1 "gistrough";
      text-transform: uppercase;

      @include break($mobile, true) {
        font-size: 31px;
      }
    }
  }

  @mixin gist50-din22upper {
    > span {
      display: block;
    }

    > span:first-of-type {
      font: 50px/1 "gistrough";
      margin-bottom: 8px;
      text-transform: uppercase;

      @include break($mobile, true) {
        font-size: 31px;
      }
    }

    > span + span {
      font: 22px/1 "dinbold";

      @include break($mobile, true) {
        font-size: 15px;
      }
    }
  }

  @mixin din30() {
    font: 30px/1.25 "dinbold";

    @include break($mobile, true) {
      font-size: 19px;
    }
  }

  @mixin din30upper() {
    font: 30px/1.25 "dinbold";
    text-transform: uppercase;

    @include break($mobile, true) {
      font-size: 19px;
    }
  }

  @mixin din26upper() {
    font: 26px/1.25 "dinbold";
    text-transform: uppercase;

    @include break($mobile, true) {
      font-size: 13px;
    }
  }

  @mixin din22() {
    font: 22px/1.25 "dinbold";

    @include break($mobile, true) {
      font-size: 15px;
    }
  }

  @mixin din22upper() {
    font: 22px/1.25 "dinbold";
    text-transform: uppercase;

    @include break($mobile, true) {
      font-size: 15px;
    }
  }

  @mixin din20 {
    font: 20px/1.5 "dinbold";

    @include break($mobile, true) {
      font-size: 14px;
    }
  }

  @mixin din18 {
    font: 18px/1.5 "dinbold";

    @include break($mobile, true) {
      font-size: 14px;
    }
  }

  @mixin hoef24 {
    font: 24px/1.5 "hoefler";

    @include break($mobile, true) {
      font-size: 17px;
      line-height: 22px;
    }
  }

  @mixin hoef18 {
    font: 18px/1.66 "hoefler";

    @include break($mobile, true) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .dairy-resources,
  &.real-california {
    em {
      font-style: italic;
    }

    .headline-content {
      @include gist77upper;
      color: #fff;
      margin: 0 0 70px;

      @include break($tablet, true) {
        text-align: center;
        font: 9.5vw/9.5vw "gistrough";
        padding: 0 20px;
      }

      @include break($mobile, true) {
        margin-bottom: 40px;
      }
    }

    .headline-subpage {
      @include gist86upper-din35upper;
      margin-top: 130px;
      text-align: center;

      @include break($mobile, true) {
        margin: 30px auto 0;
        max-width: 84%;
        text-align: center;
      }

      &.alt {
        @include din35upper-gist86upper;
      }
    }

    .subheadline-1 {
      @include din22-din60upper;
      color: $yellow;
      margin-bottom: 40px;

      @include break($mobile, true) {
        margin-bottom: 30px;
      }

      &.alt {
        @include din60upper-din22;
      }
    }

    .subheadline-2 {
      @include gist50-din22upper;
      color: $yellow;
      margin-bottom: 40px;
      text-transform: uppercase;

      @include break($mobile, true) {
        margin-bottom: 30px;
      }

      &.alt {
        @include din22upper-gist50;
      }
    }

    .accordion-toggle {
      @include din22upper;
      color: $yellow;
      cursor: pointer;
      margin-bottom: 30px;
      padding-right: 38px;
      position: relative;

      @include break($mobile, true) {
        font-size: 15px;
        padding-right: 24px;
      }

      .expand {
        background: url("../images/icon-plus-close.png") 0 0 no-repeat
          transparent;
        color: $white;
        height: 28px;
        position: absolute;
        right: 0;
        text-indent: -9999px;
        top: 0;
        transform: rotate(0deg);
        transition: all 100ms;
        width: 28px;

        @include break($mobile, true) {
          background-size: 14px 14px;
          height: 14px;
          width: 14px;
        }
      }
    }

    p {
      @include hoef24;
      color: $white;
      margin-bottom: 40px;

      @include break($mobile, true) {
        margin-bottom: 25px;
      }
    }

    a {
      text-decoration: none;

      &.download {
        @include link-download;
        display: inline-block;
        font: 22px/1.25 "dinbold";
        text-transform: uppercase;

        @include break($mobile, true) {
          font-size: 13px;
        }
      }
    }

    p.download-link-container {
      text-align: right;
      margin-bottom: 5px !important;
      @include break($mobile, true) {
        text-align: center;
        margin-bottom: 25px !important;
      }

      a {
        &.download {
          font: 18px/1.25 "dinbold";
          @include break($mobile, true) {
            font: 14px/1.25 "dinbold";
          }
        }
      }
    }

    ul {
      &.list-simple {
        list-style: disc;
        margin-bottom: 70px;
        padding-left: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          @include hoef18;
          line-height: 1.5;
          margin-bottom: 30px;

          @include break($mobile, true) {
            font-size: 14px;
          }
        }
      }

      &.list-border-table,
      &.list-image-table,
      &.module-list-side-by-side,
      &.module-list-beside-image,
      &.list-stacked-text {
        li {
          @include din22upper;
          color: $yellow_inactive;

          @include break($mobile, true) {
            font-size: 15px;
          }

          a:not(.download) {
            color: $yellow_inactive;
          }

          > span {
            display: block;

            &:nth-of-type(2) {
              margin-top: 24px;

              @include break($mobile, true) {
                margin-top: 12px;
              }
            }
          }
        }
      }
    }

    .list-checklist {
      li {
        @include hoef18;
        background: url("../images/icon-checkbox.png") 0 6px no-repeat;
        margin-bottom: 30px;
        padding-left: 40px;

        @include break($mobile, true) {
          background-size: auto 17px;
          padding-left: 30px;
        }
      }
    }

    .list-border-table {
      display: flex;
      flex-wrap: wrap;

      &.cheese-links {
        li {
          width: 33.33%;

          @include break($mobile, true) {
            width: 50%;
          }

          &:nth-of-type(3) {
            border-right: 0;
          }
        }
      }

      &.baking-links {
        li {
          width: 25%;

          @include break($mobile, true) {
            width: 50%;
          }

          &:nth-of-type(4) {
            border-right: 0;
          }
        }
      }

      li {
        border-right: 1px solid $white;
        display: inline-block;
        margin-bottom: 80px;
        padding: 50px 60px;
        text-align: center;
        text-transform: uppercase;

        @include break($mobile, true) {
          margin-bottom: 40px;
          padding: 10px;
          width: 50%;
        }

        &:last-of-type {
          border: 0;
        }

        &:nth-of-type(even) {
          @include break($mobile, true) {
            border: 0;
          }
        }
      }
    }

    .list-image-table {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      text-align: left;

      li {
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        width: 25%;

        @include break($mobile, true) {
          height: auto;
          width: 50%;
        }

        > a {
          display: inline-block;
        }
      }

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }

      span {
        display: block;
        padding: 0 2%;
      }
    }

    .footernav {
      .subheadline-2 {
        text-align: center;

        > span {
          color: $black_cmab;

          & + span {
            color: $white;
          }
        }

        &.alt {
          > span {
            color: $white;

            & + span {
              color: $black_cmab;
            }
          }
        }
      }

      ul.list-border-table,
      ul.list-image-table {
        li {
          a {
            color: $black;
          }

          &.active {
            a {
              color: $white;
            }
          }
        }
      }
    }
  }

  .dairy-resources {
    background-color: $gray_section_bg;
    color: #fff;
    margin: 0 auto;
    padding: 91px 0 0;
    text-align: center;
    width: 100%;

    @include break($tablet, true) {
      margin: 81px auto;
    }

    @include break($mobile, true) {
      margin: 0 auto;
      padding-top: 40px;
    }

    > section {
      border-top: 1px solid $gray_section_border;
      padding-bottom: 80px;

      @include break($mobile, true) {
        padding-bottom: 40px;
      }

      &:nth-of-type(odd) {
        background-color: $gray_section_bg;
      }

      &:nth-of-type(even) {
        background-color: $gray_section_bg_dark;
      }

      &:last-of-type {
        padding-bottom: 150px;

        @include break($mobile, true) {
          padding-bottom: 120px;
        }
      }
    }

    section[data-hash="dairy-trends"] {
      padding-bottom: 30px;

      .description {
        text-align: left;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cta-wrapper {
          .cta {
            @include cta-no-border($yellow, $black, $black, $yellow, 239px);
            font-size: 30px;

            @include bp(mediumWidth) {
              font-size: 30px;
            }
          }
        }

        .heading {
          text-align: left;

          .subheadline {
            color: $yellow;
            font-size: 100px;
            font-family: gothic, sans-serif;
            text-transform: uppercase;
            line-height: 0.9;
          }
        }
      }

      @include break($mobile) {
        padding-top: 30px;
        .header {
          padding: 0 10px;
          flex-direction: column;
          align-items: center;

          .cta-wrapper {
            align-self: center;
            margin: 3vw 0 6vw 0;
            width: 100%;

            .cta {
              width: 100%;
            }
          }

          .heading {
            text-align: center;

            .headline {
            }

            .subheadline {
              font-size: 46px;
            }
          }
        }
      }

      .suggestion {
        text-align: left;
        background-color: $yellow;
        color: $black;
        max-width: 350px;

        .recipe-box {
          min-height: 260px;
        }

        .title {
          text-transform: uppercase;
          font-family: gothic, sans-serif;
          font-size: 28px;
        }

        .subtitle {
          font-family: hoefler, serif;
          font-size: 16px;
        }

        .cta {
          @include cta-no-border($black, $white, $white, $black, 160px);
          font-size: 20px;
        }
      }
    }

    .section-container {
      margin: 0 auto;
      max-width: 960px;
      width: 84%;
      @include break($mobile, true) {
        max-width: 100%;
      }
    }

    .intro {
      background: transparent;
    }

    .module-list-side-by-side {
      display: flex;

      @include break($mobile, true) {
        flex-direction: column;
      }

      > li {
        flex-grow: 1;
        position: relative;

        @include break($mobile, true) {
          margin-bottom: 40px;
        }

        &:last-of-type {
          @include break($mobile, true) {
            margin-bottom: 0;
          }
        }
      }

      img {
        display: block;
        margin: 0 auto;

        @include break($mobile, true) {
          margin-bottom: 0;
          max-width: 100px;
        }
      }

      img + span {
        margin-top: 40px;

        @include break($mobile, true) {
          margin-top: 0;
        }
      }

      span + span,
      a + span {
        margin-top: 20px;

        @include break($mobile, true) {
          margin-top: 10px;
        }
      }
    }

    .module-list-beside-image {
      display: flex;
      margin: 0 auto;
      text-align: left;

      @include break($mobile, true) {
        flex-direction: column;
        text-align: center;
      }

      > div:first-of-type {
        order: 1;
        margin-right: 5%;
        width: 45%;

        @include break($mobile, true) {
          margin-bottom: 40px;
          width: 100%;
        }
      }

      > div:last-of-type {
        margin: 0;
        order: 2;
        width: 50%;

        @include break($mobile, true) {
          width: 100%;
        }
      }

      &.reverse {
        > div:first-of-type {
          order: 2;
          margin: 0;
          width: 50%;

          @include break($mobile, true) {
            margin-bottom: 40px;
            order: 1;
            width: 100%;
          }
        }

        > div:last-of-type {
          margin-right: 15%;
          order: 1;
          width: 35%;

          @include break($mobile, true) {
            order: 2;
            width: 100%;
          }
        }
      }

      img {
        border: 0;
        border-radius: 50%;
        height: auto;
        width: 100%;

        @include break($mobile, true) {
          height: auto;
          width: 100%;
        }
      }
    }

    .list-stacked-text {
      margin-bottom: 40px;

      li {
        display: block;
        margin-bottom: 30px;

        @include break($mobile, true) {
          margin-bottom: 25px;
        }
      }
    }

    .divider {
      border-top: 1px solid #818181;
      border-bottom: 1px solid #494949;
    }
  }

  &.real-california {
    .page-heading {
      background-color: $black_cmab;
      height: 660px;
      position: relative;
      z-index: 1;

      @include break($mobile, true) {
        height: 210px;
      }

      .page-heading-image {
        background-size: cover;
        height: 100%;
        left: 0;
        opacity: 0.4;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }

      .nav-top {
        @include break($mobile, true) {
          margin-top: 0;
        }
      }
    }

    .nav-top {
      ul:not(.nav-global) {
        left: auto;
        margin: 0;
        position: relative;
      }
    }

    h1 {
      &.headline-content {
        text-align: center;
        text-transform: uppercase;

        > span {
          color: $yellow;
          display: block;
          font: 35px "dinbold";

          @include break($mobile, true) {
            font-size: 15px;
          }

          &.upper {
            color: $white;
            font: 72px "gistrough";

            @include break($mobile, true) {
              font-size: 25px;
            }
          }
        }
      }
    }

    h2 {
      color: $yellow;
      font: 48px/1 "gistrough";
      margin-bottom: 30px;
      text-transform: uppercase;

      @include break($mobile, true) {
        font-size: 31px;
        margin-bottom: 20px;
      }
    }

    h3 {
      @include din30upper;
      color: $yellow;
      margin-bottom: 10px;
    }

    h4 {
      @include din22;
      color: $yellow;
      margin-bottom: 40px;

      @include break($mobile, true) {
        font-size: 15px;
      }
    }

    h5 {
      @include din30;
      color: $yellow;
      margin-bottom: 10px;
    }

    .topic-list-section-heading {
      color: $blue_link;
      margin-bottom: 20px;

      @include break($mobile, true) {
        font-size: 19px;
      }
    }

    .component-accordion {
      > div {
        border-top: 1px solid $gray_border_accordion;
        height: 80px;
        overflow: hidden;
        padding: 30px;
        position: relative;
        transition: all 100ms;

        &.expanded {
          background-color: $gray_bg_accordion;
          height: auto;

          .expand {
            transform: rotate(45deg);
          }
        }
      }

      p {
        margin-bottom: 30px;
      }
    }

    .bg-white {
      background-color: $white;
    }

    .subnav {
      bottom: 0;
      left: 50%;
      margin: 0 auto;
      max-width: 960px;
      padding: 0 60px;
      position: absolute;
      transform: translate(-50%, -10px);
      width: 100%;

      @include break($mobile, true) {
        bottom: auto;
        height: 30px;
        max-width: 100%;
        padding: 0;
        top: 165px;
        transform: translate(-50%, 0);
        width: 84%;
      }

      &.open {
        .expand {
          transform: rotateX(180deg);
        }

        ul {
          @include break($mobile, true) {
            background: $white;
            height: initial;
            overflow: visible;
          }
        }
      }

      i {
        background: url("../images/subnav-arrows.png") 0 0 no-repeat;
        cursor: pointer;
        display: block;
        height: 40px;
        left: 0;
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;

        @include break($mobile, true) {
          display: none;
        }

        &:nth-of-type(2) {
          background-position: -40px 0;
          left: auto;
          right: 0;
        }
      }

      .scroller {
        overflow: hidden;
        position: relative;
        width: 100%;

        @include break($mobile, true) {
          height: 30px;
          left: 0;
          overflow: visible;
          top: 0;
          position: absolute;
          text-transform: uppercase;
        }
      }

      .expand {
        display: none;
        transition: all 200ms;

        @include break($mobile, true) {
          background: url("../images/icon-dropdown.png") 94% 50% no-repeat;
          background-size: 11px 8px;
          display: block;
          height: 30px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 10;
        }
      }

      $subnavScrollerUnit: 280px;

      ul {
        display: flex;
        flex-wrap: nowrap;
        //left: -$subnavScrollerUnit;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        //transform: translateX($subnavScrollerUnit);
        //width: 1680px;
        white-space: nowrap;

        @include break($mobile, true) {
          border: 2px solid $yellow;
          height: 30px;
          left: initial;
          overflow: hidden;
          flex-direction: column;
          margin: 0 auto;
          transform: none;
          width: 100%;
        }

        &.reverse {
          //transform: translateX(-$subnavScrollerUnit);

          @include break($mobile, true) {
            transform: none;
          }
        }

        &.done {
          transform: none;
          transition: all 400ms;
        }
      }

      li {
        border-right: 1px solid $gray_bprder_subnav;
        font: 26px/1.25 "dinbold";
        flex-grow: 1;
        height: 100px;
        order: 3;
        padding: 20px;
        width: auto;
        //width: $subnavScrollerUnit;

        @include break($mobile, true) {
          font-size: 13px;
          height: auto;
          padding: 0 20px;
          width: auto;
        }

        &:last-of-type {
          @include break($mobile, true) {
            margin-bottom: 10px;
          }
        }

        &.active {
          color: $yellow;

          @include break($mobile, true) {
            order: 1;
          }

          a {
            color: $yellow;
          }
        }
      }

      a {
        color: $white;
        text-decoration: none;

        @include break($mobile, true) {
          color: $black_cmab;
          display: block;
          line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span {
        display: block;
        text-align: center;

        @include break($mobile, true) {
          text-align: left;
        }
      }
    }

    .page-content {
      background-color: $gray_section_bg;
      color: $white;
      margin: 0 auto;
      width: 100%;

      @include break($mobile, true) {
        padding: 0;
      }

      section {
        padding: 60px 0;

        @include break($mobile, true) {
          padding: 50px 0;
        }

        &:nth-last-of-type(2) {
          padding-bottom: 150px;

          @include break($mobile, true) {
            padding-bottom: 40px;
          }
        }

        & + section {
          padding-top: 0;
        }

        &.padding-none {
          padding: 0;
        }

        > div {
          margin: 0 auto;
          max-width: 960px;

          @include break($mobile, true) {
            max-width: 100%;
            padding: 0 8%;
          }

          &.carousel-wrapper-mobile {
            @include break($mobile, true) {
              overflow-x: scroll;
            }
          }

          &.full-width {
            max-width: none;

            > * {
              margin: 0 auto;
              max-width: 960px;

              @include break($mobile, true) {
                max-width: 100%;
              }
            }
          }

          > img {
            margin-bottom: 60px;
            max-width: 100%;
          }

          > p + img {
            margin-top: 50px;
          }
        }
      }

      p {
        @include hoef18;
        margin-bottom: 20px;

        & + div {
          margin-top: 70px;

          @include break($mobile, true) {
            margin-top: 40px;
          }
        }

        & + h3 {
          margin-top: 50px;
        }

        & + h4 {
          margin-top: 40px;
        }

        & + h5 {
          margin-top: 40px;
        }
      }

      .table-simple {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        width: 100%;

        &.cheese-links {
          > div {
            width: 33%;
          }
        }

        &.baking-links {
          > div {
            width: 25%;
          }
        }

        &.cheese-links,
        &.baking-links {
          > div {
            @include break($mobile, true) {
              width: 50%;
            }
          }
        }

        > div {
          @include hoef18;
          border: 1px solid $gray_border_table_td;
          flex-grow: 1;
          padding: 10px;
          width: 33%;

          @include break($mobile, true) {
            width: 50%;
          }
        }
      }

      .topic-list-title {
        display: flex;
        position: relative;
        text-align: center;

        @include break($mobile, true) {
          display: inline-flex;
          left: 50%;
          margin-left: -70px;
          padding-right: 140px;
        }

        span {
          display: block;
        }

        li {
          @include din22upper;
          color: $blue_link-inactive;
          cursor: pointer;
          flex-grow: 1;
          padding: 40px 0;
          position: relative;
          text-align: center;
          width: 192px;

          @include break($mobile, true) {
            width: 140px;
          }

          &.active {
            color: $blue_link;

            &::after {
              border-bottom: 20px solid $white;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              bottom: 0;
              color: $blue_link;
              content: "";
              height: 0;
              left: 50%;
              position: absolute;
              transform: translateX(-50%);
              width: 0;
            }
          }
        }
      }

      .topic-list-content {
        color: $black_cmab;
        padding: 60px;

        @include break($mobile, true) {
          padding: 8%;
        }

        > div {
          display: none;

          &.show {
            display: block;
          }
        }

        p {
          color: $black_cmab;
        }
      }

      .callout {
        background-color: $yellow;
        color: $black_cmab;
        display: flex;
        margin: 0 auto 40px;
        padding: 50px 40px;
        width: 960px;

        @include break($mobile, true) {
          flex-direction: column;
          max-width: 100%;
        }

        & + h3 {
          margin-top: 80px;
        }

        > div {
          flex-grow: 1;
          width: 50%;

          @include break($mobile, true) {
            width: 100%;
          }

          &:last-of-type {
            text-align: center;
          }
        }

        p {
          color: $black_cmab;
        }

        img {
          border: 0;
          border-radius: 50%;
          height: 310px;
          width: 310px;

          @include break($mobile, true) {
            height: auto;
            max-width: 100%;
          }
        }

        .subheadline-2 {
          > span {
            &:first-child {
              color: $white;
            }

            & + span {
              color: $black_cmab;
            }
          }
        }
      }

      .container-chevron {
        @include add_chevron();
        background-color: $yellow;
        padding-bottom: 180px;
        padding-top: 0;

        .subheadline {
          text-align: center;

          > span {
            &:first-child {
              color: $white;
            }

            &.upper {
              color: $black_cmab;
            }
          }
        }
      }
    }
  }

  .our-dairy-partners {
    background-color: $yellow;

    .top-heading {
      @include headline-accent-3($white);
      @include break($tablet) {
        text-align: left;
      }
    }

    .heading {
      @include bold-headline-3;
      @include break($mobile, true) {
        font-size: 70px;
      }
      @include break($tablet) {
        text-align: left;
      }
    }

    .img-mobile {
      height: 144px;
      width: auto;
    }

    .img-desktop {
      max-height: 350px;
    }

    .sub-heading {
      @include subheadline-2;
      color: $white;
      line-height: 25px;
      @include break($tablet) {
        text-align: left;
      }
    }

    .description {
      @include paragraph-tiny;
      @include break($tablet) {
        text-align: left;
      }
      @include break($desktop) {
        max-width: 350px;
      }
    }

    .cta-wrapper {
      @include break($tablet) {
        text-align: left;
      }

      .cta {
        @include cta-no-border($black, $white, $white, $black, 160px);
        font-size: 20px;
      }
    }
  }
}
