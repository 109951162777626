/* Main Navigation Panel */
$nav_width_desktop: 540px;

.nav-wrapper {
  background: black;
  height: 50px;
  margin: 0 auto;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: 600;

  @include break($tablet, true) {
    height: 40px;
  }

  .dimmer {
    opacity: 0;
    background-color: $black_cmab;
    display: none;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 8888;

    @include break($mobile) {
      background-color: $yellow;
    }
  }

  .global-nav {
    background: $black_cmab;
    border-left: 6px solid $yellow;
    color: $white;
    min-height: 100%;
    padding: 0;
    position: fixed;
    right: -($nav_width_desktop);
    top: 0;
    width: $nav_width_desktop;
    z-index: 9000;
    height: 100%;

    @include break($mobile) {
      border-left: 0;
      right: -90%;
      top: 0;
      width: 90%;
    }

    .spanish-link {
      position: absolute;
      z-index: 1;
      right: 20px;
      top: 18px;

      @include break($tablet, true) {
        top: 4vw;
        right: 40px;
      }
    }
  }

  .mute {
    display: none;

    .sounds-enabled & {
      background: url("../images/icons/sound-mute-sprite.png") 0 0 no-repeat
        transparent;
      cursor: pointer;
      height: 20px;
      left: -153px;
      position: relative;
      width: 30px;
      z-index: 9000;
      margin-top: 14px;

      &.muted {
        background-position: 0px -20px;
      }

      @include break($mobile) {
        display: none;
      }
    }

    &.open {
      right: 660px;
    }
  }

  .top-link {
    height: 50px;
    color: $yellow;
    position: absolute;
    padding: 12px 22px;
    font-size: 22px;
    text-transform: uppercase;

    &.foodservice {
      right: 181px;
    }

    &.spanish {
      display: flex;
      left: 0;

      a {
        margin: auto;
      }
    }

    @include break($tablet, true) {
      height: 40px;
      padding: 8px;
      top: 0 !important;

      a {
        font-size: 22px;
      }

      &.foodservice {
        right: 20vw;
      }

      &.spanish {
        padding: 0;
        right: 100px;
        left: auto;
      }
    }
  }

  .divider {
    width: 1px;
    height: 22px;
    border-left: 1px solid $yellow;
    position: absolute;
    right: 172px;
    margin-top: 13px;
    @include break($mobile) {
      display: none;
    }
    @include break($tablet) {
    }
  }

  // TODO-devsu: remove all references to mute
  .mute + .menu-toggle {
  }

  .search-toggle {
    background: url("../images/icons/nav-search.svg") 50% 50% no-repeat;
    background-size: 20px 20px;
    cursor: pointer;
    height: 50px;
    position: absolute;
    right: 57px;
    top: 0;
    width: 40px;

    @include break($tablet, true) {
      display: block;
      height: 40px;
      right: 40px;
    }

    &:hover {
      background-image: url("../images/icons/nav-search-white.svg");

      @include break($tablet, true) {
        background-image: url("../images/icons/nav-search.svg");
      }
    }
  }

  .menu-toggle {
    @include nav_link;
    background-color: black;
    color: $black_cmab;
    cursor: pointer;
    height: 50px;
    left: -57px;
    padding: 12px 0;
    position: absolute;
    transition: left 0.25s;
    text-align: center;
    width: 40px;

    &.open {
      background: $yellow;
      left: -100px;
      width: 100px;
      padding-right: 10px;

      @include break($tablet, true) {
        color: white;
        left: -40px;
        width: 40px;
      }
    }

    @include break($tablet, true) {
      font-size: 4.5vw;
      height: 40px;
      left: -40px;
      padding: 2vw 3vw;
      top: 0 !important;
      width: 40px;

      .hamburger-icon {
        height: 4.5vw;
        width: 3.5vw;
        margin-top: 1.2vw;
      }
      span {
        margin-left: 11vw;
      }
    }

    span {
      font-family: "gothic", "sans-serif";
      font-size: 22px;
      margin-left: 4px;

      &.x {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
      }
    }

    &.scrolled.open {
      background-color: $yellow;
      color: $white;
    }

    .hamburger-icon {
      background: url("../images/icons/nav-hamburger.svg") transparent no-repeat;
      background-size: 100% 100%;
      height: 15px;
      width: 17px;
      margin: 4px auto 0;
    }

    &:hover {
      .hamburger-icon {
        background-image: url("../images/icons/nav-hamburger-white.svg");

        @include break($tablet, true) {
          background-image: url("../images/icons/nav-hamburger.svg");
        }
      }
    }
  }

  .inner {
    background-color: $black_cmab;
    height: 100%;
    overflow-y: auto;
    padding: 30px 50px;
    position: relative;

    @include break($tablet, true) {
      height: 0;
      padding: 20px;
    }
  }

  .menus,
  .social-nav {
    padding: 20px 0;
  }

  .social-nav {
    border-top: 1px solid $gray_menu_border;
  }

  .headline {
    @include nav_headline;
    line-height: 13px;
    margin-bottom: -3px;
  }

  .subheadline {
    @include nav_subheadline;
  }

  .logo-title {
    //margin-top: 35px;
    background: url(../images/cmab-logo-small.gif) center left no-repeat;
    font: 34px $sans;
    padding-left: 55px;
    text-transform: uppercase;

    @include break($tablet, true) {
      background-size: auto 100%;
      font-size: 24px;
      padding-left: 40px;
    }

    & + .menus {
      @include break($tablet, true) {
        border-top: 0;
      }
    }
  }

  .menus {
    border-top: 1px solid $gray_menu_border;
    margin-top: 30px;
    padding: 31px 0;

    @include break($tablet, true) {
      margin-top: 15px;
      padding: 10px 0;
    }

    & + .menus {
      margin-top: 0;
    }

    .secondary-nav-container {
      background-color: $black_cmab;
      height: 100%;
      left: 100%;
      opacity: 1;
      padding: 0 50px 30px;
      position: fixed;
      top: 0;
      transition: left 0.5s;
      width: 100%;
      z-index: 1;

      @include break($mobile) {
        padding: 0 20px 20px;
      }

      &.open {
        display: block;
        left: calc(100% - #{$nav_width_desktop});
        border-left: 6px solid $yellow;

        @include break($mobile) {
          left: 10%;
        }
      }
    }
  }

  ul {
    li {
      @include upper;
      list-style: none;
      padding: 8px 0 12px;

      &.sidebar {
        padding: 9px 0;
      }

      &.go-back {
        @include upper;
        background: url("../images/arrow-close-nav.png") 0 50% no-repeat;
        border-bottom: 1px solid $gray_menu_border;
        cursor: pointer;
        font-family: "gothic", sans-serif;
        font-size: 22px;
        padding: 40px 0 40px 24px;

        @include break($tablet, true) {
          border: 0;
        }
      }
    }
  }

  a {
    @include upper;
    @include nav_link;
    margin: 15px auto;

    &:hover {
      color: $white;
    }

    &:not([href]) {
      color: $yellow !important;
      cursor: default;
    }

    &.has-submenu {
      background: url("../images/arrow-open-nav.png") 100% 50% no-repeat;
      cursor: pointer;
    }

    &.active {
      color: white;
    }

    &.yt {
      background: url("../images/icons/yt.png") 37% 60% no-repeat;

      @include break($tablet, true) {
        background-position: 54% 60%;
      }
    }
  }

  a,
  a:hover {
    @include no-underline;
  }

  a,
  img {
    display: block;
    margin: 0 auto;
  }

  .social-nav {
    overflow: visible;
  }

  .social-nav {
    margin-top: 15px;

    li {
      display: inline-block;
      margin-right: 12px;

      @include break($mobile) {
        display: inline-block;
        margin: 0 10px;
        text-align: center;
        width: auto;
      }
    }

    a {
      background: url(../images/social-nav-sprite-global.webp) 0 0 no-repeat;
      background-size: auto 57px;
      text-indent: -9999em;
      height: 26px;
      width: 12px;

      @include break($mobile) {
        display: block;
        margin: 0 auto;
      }

      &.twitter {
        background-position: -33px 0;
        width: 26px;
      }

      &.pinterest {
        background-position: -76px 0;
        width: 21px;
      }

      &.instagram {
        background-position: -113px 0;
        width: 23px;
      }

      &.youtube {
        background-position: -152px 0;
        width: 34px;
      }

      &.tiktok {
        background-position: -202px 0;
        width: 34px;
      }

      &:hover {
        background-position-y: -29px;

        @include break($mobile) {
          background-position-y: -35px;
        }
      }
    }
  }
}

.admin-bar {
  .mute {
    top: 32px;
  }

  .menu-toggle {
    top: -2px;
  }
}

.touch {
  .divider {
    display: none;
  }

  .foodservice-link {
    right: 100px;
    @include break($tablet, true) {
      right: 20vw;
    }
  }

  .menu-toggle {
    top: 0;
  }
}
