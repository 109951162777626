.touch {
  .farms-youtube-gallery {
    .yt-list-info {
      &:hover:not(.active) {
        .play-btn {
          display: none;
        }
      }
    }
  }
}

.farms-youtube-gallery {
  background: $yellow;
  padding: 80px 0 80px;

  .slick-prev,
  .slick-next {
    @include prev_next_buttons(36px);
    z-index: 0;

    &:before {
      content: none;
    }
  }

  .slick-prev {
    @include prev_button(0, white, "");
    left: -60px;
    @include bp(largeWidth) {
      left: 4px;
    }

    &.slick-disabled,
    &.slick-disabled:hover {
      cursor: default;
      opacity: 0.4;
    }
  }

  .slick-next {
    @include next_button(0, white, "");
    right: -66px;
    @include bp(largeWidth) {
      right: 6px;
    }

    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
    }
  }

  .main {
    left: 0;
    width: 960px;
    margin: 0 auto;
  }

  .header {
    h3 {
      display: inline-block;
      float: left;
      margin-right: 10px;
      padding-bottom: 15px;

      .headline {
        @include headline-accent-2($white);
        padding-left: 6px;
      }

      .subheadline {
        @include bold-headline-3;
        float: left;
        padding-left: 6px;
      }
    }

    .description {
      color: $black;
      font-family: $serif;
      font-size: 21px;
      line-height: 27px;
      float: left;
      font-style: italic;
      max-width: 300px;
      position: relative;
      top: 60px;
    }
  }

  .yt-video-wrapper {
    .player {
      position: relative;
      top: -6px;
    }
  }

  .yt-video-info {
    height: 30px;
    margin: 10px 0 30px 0;

    .description {
      color: $white;
      font-family: $script;
      font-size: 25px;
      line-height: 27px;
      float: left;
    }

    .yt-video-title {
      font-family: $sans;
      text-transform: uppercase;
      font-size: 24px;
      float: left;
      padding-left: 15px;
    }
  }

  .slide-wrapper-outer {
    margin: 0 auto;
    position: relative;
    width: 960px;
  }

  .slide-wrapper-inner {
    height: 194px;
    left: 0;
    top: 0;
  }

  .yt-list-info {
    cursor: pointer;
    display: inline-block;
    height: 205px;
    float: left;
    text-align: center;

    &.active {
      .yt-video-title {
        color: $yellow_dot_active;
      }
    }

    .play-btn {
      background: url("../images/video-play-button.png") no-repeat;
      cursor: pointer;
      height: 34px;
      display: none;
      left: 50%;
      margin: -17px 0 0 -26px;
      position: absolute;
      top: 50%;
      width: 52px;
      z-index: 100;
      @include bp(mediumWidth) {
        display: block;
      }
    }

    img {
      max-width: 100%;
    }

    &:hover:not(.active) {
      color: $white;

      .play-btn {
        display: inline-block;
      }

      @include bp(mediumWidth) {
        color: initial;
      }
    }

    .yt-video-image {
      width: 235px;
      height: 125px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      cursor: pointer;

      img {
        display: block;
        width: 245px;
        top: -22px;
        left: 0px;
        position: relative;
      }
    }

    .active {
      display: none;
      background: $black_cmab;
      opacity: 0.8;
      color: $white;
      font-size: 25px;
      font-family: $script;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 10;
      padding-top: 40px;
      padding-left: 0;
    }

    .yt-video-title {
      font-family: $sans;
      text-transform: uppercase;
      font-size: 24px;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .slide-controls {
    font-family: $sans;
    font-size: 24px;
    height: 28px;
    left: 72px;
    line-height: 28px;
    position: absolute;
    top: 0;
    text-transform: uppercase;
  }

  .video-library {
    position: relative;
    top: -25px;
    left: 75px;
    font-size: 20px;
    font-family: $sans;
    text-transform: uppercase;
  }

  .slick-slider {
    margin-bottom: 0;

    .slick-dots {
      li {
        width: 10px;

        button {
          &:before {
            color: $white;
            font-size: 10px;
            opacity: 1;
          }
        }

        &.slick-active button:before {
          color: $yellow_dot_active;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    padding: 13% 0 16%;

    .slick-prev,
    .slick-next {
      background-size: 100% 100%;
      height: 30px;
      top: 80px;
      width: 30px;

      &.slick-disabled,
      &.slick-disabled:hover {
        background-size: 100% 100%;
        height: 30px;
        top: 80px;
        width: 30px;
      }
    }

    .slick-prev {
      left: -36px;

      &:hover {
        left: -36px;
      }
    }

    .slick-next {
      right: -36px;

      &:hover {
        right: -36px;
      }
    }

    .main {
      width: 100%;
    }

    .header {
      text-align: center;

      h3 {
        display: block;
        float: none;
        margin: 0;

        .headline {
          padding-left: 0;
          text-align: center;
        }

        .subheadline {
          float: none;
          padding-left: 0;
          text-align: center;
        }
      }

      .description {
        display: none;
      }
    }
    .yt-video-wrapper {
      height: 120px;
      width: 210px;
    }
    .yt-video-info {
      display: none;
    }
    .slide-wrapper-outer {
      margin: 0 auto;
      width: 70%;
    }
    .slide-wrapper-inner {
      height: auto;
      width: 100%;
    }
    .slide {
      height: auto;
      width: 100%;
    }
    .yt-list-info {
      margin-right: 0;

      .active,
      .play-btn {
        display: inline-block !important;
      }

      .yt-video-image {
        height: auto;
        width: 100%;

        img {
          top: 0;
          width: 100%;
        }
      }

      .yt-video-title {
        font-size: 20px;
        padding-top: 2px;
        width: 100%;
      }
    }
    .slide-controls {
      display: none;
    }
  }

  .mobile-yes {
    .slide-wrapper-outer {
      width: 100%;
    }

    .yt-list-info {
      .yt-video-title,
      .yt-video-image {
        padding: 0 13px 10px;
      }
    }

    @include bp(mediumWidth) {
      .slide-wrapper-outer {
        width: 100%;
      }
      .yt-list-info .yt-video-title {
        text-align: left;
        font-size: 26px;
      }
    }
  }
}
