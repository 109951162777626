// English/Spanish buttons
.nav-wrapper .top-link.spanish form {
  margin: auto;
}

.nav-wrapper .top-link form button {
  text-transform: uppercase;
  color: #ffc320;
  font: 22px "gothic";
}

@media (max-width: 768px) {
  .nav-wrapper .top-link form button {
    font-size: 22px;
  }
}

// Landing Page
#breakfast-time-stories {
  $color: #065550;
  $inverted-color: white;

  .button {
    @include cta-no-border($yellow, $black, $black, $yellow, 160px);
  }

  .secondary.button {
    @include cta-no-border($white, $black, $black, $white, 250px);

    @include break($tablet) {
      width: 210px;
      font-size: 16px;
      margin: 0 10px;
    }

    @include break($desktop-lg) {
      font-size: 20px;
    }
  }

  .masthead {
    position: relative;
    overflow: hidden;
    margin-top: 40px;
    padding-bottom: 177.7777%;

    @include break($tablet) {
      margin-top: 50px;
      padding-bottom: 48.0208%;
    }

    .lockup-img {
      position: absolute;
      top: 15vw;
      left: 0;
      right: 0;
      margin: auto;
      width: 85vw;
      z-index: 90;

      @include break($tablet) {
        top: 5vw;
        left: 10vw;
        right: auto;
        width: 40vw;
      }
    }

    // Text blocks
    article {
      position: absolute;
      left: 0;
      right: 0;
      top: 60vw;
      margin: auto;

      @include break($tablet) {
        top: 25vw;
        left: 8vw;
        right: auto;
        width: 44vw;
      }

      p {
        z-index: 90;
        font-family: hoefler, serif;
        position: relative;
        line-height: 1.2;
        font-size: 14px;
        color: $inverted-color;
        font-weight: bold;
        text-align: center;

        @include break($tablet) {
          line-height: 1.5;
          font-size: 1.3vw;
        }
      }
    }

    .cta-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 125vw;
      margin: auto;
      z-index: 90;
      text-align: center;

      @include break($tablet) {
        top: 33vw;
        left: 8vw;
        right: auto;
        width: 44vw;
      }
    }

    // Transitions
    .slide {
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;

      animation-name: StoriesFadeInOut;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 18s;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:nth-of-type(1) {
        animation-delay: 12s;
      }

      &:nth-of-type(2) {
        animation-delay: 6s;
      }

      &:nth-of-type(3) {
        animation-delay: 0s;
      }
    }

    // to add more images, please refer to: http://css3.bradshawenterprises.com/cfimg/#cfimg3

    @keyframes StoriesFadeInOut {
      0% {
        opacity: 1;
      }
      27.77% {
        opacity: 1;
      }
      33.33% {
        opacity: 0;
      }
      94.44% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .arrow {
      width: unset;
      height: 20px;
      margin: 0 auto;
      position: absolute;
      top: 94%;
      left: 0;
      right: 0;

      @include break($tablet) {
        top: 90%;
      }
    }
  }

  .book-picker {
    padding-bottom: 40px;
    background-image: url("../images/books/landing-pages/background-all-stories.jpg");
    background-size: cover;
    position: relative;
    overflow: hidden;

    @include break($tablet) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .top-milk {
      position: absolute;
      top: -40px;
      left: -70px;
      width: 180px;

      @include break($tablet) {
        top: -50px;
        left: -80px;
        width: 300px;
      }

      @include break($desktop-wide) {
        top: -50px;
        left: -100px;
        width: 380px;
      }
    }

    .top-cereal {
      position: absolute;
      top: -90px;
      right: -110px;
      width: 220px;

      @include break($tablet) {
        top: -120px;
        right: -140px;
        width: 350px;
      }

      @include break($desktop-wide) {
        top: -150px;
        right: -170px;
        width: 500px;
      }
    }

    .header {
      text-align: center;
      font-family: gothic, sans-serif;
      font-size: 72px;
      text-transform: uppercase;
      line-height: 1;
      position: relative;
      z-index: 0;

      .picker-title {
        width: 250px;

        @include break($desktop) {
          width: 300px;
        }

        @include break($desktop-wide) {
          width: 550px;
        }
      }

      .main-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1500px;
        padding: 0 40px;
        margin: auto;
        min-height: 1px;

        .card {
          background: none;
          border: none;
          justify-content: space-between;

          .buttons {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: initial;
            margin-top: 20px;
            row-gap: 10px;
            text-align: center;

            @include break($tablet) {
              margin-top: 47px;
            }

            .button {
              min-width: 100%;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      .header {
        margin-top: 10vh;
      }

      .main-content {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
      }

      .card {
        min-height: initial;
        width: 100%;
        padding: 30px 0;

        &:nth-child(1) {
          padding-top: 0;
          margin-top: -10px;
        }
      }
    }
  }
}

// Animations
#book {
  $color: #065550;
  $inverted-color: white;

  // todo: there is an issue with the .outer-wrapper and smooth-scrollbar
  .outer-wrapper {
    overflow: hidden;
  }

  .smooth-scroller {
    height: 100vh;
  }

  .story-container-mobile {
    display: none;
  }

  img.bounce {
    padding-top: 1.8%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
  }

  .bounce {
    animation-name: bounce;
    animation-timing-function: ease;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  .story-container {
    position: relative;
    width: 100%;
    z-index: 0;
    overflow: hidden;

    &.front-matter {
      margin-top: 30px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .scroll-icon {
      position: absolute;
      margin: auto;
      bottom: 26%;
      left: 0;
      right: 0;
      width: 130px;

      img {
        position: relative;
        width: 100%;
        height: auto;

        &.bounce {
          width: 80%;
        }
      }
    }

    article {
      position: absolute;
      margin-left: -50px;
      margin-top: -50px;
    }

    article > p {
      z-index: 90;
      font-family: hoefler, serif;
      position: relative;
      line-height: 1.7;
      font-size: 2.3vw;
      color: $color;
      font-weight: bold;
    }

    /* Text blocks */
    .color-inverted > p {
      color: $inverted-color;
    }

    #front-matter-copy {
      margin-left: 20%;
      margin-top: 36%;
      text-align: center;

      p {
        font-size: 1.3vw;
      }

      &.es {
        p {
          font-size: 1.2vw;
        }
      }
    }

    #shelly-copy-0102 {
      margin-top: 25%;
      margin-left: 10%;
    }

    #shelly-copy-0102-ralph {
      margin-top: 0;
      margin-left: 10%;
    }

    .shelly-0304-copy.a {
      margin-left: 27%;
      margin-top: 61%;
    }

    .shelly-0304-copy.b {
      margin-top: 4%;
      margin-left: 27%;
    }

    #shelly-05-copy {
      margin-top: 0%;
      margin-left: 8%;
    }

    #shelly-06-copy {
      margin-top: 5%;
      margin-left: 10%;
      z-index: 90;
      -webkit-transform: translate3d(0, 0, 1px);
    }

    #shelly-07-copy {
      margin-top: 0;
      margin-left: 10%;
    }

    #shelly-08-copy {
      margin-top: 8%;
      margin-left: 21%;
    }

    #shelly-09-copy {
      margin-top: 18%;
      margin-left: 34%;
      z-index: 90;
      -webkit-transform: translate3d(0, 0, 1px);
    }

    #shelly-10-copy-a {
      margin-top: 0;
      margin-left: 10%;
      z-index: 90;
      -webkit-transform: translate3d(0, 0, 1px);
    }

    #shelly-10-copy-b {
      margin-top: 56.2%;
      margin-left: 26%;
      z-index: 90;
      -webkit-transform: translate3d(0, 0, 1px);
    }

    #shelly-011012-copy-a {
      margin-top: 10%;
      margin-left: 10%;
    }

    #shelly-011012-copy-b {
      margin-top: 63%;
      margin-left: 40%;
    }

    article.shelly-013014-copy.a {
      margin-top: 8%;
      margin-left: 15%;
    }

    article.shelly-013014-copy.b {
      margin-top: 55%;
      margin-left: 15%;
    }

    #shelly-015016-copy {
      margin-top: 10%;
      margin-left: 30%;
      width: 100%;

      .line-1 {
        margin-left: -4%;
      }

      .line-2 {
        margin-left: 8%;
      }
    }

    #shelly-017018-copy {
      margin-top: 10%;
      margin-left: 15%;
    }

    &#shelly-back-matter {
      .sb-ch-end-download {
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        position: absolute;
        width: 100%;

        img {
          position: relative;
          height: auto;
          width: 100%;
        }
      }
    }
  }

  #m-back-matter {
    .sb-ch-end-download-m {
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      position: absolute;
      width: 100%;

      img {
        position: relative;
        height: auto;
        width: 100%;
      }
    }
  }

  #shelly-tout-end {
    margin-top: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  /* Mobile */
  @media only screen and (orientation: portrait) and (max-device-width: 639px) {
    .story-container,
    .footer {
      display: none;
    }

    .nav-wrapper {
      position: fixed;
    }

    .row {
      margin-top: -15px;
    }

    .container-fluid {
      padding-top: 0 !important;
    }

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      30% {
        transform: translateY(-20px);
      }
      50% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(0);
      }
    }

    body {
      margin: 0;
      place-items: center;
    }

    #scroller {
      padding: 0;
    }

    .white_bg {
      background-color: white;
    }

    .story-container-mobile {
      display: block;
      position: relative;
      margin-top: 50px;

      #m-shelly-copy-0102-b {
        z-index: 100;
        position: absolute;
        margin-top: -16vh;
        width: 100%;

        p {
          font-family: hoefler, serif;
          position: relative;
          line-height: 5vh;
          font-size: calc(16px + 6 * ((100vw - 320px) / 680));
          margin: 0 auto;
          width: 70%;
          color: $color;
          font-weight: bold;
        }
      }

      .scroll-icon {
        position: absolute;
        margin: auto;
        top: 34%;
        left: 0;
        right: 0;
        width: 60px;

        img {
          position: relative;
          width: 100%;
          height: auto;
        }
      }
    }

    figure.item {
      display: block;
      position: relative;
      height: 100vh;
      margin-top: -1px;

      /* Heights */
      &.double-size {
        height: 200vh;
      }

      &.triple-size {
        height: 300vh;
      }

      &.custom_1950px {
        height: 101.5vh;
      }

      &.custom_4050px {
        height: 210.9vh;
      }

      &.custom_3420px {
        height: 178.125vh;
      }

      &.custom_4515px {
        height: 235.15625vh;
      }

      &.custom_2080px {
        height: 108.333333333vh;
      }

      &.custom_2192px {
        height: 114.0625vh;
      }

      img,
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      /* Text blocks */
      article {
        z-index: 90;
      }

      article > p {
        z-index: 90;
        font-family: hoefler, serif;
        position: relative;
        line-height: 5vh;
        font-size: calc(16px + 6 * ((100vw - 320px) / 680));
        top: 18vh;
        margin: 0 auto;
        width: 70%;
        color: $color;
        font-weight: bold;
      }

      .color-inverted > p {
        color: $inverted-color;
      }

      #m-front-matter-copy > p {
        line-height: 3vh;
        font-size: calc(12px + 6 * ((100vw - 320px) / 680));
        top: 24vh;
        margin-left: 7.5vh;
        text-align: center;
      }

      #m-shelly-copy-0102-a > p {
        top: 14vh;
      }

      #m-shelly-copy-0304 > p {
        top: 9vh;
      }

      #m-shelly-copy-05 > p {
        top: 10vh;
      }

      #m-shelly-copy-06 > p {
        top: 6vh;
      }

      #m-shelly-copy-07 > p {
        top: -1.5vh;
      }

      #m-shelly-copy-08 > p {
        top: 16vh;
      }

      #m-shelly-copy-09 > p {
        top: 26vh;
      }

      #m-shelly-copy-10-A > p {
        top: 6vh;
      }

      #m-shelly-copy-10-B > p {
        top: 128vh;
      }

      #m-shelly-copy-10-C > p {
        top: 131vh;
      }

      #m-shelly-copy-1314-a > p {
        top: 10vh;
      }

      #m-shelly-copy-1314-b {
        margin-top: 103vh;
      }

      #m-shelly-10-copy-a > p {
        top: 11vh;
      }

      #m-shelly-10-copy-b > p {
        top: 43vh;
      }

      #m-shelly-copy-1516 > p {
        top: 7vh;
      }

      #m-shelly-1718-copy > p {
        top: 7vh;
      }
    }
  }

  /* Order of layers */
  .z-index-1 {
    z-index: 1;
  }

  .z-index-2 {
    z-index: 2;
  }

  .z-index-3 {
    z-index: 3;
  }

  .z-index-4 {
    z-index: 4;
  }

  .z-index-5 {
    z-index: 5;
  }

  .z-index-6 {
    z-index: 6;
  }

  .z-index-7 {
    z-index: 7;
  }

  .z-index-8 {
    z-index: 8;
  }

  .z-index-9 {
    z-index: 9;
  }

  .z-index-10 {
    z-index: 10;
  }

  .z-index-11 {
    z-index: 11;
  }

  .z-index-12 {
    z-index: 12;
  }

  .z-index-13 {
    z-index: 13;
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  padding-top: 1.8%;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  position: relative;
  height: 5%;
  width: 5%;
}

@import "book-charlie";

.book-chevron {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  margin-top: -50px;
  position: absolute;
  text-align: center;
  top: 90%;
  width: 100%;
  z-index: 30;

  img {
    width: auto;
  }

  span {
    font-size: 4.8vw;

    @media (orientation: landscape) {
      font-size: 1.8em;
      top: 70%;
    }
  }
}

/*********** BURT ***********/

.sb-burt-container {
  display: none;

  .book-chevron {
    span {
      @media (min-width: 1024px) {
        font-size: 1.8em;
      }
    }
  }
}

@media (min-width: 1024px) {
  .sb-burt-container-m {
    display: none;
  }
  .sb-burt-container {
    display: block;
  }
}

.sb-burt-container,
.sb-burt-container-m {
  transform-style: preserve-3d;

  .sb-burt-rel {
    margin-top: -5%;

    @media (min-width: 1024px) {
      margin-top: -3%;
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.sb-burt-rel {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  img {
    width: 100%;
    display: block;
  }
}

.sb-burt-abs {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.burt-copy-img {
  display: none !important;
}

.sb-burt-chevron {
  span {
    color: black;
    @media (max-width: 1023px) {
      font-size: 18px;
    }
  }

  img {
    width: auto;
  }
}

.sb-burt-copy,
.sb-burt-copy-m {
  position: absolute;
  z-index: 20;
  // display: none;

  p {
    color: black;
    font-family: "Hoefler Text", serif;
    line-height: 1.4;
  }
}

.sb-burt-copy {
  p {
    font-size: 2vw;
  }
}

.sb-burt-copy-m {
  p {
    font-size: 3.7vw;
    letter-spacing: -0.015em;
  }
}

.burt-0102-text-1 {
  left: 3.6%;
  top: 10.2%;
  width: 45vw;
}

.burt-0102-text-2 {
  top: 56.6%;
  left: 59.7%;
  width: 30vw;
}

.burt-0102-m-text-1 {
  left: 6.4%;
  top: 3%;
  width: 86vw;

  p {
    color: white;
  }
}

.burt-0102-m-text-2 {
  top: 71.6%;
  left: 38.9%;
}

.burt-0304-text-1 {
  left: 9.8%;
  top: 7.1%;
  width: 84vw;
}

.burt-0304-text-2 {
  left: 50%;
  top: 19.7%;
  width: 44vw;
}

.burt-03-m-text-1 {
  left: 5.4%;
  top: 13%;
  width: 90vw;

  p {
    font-size: 3.3vw;
    line-height: 1.29;
  }
}

.burt-03-m-text-2 {
  top: 29%;
  left: 5%;
  width: 70vw;

  p {
    font-size: 3.3vw;
    line-height: 1.29;
  }
}

.burt-04-text-1 {
  top: 10%;
  left: 10%;
  width: 53vw;
}

.burt-04-text-2 {
  left: 54.5%;
  top: 65.3%;

  p {
    line-height: 1.35;
    color: white;
  }
}

.burt-04-m-text-1 {
  left: 7.2%;
  top: 9.4%;
  width: 87vw;
}

.burt-04-m-text-2 {
  top: 82.4%;
  left: 54.8%;

  p {
    color: white;
    line-height: 1.35;
  }
}

.burt-0506-text {
  top: 73.7%;
  left: 8.3%;
  width: 90vw;
}

.burt-0506-m-text {
  top: 77.5%;
  left: 9.8%;
  width: 79vw;

  p {
    letter-spacing: 0.01em;
    line-height: 1.25;
  }
}

.burt-07-text {
  top: 56.5%;
  left: 48.9%;
  width: 30vw;
}

.burt-07-text.es {
  top: 54.5%;
  left: 48.9%;
  width: 30vw;
}

.burt-07-m-text {
  left: 8.2%;
  top: 17%;
  width: 87vw;

  p {
    color: white;
    letter-spacing: 0.027em;
    font-size: 4vw;
  }
}

.burt-08-text {
  top: 80.3%;
  left: 10.9%;
  width: 79.3vw;

  p {
    line-height: 1.25;
  }
}

.burt-08-m-text {
  top: 82.6%;
  left: 13.5%;
  width: 80vw;
}

.burt-09-text {
  left: 19.1%;
  top: 9.7%;
  width: 66vw;
}

.burt-09-m-text {
  left: 8.3%;
  top: 7.7%;
  width: 84vw;

  p {
    letter-spacing: -0.02em;
    line-height: 1.43;
  }
}

.burt-010-text {
  left: 4.8%;
  top: 9.1%;
  width: 72vw;

  p {
    line-height: 1.42;
  }
}

.burt-010-m-text {
  top: 84.5%;
  left: 8.9%;
  width: 87vw;

  p {
    letter-spacing: 0.004em;
  }
}

.burt-011-text {
  top: 81.5%;
  left: 16.5%;
  width: 70vw;
}

.burt-011-m-text-1 {
  top: 9.5%;
  left: 7.7%;
  width: 67vw;

  p {
    letter-spacing: -0.03em;
    line-height: 1.2;
  }
}

.burt-011-m-text-2 {
  top: 82.9%;
  left: 7.9%;
  width: 80vw;

  p {
    letter-spacing: -0.03em;
    line-height: 1.2;
  }
}

.burt-012-text {
  left: 4.4%;
  top: 8.1%;
  width: 58vw;
}

.burt-012-m-text-1 {
  top: 9.4%;
  left: 9.3%;
  width: 70vw;

  p {
    letter-spacing: -0.02em;
  }
}

.burt-012-m-text-2 {
  top: 84%;
  left: 9.7%;
  width: 80vw;

  p {
    letter-spacing: -0.02em;
    line-height: 1.25;
  }
}

.burt-013014-text {
  left: 4.5%;
  top: 13.3%;
  width: 55vw;
}

.burt-013014-m-text {
  top: 11%;
  left: 13.6%;
  width: 82vw;
}

.burt-015-text {
  left: 13.4%;
  top: 9.1%;
  width: 49vw;

  p {
    line-height: 1.22;
  }
}

.burt-015-m-text {
  left: 10.6%;
  top: 10.2%;
  width: 81vw;

  p {
    letter-spacing: -0.02em;
    line-height: 1.1;
  }
}

.burt-016-text {
  left: 4.6%;
  top: 9.7%;
  width: 72vw;
}

.burt-016-m-text {
  top: 8.6%;
  left: 9.3%;
  width: 80vw;

  p {
    letter-spacing: -0.005em;
    line-height: 1.2;
  }
}

#burt-0102,
#burt-0304,
#burt-04,
#burt-0506,
#burt-07,
#burt-08,
#burt-09,
#burt-010,
#burt-011,
#burt-012,
#burt-013014,
#burt-015,
#burt-016 {
  -webkit-transform: translate3d(0, 0, 1px);
}

.burt-0506-text,
.burt-08-text {
  -webkit-transform: translate3d(0, 0, 1px);
}
