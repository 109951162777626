.partnership-featured {
  &.nutrition {
    background-color: $white;
    background-image: url("../images/background-marble.jpg");
    background-size: cover;

    @include break($tablet) {
      padding: 80px 0;
    }

    .post {
      &:nth-child(odd) {
        .img-section {
          order: 2;
          -ms-flex-order: 2;
          @include break($mobile, true) {
            order: 0;
            -ms-flex-order: 0;
          }
        }
      }

      &:nth-child(even) {
        .img-section {
          order: 0;
          -ms-flex-order: 0;
        }
      }

      &:nth-child(even),
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0);

        .headline,
        .description {
          color: $black;
        }

        .cta {
          @include cta-no-border($yellow, $black, $black, $yellow, 200px);
        }
      }

      .headline {
        text-transform: uppercase;
        font-size: 62px;
        @include break($mobile, true) {
          text-align: left;
          font-size: 50px;
        }
      }

      .subheadline {
        display: none;
      }

      .description {
        font-family: "hoefler", serif;
        font-size: 26px;
        line-height: 30px;
        padding-top: 14px;
        padding-bottom: 24px !important;

        @include break($mobile, true) {
          font-size: 20px;
          line-height: 26px;
          text-align: left;
        }
      }

      .cta-container {
        @include break($mobile, true) {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          text-align: left;
        }

        > div {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        .cta {
          @include cta-no-border($black, $white, $yellow, $black, 200px);
        }
      }
    }
  }

  .post:nth-child(odd) {
    .img-section {
      order: 0;
      -ms-flex-order: 0;
    }

    .headline {
      color: $yellow-ada;
    }

    .subheadline,
    .description {
      color: $black;
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, 200px);
    }
  }

  .post:nth-child(even),
  .post:only-child {
    background: $yellow_partnership;

    .img-section {
      order: 2;
      -ms-flex-order: 2;
      @include break($mobile, true) {
        order: 0;
        -ms-flex-order: 0;
      }
    }

    .headline {
      color: $black;
    }

    .subheadline,
    .description {
      color: $white;
    }

    .cta {
      @include cta-no-border($black, $white, $white, $black, 200px);
    }
  }

  .headline {
    font-family: "gothic", sans-serif;
    text-transform: uppercase;
    font-size: 72px;
    @include break($mobile, true) {
      font-size: 67px;
    }
  }

  .subheadline {
    font-family: "gothic", sans-serif;
    text-transform: uppercase;
    font-size: 32px;
    @include break($mobile, true) {
      font-size: 40px;
    }
  }

  .description {
    font-family: "hoefler", serif;
    font-size: 20px;
  }
}
