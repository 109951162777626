.sb-ch-abs {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.sb-ch-masthead,
.sb-ch-masthead-m {
  background-color: #54adfc;
}

.sb-ch-masthead {
  height: 62.578125vw;
}

.sb-ch-masthead-m {
  height: 177.7777777778vw;
}

.sb-ch-p3 {
  margin-top: -10%;
}
.sb-ch-p3b {
  margin-top: -10%;
}

.sb-ch-p3b {
  z-index: 3;
}
.sb-ch-p4-charlie {
  bottom: 12%;
  left: 0;
  z-index: 5;
}

.sb-ch-p4 {
  margin-top: -10%;
  z-index: 4;
}
.sb-ch-p5 {
  margin-top: -10%;
  z-index: 5;
}

.sb-ch-p6 {
  margin-top: -10%;
  z-index: 6;
}

.sb-ch-p7-8 {
  margin-top: -10%;
  z-index: 7;
}

.sb-ch-p7-8-running-wolf {
  top: -5%;
}
.sb-ch-p9 {
  z-index: 9;
}

.sb-ch-p10 {
  margin-top: -19%;
  z-index: 10;
}

.sb-ch-p11 {
  margin-top: -21%;
  z-index: 11;
}
.sb-ch-bottom-left {
  bottom: 0;
  top: auto;
}
.sb-ch-p12 {
  z-index: 12;
}

.sb-ch-p12-vine {
  left: auto;
  right: 0;
  z-index: 12;
}
.sb-ch-center {
  bottom: auto;
  left: 50%;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sb-ch-p13 {
  margin-top: -24%;
  z-index: 13;
}

.sb-ch-p14 {
  z-index: 14;
}

.sb-ch-p-end {
  z-index: 15;
}
.sb-ch-p-end,
.sb-ch-p9,
.sb-ch-p12,
.sb-ch-p14 {
  margin-top: -12%;
}
.sb-ch-rel {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  img {
    width: 100%;
  }
}
.sb-ch-end-download,
.sb-ch-end-download-m {
  bottom: 0;
  display: block;
  position: absolute;
  top: auto;
  width: 100%;
  img {
    width: 100%;
  }
}
.scroll {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.5vw;
  font-weight: 700;
  color: white;
  line-height: 1.2;
  position: relative;
  text-transform: uppercase;
  @media (min-width: 1024px) {
    font-size: 1.5vw;
  }
}
.sb-ch-p10 {
  height: 3234/1920 * 100vw;
}
.sb-ch-p11 {
  height: 2054/1920 * 100vw;
}
.sb-ch-p12 {
  height: 2575/1920 * 100vw;
}
.sb-ch-p13 {
  height: 1930/1920 * 100vw;
}
.sb-ch-p14 {
  height: 2150/1920 * 100vw;
}

.sb-ch-p1-2,
.sb-ch-p1-2-m {
  transform: translate3d(0, 0, 1px);
}
.sb-ch-p3,
.sb-ch-p3-m {
  transform: translate3d(0, 0, 3px);
}
.sb-ch-p3b,
.sb-ch-p3b-m {
  transform: translate3d(0, 0, 4px);
}
.sb-ch-p4,
.sb-ch-p4-m {
  transform: translate3d(0, 0, 5px);
}
.sb-ch-p5,
.sb-ch-p5-m {
  transform: translate3d(0, 0, 7px);
}
.sb-ch-p6,
.sb-ch-p6-m {
  transform: translate3d(0, 0, 8px);
}
.sb-ch-p7-8,
.sb-ch-p7-8-m {
  transform: translate3d(0, 0, 9px);
}
.sb-ch-p9,
.sb-ch-p9-m {
  transform: translate3d(0, 0, 10px);
}
.sb-ch-p10,
.sb-ch-p10-m {
  transform: translate3d(0, 0, 11px);
}
.sb-ch-p11,
.sb-ch-p11-m {
  transform: translate3d(0, 0, 12px);
}
.sb-ch-p12,
.sb-ch-p12-m {
  transform: translate3d(0, 0, 13px);
}
.sb-ch-p13,
.sb-ch-p13-m {
  transform: translate3d(0, 0, 14px);
}
.sb-ch-p14,
.sb-ch-p14-m {
  transform: translate3d(0, 0, 15px);
}
.sb-ch-p-end,
.sb-ch-p-end-m {
  transform: translate3d(0, 0, 16px);
}

.sb-ch-p1-2-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p3-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p3b-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p4-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p5-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p6-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p7-8-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p9-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p10-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p11-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p12-m {
  height: 2261/1080 * 100vw;
}
.sb-ch-p13-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p14-m {
  height: 1920/1080 * 100vw;
}
.sb-ch-p-end-m {
  height: 1920/1080 * 100vw;
}

.sb-ch-p3-m {
  margin-top: -16%;
}
.sb-ch-p3b-m {
  margin-top: -2%;
}
.sb-ch-p4-m {
  margin-top: -10%;
}
.sb-ch-p5-m {
  margin-top: -20%;
}
.sb-ch-p6-m {
  margin-top: -32%;
}
.sb-ch-p7-8-m {
  margin-top: -22%;
}
.sb-ch-p9-m {
  margin-top: -32%;
}
.sb-ch-p10-m {
  margin-top: -25%;
}
.sb-ch-p11-m {
  margin-top: -37%;
}
.sb-ch-p12-m {
  margin-top: -18%;
}
.sb-ch-p13-m {
  margin-top: -37%;
}
.sb-ch-p14-m {
  margin-top: -28%;
}
.sb-ch-p-end-m {
  margin-top: -34%;
}

.sb-ch-copy,
.sb-ch-copy-m {
  color: #3e4343;
  font-family: "Hoefler Text", Times;
  font-weight: 900;
  line-height: 1.6;
  opacity: 0;
  position: absolute;
  z-index: 20;
}
.sb-ch-copy {
  font-size: 2.3vw;
  max-width: 71.25vw;
  top: 10vw;
}
.sb-ch-copy-m {
  font-size: 4.0740740741vw;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  width: 78%;
}
.sb-ch-p1-2-copy {
  left: 50%;
  top: 10vw;
  transform: translateX(-50%);
  width: 71.25vw;
}
.sb-ch-p3-copy1 {
  left: 8%;
  top: 20%;
}
.sb-ch-p3-copy2 {
  left: 33.8541666667%;
  top: 59%;
}
.sb-ch-p3b-copy {
  left: 8%;
  top: 13%;
}
.sb-ch-p4-copy {
  left: 12%;
  top: 10%;
  max-width: 62%;
}
.sb-ch-p5-copy {
  left: 10%;
  top: 14%;
  width: 60%;
}
.sb-ch-p6-copy {
  left: 50%;
  width: 65%;
  transform: translateX(-50%);
  top: 12%;
}
.sb-ch-p7-copy {
  left: 50%;
  width: 71%;
  top: 18%;
  transform: translateX(-50%);
}
.sb-ch-p9-copy {
  left: 50%;
  width: 62%;
  top: 14%;
  transform: translateX(-50%);
}
.sb-ch-p10-copy {
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  max-width: 60%;
}
.sb-ch-p11-copy {
  left: 50%;
  bottom: 15%;
  top: auto;
  transform: translateX(-50%);
  width: 65%;
}
.sb-ch-p12-copy {
  left: 9.8958333333vw;
  top: 17.7083333333vw;
  width: 42%;
}
.sb-ch-p13-copy {
  left: 50%;
  top: 14%;
  transform: translateX(-50%);
  width: 72%;
}
.sb-ch-p14-copy {
  left: 50%;
  top: 16%;
  transform: translateX(-50%);
  width: 71%;
}
.sb-ch-p3-copy1-m {
  top: 16%;
}
.sb-ch-p3-copy2-m {
  top: 86%;
}
.sb-ch-p5-copy-m {
  top: 15%;
}
.sb-ch-p3b-copy-m {
  top: 48%;
}
.sb-ch-p10-copy-m {
  top: 20%;
}
.sb-ch-p11-copy-m {
  top: 7%;
}
.sb-ch-p13-copy-m {
  top: 16%;
}
.sb-ch-p14-copy-m {
  top: 15%;
}

.sb-ch-container,
.sb-ch-container-m {
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
}
@media (orientation: portrait) {
  .sb-ch-container {
    display: none;
  }
}
.sb-ch-p11-sky-m {
  top: 22%;
}

.sb-ch-p11-charlie {
  top: 13%;
}

@media (orientation: landscape) {
  .sb-ch-container-m {
    display: none;
  }
  .sb-ch-container {
    display: block;
  }
}
