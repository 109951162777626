.chevron {
  bottom: 0;
  display: none;
  height: 50px;
  position: absolute;
  width: 100%;
  @include bp(mediumWidth) {
    height: 10px;
  }
}
@mixin chevron-yellow {
  .chevron {
    background: url("../images/chevron-y.png") center center no-repeat
      transparent;
    background-size: 100% 100%;
    display: block;
    @include bp(mediumWidth) {
      background-image: url("../images/chevron-y-mobile.png");
      background-size: 100% 100%;
    }
  }
}
@mixin chevron-white {
  .chevron {
    background: url("../images/chevron-w.png") center center no-repeat
      transparent;
    background-size: 100% 100%;
    display: block;
    @include bp(mediumWidth) {
      background-image: url("../images/chevron-w-mobile.png");
      background-size: 100% 100%;
    }
  }
}
.header-wrapper {
  @include chevron-yellow;
}
#home {
  .header-wrapper {
    @include chevron-white;
  }
}
.commercials {
  @include chevron-yellow;
}
.intro {
  @include chevron-white;
}
.home-expander {
  @include chevron-yellow;
}
.carousel.food-the-history {
  //@include chevron-yellow;
  .chevron {
    display: none;
  }
  @include bp(mediumWidth) {
    .chevron {
      display: none;
    }
  }
}
.module.food-for-thought {
  @include bp(mediumWidth) {
    @include chevron-yellow;
  }
}
.carousel.farm-smarts {
  @include chevron-yellow;
}

.california-difference {
  @include chevron-yellow;
}

.timeline.princess {
  @include chevron-yellow;
}
.foodservice {
  @include chevron-yellow;
}

.about-us {
  @include chevron-yellow;
}
