@font-face {
  font-family: 'gothic';
  src: url('../fonts/AlternateGothicNo3.eot');
  src: url('../fonts/AlternateGothicNo3.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AlternateGothicNo3.woff') format('woff'),
  url('../fonts/AlternateGothicNo3.ttf') format('truetype'),
  url('../fonts/AlternateGothicNo3.svg#AlternateGothicNo3') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'wisdom';
  src: url('../fonts/WisdomScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hoefler';
  src: url('../fonts/Hoefler.eot');
  src: url('../fonts/Hoefler.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Hoefler.woff') format('woff'),
  url('../fonts/Hoefler.ttf') format('truetype'),
  url('../fonts/Hoefler.svg#Hoefler') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hoefler';
  src: url('../fonts/HoeflerBlack.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'hoefler';
  src: url('../fonts/HoeflerBlackItalic.eot');
  src: url('../fonts/HoeflerBlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HoeflerBlackItalic.woff') format('woff'),
  url('../fonts/HoeflerBlackItalic.ttf') format('truetype'),
  url('../fonts/HoeflerBlackItalic.svg#HoeflerBlackItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'hoefler';
  src: url('../fonts/HoeflerItalic.eot');
  src: url('../fonts/HoeflerItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HoeflerItalic.woff') format('woff'),
  url('../fonts/HoeflerItalic.ttf') format('truetype'),
  url('../fonts/HoeflerItalic.svg#HoeflerItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'cmabicons';
  src: url('../fonts/cmabicons.eot');
  src: url('../fonts/cmabicons.eot?#iefix3qod5l') format('embedded-opentype'),
  url('../fonts/cmabicons.ttf') format('truetype'),
  url('../fonts/cmabicons.woff') format('woff'),
  url('../fonts/cmabicons.svg#cmabicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gistrough';
  src: url('../fonts/GistRoughUprExboldTwoDemo.eot');
  src: url('../fonts/GistRoughUprExboldTwoDemo.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GistRoughUprExboldTwoDemo.woff') format('woff'),
  url('../fonts/GistRoughUprExboldTwoDemo.ttf') format('truetype'),
  url('../fonts/GistRoughUprExboldTwoDemo.svg#gist_rough_uprightXBdtwo') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dinconbold';
  src: url('../fonts/DINCond-Bold.otf');
}

@font-face {
  font-family: 'dinconmedium';
  src: url('../fonts/DINCond-Medium.otf');
}

@font-face {
  font-family: 'dincon';
  src: url('../fonts/DINCond-Regular.otf');
}

@font-face {
  font-family: 'dinnextltpro';
  src: url('../fonts/DINNextLTPro-Condensed.otf');
}

@font-face {
  font-family: 'dinbold';
  src: url('../fonts/DIN-Bold.otf');
}

@font-face {
  font-family: 'gotham';
  src: url('../fonts/Gotham-Medium.otf');
}

@font-face {
  font-family: 'gothic-atf';
  src: url('../fonts/AlternateGothicATF.otf');
}

@font-face {
  font-family: 'knockout-junior-featherweight';
  src: url('../fonts/Knockout-28JuniorFeathrwt.otf') format('opentype'),
  url('../fonts/Knockout-28JuniorFeathrwt.eot') format('embedded-opentype'),
  url('../fonts/Knockout-28JuniorFeathrwt.woff') format('woff');
}

@font-face {
  font-family: 'helvetica-light-condensed';
  src: url('../fonts/HelveticaLT47LightCondensed.ttf') format('truetype');
}

// fonts
$sans: 'gothic';
$script: 'wisdom';
$serif: 'hoefler';
