.real-maker-carousel {
  padding-top: 97px;
  background: #201e1f;
  background-image: url("../images/bg-realmakers.png");
  background-size: 100% 100%;
  padding-bottom: 45px;

  @include break($tablet, true) {
    height: 208vw;
    padding-top: 21vw;

    .yt-video-wrapper {
      padding: 16px 20px;

      .player {
        height: 300px !important;
      }
    }
  }

  .main {
    width: 100%;

    @include break($tablet) {
      width: 960px;
    }
  }

  .header {
    margin-bottom: 10px;
    text-align: center;

    .headline {
      .real {
        padding-left: 10px;
        text-align: center;
        font-family: "dinbold";
        font-size: 58px;
        color: $yellow;
        text-transform: uppercase;
        letter-spacing: 0.25em;

        @include break($tablet, true) {
          padding-left: 7px;
          font-size: 30px;
        }
      }

      .line-container {
        flex-grow: 1;
        padding-top: 30px;

        @include break($tablet, true) {
          padding-top: 15px;
        }

        .mid-line {
          border-top: 1px solid $yellow;
        }
      }
    }

    .subheadline {
      font-family: "gistrough";
      font-size: 122px;
      color: #fff;
      text-transform: uppercase;
      padding-top: 10px;
      margin-bottom: -7px;

      @include break($tablet, true) {
        padding-top: 5px;
        font-size: 50px;
        margin-bottom: 10px;
      }
    }

    .headline-description {
      color: $yellow;
      font-size: 47px;
      font-family: dinconbold, sans-serif;
      line-height: 1.3;
      border-top: 1px solid $yellow;

      @include break($tablet, true) {
        font-size: 23px;
        padding-top: 5px;
        max-width: 340px;
        line-height: 1;
        margin: auto;
      }
    }
  }

  .bottom_ctas {
    text-transform: uppercase;
    position: relative;
    text-align: right;
    margin-top: 7px;

    a {
      @include commericalLinks($yellow, $white);
      background-size: 20px;
      background: url("../images/icons/cta-play.png") no-repeat 0 3px;
      content: "";
      font-size: 24px;
      height: 15px;
      padding-left: 24px;
      position: relative;
      text-decoration: none;
      width: 15px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .thumbnails-container {
    max-width: 990px;
    text-align: center;
    margin: 10px -1%;

    .thumbnail-container {
      text-align: left;
      text-decoration: none;
      width: 23%;
      display: block;
      float: left;
      margin: 1%;

      .video-thumbnail {
        height: 126px;
        display: block;
        background-color: transparent;
        background-position: center center;
        background-size: cover;
        margin-bottom: 6px;
      }

      .video-title {
        display: block;
        font-weight: bold;
        font-family: "dinbold";
        color: #8c8c8c;
        text-transform: uppercase;
        font-size: 14px;

        &.selected {
          color: $yellow;
        }
      }
    }

    .thumbnail-selector {
      &.selected {
        color: $yellow;
      }
    }

    .dot-selector-container {
      padding-top: 19px;
    }

    .thumbnail-container-dot {
      text-decoration: none;
      color: #8c8c8c;
      font-size: 30px;
      margin-left: 4px;

      &.active {
        color: $yellow;
      }
    }
  }

  .yt-video-wrapper {
    @include break($tablet) {
      margin-top: 40px;
    }

    .player {
      width: 100%;
    }

    .previous,
    .next {
      @include prev_next_buttons(36px);
      position: relative;
      top: -183px;
      text-transform: uppercase;
    }

    .previous {
      @include prev_button(0, yellow, "");
      float: left;
      left: -63px;

      @include bp(largeWidth) {
        left: 44px;
      }

      &.slick-disabled,
      &.slick-disabled:hover {
        cursor: default;
        opacity: 0.4;
      }
    }

    .next {
      @include next_button(0, yellow, "");
      float: right;
      right: -68px;

      @include bp(largeWidth) {
        right: 6px;
      }

      &.slick-disabled {
        cursor: default;
        opacity: 0.4;
      }
    }
  }

  @include break($mobile) {
    height: 760px;

    .yt-video-wrapper {
      margin: 0 auto;
      height: 300px;
      width: 300px;
      position: relative;

      .player {
        width: 100%;
        height: 71%;
      }
    }

    .slick-dotted {
      margin-bottom: 0;
    }

    .bottom_ctas {
      text-align: center;

      a {
        background: url("../images/icons/cta-play.png") no-repeat 0 2px;
        background-size: 16px;
        font-size: 18px;
      }
    }
  }

  .mobile-yes {
    padding-bottom: 30px;

    .headline,
    .subheadline {
      text-align: center;
    }

    .yt-video-wrapper {
      width: 100%;
    }

    .thumbnails-container {
      margin: 35px 0;

      a {
        font-size: 82px;
        margin-left: 11px;
      }
    }
  }

  .farmer-container {
    margin-top: -2px;
    background-color: transparentize(#5a5a5a, 0.85);
    padding-top: 41px;
    padding-right: 30px;
    height: 216px;

    @include break($tablet, true) {
      padding: 5vw 3vw 0;
      height: 240px;
      margin: -1vw 1.4vw;
    }

    .farmer-col1 {
      width: 240px;
      height: 180px;
      float: left;
      padding: 5px 60px 0 60px;

      .farmer-photo {
        width: 130px;
        height: 130px;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .title {
      font-family: "dinbold";
      color: $yellow;
      font-size: 26px;
      text-transform: uppercase;

      @include break($tablet, true) {
        text-align: left;
        font-size: 3.5vw;
        margin-top: 5vw;
      }

      a {
        text-decoration: none;
        color: $yellow;
      }
    }

    .farmer-description {
      font-family: "hoefler";
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      margin-top: 12px;

      @include break($tablet, true) {
        text-align: left;
        font-size: 4.5vw;
        line-height: 6vw;
        margin-top: 2vw;
      }
    }

    .playing-video-title {
      margin-bottom: 3vw;
      display: block;
      font-family: "dinbold";
      color: $yellow;
      text-transform: uppercase;
      font-size: 3.5vw;
      text-align: left;
    }
  }

  .slide-wrapper-inner {
    .slick-list {
      width: 100%;
      display: block;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: relative;

      .yt-list-info {
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 175px;
        text-align: center;

        @include break($tablet) {
          &:hover {
            .yt-video-image:before {
              background-image: url("../images/icons/video-play.png") !important;
              background-size: 46px 46px;
              background-position: center center;
              background-repeat: no-repeat;
              background-color: transparentize(#000, 0.6);
              content: " ";
              display: block;
              height: 126px;
              width: 100%;
              position: relative;
              z-index: 1001;
              margin-bottom: -126px;
            }
          }

          &.active {
            .yt-video-image:before {
              background-size: 46px 46px;
              background-position: center center;
              background-repeat: no-repeat;
              background-color: transparentize(#000, 0.6);
              content: "Now Playing";
              text-align: center;
              line-height: 126px;
              display: block;
              height: 126px;
              width: 100%;
              position: relative;
              z-index: 1000;
              color: #fefefe;
              font-family: "dinbold";
              text-transform: uppercase;
              margin-bottom: -126px;
            }

            .yt-video-image:hover:before {
              content: " ";
            }

            .yt-video-title {
              color: $yellow;
            }
          }

          .yt-video-image {
            cursor: pointer;
            display: inline-block;
            height: 125px;
            overflow: hidden;
            position: relative;
            vertical-align: middle;
            width: 235px;

            img {
              display: block;
              left: 0;
              position: relative;
              top: -22px;
              width: 245px;
              max-width: 100%;
            }
          }
          .yt-video-title {
            display: block;
            font-weight: bold;
            font-family: "dinbold";
            color: #8c8c8c;
            text-transform: uppercase;
            font-size: 14px;
            text-align: left;
            padding-top: 7px;

            &.selected {
              color: $yellow;
            }
          }
        }

        @include break($tablet, true) {
          height: 400px;

          .image {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 45vw;
            margin: 0 1.5vw;
          }

          .play-btn {
            background: transparent url("../images/icons/video-play.png")
              no-repeat scroll 0 0 / 100% 100%;
            cursor: pointer;
            display: block;
            height: 10.5vw;
            margin: auto auto -7.5vw;
            position: relative;
            top: 21vw;
            width: 10.5vw;
            z-index: 100;
          }
        }
      }
    }

    .slick-prev {
      background: rgba(0, 0, 0, 0) url(../images/icons/arrow-prev-yellow.png)
        no-repeat scroll 0 0;
      left: -60px;
    }

    .slick-next {
      background: rgba(0, 0, 0, 0) url(../images/icons/arrow-next-yellow.png)
        no-repeat scroll 0 0;
      right: -66px;
    }

    .slick-prev,
    .slick-next {
      &.slick-disabled {
        opacity: 0.25;
      }
    }

    .slick-arrow {
      cursor: pointer;
      height: 60px;
      position: absolute;
      text-indent: -9999px;
      top: 74px;
      width: 59px;
      z-index: 0;
    }

    .slick-dots {
      pointer-events: none;

      @include break($tablet, true) {
        padding-top: 10vw;
        button::before {
          font-size: 3.5vw;
        }
      }

      li {
        button {
          &::before {
            color: #8c8c8c;
          }
        }

        &.slick-active {
          button {
            ::before {
              color: #d7d7d7;
            }
          }
        }

        @include break($tablet, true) {
          margin: 0 1.5vw;
        }
      }
    }
  }
}
