.cta-top {
  @include cta-circle(235px, 235px);
  background-image: url("../images/icon-arrow-down.png");
  background-repeat: no-repeat;
  background-position: 50% 190px;
  margin: -117px 0 0;
  padding: 0;
  transform: scale(0.9);

  &:hover {
    background-color: $blue_dark;
  }

  color: white;
  @include break($mobile) {
    background-image: url("../images/icons/arrow-cta-down-mobile.png");
    background-position: 50% 60px;
    background-size: 8px auto;
    margin-top: -5%;
    padding: 0;
  }
}

.cta-bottom {
  @include cta-circle(190px, 190px);
  left: 50%;
  margin: -160px 0 0 -80px;
  padding: 0;

  .icon {
    background: url("../images/icons/arrow-cta-next.png") 50% 0 no-repeat
      transparent;
    background-size: 100% auto;
    height: 18px;
    position: absolute;
    bottom: 20px;
    left: 85px;
    padding: 0;
    width: 20px;
  }

  &:hover {
    background-color: $blue_dark;
    text-decoration: none;
  }

  @include bp(mediumWidth) {
    margin: -160px 0 0 -96px;
    height: 192px;
    padding: 0;
    width: 192px;
    .icon {
      background: url("../images/icons/arrow-cta-next-mobile.png") 50% 0
        no-repeat transparent;
      background-size: 100% auto;
      height: 18px;
      margin: 7% auto 0;
      width: 20px;
    }
  }
}

.cta-top,
.cta-bottom {
  .inner {
    color: $white;
    font-family: $sans;
    margin: 0 auto;
    text-align: center;
    line-height: 35px;
  }
}

//#home {
.cta-bottom[href*="/our-food"] {
  /*to food*/
  #home & {
    display: none;
  }

  .inner {
    margin-top: 50px;
  }

  .first {
    @include cta-script(27px);
  }

  .second {
    @include cta-sans(44px);
  }

  &:hover {
    .first {
      color: $white;
    }
  }
}

.cta-bottom[href*="/foodservice"] {
  /*to farms*/
  .inner {
    line-height: 1;
    margin: 0;
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    width: 100%;
  }

  .icon {
    display: inline-block;
    bottom: initial;
    position: relative;
    left: initial;
    transform: rotateY(180deg);
  }

  .first {
    @include cta-script(30px);
    margin-bottom: 2px;
    padding-bottom: 2px;
  }

  .second {
    @include cta-sans(30px);
    margin-bottom: 4px;
  }

  &:hover {
    .first,
    .second {
      color: $white;
    }
  }
}

.cta-bottom[href*="/dairy-farms"] {
  /*to farms*/
  .inner {
    margin-top: 40px;
  }

  .first,
  .second {
    @include cta-script(25px);
    line-height: 0.8;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .third {
    @include cta-sans(40px);
  }

  &:hover {
    .first,
    .second {
      color: $white;
    }
  }
}

.cta-bottom[href*="/dairy-cows"] {
  /*to cows*/
  .inner {
    margin-top: 60px;
  }

  .first {
    @include cta-script(24px);
    line-height: 24px;
  }

  .second {
    @include cta-sans(45px);
    line-height: 45px;
  }

  &:hover {
    .first {
      color: $white;
    }
  }
}

.cta-bottom[href*="/product-locator"] {
  /*to products*/
  .inner {
    margin-top: 40px;
  }

  .first {
    @include cta-script(32px);
    line-height: 28px;
  }

  .second {
    @include cta-sans(28px);
    line-height: 52px;
  }

  &:hover {
    .first {
      color: $white;
    }
  }
}

.cta-bottom[href*="/our-community"] {
  /*to community*/
  .inner {
    margin-top: 65px;
  }

  .first {
    @include cta-script(27px);
    line-height: 27px;
  }

  .second {
    @include cta-sans(26px);
    line-height: 26px;
  }

  &:hover {
    .first {
      color: $white;
    }
  }
}

#home,
#food {
  .slick-dots {
    //display: none !important;
  }
}

#home {
  // This is a peculiar place to have this, but i wanted it to be related to the CTA. This is how we bring it above the CTA.
  .home-splash {
    order: -5;
  }

  .header-wrapper-2 {
    z-index: 501;
  }

  .divider {
    display: block;
    border-width: 1px;
    border-style: solid;
    border-bottom: black;
    width: 80vw;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .cta-top {
    @include break($mobile) {
      @include square(80px);
      font-size: 5px;
      left: 72%;
      margin: -52px 0 0;
    }
    @include break($tablet) {
      @include square(162px);
      font-size: 9px;
      left: 70%;
      margin: -166px 0 0;
    }
    background-image: none;

    .inner {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }

    .first {
      @include cta-script(27px);
      margin-top: 1em;
      font-size: 2em;
      line-height: 1;
    }

    .third {
      @include cta-sans(45px);
      font-size: 4em;
      line-height: 1.2;
    }

    .cmab2-arrow-right {
      font-size: 2.4em;
    }

    &:hover {
      .first,
      .second,
      .third {
        color: $white;
      }
    }
  }
}

//}
#food {
  .cta-top {
    .icon {
      background: url("../images/icons/cta-food.png") 50% 0 no-repeat
        transparent;
      background-size: 100% auto;
      height: 72px;
      margin: -16px auto 0;
      width: 38px;
    }

    @include break($mobile) {
      margin-top: -27px;
      .inner {
        margin-top: 10% !important;
      }
      .icon {
        height: 36px !important;
        margin: -16px auto 2px !important;
        width: 20px !important;
      }
    }

    .inner {
      line-height: 32px;
      margin: 15px auto;
    }

    .first,
    .second {
      @include cta-sans(35px);
    }

    .third {
      @include cta-script(35px);
    }

    &:hover {
      .third {
        color: $white;
      }
    }
  }
}

//}
#farms {
  .cta-top {
    .first,
    .second,
    .third {
      @include cta-sans(41px);
      line-height: 34px;
    }

    .third {
      color: $blue_dark;
    }

    .fourth {
      @include cta-script(27px);
    }

    .icon {
      background: url("../images/icons/sprite-cta-play.png") no-repeat
        transparent;
      background-size: 100% auto;
      height: 77px;
      margin: auto;
      position: relative;
      top: -28px;
      width: 65px;

      @include break($mobile) {
        top: -5px;
      }
    }

    &:hover {
      .icon {
        background-position: 0 -78px;
      }

      .third,
      .fourth {
        color: $white;
      }
    }

    .inner {
      margin-top: -30px;
    }
  }
}

#cows {
  .cta-top {
    .icon {
      background: url("../images/icons/cta-cows.png") 50% 0 no-repeat
        transparent;
      background-size: 100% auto;
      height: 62px;
      margin: -20px auto 14px;
      width: 109px;
    }

    .first,
    .second {
      @include cta-sans(51px);
      line-height: 42px;
    }

    .third {
      @include cta-script(36px);
    }

    @include bp(mediumWidth) {
      background-position: 50% 65px;
      .icon {
        margin-bottom: 0;
      }
      .inner {
        .first {
          //line-height: 5px;
        }

        .second {
          font-size: 18px;
          line-height: 26px;
        }

        .third {
          line-height: 14px;
        }
      }
    }

    &:hover {
      .third {
        color: $white;
      }
    }
  }
}

#foodservice {
  .cta-bottom {
    .inner {
      margin-top: 65px;
    }

    .first {
      @include cta-script(27px);
      line-height: 27px;
    }

    .second {
      @include cta-sans(26px);
      line-height: 26px;
    }

    &:hover {
      .first {
        color: $white;
      }
    }
  }
}

#community {
  .cta-top {
    .inner {
      margin-top: 40px;
    }

    .first,
    .second {
      text-transform: uppercase;
    }

    .first {
      @include cta-sans(26px);
    }

    .second {
      @include cta-sans(24px);
    }

    // .icon {
    //     background: url('../images/icons/cta-community.png') 50% 0 no-repeat transparent;
    //     height: 82px;
    //     margin: 2% auto 0;
    //     width: 133px;
    // }
    .icon1 {
      background: url("../images/icons/GAMD_logo_wht.png") no-repeat transparent;
      background-size: 100%;
      background-position: center;
      height: 60px;
      margin: 2% 5%;
      width: 221px;
    }

    .icon2 {
      background: url("../images/icons/down-arrow.png") 50% 0 no-repeat
        transparent;
      height: 27px;
      margin: 0 auto;
      width: 133px;
    }
  }
}

@include break($mobile) {
  #home {
    .cta-bottom {
      .inner {
        margin-top: 22%;
      }

      .first,
      .second {
        @include cta-script(26px);
      }

      .third {
        @include cta-sans(43px);
        letter-spacing: -2px;
      }
    }
  }
  #farms {
    .cta-top {
      .icon {
        background-size: 24px 52px;
        height: 23px;
        margin: -7.5% auto 0;
        width: 24px;
      }

      .inner {
        margin-top: 14.6%;
      }

      .first,
      .second {
        display: none;
      }

      .third {
        font-size: 16px;
        line-height: 1;
      }

      .fourth {
        font-size: 11px;
        line-height: 1;
      }

      &:hover {
        .icon {
          background-position: 50% -26px;
        }
      }
    }

    .cta-bottom {
      .inner {
        margin-top: 29.7%;
      }

      .first {
        @include cta-script(26px);
      }

      .second {
        @include cta-sans(47px);
      }
    }
  }
  #cows {
    .cta-top {
      .icon {
        background: url("../images/icons/cta-cows.png") 50% 0 no-repeat
          transparent;
        background-size: 100% auto;
        height: 30px;
        margin: -10% auto 2%;
        width: 42px;
      }

      .first {
        font-size: 16px;
        line-height: 16px;
      }

      .third {
        font-size: 14px;
      }
    }

    .cta-bottom {
      .icon {
        height: 21px;
        width: 19px;
      }

      .inner {
        margin-top: 19.7%;
      }

      .first {
        font-size: 28px;
      }

      .second {
        font-size: 43px;
        line-height: 35px;
      }
    }
  }
  #food {
    .cta-top {
      .icon {
        height: 18px;
        margin: -8px auto 3px;
        width: 10px;
      }

      .inner {
        margin-top: 20%;
      }

      .first {
        font-size: 15px;
        line-height: 1;
      }

      .third {
        font-size: 14px;
        line-height: 1;
      }

      .arrow {
        height: 11px;
        margin-top: 8px;
        width: 14px;
      }
    }

    .cta-bottom {
      .icon {
        height: 21px;
        width: 19px;
      }

      .inner {
        margin: 22.25% auto 13%;
      }

      .first {
        font-size: 25px;
      }

      .second {
        font-size: 29px;
      }
    }
  }
  #community {
    .cta-top {
      .inner {
        margin-top: 21%;
        line-height: 13px;
      }

      .first,
      .second {
        display: none;
      }

      .icon {
        background-size: 100% auto;
        height: 41px;
        margin: 0 auto;
        width: 66px;
      }

      .icon1 {
        width: 91%;
        margin: -10px 1px;
        width: 80px;
        background-size: 89%;
      }
    }
  }
}
