body.single-foodservicerecipe {
  .outer-wrapper {
    @include vertical_gradient(#333333, #191919);

    .recipe {
      .see-all-recipes {
        font-family: "dinbold";
        font-size: 16px;
      }

      h1 {
        color: $white;
        font-family: "gistrough";
        font-size: 60px;
        line-height: 1;

        @include break($mobile) {
          font-size: 28px;
        }
      }

      .recipe-wrapper {
        @include break($mobile) {
          padding: 15px;
        }

        .section {
          padding: 40px 0 25px;

          @include break($mobile) {
            padding: 25px 0 10px;
          }

          h2 {
            font-family: "dinbold";
            font-size: 30px;
            color: $white;

            @include break($mobile) {
              font-size: 20px;
            }
          }
        }

        .copy {
          span,
          p,
          .product-locator-widget {
            color: $white;
            font-size: 18px;
            line-height: 30px;

            @include break($mobile) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

        .perorder {
          border-bottom: 1px solid $gray_dark;
        }
      }

      .related-recipes {
        header {
          .subheadline {
            font-family: "dinbold";
            font-size: 30px;
            letter-spacing: 4px;
            text-transform: uppercase;

            @include break($mobile) {
              font-size: 20px;
            }
          }

          .headline {
            color: $white;
            font-family: "gistrough";
            font-size: 75px;
            margin-bottom: 40px;
            margin-top: 10px;

            @include break($mobile) {
              font-size: 30px;
            }
          }
        }

        .ul {
          margin: auto;
        }
      }

      .empty-block {
        height: 54px;
        @include add_chevron();
      }
    }
  }
}
