@mixin header-bg-image($url) {
  .header-wrapper {
    background: url($url) 50% 0 no-repeat $white;
  }
}

#home .header-wrapper .cta-down {
  background: url("../images/icons/arrow-down.png") 0 0 repeat transparent;
  bottom: 30px;
  cursor: pointer;
  display: block;
  height: 55px;
  left: 50%;
  margin-left: -27px;
  position: absolute;
  text-indent: -9999px;
  width: 54px;
  z-index: 9000;

  @include bp(mediumWidth) {
    display: none;
  }
}

.touch .home {
  @include header-bg-image("../images/bg-home-big.jpg");

  .header-wrapper {
    background-size: cover;
  }
}

.touch .farms {
  @include header-bg-image("../images/bg-farms-big.jpg");

  .header-wrapper {
    background-size: cover;
  }
}

.touch .cows {
  @include header-bg-image("../images/bg-cows-big.jpg");

  .header-wrapper {
    background-size: cover;
  }
}

.touch .food {
  @include header-bg-image("../images/bg-food-big.jpg");

  .header-wrapper {
    background-size: cover;
  }
}

.touch .community {
  @include header-bg-image("../images/bg-community-big.jpg");

  .header-wrapper {
    background-size: cover;
  }
}

.foodservice_link {
  margin-right: 10px;
  position: relative;
  text-align: right;
  text-decoration: none;
  top: 15px;
  z-index: 999;

  a {
    color: $yellow;
    font-family: "hoefler", sans-serif;
    font-size: 19px;
    text-decoration: none;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.58);

    &:hover {
      opacity: 0.4;
    }
  }

  @include break($mobile) {
    top: 5px;
    margin-right: 10px;
    position: relative;
    a {
      font-size: 12px;
    }
  }
}

.header-wrapper-2 {
  order: -10;
  position: relative;
  z-index: 5;

  &.has-video {
    height: 77vh;

    @include break($tablet) {
      height: calc(77vh - 50px);
      margin-top: 50px;
      min-height: 400px;
    }
  }

  &.has-image.not-has-video {
    height: 350px;

    &.is-cheese {
      height: 480px;
    }

    &.rc-cheese {
      .subheadline {
        color: $yellow;
        margin-top: 20px !important;

        @include break($tablet) {
          margin-top: 50px;
        }
      }

      .heading {
        color: white;
        font-family: gothic, sans-serif;
        font-size: 75px;
        line-height: 0.6;

        @include break($tablet) {
          font-size: 120px;
        }
      }
    }
  }

  &.has-video,
  &.has-image {
    background-color: black;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }

  .has-video {
    svg {
      display: none;
    }
  }

  body.mobile & {
    .subheadline {
      margin-top: 60px;
      font-size: 25px;
      margin-bottom: 7px;
    }

    svg {
      margin-bottom: 20px;
      height: 50vw !important; //This was the easiest way for it to work in actual mobile.
      width: 100vw !important;

      &.es {
        height: 200px !important;
        width: 400px !important;
        margin-top: -45px;
        margin-bottom: -10px;
      }
    }
  }

  &.not-has-video.not-has-image {
    #big-video-wrap {
      display: none !important;
    }

    @include break($mobile) {
      height: 100px;
    }
  }

  @include break($mobile) {
    .headline {
      display: none;
    }
  }

  #food & {
    //We don't have a super easy top-level way to determine the three types of device. Desktop is the only one with
    //  a video, and it's entirely absent in iOS so then header will be first child. Mobile has the crest baked in.

    svg,
    .subheadline {
      display: none !important;
    }
  }

  body#recipes & {
    &.es {
      .subheadline {
        margin-top: 20px;
        margin-bottom: 0;
      }

      .headline_arc {
        overflow: visible;
        transform: scale(0.8);
      }
    }
  }

  header {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  .subheadline {
    @include headline-accent-3($white);
    margin-bottom: 10px;
    margin-top: 130px;
  }

  .headline_arc {
    text {
      font-size: 131px;
      font-family: $sans;
    }
  }

  @include break($mobile) {
    height: 210px;
  }
}

.header-wrapper {
  width: 100%;
  z-index: 0;
  position: relative;
  overflow: hidden;
  min-height: 650px;
  background: #000;

  #headerVideo {
    width: 100%;
    min-width: 960px;
  }

  .cta-down {
    display: none;
  }

  .video-header {
    background: url("../images/bg-video-overlay.png") 0 0 repeat transparent;
    color: $white;
    z-index: 10;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;

    h1 {
      @include span-block;
      margin: 0;
      padding: 0 30px;
      position: relative;

      .subheadline {
        display: block;
        font-family: $script;
        font-size: 65px;

        body#farms &,
        body#cows & {
          font-size: 50px;
        }
      }

      .headline {
        display: block;
        margin-top: 15px;
      }
    }

    .crest {
      margin-top: 14px;
      padding: 0 30px;
      position: relative;
    }

    .summary {
      font-family: $serif;
      font-style: italic;
      font-weight: normal;
      font-size: 29px;
      margin-top: 40px;
      padding: 0 30px;
      position: relative;
    }
  }

  .heading-wrapper {
    height: auto;
    left: 50%;
    margin-left: -480px;
    position: absolute;
    width: 960px;
  }
}

.nav-top {
  background: black;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0 auto;
  max-width: 960px;
  width: 100%;

  @include break($tablet, true) {
    height: 40px;
  }

  $nav-button-width: 162px;

  > ul {
    display: flex;
    justify-content: center;

    @include break($tablet, true) {
      display: none;
    }

    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: $nav-button-width;
      position: relative;

      > a {
        margin: auto;
        padding: 0 10px;
      }

      &:hover {
        .sub-nav {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: $nav-button-width;
          left: 0;

          &:before {
            $border-width: 10px;
            content: "";
            position: absolute;
            left: calc(50% - #{$border-width});
            top: -$border-width;
            width: 0;
            height: 0;
            border-left: $border-width solid transparent;
            border-right: $border-width solid transparent;
            border-bottom: $border-width solid $yellow;
            clear: both;
          }
        }
      }
    }
  }

  .sub-nav {
    background-color: $yellow;
    display: none;
    padding: 6px 0;
    position: absolute;
    top: 100%;

    a {
      color: black;

      &:hover {
        color: white;
      }
    }

    > li {
      text-align: center;
      padding: 6px 3px;
    }
  }
}

.foodservice {
  .footer .nav-primary li:last-child {
    display: none;
  }
}

@keyframes logoCheese {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes logoMilk {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes logoCheese {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@-webkit-keyframes logoMilk {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@-moz-keyframes logoCheese {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@-moz-keyframes logoMilk {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@-ms-keyframes logoCheese {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@-ms-keyframes logoMilk {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.fix-logo {
  .logo-top {
    top: 20px;
    position: fixed;
  }
}

.logo-top {
  $perspective: 88px;
  perspective: $perspective;
  z-index: 5000;
  height: 73px;
  left: 20px;
  position: absolute;
  top: 80px;
  width: 88px;

  @include break($mobile) {
    @include max-width-image;
    height: 30px;
    left: 10px;
    position: absolute;
    top: 5px;
    transition: 0.2s transform;
    width: 36px;

    body.noscroll & {
      transform: translateX(-60px) scale(0);
    }
  }

  .logo-home {
    color: $white;
    display: block;
    font-family: $sans;
    font-size: 12px;
    padding-top: 4px;
    text-align: center;
    text-transform: uppercase;

    @include break($tablet, true) {
      display: none;
      text-indent: -9999px;
      visibility: hidden;
    }
  }

  .flip-container {
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    width: 100%;

    &.flip-cheese {
      animation: logoCheese 1s;
      perspective: $perspective;
      transform: rotateY(180deg);
    }

    &.flip-milk {
      animation: logoMilk 1s;
      perspective: $perspective;
      transform: rotateY(360deg);
    }
  }

  .front,
  .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .front {
    //z-index: 100;
    img {
      width: 88px;
    }
  }

  .back {
    transform: rotateY(180deg);
    z-index: 100;

    html.lt-ie10 & {
      display: none;
    }

    img {
      width: 78px;
      left: 5px;
    }
  }

  a {
    border: 0;
    height: 100%;
    width: 100%;

    @include break($mobile) {
      padding: 0;
    }
  }

  &:hover {
    .logo-home {
      display: block;
    }
  }
}

.header-generic {
  background: $black_cmab url("../images/bg-header-all-about.png") no-repeat
    scroll 50% 0 / cover;
  height: 714px;
  text-align: center;
  width: 100%;
  margin-top: -50px;
  padding-top: 50px;
  display: flex;
  align-items: center;

  .content {
    margin: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headlines {
    line-height: 72px;
    padding: 45px 0 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .arctext {
      font-family: "wisdom", cursive;
      font-size: 35px;
      color: $yellow;
    }

    .subheadline {
      @include bold-headline-1;
      color: $white;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.34;
      letter-spacing: 0.83px;
      margin-top: 56px;
    }
  }
}

@include bp(mediumWidth) {
  .header-generic {
    padding-top: 18.75%;

    .content {
      padding: 0 3.125%;
      width: auto;
    }

    h2 {
      padding: 0 0 9%;
      text-align: center;
    }
  }
  .home {
    @include header-bg-image("../images/bg-home-mobile.jpg");

    .heading-wrapper {
      width: 96%;
    }
  }
  .farms {
    @include header-bg-image("../images/bg-farms-mobile.jpg");

    .heading-wrapper {
      width: 90%;
    }
  }
  .cows {
    @include header-bg-image("../images/bg-cows-mobile.jpg");

    .heading-wrapper {
      width: 80%;
    }
  }
  .food {
    @include header-bg-image("../images/bg-food-mobile.jpg");

    .heading-wrapper {
      width: 90%;
    }
  }
  .community {
    @include header-bg-image("../images/bg-community-mobile.jpg");

    .heading-wrapper {
      width: 80%;
    }
  }
  .header-wrapper {
    background-size: cover !important;
    height: 64vw !important;
    min-height: initial;

    #big-video-wrap {
      display: none;
    }

    .heading-wrapper {
      left: 0;
      margin: 0 auto;
      position: relative;
      top: 0;

      img {
        max-width: 100%;
      }
    }

    .video-header {
      h1 {
        padding: 0;

        .headline {
          @include max-width-image;
          margin: 0 auto;
        }

        .subheadline {
          font-size: 4.55vw;
          padding-bottom: 6px;

          body#farms &,
          body#cows & {
            font-size: 17px;
          }
        }
      }

      .crest {
        @include max-width-image;
        margin-top: 2px;
      }

      .summary {
        font-size: 15px;
        margin-top: 5px;
      }
    }
  }
  .nav-top {
    margin-top: 5px;
    position: absolute;

    @include break($tablet, true) {
      margin-top: 0;
    }

    ul {
      display: none;
    }
  }
}

.admin-bar {
  .logo-top {
    top: 110px;
  }
}

@include bp(mediumWidth) {
  .header-generic {
    padding-top: 28.1%;
    height: 70vw;
    margin-top: 0;
    background-position-x: 75%;
    background-position-y: 9vw;

    .content {
      padding: 0 3%;
      width: auto;

      .headlines {
        padding: 0 0 16vw;

        .arctext {
          margin: -28px 0;
          font-size: 20px;
        }

        .subheadline {
          margin-top: 1vw;
          font-size: 15vw;
        }
      }
    }
  }
}
