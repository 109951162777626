.foodservice-recipes-carousel,
.newsletters-carousel {
  .foodservice-recipes,
  .featured-newsletter {
    $spacer-width-mobile: 10px;
    margin: 0 auto;
    width: 100%;

    @include break($tablet) {
      padding: 96px 0 100px 0;
      height: 630px;
    }

    a {
      text-decoration: none;
    }

    .slick-list {
      @include break($tablet) {
        overflow: initial;
      }
    }

    .slide-wrapper-outer {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 0;

      @include break($tablet) {
        width: 85%;
        max-width: 1150px;
      }
    }

    .header {
      text-align: center;

      .heading {
        .headline {
          font-family: "dinbold";
          @include break($mobile) {
            font-size: 4.6vw;
          }
        }

        .subheadline {
          @include break($mobile) {
            font-size: 9.5vw;
            line-height: 9.5vw;
          }
        }
      }

      .cta-wrapper {
        .cta {
          @include cta-no-border($yellow, $black, $black, $yellow, auto);
        }
      }
    }

    .heading {
      span {
        display: block;
      }

      .headline {
        @include headline-accent-3($white);
        font-size: 30px;
        font-family: "dinbold";
        text-transform: uppercase;
        color: $yellow;
        letter-spacing: 4px;

        @include break($tablet, true) {
          font-size: 4.6vw;
          letter-spacing: 4px;
        }
      }

      .subheadline {
        color: #fff;
        font-family: "gistrough";
        font-size: 40px;
      }
    }

    .prev,
    .slick-prev,
    .next,
    .slick-next {
      @include prev_next_buttons();

      @include break($desktop + 1) {
        display: none !important;
      }

      &::before {
        content: "";
      }
    }

    .prev,
    .slick-prev {
      @include prev_button(0, yellow, false);

      body#home & {
        @include prev_button(0, yellow, false);
      }
    }

    .next,
    .slick-next {
      @include next_button(0, yellow, false);

      body#home & {
        @include next_button(0, yellow, false);
      }
    }

    .newsletter-cta {
      @include cta-no-border($yellow, $black, $black, $yellow, 300px);

      font-family: "dinbold";
      padding: 0px 18px;
      font-size: 17px;
      height: initial;
    }

    .slide {
      padding: 0 5px;

      @include break($desktop) {
        padding: 0 45px;
      }
      @include break($mobile) {
        text-align: center;
      }

      .panels {
        display: flex;
        width: 95%;
        margin: 0 auto;

        @include break($mobile) {
          flex-direction: column;
        }
      }

      .photo {
        display: block;
        height: 230px;
        background-size: cover;
        background-position: center;

        @include break($tablet) {
          height: auto;
          min-height: 308px;
          flex: 0 0 50%;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        padding: 33px 10px 33px;
        background-color: $yellow;
        position: relative;
        flex-basis: 50%;
        min-height: 300px;

        @include break($tablet, true) {
          text-align: center;
          width: 100%;
          padding: 4vw 3vw 4vw;
        }

        @include break($tablet) {
          padding: 33px 33px;
          min-height: 325px;
        }

        .headline {
          text-align: left;
          line-height: 36px;
          @include bold-headline-6;

          @include break($tablet, true) {
            font-size: 7vw !important;
          }
        }

        .description {
          font-family: $serif;
          line-height: 1.4;
          font-size: 1.4vw;
          flex-grow: 2;
          margin-top: 10px;
          text-overflow: ellipsis;

          @include break($tablet) {
            font-size: 1.7vw;
          }

          @include break($desktop-wide) {
            font-size: 18px;
          }

          @include break($mobile) {
            font-size: 4.6vw;
          }
        }

        .cta {
          @include cta-no-border($black, $white, $white, $black, 160px);
          top: 47px;
          margin: 0 auto;

          @include break($tablet) {
            margin-left: 0;
          }
        }
      }
    }

    .slick-dots {
      pointer-events: none;

      @include break($tablet, true) {
        margin-top: 41px;

        li {
          margin: 0 8px;

          button:before {
            font-size: 16px;
            color: #6d6d6d;
          }

          .slick-active {
            button:before {
              color: #f1f1f1;
            }
          }
        }
      }
    }

    .cooking-tools {
      display: none;
      position: relative;
      width: 100%;
      height: 240px;
      background: url("../images/rolling-pin.png") no-repeat center;
      top: -30px;

      body#home.not-mobile & {
        display: block;
      }
    }
  }
}

.newsletters-carousel {
  .featured-newsletter {
    height: auto;
    padding-top: 0;

    @include break($mobile) {
      margin-top: -40px;
    }

    .newsletter-cta {
      @include break($mobile) {
        font-size: 16px;
      }
    }
  }

  .slide-wrapper-outer {
    margin: 80px 0 0;

    @include break($mobile) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .featured-newsletter .slide .details {
    text-align: left;

    @include break($mobile) {
      height: 84vw;
    }

    .description {
      color: black;

      @include break($mobile) {
        line-height: 5.5vw;
      }
    }

    .cta {
      @include break($mobile) {
        width: 80%;
        right: 6vw;
        font-size: 4.5vw;
        bottom: 5vw;
      }
    }
  }
}
