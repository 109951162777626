$sans: "gothic";
$script: "wisdom";
$serif: "hoefler";

#california-newsletters {
  .california-newsletters-browser {
    background: white;
    padding-top: 40px;
  }

  #browser .type-selector {
    width: auto;

    button {
      @include square(50px);
      color: $yellow;
      background: $black_cmab;

      &:hover {
        color: $yellow_active;
      }

      font-family: cmab2;
      font-size: 24px !important;
      width: 49px !important;
      height: 49px !important;
      border: 2px solid $yellow;
      border-left: 0;
      background: transparent;
    }

    .searchform {
      width: auto;
      white-space: nowrap;

      #newsletter-search {
        color: $gray_input_text;
        background: $white;
        font-size: 23px;
        border: 2px solid $yellow;
        border-right: 0;
        height: 49px;
        width: 100%;
        @include break($mobile) {
          height: 49px;
        }
      }

      ::-webkit-input-placeholder {
        color: $yellow;
        opacity: 1 !important;
      }

      :-moz-placeholder {
        color: $yellow;
      }

      ::-moz-placeholder {
        color: $yellow;
      }

      :-ms-input-placeholder {
        color: $yellow !important;
      }
    }

    .search-form {
      position: static;
      margin: 0 !important;
      width: 28%;
      @include break($mobile) {
        width: 98%;
        margin-top: 10px !important;
      }

      .searchform {
        margin: 0 !important;
        width: calc(100% - 50px);
      }
    }
  }
}

.newsletters {
  .chosen-select,
  .chosen-container-single,
  .chosen-container-active.chosen-with-drop {
    margin-right: 7px;
    text-align: left;

    .chosen-single {
      @include border-radius-and-pie(0);
      background: $white url(../images/select-arrow-yellow.png) no-repeat;
      background-position: 175px;
      box-shadow: 0 0 0 transparent;
      padding: 11px 0 0 12px !important;
      border: 0;

      span {
        @include bold-headline;
        font-size: 23px !important;
        color: $black;
      }
    }

    .chosen-drop {
      @include border-radius-and-pie();
      border: 0;
      border-radius: 0;
      background: $white;

      .chosen-results {
        padding: 0;
        margin: 0;

        li {
          @include bold-headline;
          background: $white;
          font-size: 23px;
          padding: 12px;

          &:hover {
            background: #f1f0ef;
          }
        }
      }
    }
  }
}

.module.newsletter-content {
  .content-container {
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 960px;
      padding: 40px 0;
    }

    .bg-gray {
      background-color: #e1e1e1;
    }

    .bg-black {
      background-color: #000000;
    }

    .yellow-text {
      color: $yellow-med;
    }

    .line-height-tall {
      @media (min-width: 992px) {
        p {
          line-height: 1.4;
        }

        li {
          line-height: 1.4;
        }
      }
    }

    .line-height-normal {
      line-height: normal;
    }

    .rcm-banner {
      h4 {
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 18px;
        }
      }

      h3 {
        font-size: 27px;
        @media (min-width: 768px) {
          font-size: 30px;
        }
      }

      p {
        font-size: 14px;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: $sans, sans-serif;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 54px;
      @media (min-width: 1024px) {
        font-size: 84px;
      }

      > strong {
        color: $yellow;
      }

      &.text-uppercase {
        text-transform: uppercase;
      }
    }

    h2 {
      font-size: 36px;

      a {
        text-decoration: none;
        font-size: 36px;
        color: $yellow;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }

        @include break($mobile) {
          font-size: 28px;
        }
      }

      > strong {
        color: $yellow;

        > em {
          font-size: 20px;
          font-family: $serif;
          display: block;
          margin-top: -10px;
          margin-bottom: 30px;
        }
      }
    }

    h3 {
      font-size: 30px;

      > strong {
        color: $gray_title;
      }
    }

    h4 {
      font-size: 20px;
    }

    .technomic {
      font-size: 16px;
    }

    a {
      color: $yellow;

      &.download {
        @include link-download;
        text-transform: uppercase;
        font-family: "dinbold";
        @include break($mobile) {
          display: block;
          margin-top: 5px;
          font-size: 15px;
        }
      }

      &.black-link {
        color: inherit;
        text-decoration: underline;
        overflow-wrap: break-word;
      }
    }

    header {
      h1 {
        margin-bottom: 0;

        &:only-child {
          margin-bottom: 30px;
        }
      }

      &.trends-newsletter {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include break($mobile) {
          display: block;
          margin-bottom: 20px;
        }

        h1:only-child {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;
          @include break($mobile) {
            margin-bottom: 20px;
          }
        }
      }
    }

    p > strong {
      font-weight: bold;
      font-style: italic;
      font-size: 20px;
    }

    em {
      font-style: italic;
    }

    p {
      font-family: $serif;
      margin-bottom: 30px;
      font-size: 20px;

      > .red-title {
        color: #d1252f;
        font-size: 22px;
        font-weight: bold;
        padding-bottom: 0.5rem;
      }

      &.pic-description {
        font-size: 14px;
      }

      .gothic-black {
        font-family: gothic, sans-serif;
        font-size: 24px;
      }

      .gothic-red {
        font-family: gothic, sans-serif;
        font-size: 24px;
        color: #d1252f;
      }

      .helvetica-light {
        font-family: helvetica-light-condensed, sans-serif;
      }
    }

    li {
      list-style: disc;
      font-family: $serif;
      margin-bottom: 30px;
      font-size: 20px;
      margin-left: 15px;

      &.red-bullet {
        color: #d1252f;

        span {
          color: black;
        }
      }
    }

    .back-link {
      font-family: $sans;
      font-size: 26px;
      text-transform: uppercase;
      color: $yellow;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      i {
        background: url("../images/subnav-arrows.png") 0 0 no-repeat;
        cursor: pointer;
        display: block;
        height: 40px;
        width: 40px;
      }
    }

    img {
      max-width: 100%;
      margin-bottom: 30px;

      &.rcm-roundup {
        max-height: 70px;
      }
    }

    .cali-newsletter {
      text-align: center;
      margin-top: 40px;

      h1 {
        margin: 40px 0;
      }

      h3 {
        width: 60%;
        margin: 0 auto 40px;
        @include break($mobile) {
          width: 84%;
        }
      }
    }
  }
}

.is-trends-dairy.header-wrapper-2 {
  background: url(../images/trends-dairy-header.jpg) no-repeat top center;
  background-size: cover;
  height: 500px;
  @include break($mobile) {
    background: url(../images/trends-dairy-header_mobile.jpg) no-repeat top
      center;
    background-size: cover;
    overflow: visible;
    height: 350px;
  }
}

.is-cali-newsletter-listing.header-wrapper-2 {
  background: url(../images/header-california-newsletters.jpg) no-repeat top
    center;
  background-size: cover;
  height: 500px;
  @include break($mobile) {
    background: url(../images/header-california-newsletters_mobile.jpg)
      no-repeat top center;
    background-size: cover;
  }
}

.is-cali-newsletter-detail.header-wrapper-2 {
  background: url(../images/header-california-newsletter.jpg) no-repeat top
    center;
  background-size: cover;
  @include break($mobile) {
    background: url(../images/header-california-newsletter_mobile.jpg) no-repeat
      top center;
    background-size: cover;
  }
}

.is-product-locator.header-wrapper-2 {
  background: url(../images/header-product-locator.jpg) no-repeat top center;
  background-size: cover;
  height: 340px;

  h1 {
    margin-top: 108px;
  }

  p {
    color: #fff;
    text-transform: uppercase;
    font-family: "gothic", "sans-serif";
    font-size: 1.7rem;
    text-align: center;
  }

  @include break($mobile) {
    background: url(../images/header-product-locator.jpg) no-repeat top left;
    background-size: cover;
    h1 {
      width: 100%;
    }
    p {
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 1.5rem;
    }
  }
}

.newsletter-subnav {
  position: relative;
  @include break($mobile) {
    top: -23px;
  }

  .subnav {
    bottom: 0;
    left: 50%;
    margin: 0 auto;
    max-width: 960px;
    padding: 0 60px;
    width: 100%;
    @include break($mobile, true) {
      bottom: auto;
      height: 30px;
      max-width: 100%;
      padding: 0;
      top: 165px;
    }

    &.open {
      .expand {
        transform: rotateX(180deg);
      }

      ul {
        @include break($mobile, true) {
          background: $white;
          height: initial;
          overflow: visible;
        }
      }
    }

    i {
      background: url("../images/subnav-arrows.png") 0 0 no-repeat;
      cursor: pointer;
      display: block;
      height: 40px;
      left: 0;
      position: absolute;
      text-indent: -9999px;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      @include break($mobile, true) {
        display: none;
      }

      &:nth-of-type(2) {
        background-position: -40px 0;
        left: auto;
        right: 0;
      }
    }

    .scroller {
      overflow: hidden;
      position: relative;
      width: 100%;
      @include break($mobile, true) {
        height: 30px;
        left: 0;
        overflow: visible;
        top: 0;
        text-transform: uppercase;
      }
    }

    .expand {
      display: none;
      transition: all 200ms;

      @include break($mobile, true) {
        background: url("../images/icon-dropdown.png") 94% 50% no-repeat;
        background-size: 11px 8px;
        display: block;
        height: 30px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
      }
    }

    $subnavScrollerUnit: 280px;

    ul {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      white-space: nowrap;
      @include break($mobile, true) {
        border: 2px solid $yellow;
        height: 30px;
        left: initial;
        overflow: hidden;
        flex-direction: column;
        margin: 0 auto;
        transform: none;
        width: 100%;
      }

      &.reverse {
        @include break($mobile, true) {
          transform: none;
        }
      }

      &.done {
        transform: none;
        transition: all 400ms;
      }
    }

    li {
      border-right: 1px solid $gray_bprder_subnav;
      font: 26px/1.25 "dinbold";
      flex-grow: 1;
      order: 3;
      padding: 20px;
      width: auto;
      @include break($mobile, true) {
        font-size: 13px;
        height: auto;
        padding: 5px 20px;
        width: auto;
      }

      &:last-of-type {
        @include break($mobile, true) {
          margin-bottom: 10px;
        }
      }

      &.active {
        color: $yellow;
        @include break($mobile, true) {
          order: 1;
          padding: 0 20px;
        }

        a {
          color: $yellow;
        }
      }
    }

    a {
      color: $white;
      text-decoration: none;
      @include break($mobile, true) {
        color: $black_cmab;
        display: block;
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    span {
      display: block;
      text-align: center;
      @include break($mobile, true) {
        text-align: left;
      }
    }
  }
}
