.module.a-return-to-real {
  padding: 100px 0 50px 0;
  position: relative;

  .header {
    text-align: center;

    .heading {
      margin-bottom: 14px;
    }

    .headline {
      @include headline-accent-3($yellow-ada);
    }

    .subheadline {
      @include bold-headline-5;
    }

    .description {
      p {
        @include paragraph-large;
      }
    }
  }

  .slide-wrapper-outer {
    margin: 0 auto;
    width: 960px;

    .slide-wrapper-inner {
      padding: 50px 0 30px;
    }

    .slide {
      display: inline-block;
      text-align: center;
      width: 25%;
    }

    .image-container {
      height: 170px;
      margin-bottom: 10px;
      width: 100%;
    }

    img {
      transition: all 0.2s;
      width: 80%;
    }

    h4 {
      @include span-block;

      .headline {
        @include headline-accent-4($yellow-ada);
      }

      .subheadline {
        @include bold-headline-7;
      }
    }

    a {
      display: block;
      text-decoration: none;

      &:hover {
        .subheadline {
          color: $yellow;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    padding: 10.87% 0 0;
    .slide-wrapper-outer {
      padding: 0 9.37%;
      width: 100%;

      .slide-wrapper-inner {
        padding: 11.87% 0 0;
      }

      .slide {
        height: auto;
        margin-bottom: 20%;
        width: 100%;
      }

      .image-container {
        height: auto;
        margin-bottom: 2%;
      }

      img {
        width: 55%;
      }

      a {
        &:hover {
          img {
            width: 55%;
          }
        }
      }
    }
  }
}

.carousel.whats-fresh-from-califarmia {
  background-color: $yellow;
  @include chevron($yellow);

  height: 680px;
  padding: 100px 0;
  position: relative;
  width: 100%;

  .header {
    margin-bottom: 30px;

    .heading {
      @include span-block;

      .headline {
        @include bold-headline-3;
        letter-spacing: -1px;
      }

      .subheadline {
        @include headline-accent-3($white);
        padding-left: 10px;

        sup {
          font-family: arial, helvetica, sans-serif;
          font-size: 25%;
          position: relative;
          top: -10px;
        }
      }
    }
  }

  .slide-wrapper-outer {
    margin: 0 auto;
    position: relative;
    width: 960px;
  }

  .image-container {
    left: 408px;
    position: absolute;
    top: 20px;
    z-index: 0;

    .image {
      height: 454px;
      width: 454px;

      &:after {
        display: block;
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url("../images/edgemask.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      .circle {
        @include border-radius-and-pie();
      }
    }
  }

  .content-container {
    width: 40%;

    h4 {
      margin-bottom: 15px;

      .headline {
        @include subheadline-2;
        letter-spacing: -0.5px;
      }
    }

    p {
      @include paragraph-tiny;
      padding-bottom: 20px;
    }

    .cta {
      @include cta-no-border($black, $white, $white, $black, auto);
      display: inline-block;
    }
  }

  @include bp(mediumWidth) {
    height: auto;
    padding: 12.5% 0;
    text-align: center;
    .header {
      margin-bottom: 20px;
    }
    .slide-wrapper-outer {
      padding: 0 9.37%;
      width: 100%;
    }
    .image-container {
      height: 230px;
      left: 0;
      margin: 0 auto 7%;
      position: relative;
      top: 0;
      width: 230px;

      .image {
        height: 100%;
        width: 100%;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
    .content-container {
      width: 100%;

      h4 {
        margin-bottom: 4%;
      }

      li {
        display: block;
      }

      .cta {
        min-width: 48%;
      }
    }
  }
}

.carousel.brought-to-you-by {
  background-color: $yellow;
  padding: 4vw 0 4.2vw;
  @include break($desktop) {
    padding: 35px 0 55px;
  }
  position: relative;
  width: 100%;

  .slide-wrapper-outer {
    margin: 0 auto;
    position: relative;
    max-width: 960px;
    width: 73%;
  }

  .slide {
    h4 {
      @include span-block;

      .headline {
        @include headline-accent-4($white);
        font-size: 1.75vw;
        @include break($desktop-wide) {
          font-size: 25px;
        }
      }

      .subheadline {
        @include subheadline-1;
        font-size: 3.5vw;
        @include break($desktop-wide) {
          font-size: 50px;
        }
      }
    }

    .cta {
      @include cta-no-border($black, $white, $white, $black, auto);
    }
  }

  @include bp(mediumWidth) {
    text-align: center;
    .slide-wrapper-outer {
      padding: 0 21%;
      width: 100%;
    }
    .slide {
      li {
        display: block;
      }

      .cta {
        min-width: 69%;
        position: relative;
        right: auto;
        top: 0;
      }

      h4 {
        .headline {
          line-height: 17px;
        }

        .subheadline {
          padding: 0 0 25px;
        }
      }
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    @include break($desktop) {
      .content-body {
        align-self: flex-end;
      }
    }
    @include break($mobile) {
      flex-direction: column;
    }
  }
}

.carousel.home-resources-for-2 {
  border-bottom: $gray_border 1px solid;
  border-top: $gray_border 1px solid;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 20px 0 50px 0;
  position: relative;
  width: 80%;

  .slide-wrapper-outer {
    margin: 0 auto;
    position: relative;
    width: 960px;
  }

  .slide {
    h4 {
      @include span-block;

      .headline {
        @include headline-accent-4($yellow-ada);
        line-height: 35px;
      }

      .subheadline {
        @include subheadline-1;
        line-height: 35px;
        margin-bottom: -11px;
      }
    }

    li {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      position: absolute;
      right: 0;
      top: 24px;

      a {
        display: inline-block;
        font-family: $sans;
        font-size: 20px;
        line-height: 22px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          color: #b48811;
          background-position: 0 -27px;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    text-align: center;
    .slide-wrapper-outer {
      padding: 0 21%;
      width: 100%;
    }
    .slide {
      li {
        display: block;
      }

      .cta {
        min-width: 69%;
        position: relative;
        right: auto;
        top: 0;
      }

      h4 {
        .headline {
          line-height: 17px;
        }

        .subheadline {
          padding: 0 0 -0.31%;
          line-height: 35px;
        }
      }

      li {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        position: relative;
        right: 0;
        top: 24px;

        a {
          color: $yellow;
          display: inline-block;
          font-family: $sans;
          font-size: 20px;
          line-height: 22px;
          text-transform: uppercase;
          text-decoration: none;

          &:hover {
            color: #b48811;
            background-position: 0 -27px;
          }
        }
      }
    }
  }
}

.recipes-cookbook {
  background-color: $yellow;
  height: auto;
  padding: 100px 0;
  width: 100%;

  .shadow {
    display: none;
  }

  .single-recipe &,
  #recipes & {
    padding-bottom: 250px;
    @include chevron("yellow");
    @include break($mobile) {
      margin-top: 50px;
      padding-top: 50px;
      padding-bottom: 68%;
    }
  }

  @include break($tablet) {
    #food &::before {
      display: none !important;
    }
    #food & {
      //margin-top: -100px;
    }
  }

  .heading {
    display: none;
    @include span-block;

    .headline {
      @include headline-accent-3($white);
    }

    .subheadline {
      @include bold-headline-3;
    }

    padding-bottom: 23px;
    @include break($mobile) {
      padding-bottom: 13px;
    }
  }

  h4 {
    @include span-block;

    .headline {
      @include headline-accent-3($white);
    }

    .subheadline {
      @include bold-headline-3;
      @include break($tablet) {
        margin-top: 10px;
      }
    }

    padding-bottom: 23px;
  }

  p {
    &.title {
      @include subheadline-2;
      color: $white;
      line-height: 25px;
      padding-bottom: 14px;
    }

    &.description {
      @include paragraph-tiny;
      padding-bottom: 15px;
    }
  }

  .slide-wrapper-outer {
    height: auto;
    margin: 0 auto;
    width: 788px;
  }

  .slide-wrapper-inner {
  }

  .content-container,
  .image-container {
    //display: inline-block;
  }

  .slide {
    display: flex;
  }

  .image-container {
    height: 295px;
    left: auto;
    right: auto;
    top: auto;
    position: relative;
    //width: 254px;

    @include break($tablet) {
      flex: 0 0 365px;
      height: 355px;
    }

    img {
      height: auto;
      max-width: 100%;
      @include break($tablet) {
        width: 100%;
      }
    }
  }

  .content-container {
    margin-left: -18px;
    //padding-left: 14px;
    //width: 494px;
  }

  ul {
    li {
      float: left;
      margin-right: 14px;
    }
  }

  .cta {
    @include cta-no-border($black, $white, $white, $yellow, auto);
    display: inline-block;
  }

  @include break($mobile) {
    height: auto;
    padding: 9% 0;
    width: 100%;
    .heading {
      display: block;
      text-align: center;

      .subheadline {
        font-size: 46px;
      }
    }
    h4 {
      display: none;
    }
    .shadow {
      display: none;
    }
    .slide-wrapper-outer {
      margin: 0 auto;
      padding: 0 3%;
      width: 100%;
    }
    .slide {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-flow: column;
    }
    .content-container,
    .image-container {
    }
    .image-container {
      height: auto;
      width: 144px;
      margin: 0 auto 20px;

      img {
        max-width: 100%;
      }
    }
    .content-container {
      //width: 100%;
      width: 70%;
      margin: 0 auto;
      text-align: center;

      p {
        &.title {
          color: $white;
          padding-right: 3%;
        }
      }

      ul {
        width: 100%;

        li {
          float: none;
          margin-bottom: 3%;
          margin-right: 0;
          min-width: 80%;
        }

        .cta {
          //width: 100%;
          //font-size: 18px;
        }
      }
    }
  }
}

.carousel.food-the-history {
  padding: 70px 0 100px;
  position: relative;

  .slide-1 {
    .subheadline {
      order: -1;
    }

    .subheadlinetwo {
      margin-top: 10px;
    }
  }

  h4 {
    display: flex;
    flex-flow: column;

    @include span-block;

    .headline {
      @include bold-headline-4;
      @include break($mobile) {
        font-size: 35px;
      }
    }

    .subheadline {
      @include headline-accent-3($yellow-ada);
      @include break($tablet) {
        font-size: 45px;
        margin-top: -9px;
      }
    }

    .subheadlinetwo {
      &::before,
      &::after {
        content: " \2013 ";
      }

      color: $blue_dark;
      font-family: $sans;
      font-size: 20px;
      margin-top: 18px;
      margin-bottom: 38px;
      @include break($mobile) {
        margin-top: 8px;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }

  .slide-wrapper-outer {
    width: 960px;
    margin: 0 auto;
  }

  .slide {
    //border-bottom: 1px solid #ddd;
    padding: 55px 0 45px 45px;
    //height: 320px;
    text-align: center;
    position: relative;

    .image-container {
    }

    &:last-child {
      border-bottom: 0;
    }

    .content-container {
      margin-top: 20px;

      .headline {
        //@include subheadline-2;
        text-transform: uppercase;
      }

      .content-body {
        margin-top: 10px;
      }

      .read-more {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        margin-top: 15px;
      }

      .copy {
        @include paragraph-medium;
        text-align: left;
      }
    }

    .description {
      @include paragraph-tiny;
      position: absolute;
      width: 190px;
      top: 70px;
      left: 0;
      box-sizing: content-box;
      padding: 50px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      color: white;
      font-size: 12px;
      line-height: 1.4;

      @include break($mobile) {
        width: 140px;
      }

      @include break($tablet) {
        background-image: url("../images/food-dairy-diversity-bg-subslide.png");
      }

      @include break($mobile) {
        background-image: url("../images/food-dairy-diversity-bg-subslide-top-left.png");
      }

      .heading {
        display: block;
        font-family: $sans;
        font-size: 31px;

        @include break($mobile) {
          margin-bottom: 15px;
          font-size: 22px;
        }
      }
    }
  }

  @include break($mobile) {
    width: 100%;
    padding: 0;
    .header {
      text-align: center;
    }
    .headline {
      font-size: 35px;
      margin-top: 6px;
    }
    .subheadline {
      font-size: 25px !important;
    }
    .subheadlinetwo {
      margin-top: 8px;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .slide-wrapper-outer {
      width: 100%;
    }
    .slide {
      border: 0;
      height: auto;
      overflow: auto;
      padding: 10% 2%;

      //padding-bottom: 270px;
      &:nth-child(1) {
        //border-bottom: 1px solid $black_cmab;
      }

      //TODO: DEBT
      .description {
        bottom: 40px;
        top: auto;
        position: static;
        margin: 20px auto 0;
        font-size: 8px;
        line-height: 1.2;
      }

      .image-container {
        ///float: left;
        //height: 80px;
        width: 105px;
        margin: 0 auto;
        position: relative;
        top: 0;
        //width: 80px;
        .image {
          height: auto;
          max-width: 100%;
        }

        .title,
        .description {
          display: none;
        }

        .shadow {
          background-size: 100% auto;
          bottom: -2px;
          height: 4px;
          margin-left: -21px;
          //width: 42px;
        }
      }

      .content-container {
        //float: left;
        padding: 0 25px;
        position: relative;
        margin-top: 0;
        //width: 72%;
        .read-more {
          width: auto;
        }
      }
    }
  }
}

.community-partners {
  background-color: $yellow;
  padding: 95px 0;
  width: 100%;

  .heading {
    @include span-block;
    text-align: center;

    .headline {
      @include bold-headline-3;
      padding-bottom: 10px;
    }

    .subheadline {
      @include headline-accent-2($white);
      padding-bottom: 8px;
    }
  }

  .description {
    p {
      @include paragraph-em-small;
      color: $white;
      padding-bottom: 20px;
      text-align: center;
    }
  }

  h4 {
    @include span-block;
    background: url("../images/community-partners-bubble.png") 0 0 no-repeat
      transparent;
    color: $blue_light;
    height: 115px;
    left: 0;
    position: absolute;
    top: -60px;
    width: 99px;

    .headline {
      font-family: $sans;
      font-size: 20px;
      height: 34px;
      padding-top: 34px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }
  }

  p {
    &.title {
      @include subheadline-2;
      letter-spacing: -1px;
      padding: 24px 0 14px;
    }

    &.textarea {
      @include paragraph-tiny;
      padding-bottom: 36px;
    }

    &.footnote {
      @include paragraph-footnote;
      padding-bottom: 36px;
    }
  }

  .slide-wrapper-outer {
    height: 100%;
    margin: 0 auto;
    width: 960px;
  }

  .slide-wrapper-inner::after {
    content: "";
    display: table;
    clear: both;
  }

  .slide {
    float: left;
    padding: 0 14px;
    width: 50%;
  }

  .image-container {
    width: 100%;
  }

  .content-container {
    padding: 0 20px 0 90px;
    position: relative;
    width: 100%;
  }

  .cta {
    @include cta-no-border($black, $white, $white, $black, auto);
    display: inline-block;
  }

  @include bp(mediumWidth) {
    height: auto;
    padding: 40px 0 60px;
    width: 100%;
    h4 {
      display: none;
    }
    .description {
      display: none;
    }
    .slide-wrapper-outer {
      height: auto;
      margin: 0;
      position: relative;
      width: 100%;
    }
    .slide-wrapper-inner {
    }
    .slide {
      float: none;
      padding: 0;
      width: 100%;

      .image-container {
        text-align: center;
      }

      img {
        margin: 33px 23px !important;
        max-width: 91% !important;
      }
    }
    .content-container {
      padding: 0 10px;
      text-align: center;
    }
  }
}
