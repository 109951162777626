.recipe-grid-container {
  &.breakfast {
    .recipe-card {
      color: black;
      background-color: white;

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, 160px);
        font-size: 18px;
      }
    }

    .more-recipes-cta {
      @include cta-no-border($black, $white, $white, $black, "fit-content");
    }
  }

  .title {
    text-transform: uppercase;
    font-family: gothic, sans-serif;
    font-size: 56px;
    @include break($tablet) {
      font-size: 72px;
    }
  }

  .description {
    font-family: $serif, Serif;
    font-size: 20px;
    @include break($tablet) {
      font-size: 18px;
    }
  }

  .recipe-card {
    color: $white;
    text-align: left;
    background-color: $yellow-partnership;
    max-width: 380px;

    img {
      width: 100%;
    }

    .recipe-box {
      height: 300px;
      min-height: 210px;
    }

    .title {
      text-transform: uppercase;
      font-family: gothic, sans-serif;
      font-size: 30px;
    }

    .subtitle {
      font-family: hoefler, serif;
      font-size: 16px;
    }

    .cta {
      @include cta-no-border($black, $white, $white, $black, 160px);
      font-size: 18px;
    }
  }

  .featured-recipes {
    padding-bottom: 2em;

    .header {
      display: none !important;
    }

    .details {
      padding: 25px !important;
      background-color: $yellow-partnership !important;

      .cta {
        background-color: $yellow-partnership !important;
      }
    }
  }

  .more-recipes-cta {
    @include cta-no-border($yellow, $black, $black, $yellow, "fit-content");
    font-size: 30px !important;
    padding: 0 30px !important;
    height: auto !important;
  }

  .cheese-container {
    &.expanded {
      .card {
        transition-timing-function: cubic-bezier(0.56, 0.19, 0.39, 1.42);
        transform: scale3d(1, 1, 1.5) rotate(0deg);
        z-index: 200;
      }
    }

    .img-fluid {
      margin: 0 auto;
    }

    @include break($desktop-lg) {
      &:nth-of-type(1) {
        .img-fluid {
          margin-right: -50px;
        }
      }
      &:nth-of-type(2) {
        .img-fluid {
          margin-left: -50px;
          margin-right: -50px;
        }
      }
    }
  }

  .cheese-title {
    text-transform: uppercase;
    font-family: gothic, sans-serif;
    font-size: 32px;
    @include break($tablet) {
      font-size: 30px;
    }
  }

  .title-container {
    margin-bottom: -30px;
  }

  .more-info {
    @include circle(45px, $teal);
    transition: all 0.2s;
    transform: scale3d(1, 1, 2) rotate(0deg);
    color: $white;
    cursor: pointer;
    font-family: "Verdana", sans-serif;
    font-size: 40px;
    line-height: 40px;
    z-index: 100;

    &:hover {
      background-color: $blue_dark;
    }
  }

  .card {
    color: $white;
    border-radius: 33px;
    transform-origin: top right;
    transition: all 0.2s ease-in;
    transform: scale3d(0, 0, 1.5) rotate(45deg);
    background-color: $teal;
    position: absolute;
    text-align: center;
    top: 220px;
    right: 0;
    left: 0;
    width: auto;
    z-index: 0;

    .close-card {
      cursor: pointer;
      position: absolute;
      top: -12px;
      right: -12px;
      z-index: 300;
    }

    .card-heading {
      cursor: pointer;
      font-family: gothic, sans-serif;
      font-size: 32px;
      @include break($tablet) {
        font-size: 30px;
      }

      .audio-icon {
        display: inline-block;
        margin-bottom: -3px;
      }
    }

    .card-description {
      font-family: $serif, serif;
      font-size: 16px;
    }

    .card-cta {
      font-family: gothic, sans-serif;
      text-decoration: underline;
      font-size: 16px;
      @include break($tablet) {
        font-size: 18px;
      }

      a {
        color: $white;

        &:visited {
          color: $white;
        }
      }
    }
  }

  .cheese-slider {
    padding-top: 3em;

    .slick-list {
      overflow: visible;
    }

    .slick-dots {
      padding: 0;

      button {
        &::before {
          z-index: -1;
        }
      }
    }
  }
}
