.partnership-carousel {
  .featured-recipes {
    padding: 50px 0 50px;

    @include break($tablet) {
      padding: 114px 0 114px;
    }

    &.cookbook-carousel {
      body#recipes & {
        padding: 50px 0 200px;

        @include break($tablet) {
          padding: 114px 0 200px;
        }
      }

      .details {
        background-color: white !important;
      }

      .cta-partnership {
        @include cta-no-border($black, $white, $yellow, $black, 160px);
      }
    }

    .partner-overlay {
      color: $white;
      text-align: left;
      font-family: $serif;
      font-size: 16px;

      @include break($tablet) {
        font-size: 18px;
      }
    }

    .headline {
      font-size: 30px !important;
      @include break($tablet) {
        font-size: 48px !important;
      }
    }

    .below {
      font-size: 24px !important;
      @include break($tablet) {
        font-size: 32px !important;
      }
    }

    .details {
      text-align: left;
      background-color: $teal !important;
      height: 285px;
      @include break($tablet) {
        height: 450px !important;
      }
    }

    .cta-partnership {
      @include cta-no-border($black, $white, $white, $black, 160px);
    }

    .photo {
      height: 250px !important;

      @include break($tablet) {
        height: 450px !important;
      }
    }
  }
}
