/* Overlay Global Styles */
.toggle-overlay {
  cursor: pointer;
}

.not-mobile {
  .overlay-wrapper,
  .health-wellness-overlay {
    @include break($tablet) {
      .close-button {
        width: 400px;
        margin: 5% auto 0;
        z-index: 3;
        position: fixed;
        left: 50%;
        top: 0;

        div {
          background: url("../images/icons/close.png") 0 0 no-repeat transparent;
          text-indent: -9999em;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: -45px;
          height: 26px;
          width: 26px;
          z-index: 4;

          &:hover {
            background-position: 0 -30px;
          }
        }
      }
    }
  }
}

.mobile {
  .close-button {
    width: 35px;
    height: 35px;
    margin: 5% auto 0;
    position: fixed;
    right: 0;
    top: 3%;
    z-index: 3;

    div {
      width: 35px;
      height: 35px;
      background: $black-cmab url(../images/icon-close-yellow.png) center
        no-repeat;
      @include border-radius-and-pie(50% 0 0 50%);
      text-indent: -9999px;
      position: static;
    }
  }
}

.overlay-wrapper,
.health-wellness-overlay {
  opacity: 0;
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background-color: #000;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  overflow-y: auto;

  html.ie8 & {
    z-index: 40;
    filter: none;
  }

  html.ie9 & {
    box-sizing: content-box;
  }

  a {
    &.back {
      background: url("../images/icons/sprite-back.png") 0 0 no-repeat
        transparent;
      display: inline-block;
      font-family: $sans;
      font-size: 25px;
      height: 38px;
      line-height: 38px;
      overflow: visible;
      padding-left: 50px;
      text-transform: uppercase;

      &:hover {
        background-position: 0 -42px;
      }
    }
  }

  &.loaded {
    .inner {
      background-image: none;
    }
  }

  .inner {
    width: 800px;
    margin: 5% auto 10%;
    position: relative;
    overflow-x: hidden;
    background: $white url(../images/cmab_loading_gif_fast.gif) center no-repeat;
    background-size: 100px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
    color: $black_cmab;
    min-height: 800px;

    .logo {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .header {
      padding: 0;
    }

    .body {
      padding: 0;

      .recipe {
        padding: 0;
      }
    }

    .recipe {
      .header {
        display: none;
      }
    }
  }

  .cowhealth {
    @include break($mobile) {
      &.template-wrapper {
        padding: 5% 0;
      }
    }

    .headline {
      background: url("../images/cowology-health-overlay.png") 50% 0 no-repeat
        $white;
      margin-top: 20px;
      padding: 128px 0 10px;
    }

    .content {
      padding: 0 50px;
    }

    h3 {
      padding-bottom: 10px;

      .title {
        @include headline-accent-4($yellow-ada);
        padding-right: 4px;
      }

      .description {
        @include subheadline-3;
      }
    }

    h4 {
      @include subheadline-2;
    }

    p {
      @include paragraph-tiny;
    }

    h3,
    h4,
    p {
      text-align: left;
    }

    .animal-care-video {
      margin-bottom: 50px;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  @include bp(mediumWidth) {
    a {
      &.back {
        background: url("../images/icons/sprite-back.png") 0 0 no-repeat
          transparent;
        background-size: 20px auto;
        font-size: 18px;
        height: 20px;
        line-height: 20px;
        padding-left: 30px;

        &:hover {
          background-position: 0 -20px;
        }
      }
    }

    .inner {
      width: 95%;
      margin-top: 3%;

      .logo {
        img {
          width: 25%;
        }
      }

      .header {
        margin-bottom: 10%;

        .headline {
          margin: 0 auto;
        }
      }

      .body {
        width: 100%;

        .question {
          font-size: 25px;
          line-height: 25px;
          padding-top: 3px;
          padding-left: 45px;
        }

        .answer {
          padding-left: 45px;
          font-size: 16px;
          line-height: 20px;
          padding-top: 6px;
        }
      }
    }
  }
}

/* Template Styles */
.template {
  display: none;

  .header {
    padding: 3%;
  }
}

//Template wrapper should have all these, but not the display:none;
.template,
.outer-wrapper .template-wrapper,
.health-wellness-overlay {
  &.open {
    display: block;

    .template {
      display: block;
    }
  }

  .header {
    text-align: center;

    .headline {
      font: 100px/80px $sans;
      @include upper;
    }

    .subheadline {
      font: 50px/50px $script;
      color: $yellow-ada;
    }

    .description {
      font: 24px/36px $serif;

      .mobile & {
        font: 15px/1.4 $serif;
      }

      color: $gray_dark;
    }

    .icon {
      margin-bottom: 30px;
    }
  }

  p {
    margin: 20px 0;
  }

  .story {
    .header {
      padding-top: 40px;

      img {
        margin-bottom: 20px;
      }

      .image-text {
        font: 18px $sans;
        text-transform: uppercase;
        color: $teal;
        position: absolute;
        top: 130px;
        right: 180px;
      }

      .icon {
        margin: 20px auto;
      }
    }

    .post-content {
      text-align: center;
      margin: 0 auto 50px;
      font: 18px/26px $serif;
      width: 63%;

      p,
      div {
        margin-left: auto;
        margin-right: auto;
        font: 18px/26px $serif;
      }

      p.emphasized {
        color: $olive;
        width: 120%;
        font: 24px/34px $serif;
        position: relative;
        right: 10%;
      }

      h2 {
        font: 32px/32px $sans;
        text-transform: uppercase;
        margin: 35px 0 15px;

        &.border-top {
          padding-top: 45px;
          border-top: 2px solid #eee;
          margin: 35px 80px 15px;
        }
      }
    }

    @include bp(mediumWidth) {
      .header {
        .title-img {
          width: 30%;
          margin-bottom: 1%;
        }

        .image-text {
          display: none;
        }

        .headline {
          font: 45px/45px $sans;
        }

        .subheadline {
          font: 18px/18px $script;
        }

        .description {
          font: 16px/24px $serif;
        }
      }
      .body {
        .post-content,
        .post-content p {
          width: 100%;
          font: 14px/22px $serif;

          .emphasized {
            width: 88%;
            margin: 5% auto;
            position: static;
            font: 16px/24px $serif;
          }
        }
      }
    }
  }

  &[data-dialog-id="406"] {
    .header {
      .headline {
        color: $yellow-ada;
        font: 50px/50px $script;
        text-transform: none;
      }

      .subheadline {
        font: 100px/80px $sans;
        text-transform: uppercase;
        color: $black-cmab;
      }

      .description {
        margin: 30px auto 0;
        width: 70%;
        line-height: 34px;
      }
    }

    .icon {
      margin-bottom: 0;
    }

    .border-top:before {
      content: "";
      display: block;
      width: 20%;
      margin: 30px auto 25px;
      border-top: 1px solid #ddd;
    }

    @include bp(mediumWidth) {
      .header {
        .headline {
          font: 20px/20px $script;
        }

        .subheadline {
          font: 45px/45px $sans;
        }

        .description {
          width: 88%;
        }
      }
    }
  }

  .agenda,
  .dairy-princess {
    padding: 5%;
    text-align: center;

    .header {
      padding: 0;
    }

    .headline {
      font-size: 50px;
    }

    .description {
      color: $black-cmab;
      font-size: 18px;
      line-height: 22px;
      width: 60%;
      margin: 0 auto 40px;
    }

    .subheadline,
    ul li a {
      color: $black;
      font: 18px $serif;
      width: 65%;

      &:hover {
        text-decoration: underline;
      }
    }

    .subheadline {
      padding-bottom: 25px;
      border-bottom: 1px solid #ddd;
      margin: 0 auto;
      display: none;
    }

    ul {
      margin-top: 20px;

      li a {
        line-height: 30px;
      }
    }
  }

  .agenda {
    @include break($mobile) {
      .body {
        padding: 0 20px;

        h4 {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.template,
.outer-wrapper {
  .chart {
    display: none;

    &.active {
      display: block;
    }

    @include break($tablet) {
      width: 960px;
      margin: 0 auto;
    }

    .header {
      display: none;

      .title-img {
      }

      .image-text {
        color: $teal;
        font: 15px $serif;
        text-align: left;
        position: absolute;
        top: 75px;
        right: 250px;

        strong {
          font: 20px $sans;
          text-transform: uppercase;
          display: block;
        }
      }

      .headline {
        width: 65%;
        margin: 0 auto;
      }

      .description {
        width: 90%;
        margin: 20px auto;
      }
    }

    table {
      border-top: 3px solid $yellow-ada;
      color: black;
      font: 15px/17px $serif;

      tr:nth-child(odd) {
        background-color: $white;
      }

      tr:nth-child(n + 2) {
        .all & {
          display: table-row;
        }
      }

      td {
        border-left: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        padding: 15px 10px;
        vertical-align: top;
        width: 15%;
        line-height: 1.1;

        &.heading {
          font: 20px $sans;
          color: black;
          @include upper;
        }

        &:last-child {
          width: 30%;
        }
      }

      tr.heading {
        color: $olive;
        border-bottom: 3px solid $yellow-ada;
      }

      tr.heading td,
      td.heading {
        font: 26px $sans;
        @include upper;
      }
    }

    @include break($mobile) {
      .header {
        .headline {
          width: 80%;
          font: 45px/45px $sans;
        }

        .subheadline {
          font: 30px/30px $script;
        }

        .description {
          width: 100%;
          font: 18px/25px $serif;
        }
      }

      .mobile-no {
        display: none;
      }

      .mobile-yes {
        ul {
          li {
            .details {
              font: 16px/20px $serif;

              .label {
                font: 16px $sans;
                text-transform: uppercase;
                color: $olive;
                margin-top: 5%;
              }
            }
          }
        }
      }
    }
  }
}

.outer-wrapper .template-wrapper {
  > .header {
    .title-img,
    .headline {
      display: none;
    }
  }
}

.outline {
  $padding: 25px;
  color: $black_cmab;

  .outer-wrapper &.template-wrapper {
    .not-mobile & {
      width: $middle-width;
      margin: 0 auto;
    }
  }

  .header {
    padding: $padding;

    .headline {
      margin: 20px 0 50px;
    }

    .description {
      line-height: 34px;
    }
  }

  .cards-nav {
    margin: 20px 0 30px;

    li {
      color: $yellow-ada;
      font: 22px $sans;
      @include upper;
      cursor: pointer;
      display: inline-block;
      color: $yellow-ada;
      margin-right: 15px;
      padding-bottom: 5px;

      &:hover {
        border-bottom: 3px solid $yellow-ada;
      }

      &.active {
        color: $black_cmab;
        border-bottom: 3px solid $black_cmab;
      }
    }
  }

  .accordion {
    .term {
      padding-left: $padding;

      @include break($mobile) {
        padding-left: 0;
      }
    }

    .definition {
      padding: 0 $padding;
    }
  }

  .cards {
    $top-padding: 45px;
    $term-padding: 25px;

    > li {
      padding-top: $top-padding;
      border-top: 1px solid $gray_border;
      margin-top: $top-padding;
    }

    li {
      clear: both;

      &.active {
        display: block;
      }

      .term {
        @include upper;
        font: 34px/30px $sans;
        padding-bottom: $term-padding;

        @include break($mobile) {
          padding-left: $padding;
        }
      }

      .definition,
      .definition p {
        font: 18px/26px $serif;
        margin: 0;
      }

      .definition {
        padding-bottom: 50px;
        width: 80%;

        ul {
          li {
            display: block;
            list-style-type: disc;
            margin: 10px auto;
            padding-left: 1.1em;

            &::before {
              content: "\2022";
              display: inline-block;
              margin: 0 15px 0 -1.1em;
            }
          }
        }
      }
    }
  }

  @include bp(mediumWidth) {
    .header {
      .headline {
        margin: 5% auto;
        width: 70%;
        text-align: center;
        font-size: 50px;
        line-height: 45px;
        padding-bottom: 5%;
      }

      .description {
        font-size: 16px;
        line-height: 20px;
      }
    }
    ul li {
      .definition,
      .definition p {
        font: 20px/26px $serif;
        margin: 5% auto;
      }
    }
  }
}

.faq {
  padding: 25px;
  width: 950px;
  margin: 90px auto;

  @include break($mobile) {
    width: 100%;
  }

  @include break($mobile) {
    margin: 20px auto;
  }

  &.es {
    .body {
      .question {
        background: url(../images/sprite-faq-q.es.png) top left no-repeat;
      }

      .answer {
        background: url(../images/sprite-faq-a.es.png) top left no-repeat;
      }
    }
  }

  .header {
    display: none;
  }

  .body {
    width: 100%;
    margin: 0 auto;

    .question,
    .answer {
      vertical-align: top;
      padding-left: 55px;
      min-height: 55px;
    }

    .question {
      font: 34px/30px $sans;
      text-transform: uppercase;
      padding-top: 6px;
      background: url(../images/sprite-faq-q.gif) top left no-repeat;

      @include break($mobile) {
        font-size: 25px;
        line-height: 25px;
        padding-top: 3px;
        padding-left: 45px;
      }
    }

    .answer {
      background: url(../images/sprite-faq-a.gif) top left no-repeat;
      margin-bottom: 45px;
      font: 18px/24px $serif;
      padding-top: 12px;

      @include break($mobile) {
        padding-left: 45px;
        font-size: 16px;
        line-height: 20px;
        padding-top: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.overlay-carousel {
  height: 100%;
  left: 50%;
  margin-left: -480px;
  position: fixed;
  top: 0;
  width: 960px;

  .prev,
  .next {
    position: absolute;
    top: 50%;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  .slide-wrapper-outer {
    left: 50%;
    margin-left: -75%;
    position: absolute;
    top: 0;
    width: 810px;
  }

  .slide-wrapper-inner {
  }

  .slide {
    position: absolute;
    width: 810px;
  }
}

.glossary {
  &.template-wrapper {
    @include break($tablet) {
      width: 960px;
      margin: 0 auto;
    }
  }

  .header {
    .title-img {
      margin: 55px auto 15px;
    }

    .headline {
      margin-bottom: 75px;
    }
  }

  .body {
    sub {
      font-size: smaller;
      position: relative;
      top: 2px;

      &.lower {
        top: 5px; /* this font has numbers that go low, so this pushes the non-low numbers further. */
      }
    }

    .glossary {
      padding: 100px 5% 10%;

      @include break($mobile) {
        padding: 40px 20px;
      }

      .letter-index {
        border-top: 3px solid $yellow-ada;
        border-bottom: 3px solid $yellow-ada;
        padding: 16px 5px 12px;
        display: flex;

        &.secondary {
          @include break($tablet) {
            display: none;
          }
        }

        * {
          font: 30px $sans;
          text-transform: uppercase;
          flex: 1;
          text-align: center;
        }

        a,
        a:hover {
          text-decoration: none;
          color: $yellow-ada;

          &.active {
            color: $black_cmab;
            padding-bottom: 5px;
            border-bottom: 3px solid $black_cmab;
          }
        }

        span {
          color: #ddd;
        }

        &.top {
          a,
          a:hover {
            &.active {
              padding: 5px 0 0 0;
              border-bottom: 0;
              border-top: 3px solid $black_cmab;
            }
          }
        }
      }

      .letter-tables {
        .table {
          display: table;
          border-bottom: 3px solid $yellow-ada;

          .row {
            display: table-row;
            width: 100%;

            &:nth-child(2n) {
              background-color: $gray_pale;
            }

            div {
              display: table-cell;
              border-bottom: 1px solid #ddd;
              vertical-align: top;
              padding: 35px 0;
              font: 18px/28px $serif;

              a,
              a:hover {
                color: $olive;
                text-decoration: none;
              }
            }

            &:last-child {
              border-bottom: 0;
            }

            .heading {
              padding: 35px 20px 35px 0;
              width: 30%;
              font: 34px/30px $sans;
              text-transform: uppercase;
            }

            &:last-child {
              @include break($tablet) {
                border-bottom: 0;
                width: 70%;
              }
            }
          }
        }
      }
    }
  }

  @include bp(mediumWidth) {
    .header {
      .headline {
        font-size: 45px;
        line-height: 45px;
        width: 100%;
      }
    }

    .body {
      .glossary {
        .letter-index {
          width: 100%;
          flex-flow: row wrap;

          a,
          a.active,
          span {
            margin-right: 6px;
            display: inline-block;
            padding-bottom: 1% !important;
            margin-bottom: 2%;
            flex: 1 0 5%;
          }
        }

        .letter-tables {
          padding: 3% 0 8%;

          .table {
            border-bottom: none;
            padding: 20px;
            display: block;

            .row {
              border-bottom: 0;
              display: block;
              background-color: transparent !important;

              div {
                display: block;
                border-bottom: 0;
                width: 100%;
                padding: 0 0 5%;
                line-height: 1.4;
              }

              .heading {
                padding: 10% 0 5%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.recipe-wrapper {
  .overlay-wrapper & {
    padding: 0 40px;

    img.recipe-image {
      margin: 40px 0 40px;
    }
  }

  h1 {
    @include subheadline-1;
    display: block;
    line-height: 47px;
    letter-spacing: -0.5px;
  }

  img {
    max-width: 100%;
  }

  a {
    color: $yellow_inactive;
    text-decoration: none;

    &:hover {
      color: $yellow_link_hover;
    }
  }

  .wrapper {
    @include break($tablet) {
      padding-top: 40px;
    }
  }

  .recipe-title {
    color: #a6a292;
    font-family: $sans;
    font-size: 25px;
    text-transform: uppercase;
    margin: 0;
  }

  .recipe-action {
    padding: 2% 0 5%;

    ul {
      display: block;
      height: 40px;
      width: 100%;
    }

    li {
      float: left;
      padding: 0 5px;

      &.share {
        display: none;
        padding: 10px 0;
      }

      &.print {
        cursor: pointer;
        line-height: 40px;

        p {
          font-family: $serif;
          font-size: 15px;
          margin: 0;
        }
      }

      &.share-icon {
        @include break($tablet) {
          border-right: 1px solid #e3e3e3;
        }
      }
    }
  }

  .section {
    border-top: 2px solid #eee;
    overflow: auto;
    padding: 40px 0;
    width: 100%;

    h2 {
      @include subheadline-2;
      float: left;
      width: 30%;
    }

    .product-locator-widget {
      @include subheadline-2;
      font-size: 25px;
      padding-top: 1em;
      float: left;
      width: 100%;
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
    }

    p,
    li {
      @include paragraph-tiny;
      margin: 0;
    }

    p {
      padding: 0 0 20px;
    }

    .copy {
      float: left;
      width: 70%;

      strong {
        font-weight: bold;
      }
    }

    @include bp(mediumWidth) {
      padding: 25px;
    }
  }

  .ingredients {
    display: block;

    span {
      @include paragraph-tiny;
      display: block;

      &.label {
        margin: 20px 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .perorder {
    border-bottom: 2px solid #eee;
    margin-bottom: 42px;
  }

  @include bp(mediumWidth) {
    .section {
      h2,
      .copy {
        float: none;
        width: 100%;
      }

      h2 {
        margin-bottom: 4%;
      }
    }
  }

  &.new {
    border-top: 5px solid #fec31f;

    .recipe-image,
    .image-placeholder {
      width: 219px;
      height: 219px;
      float: right;
      margin: 30px;

      @include bp(mediumWidth) {
        width: 93px;
        height: 93px;
        margin: 20px 30px;
      }
    }

    h1 {
      min-height: 135px;

      @include bp(mediumWidth) {
        min-height: 45px;
        letter-spacing: -1px;
      }
    }
  }
}

.recipe-action.cheese {
  position: absolute;
  right: 15px;
  top: 15px;

  span {
    font-family: "hoefler";
    font-size: 15px;
    position: relative;
    left: -2px;
    top: -14px;
  }
}

@include bp(mediumWidth) {
  li.print {
    display: none;
  }
}

.farmerstory {
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  height: 1200px;

  .mugshot {
    position: relative;
    top: -130px;
  }

  .title {
    @include headline-accent-4($yellow-ada);
    font-size: 21px;
  }

  p {
    margin: 20px 0 5px 0;
  }

  .farmer_name {
    @include subheadline-1;
    text-transform: uppercase;
  }

  .farm_name {
    width: 100%;
    font: 14px/22px $serif;
  }

  .bio {
    font: 16px/24px $serif;
    margin: 0 5%;
    padding-top: 20px;
    position: static;
    width: 88%;
  }

  .pull_quote {
    color: $olive;
    font: 24px "hoefler";
    padding: 40px 30px 100px 30px;
    width: auto;
    margin: 0 auto;
    font-style: italic;
    line-height: 26px;
    margin-bottom: 40px;
  }

  .sayHelloVideo {
    margin-bottom: 50px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
