body#home {
  .recipes-browser,
  .california-newsletters-browser {
    background-color: $white;
  }
}

.recipes-browser,
.foodservice-recipes-browser,
.california-newsletters-browser {
  height: auto;
  margin: 0 auto;

  width: 100%;

  .headline {
    @include bold-headline;
  }

  .heading {
    margin-bottom: 30px;
    @include bp(mediumWidth) {
      margin-bottom: 15px;
    }

    span {
      display: block;
    }

    .headline {
      @include headline-accent-2($white);
      font-size: 35px;
      text-transform: capitalize;
      @include bp(mediumWidth) {
        margin-top: 14px;
      }
    }

    .subheadline {
      @include bold-headline-1;
      font-size: 132px;

      text-transform: uppercase;
      margin-left: -5px;
      @include bp(mediumWidth) {
        font-size: 47px;
      }
    }
  }

  .slick-slider {
    .slick-dots {
      padding-top: 35px;

      li {
        width: 10px;

        button {
          &:before {
            color: $white;
            font-size: 10px;
            opacity: 1;
          }
        }

        &.slick-active button:before {
          color: $yellow_dot_active;
        }
      }
    }
  }

  .recipe-selector {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 99;

    @include break($tablet) {
      padding-bottom: 50px;
      padding-top: 50px;
    }
    @include break($mobile) {
      padding-bottom: 10px;
    }

    .selectors {
      display: inline-block;
      vertical-align: top;

      a {
        text-decoration: none;
        position: relative;
      }
    }

    .headline {
      font-size: 63px;
      line-height: 46px;
      margin-right: 38px;

      .mobile {
        display: none;
      }
    }

    .controls {
      opacity: 1;
      transition: 815ms all;
      width: 470px;
      margin-right: 45px;

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      li {
        display: inline-block;
        width: 210px;
        margin-right: 20px;
        background: $white;
      }

      .ingredient-list {
        @include bp(mediumWidth) {
          margin-right: 0;
        }
      }
    }

    .filter {
      display: inline-block;
      margin-right: 60px;

      @include break($mobile) {
        display: inline;
      }

      label {
        @include bold-headline;
        position: relative;
        display: block;
        margin-top: 13px;
        padding-left: 30px;
        font-size: 23px;
        color: $black;

        @include break($mobile) {
          font-size: 5vw;
          margin-top: 1vw;
          margin-left: 7vw;
          margin-right: 0;
          padding-left: 4vw;
          width: 48.7%;
          float: left;
        }

        input[type="checkbox"] {
          position: absolute;
          top: 3px;
          left: 0;
          width: 15px;
          height: 15px;
          outline: 0;
          border: 2px solid $yellow-ada;
          transition: all 0.1s;

          @include break($mobile) {
            width: 3.5vw;
            height: 3.5vw;
            top: 0.8vw;
            left: -1.3vw;
            border-width: 0.7vw;
          }

          &.checked {
            background: url(../images/icon-close-yellow.png) 50% 50% no-repeat;
            background-size: cover;
          }
        }
      }
    }

    .chosen-container {
      background: $white;
      border: 2px solid $yellow-ada;
    }

    .chosen-select,
    .chosen-container-single,
    .chosen-container-active.chosen-with-drop {
      margin-right: 7px;
      text-align: left;

      @include break($mobile) {
        display: none;
      }

      .chosen-single {
        @include border-radius-and-pie(0);
        background: $white url(../images/select-arrow-yellow.png) no-repeat;
        background-position: 175px;
        box-shadow: 0 0 0 transparent;
        height: 45px;
        padding: 11px 0 0 12px;
        border: 0;

        span {
          @include bold-headline;
          font-size: 23px;
          color: black;
        }
      }

      .chosen-drop {
        @include border-radius-and-pie();
        border: 0;
        border-radius: 0;
        background: $white;

        width: 212px;

        .chosen-results {
          padding: 0;
          margin: 0;

          li {
            @include bold-headline;
            background: $white;
            line-height: 1;
            font-size: 23px;
            padding: 12px;

            &:hover {
              background: #f1f0ef;
            }

            &:first-child {
            }
          }
        }
      }
    }

    .searchform {
      width: auto;
      white-space: nowrap;

      #search {
        width: 200px;
        height: 49px;
        color: $gray_input_text;
        background: $white;
        font-size: 23px;
        border: 2px solid $yellow-ada;
        border-right: 0;
      }

      ::-webkit-input-placeholder {
        color: $olive;
        opacity: 1 !important;
      }

      :-moz-placeholder {
        color: $yellow-ada;
      }

      ::-moz-placeholder {
        color: $yellow-ada;
      }

      :-ms-input-placeholder {
        color: $yellow-ada;
      }

      input[type="submit"],
      button {
        @include square(49px);
        color: $white;
        background: $black_cmab;
        font-family: cmab2;
        font-size: 24px;
        width: 50px;
        border: 2px solid $yellow-ada;
        border-left: 0;
        background: white;

        &:hover {
          background-color: $yellow;
          color: $black;
        }
      }

      input[type="text"] {
        padding: 12px 0 12px 13px;
      }
    }
  }

  .recipe-pinterest-link-container {
    border: 3px solid $white;
    height: 48px;
    font-family: $sans;
    font-size: 22px;
    margin: 48px auto 0;
    text-align: center;
    width: 320px;

    a {
      color: $white;
      height: 48px;
      line-height: 42px;
      text-transform: uppercase;
      text-decoration: none;
    }

    .pinterest-logo {
      background: url("../images/pinterest-small.png") 0 0 no-repeat $yellow;
      background-color: transparent;
      display: inline-block;
      height: 26px;
      margin-left: 8px;
      text-indent: -9999px;
      width: 26px;
      vertical-align: text-bottom;
    }

    &:hover {
      background-color: $white;

      a {
        color: $yellow;
      }

      .pinterest-logo {
      }
    }
  }

  .slide-wrapper-outer {
    margin: 0 auto;
    position: relative;
  }

  .slide-wrapper-inner {
    margin: 0 auto;
  }

  .content-container {
    width: 990px;
    margin: 0 auto;

    .recipe {
      width: 219px;
      height: 219px;
      margin: 0 24px 20px 0;
      position: relative;
      overflow: hidden;
      float: left;

      &.big {
        width: 470px;
        height: 280px;
        background: $yellow;
        margin-right: 20px;
      }

      .recipe-image {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        top: -50%;
      }

      .recipe-image-highlighted {
        height: auto;
        width: 100%;
      }

      .recipe-image-top {
        height: 78%;
        width: initial;
        top: 0;
        left: -200px;
      }

      .watermark {
        position: absolute;
        opacity: 0.45;
        background: black;
      }

      .recipe-image-featured {
        top: -40%;
        width: 100%;
        height: initial;
        position: absolute;
        left: 0;
      }

      .recipe-title {
        @include bold-headline;
        background: rgba(0, 0, 0, 0.7);
        bottom: 0;
        color: $white;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#000000, endColorstr=#000000); /* IE */
        font-size: 19px;
        height: 50px;
        left: -1px;
        line-height: 21px;
        overflow: hidden;
        padding: 2.5% 4%;
        position: absolute;
        text-overflow: ellipsis;
        text-transform: uppercase;
        width: 101%;
      }

      &:hover .recipe-title {
        background: url(../images/bg-recipetitle.png) no-repeat;
      }
    }

    .recipe-description {
      width: 97%;

      a {
        text-decoration: none;
      }

      .headline {
        font-size: 45px;
        margin-bottom: 8px;
      }

      .description {
        @include paragraph-tiny;
        margin: 11px 0 18px 494px;
      }
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, 160px);
      border: 3px solid $white;
      text-align: center;
      width: 166px;
    }
  }

  .loading {
    position: relative;
    background: url(../images/cmab_loading_gif_white.gif) no-repeat $white;

    background-position: center;
    background-size: 200px;
    min-height: 896px;
    @include bp(mediumWidth) {
      min-height: 500px;
      background-size: 100px;
    }
  }

  #browser {
    width: 960px;
    margin-left: auto;
    margin-right: auto;

    padding-top: 60px;
    padding-bottom: 140px;

    .prev,
    .slick-prev,
    .next,
    .slick-next {
      top: 270px;
    }

    .browser-container {
      position: relative;
      @include bp(mediumWidth) {
      }
    }

    .search-results {
      @include bold-headline;
      font-size: 28px;
      margin: 15px 0;
      display: none;
      position: relative;
      top: -23px;
      padding: 30px 0;

      i {
        font-style: italic;
      }
    }

    .not-found,
    .not-a-match {
      @include bold-headline;
      font-size: 28px;
      margin: 15px 0;
      display: none;
      position: relative;
      padding: 30px 0;

      text-align: center;
      @include bp(mediumWidth) {
        margin: 0;
        padding: 0;
      }
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, 160px);
      border: 3px solid $white;
      text-align: center;
      width: 200px;
    }

    .noMatch {
      font-size: 50px;
      margin-bottom: 60px;
      @include bp(mediumWidth) {
        font-size: 25px;
      }

      span {
        font-size: 150px;
        @include bp(mediumWidth) {
          font-size: 75px;
        }
      }
    }

    .loadCta {
      margin: 0 auto;
      width: 200px;
    }

    .moreMeal,
    .moreIngredient {
      margin-bottom: 16px;

      a {
        min-width: 325px;
        @include bp(mediumWidth) {
          min-width: 0;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    width: 100%;
    padding: 20px 0 20px;

    .header {
      text-align: center;
    }

    .recipes-browser .slide {
      overflow: visible;
    }

    .recipe-selector {
      width: 100%;

      .selectors {
        display: block;
      }

      .headline {
        font-size: 28px;
        line-height: 1.1;
        margin-bottom: 10px;

        .mobile {
          display: inline;
        }
      }

      .controls {
        width: 100%;

        &.hidden {
          visibility: visible;
          opacity: 1;
        }

        margin-bottom: 10px;

        li {
          display: inline-block;
          width: 48.7%;

          @include break($mobile) {
            width: 48%;
            margin-right: 2.5%;
            margin-bottom: 2.5vw;
          }

          select {
            background: url(../images/select-arrow-black.png) 96% center
              no-repeat $yellow;
            color: $white !important;
            font-family: "gothic", sans-serif;
            font-size: 19px;
            height: 13vw;
            padding: 2vw 28px 2vw 2vw;
            text-transform: uppercase;
            width: 100%;

            @include break($mobile) {
              font-size: 5vw;
              display: block !important;
            }
          }
        }
      }

      .chosen-select {
        @include bold-headline;
        @include upper;
        width: 100%;
        color: $yellow !important;
        background: $white url(../images/mobile-list.jpg) no-repeat;
        background-position: 1px -20px;
        text-align: center;
        height: 20px;
        background-size: 90%;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 0;
      }

      .search-form {
        @include break($mobile) {
          position: absolute;
          right: -4vw;
          top: 15.5vw;
          width: 48%;
        }

        .searchform {
          height: auto;
          width: 100%;

          @include break($mobile) {
            margin: 0 0 0 -10% !important;
            width: 100%;
            white-space: nowrap;
          }

          #search {
            width: 70%;
            padding: 0 0 0 5px;
            height: 24px;
            font-size: 12px;
            line-height: 1;
            border-right: 0;

            @include break($mobile) {
              width: 80%;
              height: 13vw;
              font-size: 5vw;
            }
          }

          button {
            @include break($mobile) {
              width: 20%;
              height: 13vw;
              font-size: 6vw;
            }
          }

          input[type="submit"],
          button {
            border: 2px solid $yellow-ada;
            border-left: 0;
          }
        }
      }
    }

    .slide-wrapper-outer {
      width: 100%;

      .slide {
        width: 100%;
      }
    }

    .content-container {
      width: 102%;

      .recipe,
      .newsletter {
        width: 145px;
        height: 145px;
        margin: 0 2% 2% 0;

        &.big {
          width: 470px;
          height: 240px;
          float: none;
          margin: 0 30px;
        }

        .recipe-title {
          height: 30px;
          line-height: 1;
          font-size: 12px;
        }
      }

      .recipe-description,
      .newsletter-description {
        width: 235px;
        margin: 10px auto 0 30px;

        .headline {
          font-size: 25px;
        }

        .description {
          display: none;
        }

        .cta {
          display: none;
        }
      }
    }

    #browser {
      padding: 6% 3% 0 0;
      margin: 0 auto;
      width: 90%;

      @include break($mobile) {
        width: 93%;
      }

      .search-results {
        font-size: 14px;
        margin: 8px 0;
      }

      .loadCta {
        width: 57%;
        margin: 50px auto 0 auto;
      }
    }

    .recipe-pinterest-link-container {
      font-size: 19px;
      height: 42px;
      margin-top: 16px;
      overflow: hidden;
      width: 183px;

      a {
        height: 42px;
        line-height: 42px;
      }

      .pinterest-logo {
        background: url("../images/icons/pinterest-mobile-normal.png") 0 58%
          no-repeat $yellow;
        height: 18px;
        line-height: 100%;
        width: 13px;
        background-size: 100% auto;
      }
    }
  }

  @include bp(smallWidth) {
    .content-container .recipe.big {
      height: 140px;
    }
    #featured .slide {
      height: 200px;
    }
    .recipe-selector .chosen-select {
      background-position: right 0;
    }
  }
}

.foodservice-recipes-browser {
  padding: 69px 0 185px;
  background-color: $yellow;

  #browser {
    @include bp(mediumWidth) {
      width: 320px;
      margin: 0 auto;
    }
    @media (max-width: 815px) and (orientation: landscape) {
      width: 340px;
    }
  }

  .content-container {
    overflow: hidden;

    &.contained {
      height: 715px;
      @include bp(mediumWidth) {
        height: 450px;
      }
    }

    .recipe {
      @include bp(mediumWidth) {
        height: 145px;
        width: 145px;
      }
    }
  }

  #browser .loadCta {
    width: 170px;
    margin-bottom: 108px;
    @include bp(mediumWidth) {
      margin-top: 16px;
    }
  }
}

.recipe-directory,
.directory {
  a.recipe-link,
  a.newsletter-link {
    position: relative;

    @include break($tablet) {
      &:nth-child(1),
      &:nth-child(2) {
        @include square(470px);

        span {
          font-size: 29px;
          min-height: 50px;
        }
      }
      &:nth-child(4n) {
        margin-right: 20px;
      }
      &:nth-child(4n + 2) {
        margin-right: 0;
      }
      margin-bottom: 20px;
      margin-right: 20px;
    }

    @include break($mobile) {
      &:nth-child(1),
      &:nth-child(2) {
        @include square(90vw);
        flex: 0 1 100%;
        margin-right: 0;
      }
      width: 43.9vw;
      height: 43.9vw;
      margin-right: 3vw;
      overflow: hidden;
    }

    &:nth-child(1),
    &:nth-child(2) {
      span.has-video {
        top: 15px;
        right: 15px;
        width: 45px;
        height: 45px;
        background-size: 43px;
      }
    }
  }
}

.recipe-directory,
.browser-container,
.directory {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  @include break($mobile) {
    justify-content: space-between;
  }

  a {
    @include break($tablet) {
      &:nth-child(4n) {
        margin-right: 0;
      }
      margin-bottom: 20px;
      margin-right: 20px;
    }
    @include break($mobile) {
      &:nth-child(2n) {
        margin-right: 0;
      }
      margin-bottom: 12px;
      margin-right: 0;
    }
    position: relative;
  }

  a.recipe-link,
  a.newsletter-link {
    span.has-video {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 25px;
      height: 25px;
      background: url(../images/icons/play-button.png) no-repeat 0 2px;
      background-size: 23px;
    }
  }
}

.mobile {
  &#foodservice-recipe,
  &#california-newsletters {
    .foodservice-recipe-selector,
    .type-selector {
      select.chosen-select {
        background-color: $yellow-ada;
        background: url(../images/select-arrow-black.png) 96% center no-repeat
          $yellow;
        color: black;
        font-family: "gothic";
        font-size: 19px;
        height: 13vw;
        padding-right: 28px !important;
        padding: 2vw;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
}
