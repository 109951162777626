.next-up {
  background-color: $teal;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headline {
    @include headline-accent-3($white);
    margin-top: 70px;
    margin-bottom: 5px;
  }

  .subheadline {
    @include bold-headline-1;
    color: $white;
    margin-bottom: 20px;
    text-align: center;
  }

  a {
    @include cta-no-border($black, $white, $white, $black, auto);
    margin: 0 0 35px;
    position: static;
    border-radius: 0;
  }

  .image {
    position: relative;
    top: 2px;

    img {
      width: 100%;
    }
  }

  @include bp(mediumWidth) {
    .headline {
      font-size: 6vw;
      margin-bottom: 0;
      margin-top: 12vw;
    }

    .subheadline {
      font-size: 22vw;
      margin-bottom: 5.3vw;
      line-height: 0.8;
      margin-top: 0.1em;
    }

    a {
      padding: 0 3vw;
      font-size: 5.7vw;
      height: 11.1vw;
      line-height: 10vw;
    }
  }
}
