.look-for-seal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 100px;

  &.hispanic-dairy {
    .cta-wrapper {
      &:nth-of-type(2) {
        display: flex;
      }
    }
  }

  @include break($mobile) {
    padding-bottom: 45px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subheadline {
      color: $yellow-ada;
      font-family: $script;
      font-size: 25px;
      line-height: 1.7;
      position: relative;
      z-index: 1;
    }

    .headline {
      font-family: $sans;
      font-size: 72px;
      line-height: 70px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      z-index: 0;
    }
  }

  .main-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1080px;
    padding: 0 40px;
    margin-top: 80px;
    min-height: 1px;

    .card {
      background: none;
      border: none;
      width: 30%;
      max-width: 280px;
      min-width: 200px;

      .image {
        width: 100%;
        min-height: 1px;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $serif;
        margin-top: 47px;
        text-align: center;

        .header {
          text-transform: uppercase;
          font-size: 45px;
          font-family: $sans;
        }

        .description {
          margin-top: 30px;
          font-size: 18px;
          line-height: 1.4;
          width: 100%;
        }
      }
    }
  }

  .cta-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 3em;

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      font-size: 30px;
    }

    &:nth-of-type(2) {
      display: none;
    }
  }
}

@include bp(mediumWidth) {
  .look-for-seal {
    .header {
      .subheadline {
        font-size: 22px;
      }

      .headline {
        font-size: 60px;
        line-height: 1;
        max-width: 200px;
      }
    }

    .main-content {
      flex-direction: column;
      align-items: center;
      margin-top: 0;

      .card {
        min-height: initial;
        width: 100%;
        padding: 30px 0;

        .image {
          width: 60%;
          min-width: 200px;
          max-width: 280px;
          margin: auto;
        }

        .content {
          margin-top: 24px;

          .header {
            font-size: 38px;
          }

          .description {
            font-size: 20px;
            line-height: 6.3vw;
            margin-top: 5px;
          }
        }
      }
    }

    .cta-wrapper {
      margin-top: 1em;

      .cta {
        font-size: 30px;
      }
    }
  }
}
