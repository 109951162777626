.partnership-posts {
  padding-top: 30px;

  @include break($tablet) {
    padding-top: 100px;
    padding-bottom: 40px;
  }

  &.health-wellness-everyone {
    background-color: $white;
    background-image: url("../images/background-marble.jpg");
    background-attachment: fixed;

    @include break($tablet) {
      background-size: cover;
    }

    .headline {
      font-family: gothic, sans-serif;
      font-size: 60px;
      line-height: 0.8;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .subheadline {
      font-family: "wisdom", cursive;
      color: $yellow-ada;
      font-size: 48px;
      line-height: 1;

      @include break($tablet) {
        font-size: 55px;
      }
    }
  }

  &.health-wellness-professionals {
    background-color: #292828;
    background-image: url("https://s3.amazonaws.com/static.realcaliforniamilk.com/media/partnership/background-marble-black-long.jpg");
    background-size: contain;
    background-attachment: scroll;

    @include break($tablet) {
      background-image: url("https://s3.amazonaws.com/static.realcaliforniamilk.com/media/partnership/background-marble-black.jpg");
      padding-top: 80px;
      background-size: cover;
      background-attachment: fixed;
    }

    .headline {
      color: $white;
      font-family: gothic, sans-serif;
      font-size: 60px;
      line-height: 0.8;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .subheadline {
      color: $white;
      font-family: gothic, sans-serif;
      font-size: 24px;
      line-height: 1;
      padding-top: 1rem !important;
      max-width: 500px;
      margin: auto;
    }

    .post {
      .description {
        color: $white;
      }

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, 250px);
        @include break($mobile, true) {
          width: 250px;
        }
      }
    }
  }

  select {
    height: 13vw;
    width: 100%;
    background:  url(../images/select-arrow-yellow.png) no-repeat transparent;
    background-position: 96% center;
    color: $yellow-ada !important;
    font-family: "gothic", sans-serif;
    font-size: 19px;
    text-transform: uppercase;
    padding: 2vw 28px 2vw 2vw;
    border: 2px solid $yellow-ada;

    @include break($mobile) {
      font-size: 5vw;
      display: block !important;
    }
  }

  .chosen-container {
    background: transparent;
    border: 2px solid $yellow-ada;
  }

  .chosen-select,
  .chosen-container-single,
  .chosen-container-active.chosen-with-drop {
    margin-right: 7px;
    text-align: left;

    @include break($mobile) {
      display: none;
    }

    .chosen-single {
      @include border-radius-and-pie(0);
      background: transparent url(../images/select-arrow-yellow.png) no-repeat;
      background-position: 230px;
      box-shadow: 0 0 0 transparent;
      height: 45px;
      padding: 11px 12px 0;
      border: 0;

      span {
        @include bold-headline;
        font-size: 23px;
        color: $yellow;
      }
    }

    .chosen-drop {
      @include border-radius-and-pie();
      border: 0;
      border-radius: 0;
      background: $white;
      width: 265px;

      .chosen-results {
        padding: 0;
        margin: 0;
        max-height: 300px;

        li {
          @include bold-headline;
          background: $white;
          line-height: 1;
          font-size: 23px;
          padding: 12px;

          &:hover {
            background: #f1f0ef;
          }
        }
      }
    }
  }
}

.partnership-posts-grid {
  .post {
    display: flex;
    flex-direction: column;

    &.hidden {
      display: none;
    }

    .img-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .headline {
      font-family: gothic, sans-serif;
      font-size: 30px;
      line-height: 32px;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 26px;
        line-height: 24px;
      }
    }

    .description {
      font-family: hoefler, serif;
      font-size: 20px;
      line-height: 26px;

      @include break($tablet) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, 200px);
      @include break($mobile, true) {
        width: 200px;
      }
    }
  }

  .load-more {
    @include cta-no-border($black, $white, $white, $black, 200px);
  }
}

.recipe-banner {
  background: url("https://s3.amazonaws.com/static.realcaliforniamilk.com/media/partnership/background-banner-recipes.jpg");
  background-size: cover;
  background-position: center center;
  padding-top: 60px;
  padding-bottom: 60px;

  @include break($tablet) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .heading {
    font-family: gothic, sans-serif;
    color: $white;
    font-size: 60px;
    line-height: 55px;
    text-transform: uppercase;
    max-width: 626px;
    margin: auto;

    @include break($tablet) {
      font-size: 72px;
      line-height: 60px;
    }
  }

  .description {
    font-family: hoefler, serif;
    color: white;
    font-size: 20px;
    line-height: 26px;
    max-width: 626px;
    margin: auto;
  }

  .cta {
    @include cta-no-border($yellow, $black, $black, $yellow, auto);

    @include break($mobile, true) {
      width: 100%;
      padding: 0;
    }
  }
}

.nutrition-banner {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  @include break($tablet) {
    padding-top: 80px;
  }

  .content {
    max-width: 900px;
    margin: auto;
    color: $white;
    padding-top: 30px;
    padding-bottom: 30px;

    @include break($tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &.health-wellness-everyone {
      background: url("https://s3.amazonaws.com/static.realcaliforniamilk.com/media/partnership/background-banner-everyone.jpg");
      background-size: cover;
      background-position: center center;
    }

    &.health-wellness-professionals {
      background: url("https://s3.amazonaws.com/static.realcaliforniamilk.com/media/partnership/background-banner-professionals.jpg");
      background-size: cover;
      background-position: center center;

      .copy-container {
        max-width: 288px;
      }
    }

    .copy-container {
      max-width: 450px;

      .heading {
        font-family: gothic, sans-serif;
        font-size: 36px;
        line-height: 32px;
        text-transform: uppercase;

        @include break($tablet) {
          font-size: 42px;
          line-height: 38px;
        }
      }

      .description {
        font-family: hoefler, serif;
        font-size: 20px;

        @include break($tablet) {
          font-size: 22px;
        }
      }
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, 210px);

      @include break($mobile, true) {
        width: 200px;
      }
    }
  }
}
