$cowology_width: 1134px;

.xray.cowology {
  height: 1059px;
  padding: 150px 0;
  position: relative;
  width: 100%;
  z-index: 0;

  .module-share {
    display: block;
    left: 1000px;
    top: 80px;
  }

  div,
  a {
    @include not-selectable;
  }

  h2 {
    @include span-block;
    background-color: $white;
    position: absolute;
    left: 439px;
    padding-left: 4px;
    top: 3px;
    width: 100%;

    .headline {
      @include headline-accent-3($yellow-ada);
      padding-left: 10px;
      font-size: 38px;
    }

    .subheadline {
      @include bold-headline-3;
      font-size: 86px;
    }
  }

  .wrapper {
    height: 100%;
  }

  .container {
    height: 100%;
    left: 50%;
    margin-left: -610px;
    overflow: visible;
    position: relative;
    top: 0;
    width: $cowology_width;
  }

  .bg,
  .fg {
    height: 100%;
    top: 0;

    h2 {
      z-index: 0;
    }

    .cow {
      height: 639px;
      left: 0;
      position: absolute;
      top: 110px;
      z-index: 500;
    }

    .description {
      background-color: $white;
      z-index: 1000;
    }
  }

  .bg {
    color: blue;
    left: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 500;

    .headline,
    .subheadline,
    .description p {
      color: $teal;
    }

    .cow {
      background: url("../images/cowology-cow-bg.png") 0 0 no-repeat transparent;
      width: $cowology_width;
    }
  }

  .fg {
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 1086px;
    z-index: 1000;

    .cow {
      background: url("../images/cowology-cow-fg.png") 0 0 no-repeat transparent;
      width: 1086px;
    }

    .description {
      p {
        color: $olive;
      }
    }
  }

  .inner {
    width: $cowology_width;
  }

  .drag-container {
    background: #ffc320;
    height: 743px;
    overflow: visible;
    position: absolute;
    left: 1086px;
    margin: 0;
    top: 0;
    width: 4px;
    z-index: 1500;
  }

  .drag-button {
    background: url("../images/cowology-drag-bg.png") 0 0 no-repeat transparent;
    bottom: 0;
    color: #b48811;
    cursor: pointer;
    display: block;
    font-family: $sans;
    font-size: 20px;
    left: -74px;
    line-height: 58px;
    margin: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
  }

  .description {
    color: $gray;
    font-family: $serif;
    font-style: italic;
    left: 580px;
    position: absolute;
    text-align: center;
    top: 530px;
    width: 350px;

    p {
      font-size: 19px;
    }
  }

  .slide-wrapper-outer {
    height: 243px;
    position: absolute;
    left: 439px;
    top: 180px;
    width: 548px;
    z-index: 500;
  }

  .slide-wrapper-inner {
    height: 100%;
  }

  .slide {
    color: white;
    line-height: 1.2;
    text-align: center;

    p {
      @include paragraph-medium;
      color: $white;
    }
  }

  .circle,
  .prev,
  .next {
    background: url("../images/cowology-sprite-slider.png") 0 0 no-repeat
      transparent;
    cursor: pointer;
    display: inline-block;
    float: left;
    text-indent: -9999px;
  }

  .prev,
  .next {
    height: 28px;
    width: 30px;
  }

  .next {
    background-position: 0 -30px;
  }

  .circle {
    background-position: 0 -60px;
    margin: 0 4px;
    position: relative;
    top: 8px;
    width: 9px;

    &.active,
    &:hover {
      background-position: -30px -60px;
    }
  }

  .cta-animal-care {
    @include border-radius-and-pie();
    background-color: $blue_light;
    height: 219px;
    left: 130px;
    overflow: visible;
    position: absolute;
    text-align: center;
    top: 60%;
    width: 219px;
    z-index: 1500;

    .cross {
      margin: -16px 0 4px;
    }

    .headline,
    .subheadline,
    .title {
      display: block;
      letter-spacing: -1px;
    }

    .headline {
      color: $blue_dark;
      font-family: $script;
      font-size: 20px;
      line-height: 1.4;
    }

    .subheadline,
    .title {
      color: $white;
      font-family: $sans;
      text-transform: uppercase;
    }

    .subheadline {
      font-size: 35px;
      line-height: 0.8;
    }

    .title {
      font-size: 27px;
    }

    .more-info {
      height: 37px;
      width: 37px;
      position: absolute;
      top: 160px;
      left: 90px;
    }

    &:hover {
      background: $blue_dark;

      .headline {
        color: $white;
      }
    }
  }

  @include bp(mediumWidth) {
    padding: 12% 0 10%;
    height: auto;

    .bg,
    .drag-container,
    .description {
      display: none;
    }

    h2,
    .container,
    .fg,
    .cow,
    .slide-wrapper-outer {
      left: 0;
      margin: 0;
      top: 0;
    }

    .slide-wrapper-outer {
      height: auto;
      margin-bottom: 0;
      position: relative;
      width: 100%;

      .slide-wrapper-inner {
      }

      .slide {
        padding: 0;

        p {
          @include paragraph-medium;
          @include break($mobile) {
            font-size: 3.75vw;
            line-height: 5vw;
          }
        }
      }
    }

    h2 {
      position: relative;
      text-align: center;

      .headline {
        margin-bottom: 0;
        font-size: 4.6vw;
      }

      .subheadline {
        font-size: 11.5vw;
      }
    }

    .container {
      padding: 0 3%;
      width: 100%;
    }

    .inner {
      width: 100%;
    }

    .fg {
      height: auto;
      margin: 0 auto 3%;
      position: relative;
      top: 0;
      width: 100%;

      .cow {
        background: url("../images/bg-cows-cowology-mobile.png") 50% 0 no-repeat
          transparent;
        background-size: contain;
        height: 26.4vw;
        left: 0;
        position: relative;
        margin-top: -4%;
        top: 0;
        width: 100%;
      }
    }

    .description {
      display: none;
    }

    .cta-animal-care {
      height: 35vw;
      left: 0;
      margin: 10% auto;
      position: relative;
      top: 0;
      width: 33.4vw;

      .cross {
        max-width: 30.4%;
        padding-bottom: 5px;
        margin-bottom: 0;
      }

      .headline {
        font-size: 4.5vw;
        line-height: 4.5vw;
        padding-bottom: 0;
      }

      .subheadline {
        font-size: 6.2vw;
        line-height: 0.8;
      }

      .title {
        font-size: 18px;
        line-height: 1;
      }

      .more-info {
        height: auto;
        max-width: 22px;
        margin-top: 6%;
        left: 0;
        line-height: 1;
        position: relative;
        top: 0;
      }
    }
  }

  .slick-slider {
    .slick-dots {
      li {
        margin: 0 3px;

        button {
          &:before {
            color: $white;
            font-size: 10px;
            opacity: 1;

            @include bp(mediumWidth) {
              color: $blue_light;
            }
          }
        }

        &.slick-active button:before {
          color: $blue_dark;
        }
      }
    }
  }
}
