//princess

.timeline.princess {
  @extend %clearfix;
  height: auto;
  padding: 100px 0 140px;
  position: relative;
  width: 100%;

  .module-share {
    display: block;
    left: 350px;
    top: 40px;
  }

  div,
  a {
    @include not-selectable;
  }

  .slide-wrapper-outer {
    height: 744px;
    margin: 0 auto;
    position: relative;
    width: 960px;
  }

  .drag-container {
    position: relative;
  }

  .header {
    height: 270px;
    width: 1075px;

    .mobile & {
      width: 100%;
    }

    .heading {
      float: left;
      width: 460px;

      .headline {
        @include bold-headline-3;
        padding-bottom: 6px;
      }

      .subheadline {
        @include headline-accent-2($yellow-ada);
        float: left;
      }

      .title {
        @include paragraph-em-small;
        width: 240px;
        float: left;
        margin-left: 20px;
      }
    }

    .description {
      @include paragraph-tiny;
      width: 300px;
      float: left;
      margin: 30px 0 0 220px;
    }
  }

  .ribbon {
    background: url("../images/princess-ribbon.png") 0 0 no-repeat transparent;
    height: 51px;
    left: -10px;
    position: absolute;
    top: 10px;
    width: 202px;
    z-index: 100;

    p {
      color: $white;
      font-size: 28px;
      font-family: $sans;
      padding: 12px 12px 12px 20px;
      text-transform: uppercase;
      text-align: left;
    }
  }

  .slide-group {
    height: 350px;

    .ie8 & {
      z-index: 0;
    }

    .image-group {
      float: left;
      position: relative;
      height: 100%;
      width: 373px;

      img {
        max-height: 100%;
      }
    }

    .image-container {
      height: 249px;
      overflow: hidden;

      &.expanded {
        .image {
          transform: scale3d(1, 1, 1);
          z-index: 0;
        }

        .inner {
          transition-timing-function: cubic-bezier(0.56, 0.19, 0.39, 1.42);
          transform: scale3d(1, 1, 1.5) rotate(0deg);
          z-index: 100;

          .lt-ie10 & {
            display: block;
          }
        }

        .more-info {
          transition-timing-function: cubic-bezier(0.56, 0.19, 0.39, 1.42);
          transform: scale3d(1, 1, 2) rotate(-45deg);
          z-index: 200;
        }
      }

      &:hover {
        .inner,
        .more-info {
          background-color: $blue_dark;
        }

        .lt-ie10 & {
          .inner,
          .more-info {
            background-color: $blue_light;
          }
        }
      }
    }

    .image {
      cursor: pointer;
      height: 100%;
      width: 100%;
      transform: scale3d(1, 1, 1);
      z-index: 100;

      img {
        max-width: 100%;
        @include border-radius-and-pie();
      }
    }

    .inner {
      @include border-radius-and-pie();
      transform-origin: left;
      transition: all 0.2s ease-in;
      transform: scale3d(0, 0, 1.5) rotate(45deg);
      background-color: $teal;
      cursor: pointer;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;

      .lt-ie10 & {
        display: none;
      }

      .title,
      .description {
        color: $white;
        filter: inherit;
      }

      .title {
        font-family: $sans;
        font-size: 35px;
        line-height: 1;
        text-transform: uppercase;
      }

      .description {
        font-family: $serif;
        font-size: 18px;
        line-height: 30px;
      }
    }

    .more-info {
      @include circle(45px, $teal);
      transition: all 0.2s;
      transform: scale3d(1, 1, 2) rotate(0deg);
      bottom: 30px;
      color: $white;
      cursor: pointer;
      font-family: "Verdana";
      font-size: 55px;
      line-height: 40px;
      position: absolute;
      z-index: 200;
    }

    .shadow {
      background: url(../images/slider-simple-shadow.png) 50% 0 no-repeat
        transparent;
      bottom: -7px;
      height: 10px;
      left: 50%;
      margin-left: -142px;
      position: absolute;
      width: 249px;
    }
  }

  .slide-wrapper-inner {
    height: 249px;
    position: absolute;
  }

  .slide {
    height: 249px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 249px;
  }

  .learn-more {
    background: $teal url(../images/icons/icon-princess-crown.png) center 10px
      no-repeat;
    @include cta-circle(165px, 165px);
    position: absolute;
    left: 500px;
    top: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    .one,
    .two {
      //position: absolute;
    }

    .one {
      color: $white;
      top: 68px;
      left: 35px;
      font: 25px $sans;
      padding-top: 25px;
      line-height: 0.8;
    }

    .two {
      color: $blue_dark;
      top: 92px;
      left: 38px;
      font: 20px $script;
      line-height: 0.9;
    }

    .icon {
      background: url(../images/icon-plus-circle.png) 0 0 no-repeat;
      height: 37px;
      left: 65px;
      position: absolute;
      top: 120px;
      width: 37px;
    }
  }

  @include bp(mediumWidth) {
    padding: 12.5% 0;
    .drag-container {
      display: none;
    }
    .header {
      height: auto;

      .heading {
        float: none;
        padding-bottom: 4px;
        text-align: center;
        width: auto;

        .headline {
          padding-bottom: 4px;
        }

        .subheadline {
          @include headline-accent-2($yellow-ada);
          float: none;
        }

        .title {
          display: none;
        }

        .module-share {
          position: static;
          margin: 3% auto;

          .ie8 & {
            z-index: 0;
          }
        }
      }

      .description {
        float: none;
        margin: 0;
        padding: 0 5px 6px;
        width: auto;
      }
    }
    .slide-wrapper-outer {
      height: auto;
      text-align: center;
      width: 100%;
    }
    .ribbon {
      background: url("../images/bg-princess-ribbon-mobile.png") 0 0 no-repeat
        transparent;
      background-size: 100% auto;
      height: 25px;
      left: 0;
      margin: 0 auto 4%;
      position: relative;
      top: 0;
      width: 101px;

      p {
        font-size: 14px;
        padding: 6px 0 0 10px;
      }
    }
    .slide-group {
      height: 197px;
      position: relative;

      .image-container {
        height: 156px;
        margin: 0 auto;
        position: relative;
        width: 156px;

        .image,
        .inner {
          height: 100%;
          margin: 0 auto;
          width: 100%;
        }

        .inner {
          padding: 0 10px;

          .title {
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 4%;
          }

          .description {
            font-size: 14px;
            line-height: 14px;
          }
        }

        .image {
          img {
            max-width: 100%;
          }
        }

        .more-info {
          font-size: 30px;
          height: 26px;
          line-height: 22px;
          width: 26px;
        }
      }

      .image-group {
        img {
          max-width: 80%;
          margin: 0 auto;
        }
      }
    }
    .slide-wrapper-inner {
      height: 156px;
      position: relative;
    }
    .learn-more {
      background: $teal url(../images/icons/princess-crown-mobile.png) 50% 5.5%
        no-repeat;
      background-size: 36% auto;
      height: 136px;
      left: 50%;
      margin-left: -78px;
      margin-top: 7.8%;
      position: relative;
      padding-top: 56px;
      top: 0;
      width: 136px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      .ie8 & {
        z-index: 0;
      }

      .one,
      .two,
      .icon {
        left: 0;
        position: relative;
        top: 0;
      }

      .one {
        font-size: 23px;
        padding-top: 0;
      }

      .two {
        font-size: 19px;
        margin-bottom: 3.7%;
      }

      .icon {
        background: url(../images/icons/icon-plus.png) 0 0 no-repeat;
        background-size: 100%;
        height: 20px;
        margin: 0 auto;
        width: 20px;
      }

      a {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        padding: 52px 0 0 0;
        text-decoration: none;
      }
    }
    .slide {
      float: auto;
      height: 156px;
      margin-left: 0;
    }
  }
}
