/*	BigVideo Styles 
	You may need to adjust these styles to get this working right in your design. 
    */

#info {
  position: fixed;
  bottom: 10px;
  left: 10px;
}
#big-video-wrap {
  position: absolute;

  height: 100%;
  width: 100%;
  .not-has-video & {
    height: 0;
    width: 0;
  }
  top: 0;
  left: 0;
  @include bp(mama-bear) {
    background: blue;
  }
}

#big-video-control-container {
  position: fixed;
  bottom: 0;
  padding: 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -ms-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

#big-video-control {
  width: 100%;
  height: 16px;
  position: relative;
}

#big-video-control-middle {
  margin: 0 56px 0 24px;
}

#big-video-control-bar {
  width: 100%;
  height: 16px;
  position: relative;
  cursor: pointer;
}

#big-video-control-progress {
  position: absolute;
  top: 7px;
  height: 3px;
  width: 0%;
  background: #fff;
}

#big-video-control-track {
  position: absolute;
  top: 8px;
  height: 1px;
  width: 100%;
  background: #fff;
}

#big-video-control-bound-left {
  left: 0;
}

#big-video-control-bound-right {
  right: -1px;
}

#big-video-control-track .ui-slider-handle {
  opacity: 1;
}

#big-video-control-playhead {
  left: 30%;
}

#big-video-control-play {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-image: url("bigvideo.png");
}

#big-video-control-timer {
  position: absolute;
  top: 1px;
  right: 0;
  color: #fff;
  font-size: 11px;
}

#big-video-control-playhead,
#big-video-control-bound-left,
#big-video-control-bound-right {
  position: absolute;
  top: 0;
  height: 16px;
  width: 1px;
  background: #fff;
}

#big-video-control .ui-slider-handle {
  border-left: solid 1px #fff;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -8px;
}

.transparent {
  opacity: 0;
}

.vjs-big-play-button {
  display: none !important;
}
