// /cheese-pairings page
#cheese-pairings {
  .pairing-description {
    padding: 50px 0 30px;
    font-size: 22px;
    line-height: 28px;
    font-family: "hoefler", serif;
    max-width: 750px;
    margin: auto;

    @include break($tablet) {
      font-size: 24px;
      line-height: 30px;
      padding: 70px 0;
    }
  }

  .pairings-cta {
    padding-bottom: 50px;

    @include break($tablet) {
      padding-bottom: 70px;
    }

    .title {
      font-family: gothic, sans-serif;
      text-transform: uppercase;
      font-size: 26px;
    }

    .description {
      font-family: hoefler, serif;
      font-size: 20px;
    }

    .cta-wrapper {
      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
      }
    }
  }

  .start-quiz {
    height: 90vh;
    max-height: 1080px;
    background: url(../images/pairings/cheese-bg-desktop.jpg) center center;
    background-size: cover;

    .top-heading {
      color: white;
      font-family: "wisdom", cursive;
      font-size: 24px;

      @include break($tablet) {
        font-size: 32px;
      }
    }

    .heading {
      color: white;
      font-family: "gothic", sans-serif;
      font-size: 45px;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .description {
      font-family: "gothic", sans-serif;
      font-size: 38px;
      text-transform: uppercase;
      max-width: 740px;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .cta-wrapper {
      .cta-start {
        @include cta-no-border(black, white, $white, black, auto);
      }
    }
  }

  .tips-and-tools {
    .heading {
      font-size: 45px;
      font-family: gothic, sans-serif;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .sub-heading {
      font-size: 24px;
      font-family: "wisdom", cursive;
      color: $yellow-ada;
      margin-top: -0.25em;

      @include break($tablet) {
        font-size: 55px;
      }
    }
  }
}

//Pairing detail pages
.pairing-detail {
  .pairing-masthead {
    height: 220px;
    justify-content: space-evenly;
    margin-top: 35px;

    @include break($tablet) {
      margin-top: 50px;
      height: 480px;
      font-size: 24px;
    }

    &.cheese-wine {
      background: url(../images/pairings/pairing-wine-mobile.jpg) center
        center/cover;

      @include break($tablet) {
        background: url(../images/pairings/pairing-wine.jpg) center center/cover;
      }
    }

    &.cheese-beer {
      background: url(../images/pairings/pairing-beer-mobile.jpg) center
        center/cover;

      @include break($tablet) {
        background: url(../images/pairings/pairing-beer.jpg) center center/cover;
      }
    }

    &.cheese-chocolate {
      background: url(../images/pairings/pairing-chocolate-mobile.jpg) center
        center/cover;

      @include break($tablet) {
        background: url(../images/pairings/pairing-chocolate.jpg) center
          center/cover;
      }
    }

    &.cheese-drinks {
      background: url(../images/pairings/pairing-drinks-mobile.jpg) center
        center/cover;

      @include break($tablet) {
        background: url(../images/pairings/pairing-drinks.jpg) center
          center/cover;
      }

      .top-heading {
        color: #888686;
      }
    }

    .breadcrumbs {
      font-size: 16px;
      font-family: gothic-atf, sans-serif;

      @include break($desktop) {
        font-size: 24px;
      }

      a {
        color: black;
        text-decoration: underline;

        &:hover {
          font-weight: bold;
        }
      }
    }

    .top-heading {
      font-family: "wisdom", cursive;
      color: white;
      font-size: 15px;

      @include break($desktop) {
        font-size: 36px;
      }
    }

    .heading {
      font-family: gothic, sans-serif;
      font-size: 45px;
      text-transform: uppercase;

      @include break($desktop) {
        font-size: 90px;
      }
    }
  }

  .pairing-description {
    padding: 50px 0 5px;
    font-size: 22px;
    line-height: 28px;
    font-family: "hoefler", serif;

    @include break($tablet) {
      font-size: 24px;
      line-height: 30px;
      padding: 60px 0 30px;
    }

    @include break($desktop-lg) {
      padding: 70px 0 40px;
    }
  }

  .pairing-carousel {
    .slide-wrapper-outer {
      .prev,
      .slick-prev,
      .next,
      .slick-next {
        @include prev_next_buttons();
        @include break($tablet, true) {
          display: none !important;
        }

        &::before {
          content: "";
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }

      .prev,
      .slick-prev {
        @include prev_button(-70px, gray, false);
      }

      .next,
      .slick-next {
        @include next_button(-70px, gray, false);
      }

      .slick-dots {
        padding-top: 0;

        li {
          button {
            &:before {
              color: #cecece;
              opacity: 1;
            }
          }

          &.slick-active button:before {
            color: $yellow_partnership;
          }
        }
      }
    }

    .pairing-card {
      .title {
        font-family: gothic, sans-serif;
        font-size: 22px;
        line-height: 20px;
        text-transform: uppercase;
        padding: 15px 0;

        @include break($tablet) {
          line-height: 22px;
        }
      }

      .description {
        font-family: "hoefler", serif;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .pairing-table {
    .heading {
      font-size: 45px;
      font-family: gothic, sans-serif;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .sub-heading {
      font-size: 24px;
      font-family: "wisdom", cursive;
      color: $yellow-ada;
      margin-top: -0.25em;

      @include break($tablet) {
        font-size: 55px;
      }
    }

    .share {
      .cheese-action {
        @include break($tablet) {
          margin-top: -40px;
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      tr {
        &.grey {
          background-color: #f5f5f5;
        }

        &:nth-child(n + 7) {
          display: none;
        }

        th {
          background-color: $yellow;
          border: 1px solid #e3e3e3;
          color: $black;
          font-family: gothic, sans-serif;
          font-size: 28px;
          padding: 13px;
          text-transform: uppercase;

          @include break($tablet) {
            padding: 10px 20px;
          }

          &:nth-child(1) {
            @include break($tablet) {
              width: 30%;
            }
          }
        }

        td {
          padding: 13px;
          border: 1px solid #e3e3e3;
          font-size: 14px;
          font-family: hoefler, serif;
          vertical-align: middle;

          i {
            font-style: italic;
          }

          @include break($tablet) {
            padding: 25px 20px;
          }

          &:nth-child(1) {
            .cheese-title {
              font-size: 20px;
              font-family: gothic, sans-serif;
              text-transform: uppercase;
              word-wrap: break-word;
              hyphens: auto;
              line-height: 1.5;
            }
          }

          &:nth-child(2) {
            p:not(:last-of-type) {
              margin-bottom: 1em;
            }
          }
        }
      }
    }

    .disclaimer {
      text-align: center;

      p {
        font-family: hoefler, serif;
        font-size: 8px;
      }
    }

    .cta-wrapper {
      text-align: center;
      padding-top: 40px;

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        min-width: 160px;
      }
    }
  }

  .tips-and-tools {
    .heading {
      font-size: 45px;
      font-family: gothic, sans-serif;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .sub-heading {
      font-size: 24px;
      font-family: "wisdom", cursive;
      color: $yellow-ada;
      margin-top: -0.25em;

      @include break($tablet) {
        font-size: 55px;
      }
    }
  }

  .more-pairings {
    padding-bottom: 50px;

    @include break($tablet) {
      padding-bottom: 80px;
    }

    .heading {
      font-size: 45px;
      font-family: gothic, sans-serif;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .title {
      a {
        font-family: gothic, sans-serif;
        color: $black;
        text-transform: uppercase;
        font-size: 26px;
      }
    }
  }

  .quiz-tout {
    height: 90vh;
    max-height: 1080px;
    background: url(../images/pairings/quiz-tout-bg-mobile.jpg);
    background-position: center center;
    background-size: cover;

    @include break($tablet) {
      height: 350px;
      background: url(../images/pairings/quiz-tout-bg-desktop.jpg);
      background-position: center center;
      background-size: cover;
    }

    @include break($desktop-wide) {
      height: 450px;
    }

    .top-heading {
      color: white;
      font-family: "wisdom", cursive;
      font-size: 24px;

      @include break($tablet) {
        font-size: 32px;
      }
    }

    .heading {
      color: white;
      font-family: "gothic", sans-serif;
      font-size: 45px;
      text-transform: uppercase;

      @include break($tablet) {
        color: black;
        font-size: 72px;
      }
    }

    .description {
      font-family: "gothic", sans-serif;
      font-size: 38px;
      text-transform: uppercase;
      max-width: 740px;

      @include break($tablet) {
        font-family: gothic, sans-serif;
        font-size: 24px;
        text-transform: none;
      }
    }

    .cta-wrapper {
      .cta {
        @include cta-no-border($black, $white, $white, $black, auto);
        min-width: 160px;
      }
    }
  }

  #quiz-modal {
    z-index: 6000;

    .modal-dialog {
      max-width: 95vw;
      margin: auto;
      height: 100%;

      @include break($tablet) {
        max-width: 80vw;
      }

      .modal-content {
        border-radius: 25px;
        overflow: hidden;
        -webkit-transform: translateZ(0);

        .modal-body {
          max-height: calc(100vh - 90px);
          overflow-y: auto;
          position: relative;

          @include break($tablet) {
            max-height: none;
          }
        }

        .icon-close {
          position: absolute;
          width: 25px;
          height: 25px;
          top: 20px;
          right: 20px;
          z-index: 6500;

          @include break($tablet) {
            width: 35px;
            height: 35px;
            top: 40px;
            right: 40px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

#personali-cheese {
  display: none;

  .quiz {
    background-color: #f5b32a;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: background-image 0.2s ease-in-out;
    background-position: center center;
    background-size: cover;

    @include break($tablet) {
      height: 90vh;
      min-height: 760px;
      max-height: 1080px;
      padding: 80px 20px;
    }

    .top-heading {
      color: white;
      font-family: "wisdom", cursive;
      font-size: 24px;

      @include break($tablet) {
        font-size: 32px;
      }
    }

    .heading {
      font-family: "gothic", sans-serif;
      font-size: 45px;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
      }
    }

    .slide {
      font-family: "gothic", sans-serif;
      padding: 0 15px;

      @include break($tablet) {
        font-size: 72px;
        padding: 0;

        > .container > .row {
          align-items: center;
        }
      }

      .quiz-img {
        width: 150px;
        padding: 20px 0 10px;

        @include break($tablet) {
          width: 360px;
          margin: auto;
          padding: 20px 0;
        }
      }

      .question-number {
        color: white;
        font-size: 25px;
      }

      fieldset {
        > div {
          display: flex;
          align-items: center;
          padding: 5px 0;

          @include break($tablet) {
            padding: 10px 0;
          }
        }

        legend {
          font-size: 28px;
          padding: 7px 0;

          @include break($tablet) {
            font-size: 48px;
            padding: 10px 0 25px;
          }
        }

        input[type="radio"] {
          position: relative;
          appearance: none;
          background-color: #f5b32a;
          margin: 0;
          font: inherit;
          color: #f5b32a;
          width: 30px;
          height: 30px;
          border: 2px solid white;
          border-radius: 50%;
          flex-shrink: 0;

          @include break($tablet) {
            width: 40px;
            height: 40px;
          }

          &:hover {
            cursor: pointer;

            &::before {
              transform: translate(-50%, -50%) scale(1);
            }
          }
        }

        input[type="radio"]::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          content: "";
          width: 18px;
          height: 18px;
          border-radius: 50%;
          transition: 100ms transform ease-in-out;
          box-shadow: inset 25px 25px white;

          @include break($tablet) {
            width: 25px;
            height: 25px;
          }
        }

        input[type="radio"]:checked::before {
          transform: translate(-50%, -50%) scale(1);
        }

        label {
          color: white;
          text-transform: uppercase;
          padding-left: 15px;
          font-size: 20px;

          @include break($tablet) {
            font-size: 25px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      &.result {
        .result-top-heading {
          font-family: gothic, sans-serif;
          color: white;
          font-size: 25px;
        }

        .result-heading {
          font-family: gothic, sans-serif;
          font-size: 35px;

          @include break($tablet) {
            font-size: 48px;
          }
        }

        .result-description {
          font-family: hoefler, serif;
          font-size: 16px;

          @include break($tablet) {
            font-size: 18px;
          }
        }

        .cta-wrapper {
          .cta-again {
            @include cta-no-border($black, $white, $white, $black, auto);
            min-width: 150px;
          }
        }

        img {
          padding-top: 20px;

          @include break($tablet) {
            padding-top: 10px;
          }
        }

        .desktop-results {
          background-color: white;
          border-radius: 15px;
          padding: 0 10px 20px;
          overflow: hidden;
        }

        .pairing-title {
          font-family: gothic, sans-serif;
          font-size: 16px;
          text-transform: uppercase;
          padding-top: 15px;
          text-align: center;

          @include break($tablet) {
            max-width: 160px;
            margin: 0 auto;
          }
        }

        .result-carousel {
          margin-bottom: 0;

          img {
            margin: 0 auto;
            max-width: 200px;
          }

          .slick-dots {
            padding-top: 5px;

            li {
              button {
                &:before {
                  color: #ffffff;
                  opacity: 1;
                }
              }

              &.slick-active button:before {
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}
