// TODO: consolodate all matching styles into generic carousel class. especially needed for mobile.

$slider_width: 960px;

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
  transform: none;
}

.lt-ie10 .carousel-simple .slide {
  position: relative;
}

.carousel-simple {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: $slider_width;

  a {
    @include cta-no-border($yellow, $black, $black, $yellow, 160px);
  }

  ul.links {
    li {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .prev,
  .slick-prev,
  .next,
  .slick-next {
    @include prev_next_buttons(0);
    z-index: 1;
  }

  .prev,
  .slick-prev {
    left: 0;
    @include arrow_carousel("left");
  }

  .next,
  .slick-next {
    right: 0;
    @include arrow_carousel("right");
  }

  .slide-wrapper-inner {
    .slick-list {
      overflow: visible;
    }

    width: 100%;
  }

  .slide {
    background: none;
    width: $slider_width;
  }

  .header {
    height: auto;
    margin-bottom: 60px;
    position: relative;

    .heading {
      display: inline-block;
      margin-right: 10px;
      @include span-block;

      .headline {
        @include headline-accent-2($yellow-ada);
      }

      .subheadline {
        @include bold-headline-2;
      }
    }

    .description {
      display: inline-block;
      position: absolute;
      top: 50%;

      p {
        @include paragraph-em-small;
      }
    }

    .cta-wrapper {
      position: absolute;
      right: 0;
      top: 40px;
      @include bp(mediumWidth) {
        position: static;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 3vw;
      }
    }
  }

  .image-container {
    height: 440px;
    position: absolute;
    right: 106px;
    top: 0;
    width: 435px;
    z-index: -2;

    .image {
      height: 435px;
      width: 435px;

      .circle {
        @include border-radius-and-pie();
        max-width: 100%;
      }
    }

    .shadow {
      background: url("../images/slider-simple-shadow.png") 50% 0 no-repeat
        transparent;
      bottom: 0;
      height: 10px;
      left: 50%;
      margin-left: -142px;
      position: absolute;
      width: 285px;
    }
  }

  .content-container {
    margin-left: 110px;
    width: 430px;

    h4 {
      @include span-block;
    }

    p {
      &.title {
        font-size: 25px;
      }

      &.description {
      }
    }

    ul,
    .single-list-item {
      list-style: none;
      margin: 10px 0 0;
      padding: 0;
    }

    li,
    .single-list-item {
      @include paragraph-tiny;
    }
  }

  @include bp(mediumWidth) {
    height: auto;
    width: 100%;
    .wrapper {
    }
    .prev,
    .slick-prev,
    .next,
    .slick-next {
      display: none;
    }
    .slide-wrapper-inner {
      height: auto;
    }
    .slide {
      height: auto;
      max-width: 100%;
    }
    .header {
      margin-bottom: 5px;

      .heading {
        display: inline-block;
        text-align: center;
        width: 100%;

        .headline {
        }
      }

      .description {
        bottom: auto;
        position: relative;
      }

      p {
        display: none;
      }
    }
    .image-container {
      margin: 0 auto 12px;
      position: relative;
      right: initial;
      top: initial;
      width: 190px;
      height: auto;
      max-width: 100%;

      .image {
        height: auto;
        width: 100%;

        img {
          max-width: 100%;
        }
      }

      .shadow {
        background-size: 100% auto;
        bottom: -3px;
        margin-left: -48px;
        width: 95px;
      }
    }
    .content-container {
      margin: 0;
      height: auto;
      width: auto;

      h4 {
        margin-bottom: 6px;
      }

      p,
      ul {
        padding-right: 0;
      }

      ul.links {
        margin: 0;
      }

      .content-container {
        text-align: center;
      }
    }
    .content-body {
      padding: 0 10px;
    }
  }
}

.carousel.dear-dairy {
  height: 647px;
  padding-top: 100px;

  .prev,
  .slick-prev,
  .next,
  .slick-next {
    top: 30px;
    z-index: 1;
  }

  .slick-prev,
  .slick-next {
    &:before {
      content: none;
    }
  }

  .module-share {
    display: none;
  }

  .header {
    margin-bottom: 0;

    .heading {
      .headline {
        @include headline-accent-3($yellow-ada);
      }

      .subheadline {
        @include bold-headline-3;
        position: relative;

        &:after {
          /* content: "TM"; */
          font-size: 20px;
          position: absolute;
          top: -33px;
          right: -21px;

          @include break($mobile) {
            font-size: 12px;
            top: 5px;
            right: calc(50% - 58px);
          }
        }
      }
    }

    .description {
      p {
        max-width: 100%;
        padding: 0 30px;
      }
    }
  }

  .image-container {
    display: inline-block;
    left: 0;
    position: relative;
    right: initial;
    width: 50%;
    float: left;
  }

  .content-container {
    display: inline-block;
    left: -17%;
    margin: 0;
    position: relative;
    padding-left: 135px;
    text-align: left;
    vertical-align: top;
    width: 50%;

    h4 {
      margin: 30px 0 15px;

      @include break($tablet) {
        width: calc(100% + 115px);
      }

      .headline {
        @include subheadline-1;
        position: relative;
      }

      .subheadline {
        @include paragraph-tiny;
      }
    }

    p {
      &.title {
        font-family: $sans;
        font-size: 20px;
        color: $olive;
        margin: 20px 0;
      }

      &.description {
        @include paragraph-medium;
        padding-right: 0;
        margin: 0px 12px;

        @include break($tablet) {
          width: calc(100% + 115px);
        }
      }
    }

    .read-more {
      display: none;
    }

    ul.links {
      display: none;
    }

    .youtube-videos {
      margin: 30px 0;
      display: flex;

      @include break($mobile) {
        align-content: center;
        justify-content: center;
        margin: 15px 0;
      }

      a {
        height: 100%;
        padding: 8px;
        position: relative;
        margin-right: 10px;
        border: none;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          width: 200px;

          @include break($mobile) {
            width: 145px;
          }
        }

        .hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url("../images/icons/video-play.png") !important;
          background-size: 40px;
          background-position: center center;
          background-repeat: no-repeat;

          @include break($mobile) {
            background-size: 30px;
          }
        }
      }
    }

    a.watch-film {
      @include subheadline-3;
      font-size: 21px !important;
      color: $yellow-med;
      height: 25px;
      padding-left: 30px;
      text-decoration: none;
      background-image: url("../images/icons/video-play.png") !important;
      background-size: 20px;
      background-position: center left;
      background-repeat: no-repeat;

      @include break($mobile) {
        text-align: center;
        line-height: 1;
      }
    }

    ul {
      margin-top: 20px;
    }

    li {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      padding: 0;

      &:hover a {
        color: white;
      }

      a {
        color: $yellow;
        padding: 10px 20px;
        display: inline-block;
        font-family: $sans;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          background-position: 0 -27px;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    height: auto;
    padding-top: 12.5%;
    .module-share {
      background-size: 100% auto;
      height: 20px;
      left: 0;
      margin: 0 auto;
      position: relative;
      top: 0;
      width: 20px;
    }
    .header {
      height: auto;
      margin-bottom: 2%;

      .heading {
        .headline {
          font-size: 4.5vw;
        }

        .subheadline {
          font-size: 11.5vw;
        }
      }

      .description {
        display: none;
      }
    }
    .image-container {
      padding: 0 18.75%;
      top: 0;
      width: 100%;

      .image {
        margin: 0 auto;
      }
    }
    .content-container {
      background-position: 16% 20%;
      background-size: 19%;
      margin: -162px 0 0;
      text-align: center;
      left: 0;
      padding: 162px 0 4%;
      top: 0;
      width: 100%;

      h4 {
        margin: 8vw 0 4vw;

        .headline {
          font-size: 6.7vw;
          line-height: 6.5vw;
        }

        .subheadline {
          font-size: 3.7vw;
          line-height: 5vw;
        }
      }

      .content-body {
        .description {
          font-size: 3.7vw;
          line-height: 5vw;
        }

        .youtube-videos {
          .lity-cta {
            img {
              width: 38.7vw;
            }
          }
        }
      }

      li {
        text-align: left;
      }
    }
  }
}

.carousel.farm-smarts,
.carousel.virtual-farm-tours {
  height: auto;
  padding: 140px 0;

  .module-share {
    display: block;
    left: 300px;
    top: 40px;
    @include break($landscape) {
      left: 420px;
      top: 50px;
    }

    .not-mobile & {
      left: 366px;
    }
  }

  .header {
    .description {
      p {
        max-width: 60%;
      }
    }
  }

  .slide-wrapper-inner {
    .slick-prev,
    .slick-next {
      &:before {
        content: none;
      }
    }
  }

  .slide {
    height: 435px;
    position: relative;
  }

  .image-container {
    right: 60px;
  }

  .content-container {
    margin-left: 80px;
    width: 100%;

    h4 {
      .headline {
        @include headline-blue;
      }

      .subheadline {
        @include headline-blue-sm;
      }
    }

    li {
      list-style-type: disc;
      padding: 0;
    }

    @include break($mobile) {
      ul:not(.links) {
        width: 80%;
        margin-left: 10%;
        text-align: left;

        li {
          margin: 10px 0;
        }
      }
    }

    .content-body {
      max-width: 350px;
      padding-top: 6px;

      .title {
        @include paragraph-medium;
        line-height: 28px;
      }
    }
  }

  @include bp(mediumWidth) {
    height: auto;
    padding: 40px 0;
    position: relative;
    .header {
      .heading {
        .headline {
          font-size: 4.6vw;
        }

        .subheadline {
          font-size: 11.5vw;
        }
      }
    }
    .slide {
      height: auto;
    }
    .image-container {
      right: auto;
    }
    .content-container {
      margin-left: 0;
      text-align: center;

      h4 {
        text-transform: uppercase;

        span {
          display: inline;
        }

        .headline {
          @include subheadline-1;
        }

        .subheadline {
          @include subheadline-1;
        }
      }

      p {
        &.title {
          @include paragraph-small;
        }
      }

      .content-body {
        max-width: 100%;
        width: 100%;

        .single-list-item {
          padding: 0 20px 15px;
        }
      }
    }
  }
}

.carousel.cows-deets {
  height: auto;
  padding-bottom: 260px;

  .module-share {
    display: block;
    left: 310px;
  }

  .prev,
  .slick-prev,
  .next,
  .slick-next {
    @include prev_next_buttons(45px);
    z-index: 1;
  }

  .slick-prev,
  .slick-next {
    &:before {
      content: none;
    }
  }

  div.more {
    border-bottom: 3px solid $yellow;
    color: $yellow;
    cursor: pointer;
    display: inline-block;
    font: 30px $sans;
    margin: 25px 0 0;
    padding: 5px 0;
    text-transform: uppercase;
    width: auto;

    &:hover {
      border-bottom: 3px solid $black;
      color: $black;
    }
  }

  .slide-wrapper-outer {
    height: 100%;

    .slide-wrapper-inner {
      height: 100% !important;

      .slide {
      }
    }
  }

  .slide {
    height: auto;
  }

  .header {
    display: block;
    overflow: hidden;
    position: relative;

    .heading {
      display: inline-block;

      .headline {
        @include headline-accent-3($yellow-ada);
        line-height: 42px;

        @include break($tablet) {
          padding-left: 0.3em;
        }
      }

      .subheadline {
        @include bold-headline-1;
      }
    }

    .description {
      display: inline-block;

      p {
        max-width: 64%;
      }
    }
  }

  .slide-wrapper-inner {
    top: -60px;
  }

  .image-container {
    height: 436px;
    left: 0;
    position: relative;
    top: 0;
    width: 436px;

    .image {
      height: 436px;
      width: 436px;

      img {
        max-width: 100%;
      }
    }

    .shadow {
      position: relative;
      left: 220px;
      top: -5px;
    }
  }

  .content-container {
    margin: 0;
    padding-left: 470px;
    position: relative;
    margin-top: -400px;
    width: 100%;

    h4 {
      @include subheadline-1;
      margin-bottom: 10px;
    }

    .cow-fact {
      @include circle(186px, $blue_light);
      left: 0px;
      position: absolute;
      text-align: center;
      top: 170px;

      .holstein-top,
      .holstein-middle,
      .holstein-bottom {
        display: block;
        position: relative;
        top: -16px;
        width: 100%;
      }

      .holstein-top {
        background: url(../images/glass-of-milk.png) 50% 0 no-repeat transparent;
        color: $blue_dark;
        font: italic 17px/17px $script;
        padding-top: 57px;
      }

      .holstein-middle {
        color: $white;
        font: 40px $sans;
        height: 53px;
        margin: 2px 0;

        span.number,
        span.percent,
        span.protein {
          position: absolute;
          text-transform: uppercase;
        }

        span.number {
          font-size: 78px;
          left: 23px;
          line-height: 55px;
          letter-spacing: -7px;
          top: 0;
        }

        span.percent {
          font-size: 24px;
          left: 55px;
          line-height: 20px;
          top: 5px;
          width: 113px;
        }

        span.protein {
          font-size: 24px;
          left: 80px;
          line-height: 20px;
          top: 25px;
        }
      }

      .holstein-bottom {
        font: 22px/22px $sans;
        color: $blue_dark;
        text-transform: uppercase;
      }

      .jersey-top,
      .jersey-middle,
      .jersey-bottom {
        display: block;
        letter-spacing: -2px;
      }

      .jersey-top {
        color: $blue_dark;
        font: 30px/30px $script;
        padding-top: 30px;
      }

      .jersey-middle {
        color: $white;
        font: 55px/45px $sans;
        text-transform: uppercase;
      }

      .jersey-bottom {
        color: $white;
        font: 34px/30px $sans;
        text-transform: uppercase;
      }
    }

    .content-body {
      position: relative;

      p {
        padding-right: 0;
      }

      .title {
        font: 50px/65px $sans;
        text-transform: uppercase;
      }

      .description {
        @include paragraph-medium;
        padding-right: 0;
      }
    }
  }

  .excerpt {
    display: none;
    width: 100%;
    position: relative;

    p {
      font: 37px $sans;
      text-transform: uppercase;
      padding-top: 50px;
    }

    .facts {
      list-style: disc;
      margin-left: 20px;

      li {
        font-size: 22px;
        padding-bottom: 15px;
      }
    }

    ul:last-of-type {
      list-style: none;
      margin: 0;

      li {
        font: 22px/32px $sans;
        text-transform: uppercase;

        span {
          color: $olive;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    height: auto;
    padding-bottom: 5vw;
    width: 100%;
    .header {
      .module-share {
        left: 70%;
      }

      .heading {
        .headline {
          font-size: 4.5vw;
        }

        .subheadline {
          font-size: 11.5vw;
        }
      }
    }
    div.more {
      font-size: 19px;
      margin: 5% auto;
    }
    .slide-wrapper-outer {
      max-width: 100%;
      text-align: center;
    }
    .slide-wrapper-inner {
      top: 0;
    }
    .slide {
      max-width: 100%;
    }
    .image-container {
      height: 43.3vw;
      padding: 0 3%;
      top: 0;
      width: 43.3vw;
      display: flex;
      align-items: center;
      flex-direction: column;

      .image {
        height: 100%;
        margin: 0 auto;
        width: 100%;
      }

      .shadow {
        left: initial;
        width: 25.334vw;
        top: initial;
        margin-left: initial;
      }
    }
    .content-container {
      margin: 0;
      padding: 0 3%;
      top: 0;
      width: 100%;

      h4 {
        font-size: 6.7vw;
        line-height: 6.4vw;
      }

      .content-body {
        .description {
          font-size: 4.4vw;
          line-height: 5vw;
        }
      }

      .more {
        font-size: 5.1vw;
      }
    }
    .cow-fact {
      display: none;
    }
    .excerpt {
      p {
        font-size: 9.8vw;
        padding-top: 13.5vw;
      }

      ul {
        width: 90%;
        margin: 0 auto;
      }

      .facts {
        list-style: none;

        li {
          font-size: 3.2vw;
          line-height: 4.3vw;
          padding: 3% 0;
        }
      }

      ul:last-of-type {
        list-style: none;
        margin: 0 auto;

        li {
          font: 4.8vw/5vw $sans;
          padding: 2% 0;
        }
      }
    }
  }
}

.carousel.virtual-farm-tours {
  .content-container {
    margin-left: 0;
  }

  .slick-slider .slick-dots {
    display: none;
  }
}

.carousel.food-bountiful-benefits {
  background: $white;
  padding: 160px 0 0;
  height: auto;

  .module-share {
    display: block;
    left: 645px;
    top: 0;
  }

  .header {
    z-index: 3;

    .heading {
      .headline {
        @include bold-headline-4;
        line-height: 70px;
        letter-spacing: -1.5px;
      }

      .subheadline {
        @include headline-accent-2($yellow-ada);
        text-transform: none;
        line-height: 50px;
        padding-left: 10px;
      }
    }
  }

  .slide-wrapper-outer {
    height: 535px;
  }

  .slick-next,
  .slick-prev {
    top: 70px;

    &:before {
      content: none;
    }
  }

  .slide {
    position: relative;
  }

  .image-container {
    height: auto;
    width: auto;
    position: absolute;
    left: 53px;
    top: -80px;

    .image {
      height: auto;
      width: auto;
    }

    .shadow {
      display: none;
    }
  }

  .content-container {
    position: absolute;
    left: 350px;
    top: -50px;
    letter-spacing: -0.5px;

    .headline {
      font: 33px $sans;
      color: $black-cmab;
      text-transform: uppercase;
    }

    .content-body {
      margin-top: 5px;

      h4 {
        @include subheadline-2;
      }

      p {
        @include paragraph-tiny;
      }

      ul {
        margin-top: 10px;

        .cta {
          @include cta-no-border($yellow, $black, $black, $yellow, auto);
        }
      }

      span.more {
        margin: 5% auto;
      }
    }
  }

  @include break($mobile) {
    height: auto;
    padding: 12% 0 0;
    width: 100%;
    margin-bottom: 40px;
    .slide-wrapper-outer {
      height: auto;
      width: 100%;
    }
    .slide-wrapper-inner {
      position: relative;
      top: 0;
    }
    .header {
      .heading {
        .headline {
          @include bold-headline-4;
          line-height: 1;
        }

        .subheadline {
          @include headline-accent-3($yellow-ada);
          line-height: 1;
        }
      }
    }
    p br {
      display: none;
    }
    .slide {
      height: auto;
      width: 100%;

      .image-container {
        height: auto;
        left: 0;
        position: relative;
        top: 0;
        width: 100%;

        img {
          margin: 0 auto;
          max-width: 60%;
        }
      }

      .content-container {
        height: auto;
        left: 0;
        padding: 0 3.125%;
        position: relative;
        text-align: center;
        top: 0;
        width: 100%;

        .content-body {
          margin: 0;

          ul {
            margin: 10px 0 0;
            text-align: center;

            a {
              display: inline-block;
              width: auto;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.carousel.community-greener-pastures {
  padding: 100px 0 0;
  height: auto;

  .module-share {
    display: block;
    left: 340px;
    top: 0;
  }

  .slide-wrapper-outer {
    margin: 0 auto;
    width: $slider_width;

    .slick-track {
      @media (min-width: 768px) {
        min-height: 354px !important;
      }
    }

    .header {
      height: 120px;
      margin-bottom: 14px;
      width: 100%;

      .heading {
        margin-right: 0;
        position: absolute;
        left: 0;
        top: 0;

        .headline {
          @include bold-headline-3;
          @include break($tablet) {
            font-size: 100px;
          }
        }

        .subheadline {
          @include headline-accent-3($yellow-ada);
          padding-left: 10px;
          text-transform: none;
        }
      }

      .description {
        height: 100%;
        left: 44%;
        line-height: 20px;
        position: absolute;
        width: 27%;
        top: 0;

        p {
          left: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .slick-arrow {
      top: 130px;

      &:before {
        content: none;
      }
    }

    .slide {
      height: auto;

      .image-container {
        display: inline-block;
        float: left;
        height: 100%;
        right: auto;
        left: 0;
        padding-right: 2.5%;
        position: relative;
        width: 40%;

        img {
          float: right;
          height: auto;
          max-width: 100%;
          width: auto;
        }

        .shadow {
          display: none;
        }
      }

      .content-container {
        display: inline-block;
        float: left;
        height: 100%;
        margin: 0;
        padding-right: 2.5%;
        position: relative;
        width: 50%;
        top: 20px;

        .headline {
          @include subheadline-2;
          margin-bottom: 14px;
        }
      }

      .content-body {
        .description {
          @include paragraph-tiny;
        }

        ul li a {
          @include cta-no-border($yellow, $black, $black, $yellow, auto);
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
  }

  @include bp(mediumWidth) {
    height: auto;
    margin: 0;
    padding: 12.5% 0 0;
    width: 100%;
    .module-share {
      position: static;
      margin: 0 auto;
    }
    .slide-wrapper-outer {
      padding: 0;
      width: 100%;

      .header {
        height: auto;
        left: 0;
        top: 0;
        position: relative;

        .heading {
          position: relative;
          text-align: center;
          width: 100%;

          .subheadline {
            padding-left: 0;
          }
        }

        .description {
          display: none;
          position: relative;
        }
      }

      .slide {
        text-align: center;
        width: 100%;

        .content-container {
          margin: 0;
          padding: 0 3%;
          width: 100%;

          .headline {
            font-size: 25px;
          }
        }

        .image-container {
          left: 0;
          margin: 0 0 6%;
          padding: 0 3%;
          position: relative;
          top: 0;
          width: 100%;

          img {
            float: none;
            margin: 0 auto;
            max-width: 50%;
          }
        }

        .content-body {
          .links {
            margin-top: 10px;
          }

          li {
            width: 100%;
          }
        }
      }
    }
  }
}

.slick-slider {
  a,
  div {
    outline: none;
  }

  margin-bottom: 0;

  .slick-dots {
    bottom: 0;
    position: relative;
    padding-top: 20px;

    li {
      height: 40px;
      margin: 0 6px;
      width: 24px;

      button {
        height: 100%;
        width: 100%;

        &:before {
          color: $blue_light;
          font-size: 20px;
        }
      }

      &.slick-active button:before {
        color: $blue_dark;
        opacity: 1;
      }
    }

    @include bp(mediumWidth) {
      li {
        height: 20px;
        margin: 0 3px;
        width: 12px;

        button {
          &:before {
            color: $blue_light;
            font-size: 10px;
          }
        }
      }
    }
  }

  @include bp(mediumWidth) {
    .slick-dots {
      padding-top: 4%;

      li {
        height: 20px;
        margin: 0 3px;
        width: 12px;

        button {
          &:before {
            color: $blue_light;
            font-size: 10px;
          }
        }
      }
    }
  }
}
