#sustainability {
  .masthead {
    position: relative;
    background-color: black;
    height: auto;
    margin-top: 50px;

    @include break($mobile, true) {
      margin-top: 40px;
    }

    .background-video-wrapper {
      position: relative;
      height: 100vw;
      width: 100%;
      overflow: hidden;

      @include break($tablet) {
        height: calc(100vh - 70px);
        max-height: 56vw;
        min-height: 56vw;
      }

      .background-video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: auto;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .copy-container {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        width: 250px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include bp(mediumWidth) {
          width: 200px;
          padding: 0 10px;
        }

        &:hover {
          color: $white;
        }
      }
    }

    .mobile {
      @include break($tablet) {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include break($tablet) {
        display: block;
      }
    }

    .heading {
      color: white;
      font-family: gothic, sans-serif;
      font-size: 37px;
      line-height: 1.1;
      text-align: center;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 47px;
        margin: 0 auto;
        max-width: 500px;
      }

      @include break($desktop) {
        font-size: 96px;
        line-height: 1;
        max-width: 840px;
      }

      span {
        white-space: nowrap;
      }
    }

    .description {
      color: white;
      font-family: gothic, sans-serif;
      font-size: 20px;
      line-height: 1.1;
      text-align: center;

      @include break($mobile-xs, true) {
        font-size: 22px;
      }

      @include break($tablet) {
        max-width: 600px;
      }
      
      @include break($desktop) {
        font-size: 30px;
        max-width: 840px;
        margin: auto;
      }
    }

    .yellow-box {
      position: relative;
      background-color: #f5b324;
      max-width: 1200px;
      margin-top: 40px;
      margin-bottom: -220px;

      @include break($tablet) {
        margin-top: 0;
        margin-bottom: -120px;
      }

      @include break($desktop-lg) {
        margin-bottom: -30px;
      }

      p {
        font-family: hoefler, serif;
        font-size: 18px;
        line-height: 22px;

        @include break($desktop) {
          font-size: 22px;
        }

        a {
          color: black;
        }
      }

      .sustainibility-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 25%;
        margin-top: -40px;
        @include break($desktop) {
          width: 10%;
          margin-top: -60px;
        }
        @include break($tablet) {
          margin-top: -50px;
        }
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  #sustainability-campaign-modal {
    .modal-dialog {
      max-width: 95vw;
      margin: auto;
      height: 100%;

      @include break($tablet) {
        max-width: 80vw;
      }

      .modal-content {
        border-radius: 25px;
        overflow: hidden;
        -webkit-transform: translateZ(0);
        position: relative;

        .icon-close {
          position: absolute;
          width: 25px;
          height: 25px;
          top: 20px;
          right: 20px;
          z-index: 1;

          @supports (-webkit-touch-callout: none) {
            display: none;
          }

          @include break($tablet) {
            width: 35px;
            height: 35px;
            top: 40px;
            right: 40px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        video {
          width: 100%;
          display: block;
          background-color: black;
        }
      }
    }
  }

  .resources {
    background-color: $white;
    background-image: url("../images/background-marble.jpg");
    background-size: auto;
    background-attachment: scroll;

    @include break($tablet) {
      background-size: cover;
      background-attachment: fixed;
    }

    .facts {
      padding-top: 270px;

      @include break($desktop) {
        padding-top: 120px;
      }

      @include break($desktop-lg) {
        padding-top: 90px;
      }

      .fact-card {
        > div.p-4 {
          max-width: 305px;
        }

        .heading {
          font-size: 30px;
          line-height: 32px;
          font-family: gothic, sans-serif;

          @include break($tablet) {
            font-size: 26px;
            line-height: 28px;
          }
        }

        .description {
          font-size: 20px;
          line-height: 26px;
          font-family: hoefler, serif;

          @include break($tablet) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        img {
          &.shift-left {
            @include break($desktop-lg) {
              margin-left: -40px;
            }
          }

          &.circle-xlarge {
            width: 300px;
          }

          &.circle-large {
            width: 250px;
          }

          &.circle-medium {
            width: 150px;

            @include break($desktop-lg) {
              width: 200px;
            }
          }

          &.circle-small {
            width: 150px;
          }
        }
      }

      .separator {
        padding-left: 30px;
        padding-right: 30px;

        @include break($tablet) {
          padding-left: 0;
          padding-right: 0;
        }

        .col {
          border-bottom: 1px solid #707070;
          padding-top: 60px;
        }
      }
    }

    .es {
      @include break($mobile-xs, true) {
        padding-top: 300px;
      }
    }

    .sustainability-resources {
      padding-top: 60px;
      padding-bottom: 60px;

      @include break($tablet) {
        padding-top: 90px;
        padding-bottom: 90px;
      }

      .title {
        .headline {
          font-family: gothic, sans-serif;
          font-size: 60px;
          line-height: 0.8;
          text-transform: uppercase;

          @include break($tablet) {
            font-size: 72px;
          }
        }

        .subheadline {
          font-family: "wisdom", cursive;
          color: $yellow-ada;
          font-size: 48px;
          line-height: 0.9;

          @include break($tablet) {
            font-size: 55px;
            line-height: 1;
          }
        }
      }

      .post {
        .cta {
          @include cta-no-border($yellow, $black, $black, $yellow, 240px);
          padding: 0 10px;

          @include break($mobile, true) {
            width: auto;
            min-width: 240px;
            padding: 0 10px;
          }
        }
      }
    }
  }

  .lower-emissions {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $yellow;

    @include break($tablet) {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    .headline {
      font-family: "gothic", sans-serif;
      font-size: 52px;
      line-height: 0.9;
      letter-spacing: -2px;

      @include break($desktop) {
        font-size: 141px;
      }
    }

    .subheadline {
      color: white;
      font-family: "wisdom", cursive;
      font-size: 30px;
      line-height: 37px;

      @include break($desktop) {
        font-size: 60px;
        line-height: 72px;
      }
    }

    .disclaimer {
      font-family: "hoefler", serif;
      font-size: 12px;
      @include break($tablet) {
        font-size: 15px;
      }
    }

    .slide {
      cursor: pointer;

      .heading {
        text-align: center;
        text-transform: uppercase;
        font-family: gothic, sans-serif;
        font-size: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .prev,
    .slick-prev,
    .next,
    .slick-next {
      @include prev_next_buttons(40%);

      &::before {
        content: "";
      }

      &.slick-disabled {
        opacity: 0.25;
      }
    }

    .prev,
    .slick-prev {
      @include prev_button(0, white, false);
      left: -65px;
    }

    .next,
    .slick-next {
      @include next_button(0, white, false);
      right: -65px;
    }
  }

  .look-for-seal {
    padding-bottom: 60px;
  }

  .next-up {
    padding-bottom: 60px;

    .image {
      max-width: 500px;
      height: 100%;
      @include bp(mediumWidth) {
        width: 65vw;
      }
    }
  }
}
