.outer-wrapper {
  .foodservice {
    overflow: visible;

    body#foodservice & {
      margin-top: 40px;

      @include break($tablet) {
        margin-top: 50px;
      }

      .notification-banner {
        background-color: $yellow;
        padding: 30px;
        margin-top: -234px;
        transition: margin 700ms;
        transition-delay: 0.3s;

        @include break($tablet) {
          margin-top: -187px;
          padding: 50px 120px;
        }

        h2 {
          font-size: 24px;
          line-height: 1.2;

          @include break($tablet) {
            font-size: 30px;
          }
        }

        .banner {
          flex-direction: column;

          @include break($desktop-wide) {
            flex-direction: row;
          }
        }

        &.show {
          margin-top: 0;
        }

        .headline {
          font-family: gothic, sans-serif;
          font-size: 20px;
          padding: 15px 0;
          text-align: center;
          text-transform: uppercase;

          @include break($tablet) {
            font-size: 35px;
          }

          @include break($desktop-wide) {
            padding: 0 20px;
          }
        }

        .cta-wrapper {
          .cta {
            @include cta-no-border($black, $white, $white, $black, auto);
            @include bp(mediumWidth) {
              padding: 0 20px;
            }
          }
        }
      }
    }

    .heading-container {
      position: relative;

      .page-heading {
        background-position: 50%;
        height: 73vmin;

        @include break($tablet) {
          height: 80vh;
          min-height: 544px;
        }

        h1 {
          .subheadline {
            color: #fff;
            font: 35px/32px "dinbold";
            text-transform: uppercase;
            text-align: center;
            display: block;
            letter-spacing: 7px;

            @include break($tablet, true) {
              letter-spacing: 5px;
            }
          }

          margin: 22vh auto 0 auto;
          font: 117px "gistrough";
          text-align: center;
          width: 100%;

          @include break($tablet, true) {
            .subheadline {
              font: 4.5vw "dinbold";
            }

            padding-top: 10.5vh;
            margin: 0 auto 0 auto;
            font: 10.5vw "gistrough";
          }
        }
      }

      .anchor-next-section {
        position: absolute;
        top: 70vh;
        height: 52px;
        width: 100%;
        display: block;
        z-index: 99;
        @include bp(mediumWidth) {
          display: none;
        }
        @include bp(smallWidth) {
          display: none;
        }

        .cta-top {
          background: transparent none;
          height: 52px;
          left: auto;
          margin: auto;
          position: inherit;
          text-align: center;
          transform: none;
          width: 100%;

          .circle {
            border-radius: 26px;
            border: 4px $yellow solid;
            height: 52px;
            margin: auto;
            width: 52px;

            &:hover {
              border-color: $yellow-drk;

              .arrow_box {
                border-color: $yellow-drk;
              }
            }

            .down-arrow {
              position: relative;
              top: 20%;
              content: "";
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 2px;
              border-right: 4px solid $yellow;
              border-top: 4px solid $yellow;
              transform: rotate(135deg);

              &:hover {
                border-color: $yellow-drk;
              }
            }
          }
        }
      }

      .sticker {
        position: absolute;
        width: 22vw;
        height: 22vw;
        bottom: 10%;
        z-index: 99;

        @include break($tablet) {
          width: 15vw;
          height: 15vw;
          bottom: auto;
          top: 55%;
        }

        @include break($desktop-wide) {
          top: 50%;
        }

        &.pizza {
          background: transparent url("../images/bg-pizza-contest-cta.gif")
            no-repeat scroll 0 0 / 100% auto;
          left: 12%;

          @include break($tablet) {
            left: 25%;
          }
        }

        &.calmex {
          width: 29.3333vw;
          background: transparent url("../images/bg-calmex-cta.gif") no-repeat
            scroll 0 0 / 100% auto;
          left: 50%;
          transform: translateX(-50%);

          @include break($tablet) {
            width: 20vw;
          }
        }

        &.dairy {
          background: transparent url("../images/bg-cadairy-competition.gif")
            no-repeat scroll 0 0 / 100% auto;
          right: 12%;

          @include break($tablet) {
            right: 25%;
          }
        }
      }
    }

    .foodservice-recipes {
      background-color: transparent;
    }

    .dark-gradient {
      @include vertical_gradient(#333333, #191919);
      padding-bottom: 1px;
      @include break($tablet, true) {
        margin-bottom: -49px;
        margin-top: 0;
      }
    }

    .newsletter {
      position: relative;
      @include add_chevron();

      .page-content {
        padding-bottom: 5em;
        margin: 0 auto;

        .headline {
          width: 100%;
          text-align: center;
          font-family: "dinbold";
          text-transform: uppercase;
          font-size: 31px;
          @include break($tablet, true) {
            font-size: 5vw;
            padding: 0 20vw;
            letter-spacing: 0.6vw;
          }
        }

        .subheadline {
          width: 100%;
          text-align: center;
          font-size: 77px;
          margin-top: 20px;
          font-family: "gistrough";
          text-transform: uppercase;
          @include break($tablet, true) {
            font-size: 9.3vw;
            margin-top: 2vw;
          }
        }

        .content {
          width: 100%;
          text-align: center;
          font-family: hoefler;
          font-size: 19px;
          margin: 10px auto;
          line-height: 25px;
          @include break($tablet, true) {
            font-size: 4vw;
            letter-spacing: 0.2vw;
            margin-top: 0.5vw;
            line-height: 6vw;
            padding: 0 1vw;
          }
        }

        form {
          margin-top: 35px;
          text-align: center;
          @include break($tablet, true) {
            margin-top: 9vw;
          }

          input {
            border: 0;
            font-family: "gothic";
            font-size: 18px;
            letter-spacing: 2px;
            @include break($tablet, true) {
              font-size: 4.4vw;
            }
          }

          .email {
            width: 306px;
            background-color: #fff;
            padding: 14px 20px;

            &::-webkit-input-placeholder,
            &:-moz-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder {
              color: #bbbbbb;
            }

            @include break($tablet, true) {
              width: 75%;
              padding: 3.5vw 2vw;
            }
          }

          .button {
            padding: 14px 52px;
            background-color: $black_cmab;
            color: #fff;
            margin-left: -4px;
            width: 166px;
            @include break($tablet, true) {
              padding: 3.5vw 2vw;
            }
          }

          .message-error {
            color: $error_message;
            font-family: hoefler;
            font-size: 14px;
            line-height: 20px;
            margin: 4px auto 8px;
            text-align: left;
            width: 218px;
          }
        }

        .success-message {
          font-family: gothic;
          font-size: 46px;
          margin-top: 30px;
          text-transform: uppercase;
          text-align: center;
        }

        .hide {
          display: none;
        }

        .icon-check {
          background-image: url("../images/icons/check.png");
          width: 39px;
          height: 29px;
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
  }

  .footer {
    margin-top: 0;
  }
}

@import "foodservice_shared";
