.tastemade-tout {
  height: 400px;
  margin-bottom: 150px;
  position: relative;

  @include break($mobile) {
    height: 250px;
    margin-bottom: 60px;
  }

  .tout-wrapper {
    @include paragraph-tiny;
    width: 420px;
    margin: auto;
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    @include break($mobile) {
      width: 100%;
      max-width: 320px;
      padding: 0 5px;
    }

    .header {
      margin-bottom: 7px;

      img {
        width: 230px;

        @include break($mobile) {
          width: 160px;
        }
      }
    }

    .cta-wrapper {
      text-transform: uppercase;
      position: relative;
      margin-top: 30px;

      a {
        @include commericalLinks($black, $yellow);
        text-decoration: none;
        background: url("../images/icons/cta-play.png") no-repeat 0 2px;
        font-size: 19px;
        width: 15px;
        height: 15px;
        content: "";
        position: relative;
        background-size: 16px;
        padding-left: 20px;

        @include break($mobile) {
          font-size: 19px;
        }
      }
    }
  }
}

body#food {
  .tastemade-tout {
    background-color: $yellow;
    margin-bottom: 0;

    .cta {
      color: $white;
      background-image: url("../images/icons/cta-play-white.png");
    }
    @include break($mobile) {
      z-index: -1;
    }
  }
}
