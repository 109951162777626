$mobile_big: 640;
$mobile_small: 320;

$middle-width: 950px;

/* Global Styles */
// color variables

$black: #000000;
$black_cmab: #231f20;
$black_cmab_transparent: transparentize($black_cmab, 0.89);

$blue_light: #0cc7b8;
$blue_medium: #02acac;
$blue_dark: #266e68;
$blue_link: #0dc6b7;
$blue_link-inactive: #1a625c;

$brown: #b88b12;

$gray_section_bg: #231f20;
$gray_section_border: #333333;
$gray_section_bg-dark: #1e1b1b;
$gray_bg_accordion: #2a2627;

$gray_border_table_td: #dcdcdc;
$gray_border_accordion: #444444;
$gray_bprder_subnav: #a8a8a7;

$gray: #a6a292;
//$gray_dark: #9b957b;
$gray_dark: #666666;
$gray_title: #898d8d;
$gray_inactive: #e8e8e8;
$gray_border: #e5e5e5;
$gray_input_bg: #383334;
$gray_input_text: #605a5b;
$gray_input_password: #b8b8b8;
$gray_input_bg_light: #efefef;
$gray_menu_border: #353434;
$gray_pale: #f5f5f5;
$gray_background: #f3f3f3;

$pink_light: #f7b1b4;

$green_dark: #a6a292;
$olive: #9b957b;

$teal: #0cc7b8;
$white: #fff;

$yellow: #fec31f;
$yellow-ada: #e0a500;
$yellow_link_hover: #b48811;
$yellow_bg_dark: #4a4a48;
$yellow_active: #d59d03;
$yellow_inactive: #ffc320;
$yellow-drk: #e7b018;
$yellow-med: #f4b91f;
$yellow_dot_active: #b48811;
$yellow_partnership: #FFB307;

$error_message: #fc0345;

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  background: $white;
  color: $black_cmab;
  font-family: Arial, Helvetica, Georgia, sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  -webkit-font-smoothing: antialiased;

  &.ca-lock {
    overflow-y: hidden;
  }

}

p {
  font-size: 16px;
}

i {
  font-style: italic;
}

b, .bold {
  font-weight: bold;
}

.content-width { // replace w/ .main?
  width: 960px;
  margin: 0 auto;
  padding: 0 50px;
  @include bp(mediumWidth) {
    width: 100%;
  }
}

.main {
  margin: 0 auto;
  width: 1060px;
  @include bp(mediumWidth) {
    width: 100%;
  }
}

.noscroll {
  overflow: hidden;
  @include bp(mediumWidth) {
    overflow: auto;
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
  }
}

.clear {
  clear: both;
}

.outer-wrapper {
  min-width: 767px;
  width: 100%;
  overflow-x: hidden;

  .lt-ie10 & {
    z-index: 1;
  }

  @include bp(mediumWidth) {
    min-width: 0;
    width: 100%;
  }
}

input {
  -webkit-appearance: none;
  @include border-radius-and-pie(0);
}