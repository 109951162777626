// MEDIA QUERIES
@mixin bp($point) {
  @if $point == largeWidth {
    @media (max-width: 1075px) {
      @content;
    }
  } @else if $point == mediumWidth {
    //@media (max-width: 815px) { @content; }
    @include break($mobile) {
      @content;
    }
  } @else if $point == smallWidth {
    @media (max-width: 400px) {
      @content;
    }
  }
}

// Breakpoints (copied from newer projects)
// This generally operates in a mobile-first way, using min-width for everything, BUT
//   you can make it use max-width by either passing in true as the second param OR
//   by using a numerically odd width. This is because standard breakpoints are always
//   even numbers, and you typically want to max-width up to breakpoint-1. This manifests
//   itself in the breakpoint $mobile, which is $tablet - 1 so we don't need to overwrite
//   mobile-specific attributes.
// Because this will be used in vw unit-loops a lot, if the width is tiny, then don't use @media.
@mixin break($width, $is-max: "") {
  $width-magnitude: strip-unit($width);
  $attr: "min-width";

  @if (type-of($is-max) == number) {
    @media (min-width: $width) and (max-width: $is-max) {
      @content;
    }
  } @else {
    @if (type-of($is-max) == bool) {
      $attr: if($is-max, "max-width", "min-width");
    } @else {
      $attr: if(not even($width-magnitude), "max-width", "min-width");
    }

    @if ($width-magnitude < 320 and $attr == "min-width") {
      @content;
    } @else {
      @media (#{$attr}: $width) {
        @content;
      }
    }
  }
}

@function even($number) {
  @return $number % 2 == 0;
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// TEXT STYLES
@mixin upper {
  text-transform: uppercase;
}

@mixin no-underline {
  text-decoration: none;
}

@mixin headline-accent {
  font-family: $script;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin headline-accent-1($color, $include-legacy-mobile: true) {
  @include headline-accent;
  color: $color;
  font-size: 65px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      font-size: 17px;
    }
  }
}

@mixin headline-accent-2($color, $include-legacy-mobile: true) {
  @include headline-accent;
  color: $color;
  font-size: 50px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      font-size: 17px;
    }
  }
}

@mixin headline-accent-3($color, $include-legacy-mobile: true) {
  @include headline-accent;
  color: $color;
  font-size: 35px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      font-size: 17px;
    }
  }
}

@mixin headline-accent-4($color, $include-legacy-mobile: true) {
  @include headline-accent;
  @include headline-accent;
  color: $color;
  font-size: 25px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      font-size: 17px;
    }
  }
}

@mixin bold-headline {
  @include upper;
  color: $black_cmab;
  font-family: $sans;
  -webkit-font-smoothing: antialiased;
}

@mixin bold-headline-medium {
  font-size: 43px;
  //letter-spacing: -1px;
  letter-spacing: 0.01em;
  line-height: 1;
}

@mixin bold-headline-large {
  font-size: 42px;
  //letter-spacing: -1px;
  letter-spacing: 0.01em;
  line-height: 1;
}

@mixin bold-headline-small {
  font-size: 24px;
  //letter-spacing: -1px;
  letter-spacing: 0.01em;
  line-height: 1;
}

@mixin bold-headline-1($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 150px;
  line-height: 108px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-medium;
    }
  }
}

@mixin bold-headline-2($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 132px;
  line-height: 94px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-medium;
    }
  }
}

@mixin bold-headline-3($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 114px;
  line-height: 83px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-medium;
    }
  }
}

@mixin bold-headline-4($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 97px;
  letter-spacing: -0.016em;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-medium;
    }
  }
}

@mixin bold-headline-5($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 90px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-medium;
    }
  }
}

@mixin bold-headline-6($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 3.5vw;
  @include break($desktop-wide) {
    font-size: 45px;
  }
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-large;
    }
  }
}

@mixin bold-headline-7($include-legacy-mobile: true) {
  @include bold-headline;
  font-size: 40px;
  @if ($include-legacy-mobile) {
    @include bp(mediumWidth) {
      @include bold-headline-large;
    }
  }
}

@mixin subheadline-mobile {
  @include bp(mediumWidth) {
    font-size: 25px;
    line-height: 23px;
  }
}

@mixin headlines(
  $headline-size: 1,
  $headline-size-mobile: 1,
  $subheadline-size: 1,
  $subheadline-size-mobile: 1,
  $subheadline-color: 1
) {
  .headline {
    @include bold-headline;
    font-size: $headline-size;
    @include break($mobile) {
      font-size: $headline-size-mobile;
    }
  }
  .subheadline {
    @include headline-accent;
    color: $subheadline-color;
    font-size: $subheadline-size;
    @include break($mobile) {
      font-size: $subheadline-size-mobile;
    }
  }
}

@mixin subheadline-1 {
  color: $black_cmab;
  font-family: $sans;
  font-size: 50px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  @include subheadline-mobile;
}

@mixin subheadline-2 {
  @include subheadline-1;
  font-size: 33px;
  @include subheadline-mobile;
}

@mixin subheadline-3 {
  @include subheadline-1;
  font-size: 24px;
  @include subheadline-mobile;
}

@mixin paragraph-large {
  color: $black_cmab;
  font-family: $serif;
  font-size: 33px;
  line-height: 45px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 17px;
    line-height: 23px;
  }
}

@mixin mobile-heading-fresh {
  @include bp(mediumWidth) {
    font-size: 20px;
  }
}

@mixin paragraph-medium {
  color: $black_cmab;
  font-family: $serif;
  font-size: 25px;
  line-height: 34px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin paragraph-small {
  color: $black_cmab;
  font-family: $serif;
  font-size: 21px;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin paragraph-tiny {
  color: $black_cmab;
  font-family: $serif;
  font-size: 18px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin paragraph-footnote {
  color: $black_cmab;
  font-family: $serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 10px;
  }
}

@mixin paragraph-em {
  color: $olive;
  font-family: $serif;
  font-size: 40px;
  font-style: italic;
  line-height: 50px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 17px;
    line-height: 23px;
  }
}

@mixin paragraph-em-small {
  @include paragraph-em;
  font-size: 19px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  @include bp(mediumWidth) {
    font-size: 17px;
    line-height: 23px;
  }
}

@mixin span-block {
  span {
    display: block;
  }
}

@mixin not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin max-width-image {
  img {
    max-width: 100%;
  }
}

@mixin headline-blue {
  color: $blue_light;
  font-family: $sans;
  font-size: 103px;
  line-height: 74px;
  text-transform: uppercase;
}

@mixin headline-blue-sm {
  @include headline-blue;
  font-size: 87px;
  line-height: 71px;
}

// CTA
@mixin border-radius-and-pie($radius: 50%) {
  border-radius: $radius;
}

@mixin cta-button-base {
  @include no-underline;
  @include upper;
  cursor: pointer;
  display: inline-block;
  font-family: $sans;
  font-size: 20px;
  height: 46px;
  line-height: 42px;
  padding: 0 20px;
  text-align: center;
  transition:
    background-color 0.2s,
    border 0.2s;
  width: auto;

  &:hover {
    @include no-underline;
  }

  @include bp(mediumWidth) {
    font-size: 19px;
    height: 42px;
    line-height: 36px;
    padding: 0 20px;
    text-align: center;
    width: auto;
  }
}

@mixin cta-border($resting-background-color, $hover-background-color, $width) {
  @include cta-button-base;
  background-color: $resting-background-color;
  border: 3px solid $hover-background-color;
  color: $hover-background-color;
  min-width: $width;

  &:hover {
    background: $hover-background-color;
    color: $resting-background-color;
  }
}

@mixin cta-no-border($bg-color, $color, $bg-color-hover, $color-hover, $width) {
  @include cta-button-base;
  background-color: $bg-color;
  border: 3px solid $bg-color;
  color: $color;
  min-width: $width;

  &:hover {
    background-color: $bg-color-hover;
    border: 3px solid $bg-color-hover;
    color: $color-hover;
  }
}

@mixin big-cta-addition() {
  border-width: 5px;
  font-size: 37px;
  padding: 20px;
}

@mixin cta-circle($width, $height) {
  @include border-radius-and-pie();
  background-color: $teal;
  cursor: pointer;
  display: block;
  height: $height;
  left: 72%;
  padding: 0 20px;
  position: absolute;
  text-decoration: none;
  width: $width;
  z-index: 500;
  @include bp(mediumWidth) {
    height: 80px;
    padding: 0 2.5%;
    width: 80px;
  }
}

@mixin cta-sans($size) {
  color: $white;
  font-family: $sans;
  font-size: $size;
  text-transform: uppercase;
}

@mixin cta-script($size) {
  color: $blue_dark;
  font-family: $script;
  font-size: $size;
}

@mixin circle($width, $color) {
  width: $width;
  height: $width;
  background: $color;
  @include border-radius-and-pie;
}

@mixin caret() {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

@mixin prev_next_buttons($top: null) {
  cursor: pointer;
  height: 60px;
  width: 59px;
  z-index: 100;
  @if $top {
    top: $top;
  }
}

@mixin prev_button($left, $color, $suffix) {
  @if not $suffix {
    background: url("../images/icons/arrow-prev-" + $color + ".png") no-repeat;
  } @else {
    background: url("../images/icons/arrow-prev-" + $color + $suffix + ".png")
      no-repeat;
  }
  left: $left;
  background-position: center;
  background-size: contain;
}

@mixin next_button($right, $color, $suffix) {
  @if not $suffix {
    background: url("../images/icons/arrow-next-" + $color + ".png") no-repeat;
  } @else {
    background: url("../images/icons/arrow-next-" + $color + $suffix + ".png")
      no-repeat;
  }
  right: $right;
  background-position: center;
  background-size: contain;
}

@mixin arrow_carousel($dir) {
  background: url("../images/icons/sprite-arrows-normal.png") 0 0 no-repeat
    transparent;
  @if $dir == "left" {
    background-position: 0 0;
    &:hover {
      background-position: 0 -60px;
    }
  }
  @if $dir == "right" {
    background-position: -59px 0;
    &:hover {
      background-position: -59px -60px;
    }
  }
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin nav_headline {
  color: $white;
  font: 18px $script;
  @include bp(mediumWidth) {
    font-size: 18px;
  }
}

@mixin nav_subheadline {
  color: $white;
  font: 33px $sans;
  text-transform: uppercase;
  @include bp(mediumWidth) {
    font-size: 33px;
  }
}

@mixin nav_link {
  color: $yellow_inactive;
  font: 22px $sans;
  text-transform: uppercase;
  &:hover {
    color: $white;
  }
  @include bp(mediumWidth) {
    font-size: 22px;
  }
}

@mixin commericalLinks($color, $hover) {
  color: $color;
  font-size: 15px;
  font-family: "gothic";
  text-transform: uppercase;

  &:hover {
    color: $hover;
  }
  @include bp(mediumWidth) {
    font-size: 10px;
  }
}

@function theme-url($filename) {
  @return url("../images/#{$filename}");
}

//TODO: use $top-or-bottom. If so, the filled area must be the triangle part of the chevron.
@mixin chevron($color: "yellow", $top-or-bottom: "top") {
  //note that in SASS #fff==white==rgba(255,255,255,1) etc.
  $suffix: if($color== "white" or $color==#fff or $color==$white, "w", "y");
  $height: 50px;

  margin-top: $height + 100;
  padding-top: 100px;
  &::before {
    content: "";
    background-image: url("../images/chevron-#{$suffix}.png");
    display: block;
    height: $height;
    position: absolute;
    top: -$height;
    width: 100%;
    background-size: 1280px 50px;
    @include break(1280px) {
      background-size: 100% 50px;
    }
    background-position: center center;
  }
}

/* SPACING */
@mixin module_padding_mobile {
  padding: 12.5% 3.125% 0;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin square($length) {
  height: $length;
  width: $length;
}

@mixin vertical_gradient($color1, $color2) {
  background: $color1; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    $color1 0%,
    $color2 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    $color1 0%,
    $color2 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    $color1 0%,
    $color2 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color1}', endColorstr='#{$color2}', GradientType=0); /* IE6-9 */
}

@mixin add_chevron() {
  background-color: $yellow;
  &:before {
    background-image: url("../images/chevron-y.png");
    background-position: center center;
    background-size: 100% 50px;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 52px;
    position: relative;
    top: -51px;
    width: 100%;
    @include break($tablet, true) {
      background-size: 1280px 100%;
    }
  }
}

@mixin link-download() {
  background: url("../images/icon-download.png") 0 50% no-repeat;
  color: $blue_link;
  font-size: 20px;
  padding-left: 40px;
  text-decoration: none;
  text-transform: none;

  @include break($mobile) {
    padding-left: 24px;
  }
}

@mixin styled-select {
  .chosen-container {
    background: $white;
    border: 2px solid $yellow-ada;
  }

  .chosen-select,
  .chosen-container-single,
  .chosen-container-active.chosen-with-drop {
    margin-right: 7px;
    text-align: left;

    @include break($mobile) {
      display: none;
    }

    .chosen-single {
      @include border-radius-and-pie(0);
      background: $white url(../images/select-arrow-yellow.png) no-repeat;
      background-position: 175px;
      box-shadow: 0 0 0 transparent;
      height: 45px;
      padding: 11px 0 0 12px;
      border: 0;

      span {
        @include bold-headline;
        font-size: 23px;
        color: $black;
      }
    }

    .chosen-drop {
      @include border-radius-and-pie();
      border: 0;
      border-radius: 0;
      background: $white;
      //margin-left: -2px;
      width: 212px;

      .chosen-results {
        padding: 0;
        margin: 0;

        li {
          @include bold-headline;
          background: $white;
          font-size: 23px;
          padding: 12px;

          &:hover {
            background: #f1f0ef;
          }

          &:first-child {
            // display: none;
          }
        }
      }
    }

    @include break($mediumWidth) {
      .controls {
        width: 100%;
        margin-bottom: 10px;

        &.hidden {
          visibility: visible;
          opacity: 1;
        }

        li {
          display: inline-block;
          width: 48.7%;

          @include break($mobile) {
            width: 48%;
            margin-right: 2.5%;
            margin-bottom: 2.5vw;
          }

          select {
            background: url(../images/select-arrow-black.png) 96% center no-repeat
              $yellow;
            color: $black !important;
            font-family: "gothic";
            font-size: 19px;
            height: 13vw;
            padding: 2vw 28px 2vw 2vw;
            text-transform: uppercase;
            width: 100%;

            @include break($mobile) {
              font-size: 5vw;
              display: block !important;
            }
          }
        }
      }

      .chosen-select {
        @include bold-headline;
        @include upper;
        width: 100%;
        color: $black !important;
        background: $white url(../images/mobile-list.jpg) no-repeat;
        background-position: 1px -20px;
        text-align: center;
        height: 20px;
        background-size: 90%;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 0;
      }
    }
  }
}
