.search {
  .footer {
    bottom: 0;
    position: relative;
    width: 100%;
  }
}

.search-header {
  form.searchform {
    margin-top: 80px;
  }
}

body.search-open {
  .nav-wrapper form.searchform {
    top: 0;
    transform: scale(1, 1);

    @include bp(mediumWidth) {
      right: -40px;
      top: 40px;
    }
  }
}

.nav-wrapper form.searchform {
  height: 50px;
  position: absolute;
  right: 0;
  transform-origin: 100% 0;
  transform: scale(0, 1);
  transition: 250ms all;
  width: 21.25vw;
  z-index: 9999;

  @include break($tablet, true) {
    transition: none;
    transform: none;
    width: 100vw;
  }
}

form.searchform {
  input[type="text"],
  input[type="submit"] {
    -webkit-font-smoothing: antialiased;
    border: 0;
    color: black;
    float: left;
    font-family: $sans;
    font-size: 22px;
    height: 50px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  input[type="text"] {
    background-color: white;
    color: $black_cmab;
    padding: 16px 0 16px 71px;
    width: 100%;

    &:focus {
      color: $black_cmab;
      height: 50px;
      outline-color: $gray_input_text;
      outline-width: 2px;
      width: 100%;
    }
  }

  input[type="submit"] {
    background: url("../images/icon-magnifier.png") 50% 50% no-repeat
      transparent;
    color: $black_cmab;
    height: 100%;
    left: 0;
    position: absolute;
    text-indent: -9999px;
    top: 0;
    width: 71px;

    @include break($tablet, true) {
      background-image: none;
      background-color: $yellow_inactive;
      color: $white;
      font-size: 22px;
      left: auto;
      line-height: 53px;
      right: 0;
      text-indent: 0;
      width: 91px;
    }
  }
}

.search-header {
  background: $black_cmab url("../images/search-bg-header.jpg") no-repeat scroll
    50% 0 / cover;
  height: 460px;
  text-align: center;
  width: 100%;
  margin-top: -50px;
  padding-top: 50px;
  @include bp(mediumWidth) {
    background-position: top left;
    background-size: 100% 230px;
    height: auto;
  }

  .content {
    margin: 0 auto;
    text-align: left;
    width: 960px;
    @include bp(mediumWidth) {
      width: 100%;
    }
  }

  h2 {
    @include bold-headline-4;
    color: $white;
    line-height: 72px;
    padding: 50px 0 0;
    margin: 70px auto 0;
    @include bp(mediumWidth) {
      margin: initial;
      padding: 60px 15px 25px;
    }
  }
}

.search-body {
  background-color: $white;
  color: $black_cmab;
  margin: 0 auto;
  min-height: 200px;
  padding: 30px 0 120px;
  width: 960px;

  @include bp(mediumWidth) {
    width: 90%;
    padding: 0;
  }

  .search-result {
    border-bottom: 1px solid $gray_border;
    padding-top: 30px;
    @include bp(mediumWidth) {
      padding-top: 0;
    }
  }

  h3 {
    @include subheadline-2;
    padding: 30px 0 20px;
  }

  p {
    @include paragraph-tiny;
    padding-bottom: 30px;

    a {
      color: $yellow-ada;
    }
  }
}

@include bp(mediumWidth) {
  form.searchform {
    height: 40px;
    right: -40px;
    margin: 0 auto !important;
    top: -50px;
    width: 100vw;

    input[type="text"] {
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      padding: 0 0 0 10px;
      width: 100%;

      &:focus {
        height: 40px;
        line-height: 40px;
        width: 100%;
      }
    }

    input[type="submit"] {
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      width: 40px;
    }
  }
}
