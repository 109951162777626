.map-wrapper {
  .cheese-map &,
  .bubble-map & {
    @include bp(mediumWidth) {
      background: none !important;
    }
  }
}

// Cheese map
.cheese-map {
  display: none;

  height: 1600px;
  width: 1370px;
  margin: -250px auto 0;
  padding-top: 100px;
  background-color: $white;
  background-position: 0 100px;
  background-repeat: no-repeat;
  position: relative;
  left: -150px;

  .lt-ie10 & {
    margin: 0 auto;
  }

  .mobile & {
    left: initial;
  }

  .module-share {
    display: block;
    left: 224px;
    top: 0;

    .ie8 & {
      z-index: 0;
    }
  }

  .subheadline {
    @include headline-accent-3($yellow-ada);
  }

  .headline {
    @include bold-headline-1;
    position: relative;
    left: -12px;
    line-height: 119px;
  }

  .mobile-no {
    .inner {
      position: absolute;
      left: 300px;
      top: 422px;

      html.ie & {
        .module-share {
          z-index: auto;
        }
      }

      .nav {
        margin-top: 10px;

        li {
          display: inline-block;
          border-right: 1px solid #ddd;
          padding: 0 15px;
          color: $yellow;
          font: 25px $sans;
          @include upper;
          cursor: pointer;

          img {
            margin: 0 auto;
            display: block;
          }

          a,
          a:hover {
            text-decoration: none;
            color: $yellow;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            border-right: 0;
          }

          &:first-child,
          &:nth-child(3) {
            img {
              margin-bottom: 10px;
            }
          }

          &:hover {
            span,
            a,
            a:hover {
              color: $yellow-drk;
            }
          }
        }
      }
    }

    .item-container {
      position: absolute;
      z-index: 0;

      .image {
        display: none;
      }

      .icon {
        display: none;
      }

      .picture-labels {
        z-index: 3;
        display: none;

        div {
          height: 50px;
          width: 200px;
          padding-top: 15px;
          background-color: rgba(245, 243, 213, 0.9);
          border: 1px solid rgba(203, 200, 150, 0.25);
          font: italic 18px $serif;
          color: $olive;
          text-align: center;
          z-index: 3;
          @include border-radius-and-pie(8px);
          position: absolute;
        }
      }

      &:hover {
        .picture-labels {
          display: block;
        }
      }
    }

    .item {
      @include circle(160px, $teal);
      color: $white;
      background-image: url(../images/icon-plus-circle.png);
      background-repeat: no-repeat;
      background-position: 63px 107px;
      position: absolute;
      z-index: -1;
      padding-bottom: 15px;

      .label {
        padding: 0 10%;
        @include vertical-align;

        .name {
          font: 24px/21px $sans;
          text-align: center;
          @include upper;
        }

        html.ie8 & {
          top: 25%;
        }
      }

      &:hover {
        background-color: $blue_dark;
      }
    }
  }

  .mobile-yes {
    padding-bottom: 19%;

    h3 {
      @include span-block;
      text-align: center;
      width: 100%;

      .headline {
        left: 0;
      }
    }

    .copy {
      @include paragraph-medium;
    }

    .items {
      .slick-list {
        height: 330px;
      }

      .icon {
        left: 50%;
        margin: 0 0 0 -125px;
        position: absolute;
        top: 140px;
      }

      .item-container {
        height: 330px;
        overflow: auto;

        .item {
          margin: 0 auto;
          @include circle(160px, $teal);
          color: $white;
          background-image: url(../images/icons/plus.png);
          background-repeat: no-repeat;
          background-position: 67px 115px;

          .label {
            display: block;
            padding-top: 20%;
            width: 75%;
            margin: 0 auto;
            font: 25px $sans;
            text-align: center;
            @include upper;
          }

          .image {
            margin-top: 50px;
          }

          img {
            display: none;
          }
        }
      }
    }

    .nav {
      padding: 10% 0;
      width: 90%;
      margin: 0 auto;

      li {
        display: inline-block;
        width: 50%;
        padding: 8%;
        text-align: center;
        font: 20px $sans;
        color: $yellow;
        text-transform: uppercase;

        a,
        a:hover {
          text-decoration: none;
          color: $yellow;
        }

        span {
          display: block;
        }

        &:first-child {
          border-bottom: 1px solid #ddd;
        }

        &:nth-child(2) {
          border-bottom: 1px solid #ddd;
          border-left: 1px solid #ddd;
        }

        &:last-child {
          border-left: 1px solid #ddd;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    padding-top: 33%;
    height: auto;
    width: 100%;
    margin: 0;
    background: none;
  }
}

.lt-ie9 .state-map .mobile-no .item .copy {
  top: 30%;
}

// State map
.state-map {
  height: 800px;
  width: 1040px;
  margin: 200px auto 0;
  background-repeat: no-repeat;
  position: relative;

  .mobile-no {
    .inner {
      .subheadline,
      .headline,
      .description {
        position: absolute;
      }

      .subheadline {
        color: $yellow-ada;
        font: 32px/37px $script;
        left: 834px;
        top: 10px;
        padding: 0 5px;
      }

      .headline {
        font: 98px/80px $sans;
        @include upper;
        left: 320px;
        top: 0;
        letter-spacing: -2px;
      }

      .description {
        top: 90px;
        left: 320px;
        font: italic 19px $serif;
        color: $olive;
        letter-spacing: 0.5px;
        max-width: 500px;
      }

      .module-share {
        display: block;
        left: 840px;
        top: 6px;
      }
    }

    .item-container {
      position: absolute;
      @include border-radius-and-pie(6px);
      perspective: 800;
      backface-visibility: hidden;

      &.open {
        z-index: 2;
      }

      &:hover .item {
        background-color: $blue_dark;
      }

      .icon {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        top: -25px;
        left: -24px;
      }

      .copy,
      .image {
        display: none;
      }

      .image {
        position: absolute;
        z-index: 1;
      }

      .marker-shadow {
        height: 53px;
        width: 117px;
        background: url(../images/map-pin-shadow.png);
        position: absolute;
        top: -23px;
        left: -14px;
      }

      &.open {
        .copy,
        .image {
          display: block;
        }
      }
    }

    .item {
      @include circle(400px, $teal);
      position: absolute;
      cursor: pointer;
      transform: scale(0.25);
      left: -200px;
      z-index: 1;
      top: -200px;
      zoom: 1;

      .name {
        color: $white;
        display: none;
      }

      .copy {
        text-align: center;
        color: $white;
        font: 19px/20px $serif;
        width: 78%;
        margin: 0 auto;
        @include vertical-align;

        h1 {
          margin: 5px;
          font-family: $serif;
          font-weight: bold;
          font-style: italic;
        }
      }
    }

    .carousel-title {
      position: absolute;
      top: 140px;
      left: 325px;
      @include upper;
      font: 24px $sans;
      color: $black_cmab;
      padding: 0 0 10px 30px;
      background: url("../images/icon-stats.png") 0 0 no-repeat;
    }

    .carousel {
      position: absolute;
      top: 177px;
      left: 325px;
      border-left: 1px solid #ddd;
      height: 116px;
      text-align: center;

      .slides {
        padding: 10px 25px 0;
        width: 650px;
        border-right: 1px solid #ddd;
        height: 160px;
        overflow: visible;

        .slide {
          height: 160px;

          img {
            float: left;
            margin-right: 25px;
          }

          .copy {
            font: 28px/34px $serif;
            text-align: left;
          }

          &:first-child {
          }
        }

        .slick-dots {
          display: inline-block;
          width: auto;
          padding: 20px 6px 20px 10px;

          li {
            margin: 0;

            button:before {
              color: #ffc320;
              opacity: 1;
              font-size: 10px;
            }

            &.slick-active button:before {
              color: #bc952a;
            }
          }
        }

        a.prev,
        a.next {
          background: url("../images/icons/arrow-map-sprite.png") 0 0 no-repeat
            $white;
          bottom: 0;
          cursor: pointer;
          display: inline-block;
          height: 30px;
          padding-top: 14px;
          position: relative;
          text-indent: -9999px;
          width: 30px;
        }

        a.next {
          background-position: 0 -30px;
        }
      }
    }
  }

  .mobile-yes {
    padding: 0 3%;

    h3 {
      @include span-block;
      background: url("../images/farms-map-mobile.png") 50% 0 no-repeat $white;
      background-size: 49px 57px;
      margin-bottom: 5%;
      padding-top: 65px;
      text-align: center;
      @include bp(mediumWidth) {
        background-size: 13.2vw 15.5vw;
        padding-top: 17.34vw;
      }

      .headline {
        @include bold-headline-4(false);

        .orange-ribbon {
          background: url("../images/orange-ribbon.png") 95px 0 no-repeat;
          background-size: 33px;
          width: 100%;
          height: 54px;
          position: absolute;
          margin: -9px 0 0 -50px;
          @include break($landscape) {
            margin: -15px 0 0 83px;
          }
        }

        @include bp(mediumWidth) {
          font-size: 11.5vw;
        }
      }

      .subheadline {
        @include headline-accent-3($yellow, false);
        @include bp(mediumWidth) {
          font-size: 4.6vw;
        }
      }
    }

    .module-share {
      display: block;
      left: 300px;
      top: 85px;
      @include break($landscape) {
        left: 400px;
        top: 150px;
      }
    }

    .copy {
      @include paragraph-medium;
      @include bp(mediumWidth) {
        font-size: 3.8vw;
        line-height: 5vw;
      }
    }

    .icon {
      height: 7.5vw;
      margin-top: 3.7vw;
    }

    .slide {
      text-align: center;

      img {
        margin: 0 auto 2%;
        max-height: 80px;
        @include bp(mediumWidth) {
          max-height: none;
          height: 21.34vw;
        }
      }
    }

    .item {
      float: left;
      width: 74%;

      img {
        display: none;
      }

      .label {
        @include bold-headline;
        font-size: 24px;
        margin: 0 0 6px 0;
        @include bp(mediumWidth) {
          font-size: 6.5vw;
        }
      }
    }

    .item-container {
      margin-bottom: 8%;
      overflow: auto;
      display: flex;

      .icon-container {
        background-image: url("../images/our-farms/bg-dairy-state.png");
        width: 18.669vw;
        height: 18.669vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 4vw;
      }
    }

    .carousel {
      padding: 0 3%;

      a.prev,
      a.next {
        display: none;
      }
    }

    .slick-dots {
      padding: 4% 0;
    }
  }

  .mobile & {
    padding-top: 19vw;
    height: auto;
    width: 100%;
    margin: 0;

    .map-wrapper {
      background: none !important;
    }
  }

  @include break($desktop, true) {
    width: 90%;
  }
}

.lt-ie10 .point .big-bubble {
  display: none;
}

.lt-ie10 .point.expanded .big-bubble {
  display: block;
}

.lt-ie9 .point .content {
  top: 25% !important;
}

.bubble-map {
  $radius-big: 180px;
  $radius-small: 23px;
  $radius-mobile: 172px;
  $small-top: 10px;
  $extra-content-side-multiplier: 0.14; /* to make a square fit perfectly in a circle, we multiply by 1.414. This multiplier gives some extra size since to allow more text since the square doesn't need to be perfectly fit. */
  $bounce-function: cubic-bezier(0.56, 0.19, 0.39, 1.42);
  $animation-time: 0.2s;

  $diameter-small: $radius-small * 2; /* Composite values */
  $diameter-big: $radius-big * 2;
  $diameter-mobile: $radius-mobile * 2;

  $content-side: $radius-big * (1.414 + $extra-content-side-multiplier);
  $content-side-mobile: $radius-mobile *
    (1.414 + $extra-content-side-multiplier);
  $content-shift: $radius-big - $content-side/2;
  $content-shift-mobile: $radius-mobile - $content-side-mobile/2;

  $big-bubble-top: -$radius-big + $small-top + $radius-small;

  position: relative;
  margin: 80px auto 0px;
  width: 960px;

  > img {
    top: 0;
    left: 110px;
  }

  .module-share {
    display: block;
    left: 110px;
    top: 0;
  }

  .header {
    position: absolute;

    .headline {
      @include headline-accent-3($yellow, false);
      margin-bottom: 8px;
      font-size: 39px;
      margin-left: 6px;

      @include break($mobile) {
        font-size: 4.6vw !important;
      }
    }

    .subheadline {
      @include bold-headline-3(false);
      font-size: 165px;
      line-height: 125px;

      @include break($mobile) {
        font-size: 11.5vw !important;
      }
    }

    .description {
      @include paragraph-em-small;
      margin-top: 7px;
      margin-left: 4px;
    }
  }

  .point {
    position: absolute;
    height: 0;
    width: 0;
    text-align: center;
    cursor: pointer;

    .label {
      text-align: center;
      color: $teal;
      width: 330px;
      margin-left: -160px;
      font-family: gothic;
      line-height: 0.75;
    }

    &.open-left .big-bubble {
      left: -$diameter-big;
      transform-origin: right 50%;
    }

    &.expanded {
      .big-bubble {
        transition-timing-function: $bounce-function;
        transform: scale3d(1, 1, 1) rotate(0deg);
        z-index: 200;
      }

      .marker {
        transition-timing-function: $bounce-function;
        transform: rotate(-45deg);
        z-index: 300;
      }
    }

    .marker {
      height: $diameter-small;
      width: $diameter-small;
      left: -$radius-small;
      top: $small-top;
      position: absolute;
      transform: rotate(0deg);
      transition: all $animation-time;
      z-index: 100;
      @include border-radius-and-pie();
      background: $teal;
      color: $white;
      font-size: 55px;
      line-height: 40px;
      font-family: "Verdana";

      &:hover {
        background: $blue_dark;
      }
    }

    /* TODO: make this work for ie8. display none here, but use js to add cross-browser transitions and scale and all. */
    .big-bubble {
      top: $big-bubble-top;
      position: absolute;
      z-index: 1;
      transform-origin: left 50%;
      transition: all $animation-time ease-in;
      transform: scale3d(0, 0, 1) rotate(45deg);

      .background {
        width: $diameter-big;
        height: $diameter-big;
        @include border-radius-and-pie();
        background: $teal;
      }

      .content {
        width: $content-side;
        height: auto;
        left: $content-shift;
        color: white;
        @include vertical-align;
        position: absolute;

        .heading {
          margin-bottom: 10px;
          font-family: $sans;
        }

        .explanation {
          font-family: $serif;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    position: relative;
    margin-top: 9.375%;
    width: 100%;

    .header {
      position: relative;
      text-align: center;

      .headline {
        @include headline-accent-3($yellow-ada);
      }

      .subheadline {
        @include bold-headline-3;
      }
    }

    .slide-wrapper-outer {
      height: auto;
      margin: 0;
      position: relative;
      width: 100%;
    }

    .slide-wrapper-inner {
    }

    .slick-list {
      overflow: visible;
      z-index: 5000;
    }

    .slide {
      background-color: $white;
      background-position: 50% 0;
      background-repeat: no-repeat;
      height: auto;
      text-align: center;
      width: auto;
    }

    img {
      margin: 0 auto;
      max-height: 180px;
      position: relative;
    }

    .realtor {
      position: relative;
      width: 300px;
      margin: -10px auto 0;
    }

    .point {
      position: relative;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      text-align: center;

      .label {
        bottom: 0;
        right: 0;
        margin-left: 0;
        font-size: 25px;
        position: relative;
        text-align: center;
        width: 100%;
      }

      &.open-left .big-bubble {
        transform-origin: 80% 0 0;
      }

      &.expanded {
        .big-bubble {
          transition-timing-function: $bounce-function;
          transform: scale3d(0.9, 0.9, 0.9) rotate(0deg);
        }

        .marker {
          transition-timing-function: $bounce-function;
          transform: rotate(-45deg);
          right: 10%;
          top: -160px;
        }
      }

      .marker {
        height: 47px;
        width: 47px;
        left: auto;
        right: 125px;
        top: -110px;
        position: absolute;
      }

      .big-bubble {
        left: 50% !important;
        right: -35px;
        margin-left: -180px;
        top: -170px;
        position: absolute;
        width: 300px;
        transform-origin: 80% 0 0;

        .background {
          width: $diameter-mobile;
          height: $diameter-mobile;
        }

        .content {
          width: $content-side-mobile;
          height: $content-side-mobile;
          position: absolute;
          top: 210px;
          left: $content-shift-mobile;
          color: white;

          .heading {
            margin-bottom: 5px;
            font-family: $sans;
            font-size: 45px;
          }

          .explanation {
            font-family: $serif;
            font-size: 18px;
            line-height: 1;
          }
        }
      }
    }
  }
}

.dg.ac {
  z-index: 200 !important;
}

.dairy-diversity {
  $subslide-side: 450px;
  height: 860px;
  padding: 80px 0 100px;
  z-index: 1;

  @include break($mobile) {
    margin-bottom: 20px;
  }

  .module-share {
    display: block;
  }

  .slide-wrapper-outer {
    width: 800px;
    margin: 0 auto;
    position: relative;
  }

  .button-previous {
    @include prev_button(0, gray, false);
    left: 0;
  }

  .button-next {
    @include next_button(0, gray, false);
    right: 0;
  }

  .button-previous,
  .button-next {
    cursor: pointer;
    height: 53px;
    position: absolute;
    text-indent: -9999px;
    top: 350px;
    width: 54px;
    z-index: 500;
    transform: translateZ(1000px);

    @include break($mobile) {
      top: 100px;
      height: 35px;
      width: 35px;
      background-size: contain;
      left: 3%;
      cursor: pointer;
    }
  }

  .button-next {
    @include break($mobile) {
      left: auto;
      right: 3%;
    }
  }

  .heading {
    position: absolute;
    width: 100%;
    top: 20px;
    text-align: center;

    @include break($mobile) {
      top: 0;
    }

    @include headlines(110px, 42px, 50px, 20px, $yellow-ada);
  }

  .lazy-wrapper {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    perspective: 1000;
    transform-style: preserve-3d;

    .noscroll & {
      transform-style: flat;
      -webkit-transform-style: flat;
    }
  }

  .slide {
    cursor: pointer;
    position: absolute;
    z-index: 300;
    top: -100px;
    transition: 0.2s opacity;

    @include break($mobile) {
      top: 0;
    }

    &.active {
      z-index: 400;

      .label {
        opacity: 1;
      }
    }

    .label {
      font-family: $sans;
      color: white;
      width: 100%;
      text-align: center;
      bottom: 21%;
      position: absolute;
      z-index: 2;
      line-height: 0.8;
      text-transform: uppercase;
      opacity: 0;
      transition: 0.3s opacity;

      @include break($tablet) {
        font-size: 30px;
        text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
      }

      @include break($mobile) {
        font-size: 17px;
        text-shadow: 0px 0px 5vw rgba(0, 0, 0, 0.3);
      }
    }

    img {
      left: 0;
      position: absolute;
      bottom: 0;
    }
  }

  .legend {
    display: flex;
    position: relative;
    flex-flow: column;
    top: 140px;
    width: 600px;
    margin: 0 auto;

    @include break($mobile) {
      top: 0;
      width: 320px;
    }

    @media (min-width: 480px) and (max-width: 767px) {
      top: 140px;
      margin-left: 240px;
    }

    @for $i from 1 through 3 {
      &.tip-#{$i} {
        .selector a:nth-child(#{$i}) {
          background-color: $blue_light;
        }

        .tip-type div:nth-child(#{$i}),
        .tip-jar .tip:nth-child(#{$i}) {
          display: block;
        }
      }
    }

    .selector {
      text-align: center;
      width: 100%;

      a {
        display: inline-block;
        @include square(68px);
        border-radius: 50%;
        color: $white;
        background-color: $blue_dark;
        font-size: 30px;
        padding-top: 18px;
        text-decoration: none;
        margin: 0 0.3em;

        @include break($mobile) {
          @include square(35px);
          font-size: 19px;
          padding-top: 7px;
        }
      }
    }

    .tip-type {
      font-family: $script;
      color: $blue_dark;
      text-align: center;
      font-size: 34px;
      margin-top: 20px;
      margin-bottom: 10px;

      @include break($mobile) {
        font-size: 19px;
        margin-top: 10px;
      }

      div {
        display: none;
      }
    }

    .tip-jar {
      text-align: center;

      @include paragraph-tiny;

      @include break($mobile) {
        padding: 0 20px;
      }

      @include break($tablet) {
        font-size: 23px;
        line-height: 1.3;
        margin-top: 13px;
      }

      .tip {
        display: none;
      }

      a {
        color: $olive;
        text-decoration: none;
      }
    }
  }

  .subslide-wrapper {
    background: url("../images/food-dairy-diversity-bg-subslide.png") 0 0
      no-repeat transparent;
    height: $subslide-side;
    left: 55%;
    padding: 80px;
    position: absolute;
    top: 20px;
    width: $subslide-side;

    .slick-dots {
      li {
        button:before {
          color: $white;
        }

        &.slick-active button:before {
          color: $blue_dark;
        }
      }
    }
  }

  .subslide {
    height: 280px;
    position: relative;
    width: 100%;
  }

  @include break($mobile) {
    height: auto;
    padding: 18.75% 0 0;

    .slide-wrapper-outer {
      left: 0;
      width: 100%;
      margin-bottom: 100px; // TOFIX better way; causes module below to move
    }

    .lazy-wrapper {
      width: 100%;
    }

    .subslide-wrapper {
      padding: 0;
      position: relative;
    }
  }
}

.cows-what-happens {
  background: url("../images/icons/icon-what-happens-question.png") 292px 0
    no-repeat transparent;
  margin: 0 auto 100px;
  position: relative;
  padding-top: 24px;
  width: 665px;

  img {
    cursor: pointer;
  }

  .headline {
    @include bold-headline-6;
    position: absolute;
    right: 20px;
    top: 78px;
    width: 270px;
    @include break($tablet) {
      font-size: 40px;
    }
    @include break($desktop-wide) {
      font-size: 45px;
    }
  }

  div.expand {
    @include circle(45px, $teal);
    position: absolute;
    top: 210px;
    right: 245px;
    background: $teal url(../images/icon-plus.png) center no-repeat;
    z-index: 400;
    cursor: pointer;
  }

  .item-container {
    .item {
      cursor: pointer;
      position: absolute;
      left: 50%;
      margin: -250px 0 0 -250px;
      top: 50%;
      @include circle(500px, $teal);
      @include border-radius-and-pie();
      transform-origin: center 50%;
      transition: all 0.2s ease-in;
      transform: scale3d(0, 0, 1) rotate(45deg);
      transform-style: flat;
      background: $blue_light url(../images/icon-droplet.png) center 55px
        no-repeat;
      cursor: pointer;

      .lt-ie10 & {
        display: none;
      }

      .name {
        font-family: $sans;
        font-size: 45px;
        line-height: 41px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      .copy {
        font-family: $serif;
        font-size: 20px;
        line-height: 24px;
      }

      .inner {
        color: $white;
        height: 254px;
        padding: 0 50px 0;
        text-align: center;
        @include vertical-align;

        .ie8 & {
          top: 30%;
        }
      }
    }

    .icon {
      position: absolute;
      top: 6px;
      left: 334px;
    }

    .marker-shadow {
      height: 20px;
      width: 21px;
      background: url(../images/bubble-marker.png) 0 0 no-repeat;
      position: absolute;
      top: 72px;
      right: 303px;
    }
  }

  .nav {
    a {
      position: absolute;
      top: 195px;
      right: 242px;
      @include circle(45px, $teal);
      background: $teal url(../images/icons/icon-plus.png) center 9px no-repeat;
      text-indent: -9999em;
    }
  }

  &.open {
    .item-container .item {
      transition-timing-function: cubic-bezier(0.56, 0.19, 0.39, 1.42);
      transform: scale3d(1, 1, 1) rotate(0deg);
      transform-style: flat;
      z-index: 100;

      .lt-ie10 & {
        display: block;
      }
    }

    div.expand {
      transition: all 0.2s ease-in;
      transform: translate(100px, -270px) rotate(45deg);

      .ie8 & {
        top: -50px;
        right: 130px;
      }

      @include bp(mediumWidth) {
        top: 210px;
      }
    }
  }

  @include bp(mediumWidth) {
    background-position: 50% 0;
    background-size: 12.7vw 14.3vw;
    height: 50.9vw;
    overflow: visible;
    padding: 17vw 12vw 0;
    width: 100%;
    margin: 10% 0;

    .headline {
      font-size: 6.7vw;
      left: 0;
      position: relative;
      text-align: center;
      top: 0;
      width: 100%;
    }

    img {
      float: right;
      max-width: 50%;
    }

    div.expand {
      top: 150px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .item-container {
      .item {
        background-position: 50% 10%;
        margin: -150px 0 0 -150px;
        height: 300px;
        width: 300px;

        .name {
          font-size: 24px;
          line-height: 1;
          margin-bottom: 1%;
        }

        .copy {
          font-size: 14px;
          line-height: 14px;
        }

        .inner {
          height: 160px;
          padding: 0 12%;
          top: 52%;
        }
      }
    }
  }
}
