$sans: 'gothic';
$script: 'wisdom';
$serif: 'hoefler';

#food .cta-top.search {
    background-position: 50% 170px;

    @include break($mobile) {
        background-position: 50% 55px;
    }

    .icon {
        background: none;
        margin-top: -36px;

        @include break($mobile) {
            margin-top: -23px !important;
        }
    }
}

.page-heading.is-search {
    background: url('../images/search-header.jpg') no-repeat top center;
    background-size: cover;

    @include break($mobile) {
        background: url('../images/search-header_mobile.jpg') no-repeat top center;
        background-size: cover;
    }

    h1 {
        font: 100px $sans;

        @include bp(mediumWidth) {
            font: 55px $sans;
        }
    }
}

.module.module-search {
    min-height: 1000px;

    @include break($mobile) {
        min-height: 500px;
    }

    .content-container {
        width: 90%;
        margin: 0 auto;
        padding: 6% 3% 0 0;

        @media (min-width: 1024px) {
            width: 960px;
            padding: 60px 0 0;
        }
    }

    .text-center {
        text-align: center;
    }

    .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        margin: 55px auto;
        display: none;

        &.show {
            display: inline-block;
        }
    }

    h3 {
        font-family: $sans;
        font-size: 36px;

        @include break($mobile) {
            font-size: 28px;
        }
    }

    p {
        font-family: $serif;
        font-size: 20px;
        line-height: 1.2;

        @include break($mobile) {
            font-size: 14px;
        }
    }

    strong {
        font-weight: bold;
    }

    .results-meta {
        font-family: $sans;
        font-size: 26px;
        float: left;
        visibility: hidden;

        @include break($mobile) {
            font-size: 20px;
        }

        &.show {
            visibility: visible;
        }
    }

    .input-field {
        border: 2px solid $yellow;
        margin-bottom: 10px;

        input {
            font-family: $sans;
            font-size: 26px;
            padding: 15px;
            width: calc(100% - 54px);

            @include break($mobile) {
                font-size: 20px;
            }
        }
    }


    .search-container {
        width: 74%;
        overflow: hidden;

        @include break($mobile) {
            width: 100%;
            margin-top: 42px;
        }

        input[type="submit"], button {
            @include square(49px);
            color: $yellow;
            background: $black_cmab;
            font-family: cmab2;
            font-size: 24px;
            width: 50px;
            border: 0;
            background: white;

            &:hover {
                color: $yellow_active;
            }
        }

        label {
            font-family: $sans;
            font-size: 26px;
            text-transform: capitalize;
            position: relative;
            display: block;
            padding-left: 23px;
            float: right;

            @include break($mobile) {
                font-size: 5vw;
                padding-left: 15px;
            }

            input[type='checkbox'] {
                position: absolute;
                top: 5px;
                left: 0;
                width: 15px;
                height: 15px;
                outline: 0;
                border: 2px solid black;
                transition: all .1s;

                @include break($mobile) {
                    width: 3.5vw;
                    height: 3.5vw;
                    top: 0.8vw;
                    left: -1.3vw;
                    border-width: 0.7vw;
                }

                &:checked {
                    background: black;
                }
            }
        }
    }

    .result {
        padding: 35px 0 30px;
        border-bottom: 1px solid;

        .listing {
            overflow: hidden;
        }

        .tag {
            font-family: $sans;
            font-size: 16px;
            text-transform: uppercase;
            padding: 3px 5px;
            display: inline-block;
            margin: 0 10px;
            line-height: 1;
            color: $black_cmab;
            position: relative;
            top: -5px;

            @include break($mobile) {
                margin: 8px 0 0;
            }

            &.tag-recipes {
                background: $teal;
            }

            &.tag-foodservice {
                background: $yellow;
            }
        }

        .title {
            display: block;
            color: black;
            text-decoration: none;
            margin-bottom: 10px;

            &:hover {
                text-decoration: underline;
            }

            h3 {
                text-transform: uppercase;
                display: inline;

                @include break($mobile) {
                    display: block;
                }
            }
        }
    }

    .no-results {
        width: 420px;
        margin: 70px auto;
        font-family: $serif;
        font-size: 20px;

        @include break($mobile) {
            width: 90%;
            margin: 30px auto;
            font-size: 14px;
        }

        h3 {
            text-align: center;
            margin-bottom: 23px;
        }

        ul {
            margin: 10px 15px;
        }

        li {
            list-style: disc;
            margin-bottom: 10px;
        }
    }

    .loading{
        position: relative;
        background: $white url(../images/cmab_loading_gif_white.gif) no-repeat;
        //background: $yellow url(../images/cmab_loading_gif_white.gif) no-repeat;
        background-position:center;
        background-size: 200px;
        min-height: 896px;
        @include bp(mediumWidth){
            min-height: 500px;
            background-size: 100px;
        }
    }
}