/*.button-share {
background: url('../images/icons/icon-share.png') 0 0 no-repeat $white;
display: inline-block;
height: 40px;
width: 41px;
}*/

.module-share {
    background: url('../images/icons/sprite-button-share-sm.png') 0 0 no-repeat transparent;
    cursor: pointer;
    display: none;
    height: 29px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 30px;
    z-index: 100;
    &:hover {
        background-position: 0 -30px;
    }
}

.share-overlay {
    margin: 0 auto;
    text-align: center;
    padding-top: 14%;
    h3 {
        @include span-block;
        .headline {
            @include headline-accent-4($yellow-ada);
        }
        .subheadline {
            @include bold-headline-4;
        }
        margin-bottom: 4%;
    }

    span {
        margin-right: 5px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    .button-share {
        display: inline-block;
        margin: 0 8px;
    }
    .share-btn-container {
        display: block;
        height: 20px;
        margin: 0 auto;
        overflow: hidden;
        width: 260px;
    }
    .share-btn {
        display: inline-block;
        float: left;
        margin: 0 4px;
    }
}