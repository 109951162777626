.partnership-banner {
  width: 100%;
  text-align: center;
  background: $yellow_partnership;

  .headline {
    font-family: "gothic", cursive;
    font-size: 90px;
    color: $black;
    @include break($mobile, true) {
      font-size: 67px;
    }
  }

  .subHeadline {
    color: $white;
    font-family: "hoefler", serif;
    font-size: 20px;
    max-width: 700px;
  }

  .cta {
    @include cta-no-border($black, $white, $white, $black, 200px);
  }

  .fruit-banner {
    height: 150px;
    background-size: cover;
    background-position: bottom center;
    background: url(../images/banner-fruits.png);
  }
}
