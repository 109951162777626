.homepage-teaser {
  background-color: $yellow;
  color: $black_cmab;
  margin: 0;
  padding: 100px 0 160px;
  width: 100%;

  h3 {
    color: $white;
    font-family: $script;
    font-size: 36px;
  }
}

.module {
  .wrapper {
    @include bp(mediumWidth) {
      margin: 0 auto;
      padding: 20px 10px !important;
      width: 320px;
    }
  }

  &.what-do-cows-eat {
    .bubble-map {
      .header {
        .subheadline {
          line-height: 0.8;
          @include break($tablet) {
            font-size: 120px;
            line-height: 0.8;
          }
        }
        .description {
          max-width: 350px;
        }
      }
    }
  }
}

.arctext > span {
  $pad: 10px;
  padding: $pad;
  margin: -$pad;
}

.intro {
  background-color: $yellow;
  overflow: hidden;
  position: relative;

  .main {
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    text-align: center;

    .curved {
      font-family: $script;
      font-size: 25px;
      color: $black;
      padding-top: 100px;
    }

    .crest {
      margin: 24px auto;
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    .headline,
    .subheadline {
      font-family: $sans;
      letter-spacing: -1px;
      text-transform: uppercase;
    }

    .headline {
      @include bold-headline-3;
      margin-bottom: 4px;
      font-size: 104px;
    }

    .subheadline {
      @include subheadline-1;
      font-size: 46px;
    }

    .image {
      img {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }

  @include bp(mediumWidth) {
    .curved {
      display: none;
    }
    .main {
      padding: 0 3%;

      .crest {
        width: 10.6vw;
        margin: 9.9vw auto 7.5vw;

        img {
          width: 100%;
        }
      }

      .headline {
        font-size: 11.5vw;
        margin: 0 auto 1.1vw;
        line-height: 10vw;
      }

      .subheadline {
        font-size: 6.67vw;
        line-height: 6.3vw;
      }

      .image {
        img {
          max-width: 77%;
        }
      }
    }
    .headline {
      padding-bottom: 4px;
    }
  }
}

.community-intro-module {
  @include bp(mediumWidth) {
    .main {
      .headline {
        width: 70vw;
      }
    }
  }
}

.drag-container {
  border-top: 1px solid #dfe2e5;
  bottom: 0;
  height: 50px;
  left: 50%;
  margin: 0 0 0 -349px;
  position: absolute;
  width: 698px;

  .drag-button {
    background: url("../images/cowology-drag-bg.png") 0 0 no-repeat transparent;
    bottom: 0;
    color: #b48811;
    cursor: pointer;
    display: block;
    font-family: $sans;
    font-size: 20px;
    height: 65px;
    margin-left: -74px;
    left: 0;
    line-height: 65px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: -65%;
    width: 148px;
  }
}

// Daily Eats Map
.daily-eats {
  width: 1040px;
  height: 1400px;
  margin: 0 auto;
  position: relative;

  .headline {
    color: $black_cmab;
    font: 160px/122px $sans;
    @include upper;
    letter-spacing: -1px;
    margin-left: -8px;
  }

  .subheadline {
    color: $yellow;
    font: 34px $script;
  }

  .description {
    font: italic 20px $serif;
    color: $olive;
    margin-top: 10px;
  }

  .item-container {
    position: absolute;
    cursor: pointer;

    .item {
      .label {
        .name {
          color: $teal;
          font: 60px $sans;
          @include upper;
        }

        .icon {
          background-image: url(../images/icon-plus.png);
          background-repeat: no-repeat;
          background-position: 14px;
          @include circle(50px, $teal);
          margin: 0 auto;
        }
      }

      .copy {
        display: none;
      }
    }
  }
}

#cows {
  .next-up {
    @include bp(mediumWidth) {
      .subheadline {
        font-size: 17vw;
        line-height: 15vw;
        padding-top: 3vw;
      }
      .image {
        width: 70vw;
      }
    }
  }
}

#community {
  .next-up {
    .image {
      max-width: 300px;
    }

    @include bp(mediumWidth) {
      .image {
        width: 55vw;
      }
    }
  }
}

#farms {
  .intro {
    .main {
      .subheadline {
        max-width: 863px;
        width: 100%;
        margin: 0 auto;
      }

      @include bp(mediumWidth) {
        .headline {
          line-height: 11.5vw;
        }
      }
    }
  }

  .next-up {
    .image {
      max-width: 749px;
    }

    @include bp(mediumWidth) {
      .cta-bottom {
        margin-bottom: 0;
      }
      .image {
        width: 120vw;
        position: relative;
        top: 27vw;
      }
    }
  }
}

#food {
  .next-up {
    .image {
      max-width: 1080px;
      width: 100%;
      @include bp(mediumWidth) {
        width: 155vw;
        position: relative;
        top: 6vw;
        left: -21px;
      }
    }
  }
}

.food-for-thought {
  width: 960px;
  margin: 0 auto;
  position: relative;

  .thought-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 374px;
    padding: 40px 25px;
    position: absolute;
    right: 50px;
    text-align: center;
    top: -200px;
    width: 362px;
    z-index: 3;

    .subheadline {
      font: 35px/32px $script;
      color: $yellow;
    }

    .headline {
      font: 60px/60px $sans;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 35px;
    }

    div.description {
      font: 20px/28px $serif;
      color: $white;
      margin: 0 25px;
    }

    .date {
      font: 16px $sans;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 30px;
    }

    a {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      position: relative;
      top: -12px;

      &:hover {
        color: $white;
      }
    }
  }

  @include break($mobile) {
    background: url("../images/chevron-background-yellow-bottom-big.png")
      no-repeat;
    height: 400px;
    height: 68vw;
    max-width: 100%;
    position: relative;
    background-position: center 12vw;
    background-size: 1500px 1100px;

    div.thought-bubble {
      height: 65vw;
      left: 21vw;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      right: auto;
      top: 6vw;
      width: 58vw;

      .subheadline {
        font: 5.6vw/1 $script;
      }

      .headline {
        font: 10.2vw/1 $sans;
        margin-bottom: 5vw;
      }

      div.description {
        font: 3.7vw/1 $serif;
      }

      .date {
        font: 15px $sans;
        margin-bottom: 15px;
      }

      a {
        left: 0;
        position: relative;
        top: 0;
      }

      a {
        //This second a selector is because when it's merged with the one above, hierarchy is strangely broken.
        height: auto;
        font-size: 3.6vw;
        line-height: 1.6;
        margin-top: 5vw;
        border-width: 2px;
        padding: 0 4vw;
      }
    }
  }
}

.commercials {
  padding: 0;
  margin-bottom: 10vw;
  @include break($desktop-wide) {
    margin-bottom: 140px;
  }

  .headline {
    position: relative;
  }

  .subheadline {
    text-transform: uppercase;
    color: #231f20;
    font-family: "gothic";
    -webkit-font-smoothing: antialiased;
    font-size: 90px;
    float: left;
    position: relative;
  }

  .main {
    left: 0px;
    margin: 0 auto;
    width: 100%;
  }

  .previous,
  .next {
    margin: 10px;
    @include prev_next_buttons();
  }

  .previous {
    @include prev_button(0, yellow, "");

    &.slick-disabled,
    &.slick-disabled:hover {
      cursor: default;
      opacity: 0.4;
    }
  }

  .next {
    @include next_button(0, yellow, "");

    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
    }
  }

  .dot {
    display: none;
    color: $gray;
    font-size: 32px;
    padding-right: 5px;
    text-decoration: none;

    &.active {
      color: $black_cmab;
    }
  }

  .header {
    display: flex;
    width: 73%;
    max-width: 960px;
    margin: 0 auto;

    h3 {
      display: inline-block;
      float: left;
      margin-right: 10px;

      .headline {
        @include headline-accent-4($yellow-ada);
        padding-left: 6px;
      }

      .subheadline {
        @include bold-headline-5;
        float: left;
        padding-bottom: 20px;
        @include break($mobile) {
          font-size: 46px;
        }
      }
    }

    .description {
      color: $white;
      font-family: $serif;
      font-size: 21px;
      line-height: 27px;
      float: left;
      font-style: italic;
      max-width: 300px;
      position: relative;
      top: 80px;
    }
  }

  .yt-video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    max-height: 600px;
    @include break($mobile) {
      flex-direction: column;
    }

    .player {
      position: relative;
      top: -6px;
      max-width: 960px;
      height: 50vw;
      max-height: 560px;
      width: 73%;
    }
  }

  a {
    text-decoration: none;
  }

  .commercialsCta {
    $border-width: 4px;
    $diameter-image: 130px;
    $diameter-circle: $diameter-image + 2 * $border-width;

    @include border-radius-and-pie();
    width: $diameter-circle;
    height: $diameter-circle;
    position: absolute;
    cursor: pointer;
    text-decoration: none;
    z-index: 500;
    display: block;
    margin-top: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    margin-left: 780px - $border-width;
    padding: 60px 20px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
    border: $border-width solid white;

    .title,
    .name {
      text-align: center;
    }

    .title {
      @include cta-script(20px);
      color: $white;
    }

    .name {
      color: $white;
      font: 27px "gothic";
      text-transform: uppercase;
    }
  }

  .bottom_ctas {
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
    max-width: 960px;
    text-align: right;
    text-transform: uppercase;
    width: 73%;

    a {
      @include commericalLinks($black, $yellow);
      background: url("../images/icons/cta-play.png") no-repeat 0 3px;
      background-size: 16px;
      content: "";
      font-size: 19px;
      height: 15px;
      padding-left: 20px;
      position: relative;
      width: 15px;
    }

    a:first-child {
      padding-right: 20px;
    }
  }

  @include break($mobile) {
    .main {
      width: 100%;
    }
    .header {
      flex-direction: column;
      align-items: center;

      h3 {
        display: block;
        float: none;
        margin: 0;
        padding-bottom: 10px;

        .headline {
          padding-left: 0;
          text-align: center;
        }

        .subheadline {
          float: none;
          padding-left: 0;
          text-align: center;
          padding-bottom: 0;
        }
      }

      .description {
        display: none;
      }
    }
    .commercialsCta {
      display: none;
    }
    .previous,
    .next {
      background-size: 100% 100%;
      height: 30px;
      width: 30px;
    }
    .dot {
      display: none;
    }
    .yt-video-wrapper {
      margin: 0 auto;
      height: 55vw;
      max-height: 320px;
      width: 300px;
      position: relative;

      .player {
        width: 100%;
        height: 71%;
      }
    }
    .yt-video-info {
      display: none;
    }
    .slide-wrapper-outer {
      margin: 0 auto;
      width: 70%;
    }
    .slide-wrapper-inner {
      height: auto;
      width: 100%;
    }
    .slide {
      height: auto;
      width: 100%;
    }
    .yt-list-info {
      margin-right: 0;

      .active,
      .play-btn {
        display: inline-block !important;
      }

      .yt-video-image {
        height: auto;
        width: 100%;

        img {
          top: 0;
          width: 100%;
        }
      }

      .yt-video-title {
        font-size: 20px;
        padding-top: 2px;
        width: 100%;
      }
    }
  }

  .mobile-yes {
    @include bp(mediumWidth) {
      .hide {
        display: none;
      }
      .bottom_ctas {
        display: flex;
        width: 73%;
        flex-wrap: wrap;

        a {
          font-size: 19px;
          height: 19px;
          display: inline;
          width: 100%;
          text-align: left;
        }
      }

      .main {
        .slick-list {
          overflow: visible;
        }
      }

      .yt-image {
        width: 93%;
        height: 100%;
        margin: 0 auto;
      }
      .yt-play-btn {
        position: absolute;
        background: url("../images/video-play-button-dark.png") no-repeat;
        background-size: 100%;
        height: 39px;
        width: 57px;
      }
      .video-title {
        text-transform: uppercase;
        align-self: flex-start;
        font-size: 5.2vw;
        line-height: 5.2vw;
        height: 2em;
        margin-left: 4%;
        font-family: gothic;
      }

      .inner {
        width: 100%;
        height: 100%;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          height: 80%;
          width: 100%;
        }

        .title {
          padding-top: 34px;
          font-size: 13px;
        }

        .name {
          font-size: 20px;
        }

        .nextArrow {
          margin: 0 auto;
          height: 11px;
        }

        .commercialsCta {
          top: -20px;
          align-self: flex-start;
          display: none;
          position: relative;
          margin: initial;
          padding: initial;
          border: none;
          width: 85px;
          height: 85px;
          z-index: 1000;
          margin-left: auto;
        }
      }
      .slick-active .inner {
        .commercialsCta {
          display: block;
        }
      }
      .slick-slider .slick-dots li button:before {
        color: #000;
      }
    }
  }
}

.home-splash {
  background-color: $yellow;
  text-align: center;
  padding-top: 52px;
  @include break($mobile) {
    padding-top: 37px;
  }

  .subheadline {
    @include headline-accent-3($white, true);
  }

  .headline {
    @include bold-headline-4(false);
    @include break($mobile) {
      font-size: 43px;
      letter-spacing: 0.01em;
    }

    h3 {
      max-width: 900px;
      margin: 0 auto;
    }
  }

  .header {
    @include break($mobile) {
      margin-bottom: 20px;
    }
  }

  .description {
    width: 874px;
    margin: 0 auto;
    @include paragraph-small;
    @include break($mobile) {
      width: 90%;
    }
  }

  .image {
    margin-top: 13px;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center top;
    background-size: 1950px;
    height: 457px;
    @include break($mobile) {
      height: 200px;
      background-size: cover;
    }
    position: relative;
    top: 2px;
  }
}
