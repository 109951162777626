.cheese-module {
  $height: 1600px;
  height: $height;
  width: 1370px;
  margin: 0 auto;
  transform-origin: top left;
  padding-top: 100px;
  background-color: $white;
  background-repeat: no-repeat;
  position: relative;
  left: -150px;

  @mixin scaler($scale) {
    transform: scale($scale);
    margin-bottom: -$height * (1 - $scale);
  }

  @include break(950px, 1070px) {
    @include scaler(0.9);
  }

  @include break(830px, 949px) {
    @include scaler(0.8);
  }

  @include break($tablet, 829px) {
    @include scaler(0.75);
  }

  @include break($mobile) {
    background-size: 150%;
    background-position: 13% 0px;
    left: 0;
    width: 100%;
    height: 160vw;
  }

  .lt-ie10 & {
    margin: 0 auto;
  }

  .mobile & {
    left: initial;
  }

  header {
    position: absolute;
    top: 360px;
    left: 360px;
    text-align: center;

    @include break($mobile) {
      top: 33vw;
      left: 28vw;
    }
  }

  .subheadline {
    @include headline-accent-3($yellow-ada, false);
    @include break($mobile) {
      font-size: 4.2vw;
    }
  }

  .headline {
    @include bold-headline-1(false);
    position: relative;
    left: -12px;
    line-height: 119px;

    @include break($mobile) {
      font-size: 17vw;
      left: 0;
      line-height: initial;
    }
  }

  .more {
    position: absolute;
    font-family: hoefler, serif;
    padding-top: 15px;
    left: -12px;

    @include break($mobile) {
      padding-top: 5px;
      width: 70vw;
      left: -50px;
    }

    p {
      font-size: 18px;
      @include break($mobile) {
        font-size: 14px;
      }
    }

    .cta-wrapper {
      padding-top: 10px;
      display: flex;
      justify-content: center;
    }

    .cta {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
    }
  }

  .module-share {
    display: block;
    left: 164px;
    top: -38px;
    @include break($mobile) {
      left: 54px;
      top: -36px;
    }

    .ie8 & {
      z-index: 0;
    }
  }

  .items {
    $item-height-mobile: 53vw;
    $padding-left: (100 - $item-height-mobile) / 2;
    $item-spacing: 4vw;
    position: absolute;
    top: 0;
    left: 0;

    @include break($mobile) {
      height: $item-height-mobile + 1;
      width: 100vw;
      top: 88vw;
      padding: 0 $padding-left;
      display: flex;
      justify-content: space-between;
      overflow-x: auto;
    }

    .spacer {
      flex: 0 0 8vw;
      height: $item-height-mobile;

      &:last-of-type {
        flex: 0 0 $padding-left;
      }

      @include break($tablet) {
        display: none;
      }
    }

    .item {
      $width: 160px;
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      @include circle($width, $teal);
      transition: 0.1s background;
      color: $white;
      text-align: center;
      text-decoration: none;

      &:hover {
        @include break($tablet) {
          background-color: $blue_dark;
        }
      }

      .icon {
        font-family: cmab2;
        font-size: 46px;
      }

      .name {
        font-family: $sans;
        line-height: 0.9;
        font-size: 28px;
        padding-top: 8px;
      }

      @include break($mobile) {
        height: $item-height-mobile;
        width: $item-height-mobile;
        position: static;
        flex: 0 0 $item-height-mobile;
      }
    }
  }
}

$tablet-link-height: 70px;

.cheese-navigation-top,
.cheese-navigation-bottom {
  display: flex;
  flex-direction: column;

  .links {
    display: flex;

    a {
      font-family: $sans;
      color: $black;
      text-align: center;
      text-decoration: none;
      font-size: 20px;
    }

    @include break($tablet) {
      a {
        height: 70px;

        + a {
          border-left: 1px solid $black;
        }
      }
    }
  }

  _:-ms-fullscreen,
  .header {
    width: 577.98px;
    text-align: center;

    .subheadline {
      text-align: left;
    }
  }
}

.dropdown {
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: center;

  select {
    font-family: $sans;
    color: $black;
    border: 2px solid $black;
    padding-left: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    background-position: right 10px center;
    background-size: 10px;
  }

  @include break($tablet) {
    display: none;
  }
}

.cheese-navigation-top {
  .links {
    @include break($mobile) {
      justify-content: center;

      &:first-of-type {
        padding: 35px 0 0 0;
      }

      &:nth-of-type(2) {
        padding: 20px 0 0 0;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 0;
      }

      a {
        font-size: 25px;
        padding: 0 7px;
      }
    }

    @include break($tablet) {
      width: $middle-width;
      margin: 0 auto;
      padding: 40px 0;

      > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 5px;
      }

      a {
        font-size: 29px;
        height: auto;
      }
    }

    > div {
      text-align: center;
      border-left: 2px solid $yellow_partnership;
      max-width: 170px;

      &:last-of-type {
        border-right: 2px solid $yellow_partnership;
      }

      a {
        &.active {
          color: $black_cmab;
        }
      }

      .caret {
        //margin-top: -0.8em;
        font-size: 2em;
        display: none;

        img {
          width: 11px;
        }

        &.active {
          display: block;
        }
      }
    }
  }

  .header {
    padding: 70px 0 80px;
    text-align: center;

    @include break($mobile) {
      padding-top: 60px;
    }

    .headline {
      @include bold-headline-2();
      color: $white;

      @include break($mobile) {
        font-size: 65px;
      }
    }

    .subheadline {
      @include headline-accent-2($white);
      padding-bottom: 10px;

      @include break($mobile) {
        font-size: 26px;
      }
    }
  }
}

.cheese-navigation-bottom {
  background: $yellow;
  padding: 100px 0 200px;

  @include break($mobile) {
    padding: 50px 0 220px;
  }

  .header {
    padding: 0 20px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .headline {
      @include bold-headline-3(true);
      @include break($mobile) {
        padding-top: 5px;
        font-size: 17vw;
        line-height: 0.8;
      }
    }

    .subheadline {
      @include headline-accent-3(white);
    }
  }

  .links {
    @include break($tablet) {
      width: 700px;
      margin: 40px auto 0;

      a {
        flex: 1;
        height: auto;

        &:not(:last-of-type) {
          border-right: 1px solid white;
        }
      }
    }

    @include break($mobile) {
      margin: 0 5%;
      flex-flow: row wrap;

      a {
        height: 110px;
        flex: 1 0 50%;
        padding-top: 30px;

        &:last-of-type {
          flex-grow: 0;
        }
      }
    }

    .icon {
      display: block;
      font-size: 40px;
      color: black;
      font-family: cmab2;
      padding-bottom: 10px;

      @include break($mobile) {
        font-size: 50px;
      }
    }

    a {
      color: olive;

      &.active,
      &.active .icon {
        color: $black;
      }
    }
  }
}

.search-by-type {
  @include bold-headline-6();
  text-align: center;
  margin-top: 50px;

  @include break($mobile) {
    margin-top: 26px;
    font-size: 26px;
    margin-bottom: -15px;
  }
}

.cheese-filters {
  display: flex;

  @include break($tablet) {
    width: 960px;
    margin: 0 auto 50px;
  }

  @include break($mobile) {
    width: 90%;
    margin: 10px auto 35px;
  }

  &.active div {
    color: black;
  }

  a {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    display: flex;
    flex: 1 1 30%;
    height: 175px;
    position: relative;
    text-align: center;
    text-decoration: none;

    @include break($mobile) {
      height: 110px;
    }

    &::before {
      @include square(50px);
      background-color: $black_cmab;
      border-radius: 50%;
      color: white;
      content: "\e909";
      display: none;
      font-family: cmab2;
      font-size: 42px;
      left: 35%;
      padding: 6px 4px 4px;
      position: absolute;
      top: 35%;

      @include break($mobile) {
        @include square(25px);
        font-size: 20px;
        padding: 3px;
      }
    }

    &.all {
      background-image: theme-url("all-cheeses.png"); //#todo
    }

    div {
      bottom: 0;
      color: $black;
      font-family: $sans;
      font-size: 20px;
      position: absolute;
      transform: translateY(50%);
      width: 100%;

      @include break($mobile) {
        font-size: 12px;
      }
    }
  }
}

.cheesemaker-list {
  .page-heading {
    display: none;
  }
}

.module.commercials {
  .chevron {
    display: none;
  }
}

#big-video-vid + header {
  .headline {
    display: none !important;
  }
}

.shadow {
  background: url("../images/slider-simple-shadow.png") 50% 0 no-repeat
    transparent;
  height: 10px;
  margin-top: -4px;
  margin-left: -30px;
}

.outer-wrapper .outline .header .description {
  margin-top: 60px;
  @include break($mobile) {
    margin-top: 14px;
  }
}

#cheese-types .outer-wrapper .recipe-action {
  display: none;
}

#cheese-types {
  .cheesemakers {
    text-align: center;
    margin-top: 30px;

    a {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      @include break($mobile) {
        font-size: 15px;
      }
    }
  }

  .actions {
    height: 0;
    width: 960px;
    margin: 0 auto;
    text-align: right;

    @include break($mobile) {
      width: 100%;
    }

    @include break($mobile) {
      .print {
        display: none;
      }
    }

    > * {
      display: inline-block;
      position: relative;
      top: -80px;
      @include break($mobile) {
        top: -25px;
      }

      img {
        width: 30px;
      }

      &:first-child {
        padding-right: 5px;
        border-right: 0 solid $gray_pale;
        margin-right: 5px;

        @include break($mobile) {
          margin-right: 10px;
        }
      }
    }
  }
}

@media print {
  .header-wrapper-2,
  #nav-wrapper,
  .logo-top {
    display: none !important;
  }

  #cheese-types {
    border: 1em solid red;
  }
}
