.outer-wrapper > .recipe {
  margin-right: 0;

  .recipe-image {
    @include square(340px);

    @include break($mobile) {
      @include square(270px);
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  p.recipe-title {
    display: none;
  }

  h1 {
    font-size: 70px;
    line-height: 0.9;

    @include break($tablet) {
      margin-top: 40px;
    }

    @include break($mobile) {
      text-align: center;
      font-size: 30px;
      line-height: 0.9;
      width: 270px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .recipe-wrapper {
    position: relative;

    @include break($tablet) {
      width: 960px;
      margin: 125px auto 140px;
    }

    .ingredients {
      .other-tags {
        opacity: 0;
        position: absolute;
      }
    }

    .section,
    &.new {
      border-top: 1px solid $gray_dark;
    }

    &.new {
      .recipe-action {
        li.share ul li {
          img {
            width: 65px;
          }

          a {
            padding-right: 4px;
          }

          &.gplus {
            img {
              width: 25px;
            }
          }
        }
      }

      @include break($mobile) {
        display: flex;
        flex-flow: column;

        .see-all-recipes {
          order: -6;
          margin-left: 25px;
          z-index: 40;
        }

        h1 {
          order: -3;
          padding-top: 20px;
        }

        .recipe-action {
          padding-left: 25px;

          li {
            padding: 0;
          }
        }
      }
    }
  }

  .see-all-recipes {
    $height: 34px;
    height: $height;
    top: -$height;
    font-family: $sans;
    font-size: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    text-transform: uppercase;

    @include break($mobile) {
      order: -10;
    }

    span {
      font-size: 0.6em;
      padding-right: 0.6em;
    }
  }

  .related-recipes {
    border-top: none !important;

    header {
      @include headlines(90px, 90px, 40px, 40px, $yellow-ada);
      text-align: center;

      .headline {
        margin-top: -7px;
        margin-bottom: 20px;
      }

      @include break($mobile) {
        .headline {
          font-size: 50px;
        }

        .subheadline {
          font-size: 30px;
        }
      }
    }

    .ul {
      display: flex;
      justify-content: space-between;

      @include break($mobile) {
        width: 100%;
        flex-flow: row wrap;

        a {
          margin-bottom: 12px;

          div {
            height: 44px;
            line-height: 1;
            font-size: 11px;
          }
        }
      }
    }
  }
}

#recipes #browser {
  padding: 0;

  .recipe-selector {
    margin: 0 auto;

    @include break($mobile) {
      margin-bottom: 14vw;
    }
  }
}

.recipe-directory,
.browser-container,
.search-results {
  display: flex;
  justify-content: space-between;

  @include break($mobile) {
    width: 100%;
    flex-flow: row wrap;
  }
}

.recipe-link,
.newsletter-link {
  @include square(225px);
  background-image: url("../images/cmab_watermark.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;

  @include break($mobile) {
    flex: 0 0 48%;
    height: 45vw;
    margin-bottom: 12px;
  }

  &.no-click {
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  div {
    font-family: $sans;
    -webkit-font-smoothing: antialiased;
    height: auto;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-transform: uppercase;
    padding: 9px;
    font-size: 19px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include break($mobile) {
      font-size: 15px;
      padding: 7px;
    }
  }

  &:hover .recipe-title,
  &:hover .newsletter-title {
    background: url(../images/bg-recipetitle.png) no-repeat;
  }
}

.featured-recipes222 {
  .prev,
  .slick-prev,
  .next,
  .slick-next {
    @include prev_next_buttons(268px);
    z-index: 0;
    top: 218px;
  }

  .prev,
  .slick-prev {
    @include prev_button(0, white, false);
    left: -60px;

    @include bp(largeWidth) {
      display: none !important;
    }
  }

  .next,
  .slick-next {
    @include next_button(0, white, false);
    right: -63px;

    @include bp(largeWidth) {
      display: none !important;
    }
  }

  .recipe-pinterest {
    display: inline-block;
    float: right;
    margin-top: 8px;
  }

  .recipe-selector {
    height: 34px;
    margin-bottom: 26px;
    width: 100%;

    .category {
      color: $black_cmab;
      cursor: pointer;
      font-family: $sans;
      float: left;
      font-size: 23px;
      margin: 0 10px;
      padding: 8px 0;
      text-transform: uppercase;
      text-decoration: none;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        border-bottom: 3px solid $white;
        color: $white;
      }

      &:hover {
        color: $white;
      }

      &.all {
        border-left: 1px solid $brown;
        border-right: 1px solid $brown;
        height: 36px;
        margin: 0;
        padding: 8px 10px;

        &.active {
          border: none;
          border-bottom: 2px solid $white;
        }
      }

      a {
        color: $black_cmab;
        text-decoration: none;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .slide-wrapper-outer {
    height: 947px;
    margin: 0 auto;
    overflow: visible;
    position: relative;
    width: 960px;
  }

  .slide-wrapper-inner {
    margin: 0 auto;
  }

  .content-container {
    height: 470px;
    margin: 0 auto;
    width: 960px;
  }

  .recipe {
    background-color: $white;
    border: none;
    float: left;
    font-family: $sans;
    font-size: 21px;
    height: 225px;
    overflow: hidden;
    position: relative;
    width: 225px;

    &.big {
      height: 470px;
      font-size: 32px;
      margin-right: 20px;
      width: 470px;
    }

    &.top {
      margin-bottom: 20px;
    }

    &.left {
      margin-right: 20px;
    }

    .recipe-image {
      height: auto;
      left: 0;
      width: 100%;
      position: absolute;
      top: -50%;
    }

    .recipe-title {
      background-color: $blue_light;
      bottom: 0;
      color: $white;
      height: 75px;
      left: 0;
      padding: 3% 4%;
      position: absolute;
      text-transform: uppercase;
      width: 100%;
      line-height: 20px;
    }

    &.big .recipe-title {
      line-height: 28px;
      height: 100px;
      padding: 5% 4%;
    }

    &:hover {
      .recipe-title {
        background-color: $blue_dark;
      }
    }
  }

  .recipe-pinterest-link-container {
    border: 3px solid $white;
    height: 48px;
    font-family: $sans;
    font-size: 22px;
    margin: 48px auto 0;
    text-align: center;
    width: 320px;

    a {
      color: $white;
      height: 48px;
      line-height: 42px;
      text-transform: uppercase;
      text-decoration: none;
    }

    .pinterest-logo {
      background: url("../images/pinterest-small.png") 0 0 no-repeat $yellow;
      background-color: transparent;
      display: inline-block;
      height: 26px;
      margin-left: 8px;
      text-indent: -9999px;
      width: 26px;
      vertical-align: text-bottom;
    }

    &:hover {
      background-color: $white;

      a {
        color: $yellow;
      }
    }
  }

  @include bp(mediumWidth) {
    height: auto;
    padding: 12% 3% 0;
    width: 100%;

    .header {
      text-align: center;
    }

    .slide-wrapper-outer {
      height: auto;
      padding-bottom: 6%;
      width: 100%;
    }

    .slide-wrapper-inner {
      height: auto;
    }

    .recipe-pinterest {
      bottom: 0;
      display: inline-block;
      float: none;
      left: 0;
      margin: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    .recipe-selector {
      height: 54px;
      margin-bottom: 3.33%;
      overflow: hidden;
      position: relative;
      width: 100%;

      ul {
        height: 100%;
        width: 100%;
      }

      li {
        margin-top: 6px;
        padding-top: 0;
      }

      .category {
        font-size: 17px;
        margin: 0 5% 0 0;

        &.all-mobile {
          border-left: 1px solid;
          margin-right: 0;
          padding-left: 6%;
        }
      }
    }

    .slide {
      height: auto;
    }

    .content-container {
      height: 436px;
      width: 100%;
    }

    .recipe {
      font-size: 17px;
      height: 213px;
      text-transform: uppercase;
      width: 49%;

      &.big {
        font-size: 17px;
        height: 213px;
        margin-right: 2%;
        width: 49%;

        .recipe-title {
          height: 75px;
          line-height: 16px;
        }
      }

      &.top {
        margin-bottom: 2%;
      }

      &.top.left {
        margin-right: 0;
      }

      &.top.right {
        margin-right: 2%;
      }

      &.bottom.left {
        margin-right: 0;
      }

      &.bottom.right {
        display: none;
      }

      .recipe-title {
        line-height: 16px;
      }

      .recipe-image {
        top: -10%;
      }

      .title {
        padding: 2%;
      }
    }

    .recipe-pinterest-link-container {
      font-size: 19px;
      height: 42px;
      margin-top: 16px;
      overflow: hidden;
      width: 183px;

      a {
        height: 42px;
        line-height: 42px;
      }

      .pinterest-logo {
        background: url("../images/icons/pinterest-mobile-normal.png") 0 58%
          no-repeat $yellow;
        height: 18px;
        line-height: 100%;
        width: 13px;
        background-size: 100% auto;
      }
    }

    .slick-slider {
      margin-bottom: 0;

      .slick-dots {
        margin-bottom: 0;
        padding-top: 8%;

        li {
          button {
            &:before {
              color: $white;
              opacity: 1;
            }
          }

          &.slick-active button:before {
            color: $blue_dark;
            opacity: 1;
          }
        }
      }
    }
  }
}

body.recipe-video {
  .recipe-wrapper {
    .see-all-recipes {
      top: -34px;

      @include break($tablet) {
        top: 94px;
      }
    }

    @include break($tablet) {
      position: static !important;
      width: 450px !important;

      &.video-stop {
        .video-player {
          bottom: -270px;

          .iframe {
            position: absolute;
            right: 0;
          }
        }
      }

      .wrapper {
        .copy {
          margin-top: 20px;
          width: 100%;
        }
      }

      .related-recipes {
        width: 960px;
      }
    }
  }

  img.do-print {
    display: none;
  }

  .video-player {
    position: absolute;
    right: 0;

    .cta {
      @include commericalLinks($black, $yellow);
      font-size: 19px;
      position: absolute;
      bottom: -25px;
      right: 0;

      @include break($mobile) {
        display: none;
      }
    }

    .iframe {
      position: fixed;
      width: 450px;
      height: 253px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    @include break($mobile) {
      position: relative;
      margin: 10px;

      .iframe {
        position: relative;
        width: 100%;
        height: 50vw;
      }
    }
  }
}
