// Styles for Auxiliary Pages
@keyframes beat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.08);
  }
  40% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.home {
  //Masthead
  .masthead {
    position: relative;
    background-color: black;
    overflow: hidden;
    height: 100vw;
    width: 100%;
    text-align: center;
    padding-top: 70px;
    max-height: 100vh;

    @include break($tablet) {
      height: auto;
      padding-top: 50px;
    }

    .masthead-video-carousel {
      width: 100%;

      .slick-prev,
      .slick-next {
        z-index: 100;
        height: 30px;
        width: 30px;

        @include break($tablet) {
          height: 60px;
          width: 60px;
        }
      }

      .slick-prev {
        left: 10px;
        background: url(../images/icons/arrow-prev-gray.png) no-repeat;
        background-position: center;
        background-size: contain;
        &::before {
          content: "";
        }

        @include break($tablet) {
          left: 20px;
        }
      }

      .slick-next {
        right: 10px;
        background: url(../images/icons/arrow-next-yellow.png) no-repeat;
        background-position: center;
        background-size: contain;

        &::before {
          content: "";
        }

        @include break($tablet) {
          right: 20px;
        }
      }

      @include break($mobile) {
        .desktop-only {
          display: none;
        }
      }
      @include break($desktop) {
        .mobile-only {
          display: none;
        }
      }
    }

    .copy-container {
      position: absolute;

      .california-freestyle img {
        width: 280px;

        @include break($tablet) {
          width: 65%;
        }
      }

      .cta-wrapper {
        transition-delay: 0.5s;
        display: flex;
        justify-content: center;

        .cta {
          @include cta-no-border($yellow, $black, $black, $yellow, auto);
        }
      }
    }
  }

  // California Freestyle modal
  #freestyle-modal {
    .modal-dialog {
      max-width: 95vw;
      margin: auto;
      height: 100%;

      @include break($tablet) {
        max-width: 80vw;
      }

      .modal-content {
        border-radius: 25px;
        overflow: hidden;
        -webkit-transform: translateZ(0);
        position: relative;

        .icon-close {
          position: absolute;
          width: 25px;
          height: 25px;
          top: 20px;
          right: 20px;
          z-index: 1;

          @supports (-webkit-touch-callout: none) {
            display: none;
          }

          @include break($tablet) {
            width: 35px;
            height: 35px;
            top: 40px;
            right: 40px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        video {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .header-wrapper-2 {
    @include break($tablet) {
      header {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: auto;
        min-width: 767px;
        max-width: 913px;
        width: 56%;

        .cta {
          @include cta-no-border($yellow, $black, $black, $yellow, auto);
          width: 190px;
          height: 59px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    header {
      > .animatable {
        width: 100%;
        top: 0;
        opacity: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        transition: all 0.4s;
        transform: translateY(20px);

        &.show {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .top {
        background-image: url(../images/home/headline@2x.png?v=2);
        min-height: 50px;
        max-height: 300px;
        width: 89%;
        height: 16vw;
      }

      .mid {
        background-image: url(../images/home/california-drop@2x.png);
        min-height: 20px;
        height: 5.5vw;
        transition-delay: 0.5s;
      }

      .bottom {
        min-height: 86px;
        max-height: 115px;
        height: 4vw;
        transition-delay: 0.5s;
        font-family: $serif;
        font-weight: bold;
        font-style: italic;
        color: white;
        font-size: 26px;
        padding-top: 25px;
        text-align: center;
        width: 90%;
      }

      .cta-wrapper {
        transition-delay: 0.5s;
        display: flex;
        justify-content: center;
      }

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        width: 209px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: $white;
        }
      }
    }

    @include break($mobile) {
      header {
        top: 50px;

        > .animatable {
          visibility: visible;
          top: 0;
          opacity: 1;
        }

        .top {
          min-height: initial;
          height: 22vw;
        }

        .mid {
          min-height: initial;
          height: 6.7vw;
        }

        .bottom {
          min-height: initial;
          width: 70%;
          height: 10vw;
          font-size: 4.3vw;
          padding-top: 0;
          margin: 4vw 0;
          line-height: 4.6vw;
        }
      }
    }

    @include bp(mediumWidth) {
      height: 100vw;
      background-image: url(../images/home/hero_mobile@2x.png);
    }
  }

  .homepage-intro {
    .cta {
      @include cta-no-border($black, $white, $white, $black, auto);
      margin: 0 auto;
      height: 48px;
      margin-top: 40px;
    }

    .cta-wrapper {
      .cta {
        @include break($mobile) {
          padding-left: 1%;
          padding-right: 1%;
        }
      }
    }
  }

  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .look-for-seal {
    @include bp(mediumWidth) {
      .main-content {
        .card {
          .content {
            .description {
              font-size: 4.3vw;
              line-height: 4.7vw;
            }
          }
        }
      }
    }
  }
}

.food {
  .header-wrapper-2 {
    background: url(../images/our-food/bg-hero.png) center;
    background-size: cover;

    @include bp(mediumWidth) {
      height: 65.625vw !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      height: 65vw !important;
    }

    > header {
      .headline {
        @include headline-accent-2($white, false);
        @include break($mobile) {
          display: block;
          font-size: 4.5vw;
          padding-top: 8vw;
        }
      }

      img {
        max-width: 600px;
        width: 100%;
        padding: 20px 0 85px;
        @include break($mobile) {
          width: 59vw;
          padding: 1vw 0 5vw;
        }
      }

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
      }
    }
  }
}

.recipes {
  .header-wrapper-2 {
    @include break($tablet) {
      background: url(../images/cheese-bg.jpg);
      background-size: cover;
    }

    @include bp(mediumWidth) {
      background: url(../images/cheese-bg.jpg) center;
      background-size: cover;
      height: 65.625vw !important;
    }
  }
}

.cheese-types,
.cheese-101,
.cheese-glossary,
.cheese-makers,
.cheese-faqs,
.cheese-history,
.cheese-pairings {
  .header-wrapper-2 {
    @include break($tablet) {
      background: url(../images/rc-cheese.jpg);
      background-size: cover;
    }

    @include bp(mediumWidth) {
      background: url(../images/rc-cheese-mobile.jpg) center;
      background-size: cover;
      height: 65.625vw !important;
    }
  }
}

.foodservice-recipe {
  .header-wrapper-2 {
    @include break($tablet) {
      background: url(../images/FoodserviceRecipeLanding.jpg);
      background-size: cover;
    }

    @include bp(mediumWidth) {
      background: url(../images/FoodserviceRecipeLanding.jpg) center;
      background-size: cover;
      height: 65.625vw !important;
    }
  }
}

.page-heading {
  height: 260px;
  background-size: cover;
  overflow: hidden;

  @include bp(mediumWidth) {
    height: initial;
  }

  @include break($mobile, true) {
    overflow: visible;
    z-index: 1;
  }

  .nav-top {
    position: static;
    height: auto;
    text-align: center;
  }

  h1 {
    font: 100px $sans;
    color: $white;
    text-transform: uppercase;
    margin: 95px auto 0;
    width: 960px;
    text-align: center;

    @include bp(mediumWidth) {
      font: 40px $sans;
      margin-top: 64px;
      margin-left: 8px;
      width: auto;
      padding-bottom: 23px;
    }

    @include break($mobile, true) {
      margin-top: 30px;
    }
  }
}

.chosen-container-single .chosen-single div b {
  display: none;
}

.page-content {
  width: 960px;
  margin: 55px auto;

  .intro {
    margin: 55px auto 0;
    background-color: $white;

    img {
      float: left;
    }

    h2,
    .copy {
      width: 715px;
      float: right;
    }

    h2 {
      font: 50px/45px $sans;
      text-transform: uppercase;
    }

    .copy {
      p {
        font: 18px/26px $serif;
        margin-top: 10px;
      }
    }
  }

  .sidebar {
    position: absolute;
    right: 125px;

    .headline {
      margin-bottom: 10px;
      font: 32px $sans;
      text-transform: uppercase;
    }

    a {
      font: 22px/30px $sans;
      text-transform: uppercase;
      color: $yellow;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: $yellow-drk;
      }
    }
  }

  .ctas {
    a {
      color: $yellow;
      text-decoration: none;
    }

    ul li {
      &:hover {
        a,
        a .headline {
          color: $yellow-ada;
          text-decoration: none;
        }
      }
    }
  }
}

@include bp(mediumWidth) {
  .page-content {
    width: auto;
    padding: 3%;

    .intro {
      h2,
      .copy {
        float: none;
        width: 100%;
      }
    }
  }
}

// Styled Selects
.contact-us,
.cheesemaker-list,
.processor-list {
  select {
    @include border-radius-and-pie(0);
    background: url(../images/select-arrow-black.png) right 10px center
      no-repeat $yellow;
    border: 0;
    color: black;
    font: 20px $sans;
    padding: 12px;
    text-transform: uppercase;
    width: 100%;
  }

  .chosen-container-single,
  .chosen-container-active.chosen-with-drop {
    margin-right: 15px;
    text-align: left;

    .chosen-single {
      @include border-radius-and-pie(0);
      background-color: $yellow;
      background-image: url(../images/select-arrow-black.png);
      background-position: 235px;
      background-repeat: no-repeat;
      border: 0;
      box-shadow: 0 0 0 transparent;
      height: 45px;
      padding: 11px 0 0 12px;
      color: black;

      span {
        color: $black;
        font: 22px $sans;
        text-transform: uppercase;
      }
    }

    .chosen-drop {
      @include border-radius-and-pie();
      border: 0;
      background: $yellow;
      border-radius: 0;

      li {
        background: $white;
        color: black !important;
        font: 22px/22px $sans;
        text-transform: uppercase;

        &:hover {
            background: #f1f0ef;
          }
      }
    }
  }
}

.for-californians {
  .intro {
    display: flex;
    justify-content: center;

    @include bp(mediumWidth) {
      display: block;
    }
  }

  .cta-content {
    position: relative;
    width: 190px;
    height: 190px;
    clear: both;

    &.mobile {
      display: none;
    }

    &.desktop {
      display: block;
    }

    @include bp(mediumWidth) {
      margin: 0 auto 30px;

      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }
    }

    .cta-bottom {
      margin: 0;
      left: 0;
    }
  }

  .copy {
    margin-bottom: 30px;

    @include bp(mediumWidth) {
      margin-bottom: 0;
    }
  }

  @include bp(mediumWidth) {
    .page-content {
      padding: 0 3%;
      text-align: center;

      .intro {
        img {
          float: none;
          margin: 0 0 3%;
        }

        h2 {
          font: 24px/24px $sans;
          margin: 0 auto;
        }

        .copy p {
          font: 14px/20px $serif;
          margin: 3% auto 6%;
        }
      }
    }
  }
}

.our-apps {
  .apps {
    text-align: center;

    li {
      display: inline-block;
      width: 45%;
      padding: 0 7% 2%;
      margin-bottom: 75px;
      vertical-align: top;

      &:first-child {
        border-right: 1px solid #ddd;
      }

      .headline {
        font: 46px $sans;
        text-transform: uppercase;
      }

      img {
        margin: 20px 0;
      }

      .description {
        font: 16px/22px $serif;
        padding-bottom: 10px;
      }

      a,
      a:hover {
        text-decoration: none;
        margin: 5px 0;
        display: block;

        &.yellow-border {
          @include cta-no-border($yellow, $black, $black, $yellow, 175px);
        }

        &.app-store,
        &.google-play {
          width: 185px;
          height: 62px;
          text-indent: -9999em;
          margin: 10px auto;
        }

        &.app-store {
          background: url(../images/cta-app-store.jpg) 0 0 no-repeat;
        }

        &.google-play {
          background: url(../images/cta-google-play.jpg) 0 0 no-repeat;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    .apps li {
      display: block;
      width: auto;
      margin-bottom: 10%;
      padding-bottom: 10%;

      &:first-child {
        border-right: 0;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

.industry-resources,
.foodservice {
  position: relative;

  .page-content {
    position: relative;

    .headline {
      font: 34px $sans;
      text-transform: uppercase;
    }

    .ctas,
    .links {
      float: left;
      width: 67%;
    }

    .ctas {
      .headline {
        margin-bottom: 25px;
      }

      li {
        display: inline-block;
        width: 32%;
        height: 170px;
        padding: 0 25px;
        text-align: center;
        vertical-align: top;

        img {
          margin-bottom: 10px;
        }

        .headline {
          font: 24px/26px $sans;
          text-transform: uppercase;
          color: $yellow-ada;
          margin-bottom: 0;
        }

        .subheadline {
          font: italic 17px/24px $serif;
          color: $olive;
        }

        &:first-child {
          border-right: 1px solid #ddd;
        }

        &:last-child {
          border-left: 1px solid #ddd;

          img {
            margin: 15px 0 20px;
          }
        }
      }
    }

    .links {
      margin: 50px 0 75px;

      .headline {
        background: url() right 0 no-repeat;
        padding-right: 45px;
        margin-bottom: 10px;
      }

      a {
        text-decoration: none;
        font: 18px/34px $serif;
        color: $black-cmab;

        &:hover {
          text-decoration: none;
          color: $yellow;
        }
      }
    }
  }

  @include bp(mediumWidth) {
    .page-content {
      .ctas,
      .links {
        float: none;
        width: 100%;
      }

      .ctas {
        li {
          display: block;
          width: 100%;
          text-align: center;
          border: 0;
          border-bottom: 1px solid #ddd;
          padding: 5% 0;
          height: auto;

          .headline {
            width: 55%;
            margin: 0 auto;
          }

          &:first-child,
          &:last-child {
            border-left: 0;
            border-right: 0;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.foodservice {
  @include bp(mediumWidth) {
    .page-content {
      margin: 23px auto;
    }
  }

  .page-content {
    margin-bottom: -42px;

    .ctas {
      li {
        &:last-child {
          border-left: 0 !important;
        }
      }
    }

    .links {
      overflow: hidden;
      width: 100%;
      margin-bottom: 100px;

      .headline {
        padding-bottom: 10px;
      }

      li {
        vertical-align: top;
        margin-bottom: 25px;
        width: 320px;
        height: 93px;
        float: left;

        .icon {
          float: left;
          margin-right: 8px;
        }

        .description {
          font: 17px/24px $serif;
        }

        a {
          font: 20px/22px $sans !important;
          text-transform: uppercase;
          color: $yellow;
        }

        &:hover {
          a {
            color: $yellow-drk;
          }
        }
      }
    }
  }
}

.contact-us {
  .subheadline {
    font: 24px $sans;
    text-transform: uppercase;
  }

  .offices,
  .right {
    margin: 35px 0 75px;
    padding-top: 25px;
    border-top: 1px solid #ddd;

    .headline {
      font: 24px/42px $sans;
      text-transform: uppercase;
    }
  }

  .offices {
    float: left;
    width: 65%;

    li {
      display: inline-block;
      width: 48%;
      vertical-align: top;
    }

    .name {
      font: 18px/26px $sans;
      text-transform: uppercase;
    }

    .address,
    .directions,
    .phone,
    .email {
      font: 14px/18px $serif;
    }

    .directions,
    .email {
      color: $yellow;
    }
  }

  .right {
    width: 35%;
    float: right;

    .international {
      @include cta-no-border($yellow, $black, $black, $yellow, 337px);
      height: 45px;
      font: 22px $sans;
      vertical-align: center;
      text-align: center;
      position: relative;
      line-height: 39px;
    }
  }

  .chosen-container-single,
  .chosen-container-active.chosen-with-drop {
    .chosen-single {
      background-position: 225px;
    }
  }

  @include bp(mediumWidth) {
    .right,
    .offices {
      float: none;
      width: 100%;
    }

    .offices {
      margin-bottom: 10%;

      ul li {
        display: block;
      }
    }

    .right {
      margin-top: 0;
    }
  }
}

.international-contacts {
  margin-bottom: 145px;

  @include bp(smallWidth) {
    margin-bottom: 50px;
  }

  .heading {
    text-align: center;

    .real-cali {
      font-family: $script;
      font-size: 29px;
      color: $yellow;
    }

    .worldwide {
      font-family: $sans;
      font-size: 71px;
      text-transform: uppercase;
    }

    .reps {
      font-family: $serif;
      font-size: 19px;
      padding-bottom: 50px;
      margin-top: 7px;
    }
  }

  .hr {
    background-color: #dddddd;
    width: 100%;
    clear: both;
    display: inline-block;
    height: 1px;
    box-sizing: border-box;
    margin-bottom: 30px;

    @include bp(smallWidth) {
      margin-top: 30px;
    }
  }

  .hr:last-child {
    display: none;
  }

  .contacts {
    line-height: 25px;

    .contact {
      float: left;
      position: relative;
      padding-right: 30px;
      width: 272px;
      height: auto;
      margin-bottom: 18px;
      margin-right: 30px;

      &.doubleMarginBottom {
        margin-bottom: 36px;

        @include bp(mediumWidth) {
          margin-bottom: 18px;
        }
      }

      @include break($tablet) {
        height: 140px;
      }

      @include bp(smallWidth) {
        .general:nth-of-type(2) {
          margin-left: 0;
          margin-top: -12px;
        }
      }
    }

    @include break($tablet) {
      .asian-contacts {
        .contact:not(:nth-last-child(-n + 3)) {
          height: 240px;
        }
      }
    }
  }

  .region {
    font: 33px/42px $sans;
    text-transform: uppercase;
    width: 40%;
    margin-bottom: 13px;
    letter-spacing: -0.5px;
  }

  .country {
    font: 22px/26px $sans;
    text-transform: uppercase;
    text-decoration: none;
    color: black;

    &:hover {
      color: $yellow;
    }
  }

  .name,
  .email,
  .international-site,
  .phone,
  .type,
  .organization {
    font: 18px $serif;
  }

  .international-site {
    color: $yellow;
    @include no-underline;
  }

  .email {
    color: $black;
    @include no-underline;
  }
}

.contact-us,
.contact-international {
  .left {
    float: left;
    width: 36%;
  }

  .thanks {
    @include paragraph-tiny;
    margin-top: 20px;
  }

  .error {
    color: red;
  }

  input,
  textarea {
    display: block;
    border: 0;
    background-color: #eee;
    font: 22px $sans;
    color: $gray_input_password;
    -webkit-font-smoothing: antialiased;
  }

  input[type="text"] {
    padding: 10px;
    border: 0;
    margin: 0 25px 23px 0;
    width: 92%;
    text-transform: uppercase;
  }

  textarea {
    margin-top: 15px;
    padding: 8px;
    width: 64%;
    height: 240px;
  }

  .submit {
    vertical-align: top;
    text-align: right;
    position: relative;

    span,
    input[type="submit"] {
      display: inline-block;
    }

    span {
      font: italic 14px $serif;
      color: $olive;
      width: 120px;
      position: absolute;
      top: 20px;
      right: 150px;
    }

    input[type="submit"] {
      @include cta-no-border($yellow, $black, $black, $yellow, 135px);
      height: 45px;
      font: 22px $sans;
      margin: 15px 0 0 10px;
    }
  }

  @include bp(mediumWidth) {
    .page-content {
      width: auto;

      .subheadline {
        margin-bottom: 3%;
      }

      .description {
        width: 100%;
      }
    }

    .left {
      input {
        width: 100%;
        margin: 0 0 5%;
      }
    }

    .submit {
      span {
        display: inline;
        right: 65%;
        font-size: 12px;
      }
    }
    .left,
    textarea {
      float: none;
      width: 100%;
    }
  }
}

.contact-international {
  font: 18px/24px $serif;

  .subheadline {
    font: 32px $sans;
    text-transform: uppercase;
  }

  .description {
    margin: 10px 0 20px;
    width: 72%;
  }

  .contacts {
    margin-bottom: 45px;

    ul li {
      margin-bottom: 10px;

      .heading {
        font: 22px/30px $sans;
        text-transform: uppercase;
      }

      a,
      a:hover {
        text-decoration: none;
        color: $yellow;
      }

      a:hover {
        color: $yellow-drk;
      }
    }
  }
}

.processor-list {
  margin-bottom: 190px;
}

.cheesemaker-list,
.processor-list {
  .page-content {
    position: relative;

    @include break($mobile) {
      margin-top: 20px;
    }

    .logo-block,
    .copy {
      width: 715px;
      margin: 0 auto;
      text-align: center;

      @include break($mobile) {
        width: 90%;
      }
    }

    .logo-block {
      padding: 20px 0;
      @include break($mobile) {
        padding: 0;
      }
      min-width: 0;
    }

    .title-img {
      height: 208px;
      margin: 0 10px;

      @include break($mobile) {
        height: 100px;
      }
    }

    .copy {
      .headline {
        text-transform: uppercase;
        font: 75px $sans;
        padding: 20px 0 20px;
        line-height: 0.9;

        @include break($mobile) {
          font-size: 30px;
          padding: 20px 0 5px;
        }
      }

      .description p {
        font: 18px/24px $serif;
        margin: 10px 0;
      }
    }

    .more {
      text-align: center;

      p {
        font: 18px/24px $serif;
      }

      .cta-wrapper {
        padding-top: 2em;
        display: flex;
        justify-content: center;
      }

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
      }
    }

    .browser {
      margin-top: 50px;

      .controls {
        text-align: left;

        .chosen-container {
          margin-top: -9px;
        }
      }

      .pdf {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        padding: 0 15px;

        @include break($tablet) {
          &:nth-of-type(1) {
            margin-right: 10px;
          }
        }

        @include bp(mediumWidth) {
          position: static;
        }
      }

      .heading {
        display: none;
      }

      .table {
        border-top: 3px solid $yellow;
        width: 100%;
        margin-top: 20px;

        @include break($mobile) {
          margin-top: 8px;
        }

        .tr {
          display: table-row;

          .td {
            display: table-cell;
            border: 1px solid #ddd;
            padding: 20px 10px;
            vertical-align: top;
            font: 18px/20px $serif;

            a,
            a:hover {
              text-decoration: none;
              color: $yellow;
            }

            &.first {
              display: none;
            }

            &.c1 {
              width: 84px;
            }

            &:last-child {
              width: 33%;
            }

            .mobile-type {
              display: none;
            }
          }

          &.highlight {
            background-color: #eee;
          }

          &:first-child {
            .td {
              font: 20px $sans;
              text-transform: uppercase;
              color: $gray_dark;
              letter-spacing: 1px;
              vertical-align: middle;

              sub {
                font-size: 16px;
              }
            }
          }

          &.hide {
            display: none;
          }
        }
      }
    }
  }

  @include bp(mediumWidth) {
    .page-content {
      .browser {
        text-align: left;
        position: static;
        margin-top: 5%;

        select {
          margin-bottom: 3%;
        }

        .heading {
          font: 22px $sans;
          text-transform: uppercase;
          color: $olive;
          position: relative;
          padding-top: 15px;
          display: block;

          sub {
            font-size: 16px;
          }
        }

        .table {
          .headers {
            display: none;
          }

          .tr {
            display: block;
            width: 100%;
            border-bottom: 1px solid #eee;

            &.headers {
              border-bottom: 0;
            }

            &.highlight {
              background: none;
            }

            &.opened .icon {
              transform: rotate(-45deg);
            }

            .td {
              display: none;
              padding: 3% 0;
              position: relative;
              font: 18px/22px $serif;
              width: 100% !important;
              height: auto !important;
              border: none;

              .mobile-label {
                display: none;
              }

              &.first {
                display: block;
              }

              &.c0 {
                padding-top: 0;
              }

              .icon {
                position: absolute;
                width: 18px;
                height: 18px;
                transform: rotate(0deg);
                transition: 0.3s all cubic-bezier(0.49, 1.92, 0.83, 0.67);
                $xline-width: 18px;
                $xline-height: 4px;
                $xline-padding: 0px;
                right: 0.6em;
                top: 0.6em;

                &::before {
                  transform: rotateZ(90deg);
                }

                &::before,
                &::after {
                  content: " ";
                  top: 7px;
                  position: absolute;
                  width: $xline-width;
                  height: $xline-height;
                  background-color: black;
                  z-index: 1;
                  left: $xline-padding;
                }
              }

              .label {
                font: 25px/25px $sans;
                text-transform: uppercase;
                padding-right: 50px;
              }

              .mobile-type {
                color: $green_dark;
                text-transform: uppercase;
                font-family: $sans;
                display: block;
              }
            }

            &.opened {
              .td {
                display: block;
              }

              .icon {
                -webkit-transform: rotate(-45deg);
              }
            }
          }
        }

        .heading {
          font: 22px $sans;
          text-transform: uppercase;
          color: $olive;

          sub {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.processor-list td:first-child {
  width: 35%;
}

.processor-list .c1 {
  width: auto !important;
}

.processor-list {
  .entry-form-container {
    text-align: center;

    h2 {
      @include subheadline-1;
      background: url("../images/bg-processor-header.png") center 0 no-repeat
        $white;
      margin-bottom: 20px;
      padding-top: 128px;
    }

    p {
      @include paragraph-tiny;
      margin-bottom: 15px;

      a {
        color: $yellow;
        text-decoration: none;

        &:hover {
          color: $yellow_link_hover;
        }
      }
    }

    input[type="password"] {
      background-color: $gray_input_bg_light;
      border: 0;
      color: $gray_input_password;
      font: 23px $sans;
      height: 46px;
      margin-bottom: 15px;
      padding: 15px;
      width: 358px;
    }

    input[type="submit"] {
      @include cta-no-border($yellow, $black, $black, $yellow, auto);
      margin-bottom: 15px;
      min-width: 142px;
    }

    @include bp(mediumWidth) {
      input[type="password"] {
        height: 38px;
        padding: 22px;
        width: 298px;
      }
    }
  }
}

.sidebar-page {
  .center {
    width: 100%;
  }

  .left {
    width: 70%;
    margin: auto;

    h2 {
      font: 32px $sans;
      text-transform: uppercase;
    }

    p,
    li {
      font: 18px/24px $serif;
    }

    p {
      margin: 10px 0 40px;
    }

    table {
      line-height: 1.4em;
      table-layout: fixed;
      overflow-wrap: break-word;

      td {
        width: 33%;
      }
    }

    ul {
      list-style: initial;
      margin-left: 65px;

      li {
        margin: 20px 0;
      }
    }

    a,
    a:hover {
      text-decoration: none;
      color: $yellow;
    }

    a:hover {
      color: $yellow-drk;
    }
  }

  .sidebar {
    right: 180px;
  }

  @include bp(mediumWidth) {
    .left {
      width: 100%;
      float: none;
      padding-right: 0;

      ul {
        margin-left: 10%;
      }
    }
  }

  .table-of-content {
    margin-bottom: 50px;

    h2 {
      font: 32px $sans;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    p {
      font: 15px/19px $serif;
    }

    p.table-of-content__item--title {
      text-decoration: underline;
    }

    p.table-of-content__item--description {
      margin-bottom: 10px;
    }

    a,
    a:hover {
      text-decoration: none;
      color: $yellow;
    }

    a:hover {
      color: $yellow-drk;
    }

    &--info {
      text-align: center;
      margin-bottom: 50px;
    }

    &--highlights {
      text-align: center;
    }

    &__left,
    &__right {
      display: inline-block;
      vertical-align: top;
      width: 49%;
      padding: 5px;

      @include bp(mediumWidth) {
        display: block;
        width: 100%;
      }
    }

    &__left {
    }

    &__right {
    }
  }
}

.cheese-action {
  width: 960px;
  position: relative;
  z-index: 3;

  @include break($tablet) {
    margin: 50px auto 0;
  }

  ul {
    display: block;
    height: 40px;
    float: right;
  }

  li {
    float: left;
    padding: 0 5px;

    &.share {
      display: none;
      padding: 10px 0;
    }

    &.print {
      line-height: 40px;

      &:hover {
        cursor: pointer;
      }

      p {
        font-family: $serif;
        font-size: 15px;
        margin: 0;
      }
    }

    &.share-icon {
      padding-right: 15px;
    }
  }
}
