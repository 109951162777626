$footer_bg: #231f20;
$footer_copyright: #565351;

/* Footer */
.footer {
  background: $footer_bg;
  padding: 50px 0 70px;

  ul {
    padding: 0;
  }

  .nav-primary,
  .nav-secondary {
    margin-bottom: 210px;
    letter-spacing: 0;

    li {
      @include upper;
      font-family: $sans;
      font-size: 20px;
      display: block;
      list-style: none;
      margin-bottom: 10px;

      a {
        color: $white;
      }

      a,
      a:hover {
        @include no-underline;
      }
    }
  }

  .nav-tertiary,
  .copyright {
    font-family: $serif;
  }

  .nav-primary {
    margin-right: 40px;
    float: right;
    max-width: 140px;

    li a {
      color: $yellow;

      &:hover {
        color: $white;
      }
    }
  }

  .nav-secondary {
    float: right;
    max-width: 135px;

    li a:hover {
      color: #fec31f;
    }
  }

  .mobile-items {
    display: none;
  }

  .nav-tertiary {
    float: right;
    clear: both;
    display: inline-block;

    li {
      display: inline;
      font-size: 16px;
      margin-right: 15px;

      a {
        text-decoration: none;
        color: $white;

        &:hover {
          color: $yellow;
          text-decoration: underline;
        }
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }

  .seals {
    display: inline-block;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $white;
      text-decoration: none;

      img {
        width: 210px;
      }
    }

    .social-nav {
      margin-top: 50px;

      li {
        display: inline-block;
        margin: 0 8px;

        a {
          background: url(../images/social-nav-sprite-global.webp) 0 0 no-repeat;
          background-size: auto 70px;
          text-indent: -9999em;
          height: 32px;
          width: 15px;
          display: block;
          margin: 0 auto;

          &.twitter {
            background-position: -40px 0;
            width: 33px;
          }

          &.pinterest {
            background-position: -93px 0;
            width: 25px;
          }

          &.instagram {
            background-position: -139px 0;
            width: 28px;
          }

          &.youtube {
            background-position: -187px 0;
            width: 42px;
          }

          &.tiktok {
            background-position: -248px 0;
            width: 42px;
          }

          &:hover {
            background-position-y: -35px;
          }
        }
      }

      li:first-child {
        margin-left: 0;
      }

      li:last-child {
        margin-right: 0;
      }
    }
  }

  .copyright {
    display: inline-block;
    color: white;
    font-size: 14px;
    max-width: 660px;
  }

  .content-width {
    padding-left: 0;
    padding-right: 0;
  }
}

@include bp(mediumWidth) {
  .footer {
    position: relative;
    z-index: 499;
    padding: 40px 0;
    text-align: center;

    .content-width {
      padding: 0;
    }

    .nav-primary,
    .nav-secondary {
      display: none;
    }

    .nav-tertiary {
      display: block;
      float: none;
      clear: none;
      margin-top: 40px;

      li {
        display: block;
        font-size: 16px;
        padding: 10px 0;
        text-align: center;
        margin: 0;

        a {
          color: $footer_copyright;
        }
      }
    }

    .copyright {
      font-size: 8px;
      line-height: 18px;
      width: 90%;
      margin: 60px auto 0;
    }

    .seals {
      .logo {
        align-items: center;

        img {
          width: 150px;
        }
      }

      .label {
        color: $white;
        display: block;
        font: 21px $sans;
        margin-bottom: 14px;
        text-transform: uppercase;
        margin-top: 70px;
      }

      .social-nav {
        margin-top: 0;

        li {
          margin-left: 20px;
        }
      }
    }

    .mobile-no {
      display: none;
    }

    .mobile-items {
      display: block;
    }
  }
}
