#foodservice-recipe {
  .header-wrapper-2 {
    .subheadline {
      font-family: "dinbold";
      text-transform: uppercase;
      margin-top: 73px;
      font-size: 30px;
      @include break($tablet, true) {
        margin-top: 28px;
        font-size: 4.8vw;
        margin-top: 16vw;
      }
    }
    .headline {
      font-family: "gistrough";
      text-transform: uppercase;
      color: #fff;
      font-size: 77px;
      margin-top: 10px;
      display: block;
      @include break($tablet, true) {
        font-size: 10.5vw;
        margin-top: 2vw;
      }
    }
  }
}
#foodservice-recipe,
#california-newsletters {
  .foodservice-recipes-browser,
  .california-newsletters-browser {
    @include vertical_gradient(#333333, #191919);
    padding-bottom: 1px;
    padding-top: 58px;

    .content-container {
      @include break($tablet, true) {
        width: 102%;
      }
      .recipe,
      .newsletter {
        @include break($tablet, true) {
          width: 43.9vw;
          height: 43.9vw;
          margin-right: 3vw;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .recipe-title,
        .newsletter-title {
          padding: 4.5% 7%;
          height: 76px;
          display: -webkit-flexbox;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          @include break($tablet, true) {
            height: 18vw;
            font-size: 4vw;
            line-height: 4vw;
          }
        }
      }
    }
  }
  .empty-block {
    height: 54px;
    @include add_chevron();
  }
  #browser {
    padding-top: 0;
    @include break($tablet, true) {
      padding: 9px 4vw 100px;
      width: 100%;
    }
    &.loading {
      background-color: transparent;
    }
    .search-results {
      color: #efefef;
    }
    .browser-container {
      & > div {
        width: 100%;
      }
    }
    .foodservice-recipe-selector,
    .type-selector {
      width: 948px;
      margin: 0 auto;
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      @include break($tablet, true) {
        width: 100%;
        display: block;
        padding-bottom: 47px;
      }
      @include break($tablet) {
        padding-bottom: 50px;
      }
      position: relative;
      z-index: 99;
      .selectors {
        display: inline-block;
        vertical-align: top;
        a {
          text-decoration: none;
          position: relative;
        }
      }
      .headline {
        font-size: 63px;
        line-height: 46px;
        margin-right: 38px;
        .mobile {
          display: none;
        }
      }
      .controls {
        opacity: 1;
        transition: 815ms all;
        width: 706px;
        display: inline-flex;
        background-color: transparent;
        justify-content: space-between;
        @include break($tablet, true) {
          display: inline-block;
          width: 100%;
        }
        &.hidden {
          visibility: hidden;
          opacity: 0;
        }
        li {
          display: block;
          background: transparent;
          @include break($tablet, true) {
            width: 48%;
            margin-right: 2.5%;
            margin-bottom: 2.5vw;
            .chosen-container {
              width: 100% !important;
              .chosen-drop {
                width: 100%;
                .chosen-results {
                  li {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .ingredient-list {
          @include bp(mediumWidth) {
            margin-right: 0;
          }
        }
      }

      .chosen-container {
        background: transparent;
        border: 0;
      }

      .chosen-select,
      .chosen-container-single,
      .chosen-container-active.chosen-with-drop {
        margin-right: 7px;
        text-align: left;
        .chosen-single {
          @include border-radius-and-pie(0);
          background: url(../images/select-arrow-black.png) no-repeat $yellow;
          background-position: 190px;
          box-shadow: 0 0 0 transparent;
          height: 50px;
          padding: 11px 0 0 12px;
          border: 0;
          color: black;

          @include break($tablet, true) {
            background-position: 92% center;
            height: 13vw;
            padding: 4vw;
          }
          span {
            @include bold-headline;
            font-size: 23px;
            

            @include break($mobile) {
              font-size: 6vw;
              overflow: visible;
            }
          }
        }
        .chosen-drop {
          @include border-radius-and-pie();
          border: 0;
          border-radius: 0;
          background: $yellow;
          .chosen-results {
            padding: 0;
            margin: 0;
            li {
              @include bold-headline;
              background: $yellow;
              font-size: 23px;
              padding: 12px;
              &:hover {
                background: #f1f0ef;
              }
            }
          }
        }
      }

      .search-form {
        width: 219px;
        @include break($tablet, true) {
          position: absolute;
          right: -4vw;
          top: 15.5vw;
          width: 48%;
        }
        .searchform {
          width: 219px;
          white-space: nowrap;
          @include break($tablet, true) {
            margin: 0 0 0 -10% !important;
            width: 100%;
          }
          #search {
            width: 170px;
            height: 50px;
            color: $yellow;
            background: transparent;
            font-size: 20px;
            border: 2px solid $yellow;
            border-right: 0;
            font-family: "dinbold";
            @include break($tablet, true) {
              width: 80%;
              height: 13vw;
              font-size: 5vw;
            }
          }
          input[type="submit"],
          button {
            @include square(50px);
            color: $yellow;
            background: $black_cmab;
            &:hover {
              color: $yellow_active;
            }
            font-family: cmab2;
            font-size: 24px;
            width: 49px;
            border: 2px solid $yellow;
            border-left: 0;
            background: transparent;
            @include break($tablet, true) {
              width: 20%;
              height: 13vw;
              font-size: 6vw;
            }
          }
          input[type="text"] {
            padding: 12px 0 12px 13px;
          }
        }
      }
    }
    @include bp(mediumWidth) {
      .recipe-selector {
        width: 100%;
        .selectors {
          display: block;
        }
        .headline {
          font-size: 28px;
          line-height: 1.1;
          margin-bottom: 10px;
          .mobile {
            display: inline;
          }
        }
        .controls {
          width: 57.5%;
          &.hidden {
            visibility: visible;
            opacity: 1;
          }
          margin-bottom: 10px;
          li {
            display: inline-block;
            width: 48.7%;
            select {
              color: $yellow_link_hover !important;
              background: url(../images/select-arrow-black.png) right 5px center
                no-repeat $yellow;
              background-size: 10px;
            }
          }
        }

        .chosen-select {
          @include bold-headline;
          @include upper;
          width: 100%;
          color: $yellow !important;
          background: $white url(../images/mobile-list.jpg) no-repeat;
          background-position: 1px -20px;
          text-align: center;
          height: 20px;
          background-size: 90%;
          font-size: 12px;
          padding-left: 5px;
          padding-right: 0;
        }

        .searchform {
          height: auto;
          width: 100%;
          padding-right: 7px;
          #search {
            width: 70%;
            padding: 0 0 0 5px;
            height: 24px;
            font-size: 12px;
            line-height: 1;
            border-right: 0;
          }
          input[type="submit"],
          button {
            height: 24px;
            font-size: 12px;
            width: 19%;
            border: 2px solid $yellow;
            border-left: 0;
            background: white;
          }
        }
      }
    }
    @include bp(smallWidth) {
      background-position: right 0;
    }
    .noMatch {
      color: #d6d6d6;
    }
  }
}
.mobile {
  &#foodservice-recipe,
  &#california-newsletters {
    .foodservice-recipe-selector,
    .type-selector {
      select.chosen-select {
        background-color: $yellow;
        height: 13vw;
        width: 100%;
        background: url(../images/select-arrow-black.png) no-repeat $yellow-ada;
        background-position: 96% center;
        color: black;
        font-family: "gothic";
        font-size: 19px;
        text-transform: uppercase;
        padding: 2vw;
        padding-right: 28px !important;
      }
    }
  }
}
