$carousel_background: #231f20;
$recipes-background-color: #f2f2f2;
$masthead-plus: 6vh;
$calm-color: rgba(159, 105, 164, 0.9);
$comfort-color: rgba(131, 147, 202, 0.9);
$excite-color: rgba(202, 131, 131, 0.9);
$curious-color: rgba(202, 196, 131, 0.9);
$bliss-color: rgba(154, 131, 202, 0.9);
$delight-color: rgba(143, 202, 131, 0.9);
@keyframes straightLine {
  50% {
    transform: translate3D(-500%, 700%, 0);
  }
  100% {
    transform: translate3D(-800%, 900%, 0);
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#campaign {
  .masthead {
    width: 100vw;
    height: calc(100vh - 50px);
    min-height: 600px;
    max-height: 1080px;
    position: relative;
    margin-top: 50px;

    @include break($mobile, true) {
      margin-top: 40px;
      height: calc(100vh - 40px);
    }

    > .carousel {
      background-color: $carousel_background;
    }

    .slide-wrapper-inner {
      height: 100%;
      margin-left: auto;
      margin-right: auto;

      .slick-list,
      .slick-track,
      .slide {
        height: 100%;
      }
    }

    .one {
      //TODO-devsu: remove bg and asset
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $carousel_background;
    }

    .header {
      height: 200px;
      width: 100%;
      position: absolute;
      z-index: 1;
      padding-top: 3vw;

      @include break($mobile) {
        height: 140px;
        padding-top: 14vw;
      }

      @include break($desktop-wide) {
        padding-top: 38px;
      }
    }

    .headline {
      background-image: url(../images/campaign/headline_plain@2x.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 178px;
      margin: 0 auto;
      width: 100%;

      @include break($tablet, true) {
        height: 24vw;
      }
    }

    .slide-copy {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .copy {
        $copy-gradient-start-color: rgba(0, 0, 0, 1);
        $copy-gradient-stop-color: rgba(0, 0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8),
          rgba(255, 255, 255, 0) 100%
        );
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        z-index: 1;
        padding-bottom: 70px;

        @include break($tablet) {
          padding-bottom: 10%;
        }

        > * {
          max-width: 800px;
          width: 800/1600 * 100vw;

          @include break($tablet, true) {
            width: 90%;
          }
        }

        .description {
          color: white;
          text-align: center;
          font-size: 20px;
          font-family: "hoefler";
          line-height: 1.36;

          @include break($mobile) {
            font-size: 4vw;
          }

          &.hidden {
            display: none;
          }
        }
      }
    }

    .carousel {
      height: 100%;
    }

    .cta-wrapper {
      padding-top: 2vw;
      @include break($mobile) {
        padding-top: 5vw;
      }

      &.hidden {
        display: none;
      }
    }

    .cta {
      @include cta-no-border($black, $white, $white, $black, auto);
    }
  }

  .section.recipes {
    flex-direction: column;
    overflow: initial;
    background-color: $recipes-background-color;
    padding-top: 7vw;
    @include break($tablet) {
      padding-top: 9vw;
    }

    #recipes-result {
      padding-top: 5vw;
      @include break($desktop) {
        padding-top: 60px;
      }
    }

    $content-width: 80vw;

    .content {
      width: $content-width;
      margin-left: calc((100vw - #{$content-width}) / 2);
      margin-right: calc((100vw - #{$content-width}) / 2);
      max-width: $content-width;
      padding-bottom: 0;
      position: absolute;
      top: -27%;
      background-color: $recipes-background-color;
      @include break($desktop-wide) {
        $content-width: 1200px;
        max-width: $content-width;
        margin-left: calc((100vw - #{$content-width}) / 2);
        margin-right: calc((100vw - #{$content-width}) / 2);
      }
      $width-mobile: 85vw;
      @include break($mobile) {
        top: -15%;
        width: $width-mobile;
        max-width: $width-mobile;
        margin-left: calc((100vw - #{$width-mobile}) / 2);
        margin-right: calc((100vw - #{$width-mobile}) / 2);
        position: initial;
      }
    }

    .recipes-categories {
      display: flex;
      justify-content: center;
      width: 100vw;
      z-index: 2;

      @include break($tablet) {
        width: 100%;
      }

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        padding-top: 2vw;

        @include break($tablet, true) {
          padding-top: 0;
          width: auto;
        }
        @include break($mobile) {
          overflow-x: scroll;
        }
      }

      .category {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid black;
        border-radius: 50%;
        width: 7vw;
        height: 7vw;
        max-width: 82px;
        max-height: 82px;
        font-family: gothic;
        text-transform: uppercase;
        font-size: 1.5vw;

        @include break($tablet, true) {
          width: 60/320 * 100vw;
          height: 60/320 * 100vw;
          font-size: 16/320 * 100vw;
          margin: 0 3.2vw;
          padding: 24/320 * 100vw 8/320 * 100vw;
        }

        @include break($desktop-wide) {
          font-size: 22px;
        }

        &:hover,
        &.active {
          color: $white;

          &.calm {
            background-color: $calm-color;
            border: 2px solid $calm-color;
          }

          &.comfort {
            background-color: $comfort-color;
            border: 2px solid $comfort-color;
          }

          &.excite {
            background-color: $excite-color;
            border: 2px solid $excite-color;
          }

          &.curious {
            background-color: $curious-color;
            border: 2px solid $curious-color;
          }

          &.bliss {
            background-color: $bliss-color;
            border: 2px solid $bliss-color;
          }

          &.delight {
            background-color: $delight-color;
            border: 2px solid $delight-color;
          }
        }
      }
    }

    .underline {
      text-align: center;
      font-size: 1.5vw;
      font-family: hoefler;
      margin: 1.2vw 0;

      @include break($tablet, true) {
        font-size: 4vw;
        margin: 20/320 * 100vw 0;
      }

      @include break($desktop-wide) {
        padding: 20px 0;
        font-size: 20px;
      }
    }
  }

  .section.recipes,
  .section.golden-state {
    .headline {
      text-align: center;
      color: $yellow;
    }

    .subheadline {
      text-align: center;
      font-size: 7vw;
      @include break($desktop-wide) {
        font-size: 114px;
      }
      @include break($mobile) {
        width: 80vw;
        font-size: 15.8vw;
        letter-spacing: -0.1px;
        z-index: 1;
      }

      sup {
        vertical-align: top;
        font-size: 16%;
        line-height: 1.4vw;
        padding-left: 10px;
        letter-spacing: 0.05em;
        @include break($mobile) {
          font-size: 2vw;
          line-height: 3vw;
        }
        @include break($desktop-wide) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }

  .section {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 1.5vw;
    padding-bottom: 3vw;
    overflow-y: hidden;
    @include break($desktop-wide) {
      padding-top: 30px;
      padding-bottom: 60px;
    }

    .background-wrapper {
      $extended: 14%;
      width: calc(100% + #{$extended * 2});
      height: 100%;
      position: absolute;
      top: 0;
      left: -$extended;
    }

    .background {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 65vw;
      max-width: 960px;
      max-height: 620px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4vw;
      padding-bottom: 5vw;
      @include break($desktop-wide) {
        padding-top: 40px;
        padding-bottom: 50px;
      }
      position: relative;

      &.white {
        color: $white;
      }
    }

    .cta-wrapper,
    .copy,
    .heading {
      display: flex;
      flex-direction: column;
      z-index: 1;
    }

    .headline {
      font-family: wisdom;
      line-height: 1.4;
      font-size: 2.4vw;
      @include break($desktop-wide) {
        font-size: 35px;
      }
      @include break($mobile) {
        font-size: 6vw;
        text-align: center;
      }
    }

    .subheadline {
      letter-spacing: -3px;
      line-height: 0.8;
      font-family: gothic;
      text-transform: uppercase;
      font-size: 13.5vw;
      @include break($desktop-wide) {
        font-size: 200px;
      }
      @include break($mobile) {
        font-size: 22vw;
      }
    }

    .copy {
      font-family: hoefler;
      width: 40%;
      height: 6vw;
      max-height: 100px;
      margin-top: 2vw;
      margin-bottom: 2vw;
      font-size: 1.5vw;
      line-height: 1.45;

      @include break($desktop-wide) {
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @include break($mobile) {
        font-size: 5vw;
        width: 90%;
        height: 20vw;
        margin-top: 50vw;
        margin-bottom: 6vw;
        text-align: center;
      }
    }

    .content .cta-wrapper .cta {
      @include break($tablet) {
        padding-right: 42px;
        padding-left: 42px;
      }
    }

    .cta {
      max-width: 165px;
      width: 12vw;
      font-size: 1.5vw;
      white-space: nowrap;
      @include break($desktop-wide) {
        font-size: 22px;
      }
    }

    &.reversed {
      .cta-wrapper,
      .copy,
      .subheadline,
      .headline {
        align-self: flex-end;
        text-align: right;
        @include break($mobile) {
          align-self: center;
          text-align: center;
        }
      }
    }

    @include break($mobile) {
      .content {
        align-items: center;
        width: 90vw;
        max-height: 90vh;
      }
    }
    $cta-width-mobile: 30vw;
    $cta-margin-top-mobile: 4vw;

    &.cheese {
      background-color: $yellow_inactive;
      overflow: initial;

      .background {
        background-image: url(../images/campaign/cheese@2x.png);
        @include break($tablet) {
          width: 64%;
          height: 55%;
          left: 36%;
          top: 43%;
        }
        @include break($mobile) {
          width: 86%;
          margin-left: auto;
          margin-right: auto;
          top: -5%;
        }
      }

      .cta {
        @include cta-no-border($black, $white, $white, $black, auto);
        @include break($mobile) {
          width: $cta-width-mobile;
          max-width: $cta-width-mobile;
          margin-top: $cta-margin-top-mobile;
        }
      }
    }

    &.butter {
      background-color: $white;

      .background {
        background-image: url(../images/campaign/butter@2x.png);
        @include break($tablet) {
          width: 76%;
          right: 12%;
          height: 118%;
        }
        @include break($mobile) {
          width: 96%;
          margin-left: auto;
          margin-right: auto;
          top: -7%;
          left: -4%;
          z-index: 1;
        }
      }

      .cta {
        @include cta-no-border($yellow, $black, $black, $yellow, auto);
        @include break($mobile) {
          width: $cta-width-mobile;
          max-width: $cta-width-mobile;
          margin-top: $cta-margin-top-mobile;
        }
      }
    }

    &.ice-cream {
      $ice-cream-background-color: #0cc7b8;
      background-color: $ice-cream-background-color;

      .background {
        background-image: url(../images/campaign/ice-cream@2x.png);
        @include break($tablet) {
          width: 70%;
          height: 75%;
          left: 37%;
          top: 19%;
        }
        @include break($mobile) {
          width: 105%;
          margin-left: auto;
          margin-right: auto;
          top: -7%;
          left: -13%;
          z-index: 1;
        }
      }

      .cta {
        @include cta-no-border($black, $white, $white, $black, auto);
        @include break($mobile) {
          width: $cta-width-mobile;
          max-width: $cta-width-mobile;
          margin-top: $cta-margin-top-mobile;
        }
      }
    }

    &.yogurt {
      $yogurt-background-color: #efa1d0;
      background-color: $yogurt-background-color;

      .background {
        background-image: url(../images/campaign/yogurt.png);
        @include break($tablet) {
          width: 70%;
          height: 110%;
          right: 5%;
          bottom: 8%;
        }
        @include break($mobile) {
          width: 85%;
          margin-left: auto;
          margin-right: auto;
          top: -11%;
          z-index: 1;
        }
      }

      .cta {
        @include cta-no-border($black, $white, $white, $black, auto);
        @include break($mobile) {
          width: $cta-width-mobile;
          max-width: $cta-width-mobile;
          margin-top: $cta-margin-top-mobile;
        }
      }
    }

    .wine {
      background-image: url(../images/campaign/wine@2x.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      width: 28%;
      height: 100%;
      top: 185%;
      left: -4%;
    }

    .popcorn {
      width: 50%;
      height: 140%;
      position: relative;
      left: -10%;
      top: -21%;
      z-index: 1;

      @include break($mobile) {
        left: 7%;
        z-index: 0;
      }

      .pop {
        position: absolute;
        width: 6vw;
        max-width: 70px;
        height: 6vw;
        max-height: 70px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @include break($mobile) {
          width: 10vw;
          height: 10vw;
        }
      }
    }

    .sprinkles {
      width: 30%;
      height: 50%;
      position: relative;
      left: 65%;
      top: -10%;

      @include break($mobile) {
        width: 37%;
        left: 33%;
        top: -4%;
      }

      .sprinkle {
        position: absolute;
        width: 2vw;
        max-width: 20px;
        height: 2vw;
        max-height: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @include break($mobile) {
          width: 3vw;
          height: 3vw;
        }
      }
    }

    .blueberries {
      width: 20%;
      height: 74%;
      position: relative;
      left: 38%;
      top: -25%;
      z-index: 1;
      @include break($mobile) {
        left: 58%;
        width: 30%;
        height: 40%;
        top: -12%;
        z-index: 0;
      }

      .blueberry {
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .strawberries {
    width: 70%;
    height: 150%;
    position: relative;
    right: 20%;
    top: -35%;
    z-index: 1;
    @include break($mobile) {
      right: 7%;
      height: 80%;
      top: -21%;
      z-index: 0;
    }

    .strawberry {
      position: absolute;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0;
    }
  }

  .first-slide-background,
  .second-slide-background,
  .third-slide-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  .first-slide-background {
    background-image: url(../images/campaign/ETGS-masthead.jpg);
  }

  .second-slide-background {
    background-image: url(../images/campaign/bear-02.jpg);
  }

  .third-slide-background {
    background-image: url(../images/campaign/snowman-02.jpg);
  }

  @include break($mobile) {
    .first-slide-background {
      background-image: url(../images/campaign/ETGS-masthead.jpg);
    }
    .second-slide-background {
      background-image: url(../images/campaign/bear_mobile-02.jpg);
    }
    .third-slide-background {
      background-image: url(../images/campaign/snowman_mobile-02.jpg);
    }
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.desert {
    }

    &.redwoods {
    }

    &.tahoe {
    }
  }

  .video {
    position: relative;
    min-width: 100%;
    height: 100%;
    width: auto;
    $video-width: 1650px;
    @include break($video-width) {
      min-height: 100%;
      height: auto;
    }
  }

  .farms-youtube-gallery {
    background-color: $white;
    @include break($mobile) {
      padding-top: 8%;
    }

    .main {
      .header {
        h3 {
          .headline {
            font-size: 35px;
            color: $yellow;
            @include break($mobile) {
              font-size: 4.5vw;
            }
          }

          .subheadline {
            line-height: 1;
            padding-left: 0;
            @include break($mobile) {
              font-size: 11.5vw;
            }
          }
        }
      }

      .footer-info {
        display: flex;

        & > .yt-video-info {
          width: 40%;

          & > .yt-video-title {
            padding-left: 0;
          }
        }

        & > .ctas {
          width: 60%;
          text-align: right;
          margin-top: 10px;
          @include bp(mediumWidth) {
            margin-top: 2.7vw;
          }

          a {
            width: 15px;
            height: 15px;
            text-decoration: none;
            text-transform: uppercase;
            font-family: $sans;
            background: url("../images/icons/cta-play.png") no-repeat 0 3px;
            background-size: 16px;
            font-size: 21px;
            padding-left: 20px;
            color: $yellow;

            &:last-child {
              margin-left: 10px;
            }
          }

          @include bp(mediumWidth) {
            width: 100%;
            text-align: center;
            a {
              font-size: 2.7vw;
              background-size: 2.5vw;
              padding-left: 3.5vw;
              background-position-y: 0.3vw;

              &:last-child {
                margin-left: 2.5vw;
              }
            }
          }
        }
      }

      .slide-wrapper-inner {
        .slick-list {
          .slick-track {
            margin-left: 0;
          }
        }
      }

      .yt-list-info {
        height: auto;

        .yt-video-image {
          .active {
            top: 0;
            padding-top: 45px;
          }

          img {
            top: -40px;
            @include break($mobile) {
              top: 0;
            }
          }
        }

        .yt-video-title {
          padding-top: 15px;
          @include break($mobile) {
            padding-top: 0;
          }
        }
      }
    }
  }

  $gradient-color-start: #283905;
  $gradient-color-stop: rgba(50, 68, 13, 0.01);

  .transitioner {
    @include break($mobile) {
      display: none;
    }
    height: calc(4 * #{$masthead-plus});
    position: relative;
    bottom: $masthead-plus;

    > div {
      position: relative;
    }

    .background {
      height: 50%;
    }

    .gradient {
      visibility: hidden;
      height: 25%;
      bottom: 50%;
      position: relative;
      background-image: linear-gradient(
        to top,
        $gradient-color-start,
        $gradient-color-stop
      );
    }

    .solid {
      background-color: $recipes-background-color;
      height: 60%;
      bottom: 50%;
    }

    .leftover {
      background-color: $recipes-background-color;
      height: 100%;
      bottom: 50%;
    }
  }

  //TODO-devsu: this needs to be moved to it's own file and must be general for all partials
  // styling for this page only must live here. hotfix overriding featured-recipes stylesheet
  #recipes-result {
    padding-bottom: 125px;
    background-color: $recipes-background-color;

    .featured-recipes {
      padding: 0;
      background-color: transparent;

      .details {
        .headline {
          @include bold-headline-6;
          color: white;
          line-height: 0.9;
          @include break($mobile) {
            font-size: 7.5vw;
          }
        }

        .description {
          color: white;
        }
      }

      .slick-next {
        background-image: url(../images/icons/arrow-next-gray.png);
      }

      .slick-prev {
        background-image: url(../images/icons/arrow-prev-gray.png);
      }
    }

    &.calm {
      .details {
        background-color: $calm-color;

        .cta {
          @include cta-no-border($black, $white, $white, $black, auto);
        }
      }
    }

    &.comfort {
      .details {
        background-color: $comfort-color;

        .cta {
          @include cta-no-border($black, $white, $white, $black, auto);
        }
      }
    }

    &.excite {
      .details {
        background-color: $excite-color;

        .cta {
          @include cta-no-border($black, $white, $white, $black, auto);
        }
      }
    }

    &.curious {
      .details {
        background-color: $curious-color;

        .cta {
          @include cta-no-border($black, $white, $white, $black, auto);
        }
      }
    }

    &.bliss {
      .details {
        background-color: $bliss-color;

        .cta {
          @include cta-no-border($black, $white, $white, $black, auto);
        }
      }
    }

    &.delight {
      .details {
        background-color: $delight-color;

        .cta {
          @include cta-no-border($black, $white, $white, $black, auto);
        }
      }
    }
  }
}

//                 1   2   3   4   5  6   7   8   9   10   11  12
$list-space-top: 50% 30% 60% 34% 12% 53% 29% 11% 5% 19% 5% 20%;
$list-space-left: 0% 5% 8% 17% 15% 15% 34% 36% 49% 63% 68% 84%;
$corn_animation_duration: 747ms 1479ms 524ms 865ms 753ms 1027ms 632ms 1631ms
  1117ms 784ms 1207ms 937ms;
$corn_initial_angle: 16deg -4deg 8deg 16deg 7deg -9deg 19deg 8deg 13deg -13deg 18deg
  11deg;
$corn_end_angle: -34deg 61deg 45deg 26deg 83deg -80deg -115deg -74deg 45deg -111deg -31deg -62deg;
$postive_negative_deg: 1deg -1deg;
$popcorn_count: 12;
@for $i from 1 through $popcorn_count {
  $initial-top: 0%;
  $duration: nth($corn_animation_duration, $i);
  $rotate_start: nth($corn_initial_angle, $i);
  $rotate_end: nth($corn_end_angle, $i);

  .pop_#{$i} {
    background-image: url("../images/campaign/popcorn/pop_#{$i}@2x.png");
    left: nth($list-space-left, $i);
    transform: rotate($rotate_start);
  }

  .popcorn.animate {
    .pop:nth-of-type(#{$i}) {
      animation-name: pop-#{$i};
      animation-duration: 500ms + $duration;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      top: $initial-top;
    }
  }

  @keyframes pop-#{$i} {
    0% {
    }
    100% {
      transform: rotate($rotate_end);
      top: nth($list-space-top, $i);
    }
  }
}

$sprinkle_end-top: 55 03 68 04 28 05 23 19 37 18 42 65 49 07 29 20 24 42 02 35
  29 01 05 10 07 41 07 24 58 18 47 45 48 62 18 34 53 66 31 39 31 44 02 12 38 13
  13 07 10 42 16 39 58 12 23 62 15 38 32 24;
$sprinkle_list-left: 39 89 22 46 09 77 11 72 18 24 87 57 61 71 84 29 57 82 40 64
  50 86 27 71 90 89 90 82 44 64 66 15 34 28 11 18 05 25 08 63 80 53 46 52 21 58
  30 43 44 90 08 16 55 42 74 27 47 79 6 27 86;
$sprinkle_end_angle: 99 60 33 -65 20 -30 -45 69 -106 104 59 15 80 -86 84 -47 14 -14
  77 70 36 -95 39 38 -51 -75 112 71 115 -40 57 -27 63 -26 114 -85 -9 86 -76 98
  39 104 -8 10 -73 79 107 42 -70 -64 -9 -55 109 12 90 35 108 94 111 -112;
$sprinkle_animation_delay: 2701 2932 2065 711 2120 53 2288 1679 1234 1000 107
  239 1672 2381 2498 371 36 2110 2648 1957 412 907 1995 808 2319 1971 1219 2245
  1452 1537 713 1179 60 916 1352 2401 2433 2987 2226 1874 14 319 22 569 1051
  1899 800 1442 1157 2595 1592 554 1101 2135 2756 1758 943 2815 2074 1121;
$sprinkles_size: 60;
$max_number_of_assets: 17;
@for $i from 1 through $sprinkles_size {
  $initial-top: 0%;
  $rotate_end: nth($sprinkle_end_angle, $i) * 1deg;

  .spr_#{$i} {
    background-image: url("../images/campaign/sprinkles/spr_#{($i%$max_number_of_assets) + 1}@2x.png");
    left: nth($sprinkle_list-left, $i) * 1%;
  }

  .sprinkles.animate {
    .sprinkle:nth-of-type(#{$i}) {
      animation-name: sprinkle-#{$i};
      animation-duration: 900ms;
      animation-delay: nth($sprinkle_animation_delay, $i) * 1ms;
      animation-iteration-count: 2;
      animation-fill-mode: forwards;
      top: $initial-top;
    }
  }

  @keyframes sprinkle-#{$i} {
    0% {
    }
    100% {
      transform: rotate($rotate_end);
      top: nth($sprinkle_end-top, $i) * 1%;
    }
  }
}

//                           1     2       3       4     5       6      7
$blueberry_list-space-top: 18% 6% 16% 55% 69% 82% 88%;
$blueberry_list-space-left: 0% 23% 78% 27% 82% 41% 53%;
$blueberry_list-size: 2.3vw 5vw 2.1vw 4.6vw 4.5vw 2.2vw 5vw;
$blueberry_list-max-size: 32px 85px 28px 65px 62px 28px 68px;
$blueberry_list-animation_delay: 200ms;
$blueberry_end_angle: 17 -8 15 -14 17 15 -16;
$max_number_of__blueberries_assets: 7;
$top_offset: 23%;
@for $i from 1 through $max_number_of__blueberries_assets {
  $initial-top: 0%;
  $rotate_end: nth($blueberry_end_angle, $i) * 1deg;

  .blue_#{$i} {
    background-image: url("../images/campaign/blueberries/blue_#{$i}@2x.png");
    left: nth($blueberry_list-space-left, $i);
    width: nth($blueberry_list-size, $i);
    height: nth($blueberry_list-size, $i);
    max-height: nth($blueberry_list-max-size, $i);
    max-width: nth($blueberry_list-max-size, $i);
    opacity: 0;
    @include break($mobile) {
      width: nth($blueberry_list-size, $i) + 5vw;
      height: nth($blueberry_list-size, $i) + 5vw;
    }
  }

  .blueberries.animate {
    .blueberry:nth-of-type(#{$i}) {
      animation-name: blueberry-#{$i};
      animation-duration: 900ms;
      animation-delay: $blueberry_list-animation_delay;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      top: $initial-top;
    }
  }

  @keyframes blueberry-#{$i} {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: rotate($rotate_end);
      top: nth($blueberry_list-space-top, $i) + $top_offset;
    }
  }
}

//                           1        2       3       4     5       6      7     8
$strawberry_list-space-top: 52 25 77 46 24 75 44 63;
$strawberry_list-space-right: 90 80 73 59 54 57 44 41;
$strawberry_list-size: 3.3vw 7.2vw 8vw 7.8vw 7.1vw 2.7vw 5vw 7.6vw;
$strawberry_list-max-size: 60px 105px 125px 120px 95px 42px 68px 115px;
$strawberry_end_angle: 14 19 2 18 8 5 13 17;
$max_number_of_strawberries_assets: 8;
$top_offset: 23%;
@for $i from 1 through $max_number_of_strawberries_assets {
  $initial-top: 0%;
  $rotate_end: nth($strawberry_end_angle, $i) * 1deg;

  .str_#{$i} {
    background-image: url("../images/campaign/strawberries/str_#{$i}@2x.png");
    top: $initial-top;
    right: nth($strawberry_list-space-right, $i) * 1%;
    width: nth($strawberry_list-size, $i);
    height: nth($strawberry_list-size, $i);
    max-height: nth($strawberry_list-max-size, $i);
    max-width: nth($strawberry_list-max-size, $i);
    @include break($mobile) {
      width: nth($strawberry_list-size, $i) + 5vw;
      height: nth($strawberry_list-size, $i) + 5vw;
    }
  }

  .strawberries.slide-right-half {
    .strawberry:nth-of-type(#{$i}) {
      animation-name: strawberry-#{$i};
      animation-duration: 1400ms;
      animation-delay: 200ms;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  @keyframes strawberry-#{$i} {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
      transform: rotate($rotate_end);
      top: nth($strawberry_list-space-top, $i) * 1%;
    }
  }
}

.ca-reveal {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.ca-wrapper {
  height: 100%;
  width: 100%;
}

.ca-bg-fill {
  background: #ffc220;
  height: 100%;
  width: 100%;
}

.ca-cheddar {
  width: 100%;
}

.ca-center {
  left: 50%;
  overflow: visible;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
}

.ca-content {
  height: 19.7395833333vw;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 72% 37%;
  width: 900/1440 * 100vw;

  @include break($tablet, true) {
    transform-origin: 72% 60%;
    width: 87.5vw;
  }
}

.ca-logo-container {
  // 239x
  height: 154/1920 * 100vw;
  left: 720/1920 * 100vw;
  overflow: visible;
  perspective: 500px;
  position: absolute;
  top: 4.1666666667vw;
  transform-origin: 61% 41%;
  width: 239/1920 * 100vw;
  z-index: 1;

  @include break($tablet, true) {
    $width: 12.4;
    $ratio: 279/334.5;
    left: 177/320 * 100vw;
    top: 20/320 * 100vw;
    width: $width * 1vw;
    height: $width * $ratio * 1vw;
  }
}

.ca-burst,
.ca-glow {
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform-origin: 50%;
  transform: translate3d(-50%, -50%, 2px);
}

.ca-burst {
  z-index: 0;
}

.ca-glow {
  z-index: 3;
}

.ca-logo-left,
.ca-logo-right {
  background: url("../images/campaign/masthead/seal.svg") left top no-repeat
    transparent;
  background-size: 12.4479166667vw 8.0208333333vw;
  backface-visibility: hidden;
  transform: translateZ(2px);
  transform-style: preserve-3d;
  height: 100%;
  width: 50%;
  overflow: visible;
  position: absolute;
  top: 0;
  z-index: 2;

  @include break($tablet, true) {
    background-size: auto 100%;
  }
}

.ca-logo-bg {
  background: url("../images/campaign/masthead/seal.svg") center top no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.ca-logo-left {
  left: 0;
  transform-origin: 0 50%;
}

.ca-logo-right {
  background-position: right top;
  right: 0;
  transform-origin: 100% 50%;
}

.ca-title {
  background: url(../images/home/headline@2x.png?v=2) 50% 0 no-repeat;
  background-size: 100% auto;
  height: 200px;
  left: 50%;
  max-height: 300px;
  min-height: 226px;
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  width: 600/1920 * 100vw;

  @include break($tablet, true) {
    height: 26.6666666667vw;
    width: 80vw;
  }
}

.ca-indicator {
  animation: arrow-bounce 2s ease-in infinite;
  background: url("../images/icons/arrow-down-white.png") 50% 0 no-repeat;
  background-size: 50px 50px;
  bottom: 50px;
  color: white;
  cursor: pointer;
  font-family: "gothic", sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: auto;
  left: 50%;
  padding-top: 66px;
  position: absolute;
  text-transform: uppercase;
  transform: translate(-50%, 0);
  width: auto;

  @include break($tablet, true) {
    background-size: 30px 30px;
    bottom: 80px;
    font-size: 12px;
    padding-top: 40px;
  }
}

@keyframes arrow-bounce {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -20px);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

//NEW N.I
.disp-landing-sub-header {
  font-family: "gothic";
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  background-color: $pink_light;
  color: #870010;
  margin-top: -2px;
  padding-top: 50px;
  padding-bottom: 50px;
  @include break($mobile, true) {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 15px;
  }
  @include break($desktop-lg) {
    //background-color: yellow;
  }

  .cta {
    @include cta-no-border($black, $white, $white, $black, auto);
    top: 47px;
    margin: 0 auto 0 50px;
    @include break($mobile, true) {
      margin-left: 10px;
      padding: 0 4px;
      font-size: 16px;
    }
  }
}

.disp-landing-sub-header-yellow {
  font-family: "gothic", sans-serif;
  text-transform: uppercase;
  font-size: 4.6875vw;
  background-color: $yellow;
  color: black;
  @include break($mobile, true) {
    font-size: 41px;
  }
  @include break($desktop-wide) {
    font-size: 71px;
    line-height: 65px;
  }
}

.image-section {
  &.bg-butter {
    background: url("../images/bg-butter-display.jpg");
    background-size: cover;
    background-position: center bottom;

    @include break($mobile, true) {
      background: url("../images/bg-butter-display-mobile.jpg");
      background-size: cover;
      background-position: center bottom;
    }
  }

  &.bg-yogurt {
    background: url("../images/bg-yogurt.jpg");
    background-size: cover;
    background-position: center bottom;

    @include break($mobile, true) {
      background: url("../images/bg-yogurt-mobile.jpg");
      background-size: cover;
      background-position: center bottom;
    }
  }

  .title {
    font-family: "gothic", sans-serif;
    text-transform: uppercase;
    font-size: 4.6875vw;

    &.title-ice-cream {
      color: $pink_light;
    }

    &.title-butter {
      color: $teal;
    }

    &.title-cheese {
      color: $yellow;
    }

    &.align-right {
      text-align: end;
      @include break($mobile, true) {
        text-align: center;
      }
    }

    @include break($mobile, true) {
      font-size: 41px;
      text-align: center;
    }
    @include break($desktop-wide) {
      font-size: 71px;
      line-height: 65px;
    }
  }

  .description {
    font-size: 18px;
    font-family: "gotham", sans-serif;
    text-align: left;

    &.align-right {
      text-align: end;
      @include break($mobile, true) {
        text-align: center;
      }
    }

    @include break($mobile, true) {
      font-size: 15px;
      line-height: 1.3;
      text-align: center;
    }

    @include break($desktop) {
      line-height: 1.4;
    }
  }

  a {
    color: black;
  }
}

.dispensary-gallery-section {
  background: $white;
  height: auto;

  .title {
    font-family: "gothic", sans-serif;
    text-transform: uppercase;
    font-size: 4.6875vw;

    @include break($mobile, true) {
      font-size: 41px;
      text-align: center;
    }
    @include break($desktop-wide) {
      font-size: 71px;
      line-height: 65px;
    }
  }

  .slick-next,
  .slick-prev {
    top: 50%;

    &:before {
      content: none;
    }
  }

  .slide {
    position: relative;
  }

  @include break($slider_width, true) {
    .slide-wrapper-outer {
      width: 100%;
    }
  }

  @include break($mobile) {
    height: auto;
    width: 100%;
    .slide-wrapper-outer {
      height: auto;
      width: 100%;
    }
    .slide-wrapper-inner {
      position: relative;
      top: 0;
    }

    .slide {
      height: auto;
      width: 100%;
    }
  }
}

.disp-landing-content {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #e7e7e7;

  .heading {
    color: white;
    font-family: "gothic";
    text-transform: uppercase;
    font-size: 1.7vw;
    margin-bottom: 20px;
    text-align: left;
    @include break($mobile, true) {
      text-align: center;
      font-size: 21px;
      margin-bottom: 10px;
    }
  }

  .content {
    display: flex;
    @include break($mobile, true) {
      display: block;
    }

    .col-1-dispensary {
      font-family: "gothic";
      text-transform: uppercase;
      font-size: 4.6875vw;
      text-align: left;
      @include break($mobile, true) {
        font-size: 41px;
      }
      @include break($desktop-wide) {
        font-size: 71px;
        line-height: 65px;
      }
    }

    .col-2-dispensary {
      font-size: 18px;
      //20 line-height: 26px;
      padding-left: 5%;
      font-family: "gotham";
      text-align: left;

      @include break($mobile, true) {
        padding-left: 0;
        font-size: 15px;
        line-height: 20px;
        padding-top: 10px;
        line-height: 1.3;
      }
      @include break($tablet, false) {
        //background-color: #00A000;
      }
      @include break($desktop) {
        line-height: 1.4;
      }
    }

    .col-1-dispensary,
    .col-2-dispensary {
      min-width: 50%;
      border: 0px solid red;
      @include break($mobile, true) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .teal-text {
    color: $teal;
  }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    margin-top: 3em;
  }

  .embed-responsive::before {
    display: block;
    content: "";
  }

  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.disp-landing-content__image {
  position: absolute;
  top: 350px;
  left: 0;
  @include break($mobile, true) {
    top: 300px;
    max-height: 340px;
  }
  @include break($desktop) {
    top: 290px;
  }
  @include break($desktop-wide) {
    top: 290px;
  }
}

.disp-wrapper {
  text-align: center;
  margin: 0 auto;
  position: relative;
  max-width: 960px;
  width: 73%;
  @include break($mobile, true) {
    width: 85%;
  }
}

.find-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "gothic";
  background-image: url("../images/campaign/FindandBuy_01.png");
  color: white;
  text-transform: uppercase;
  background-color: #0cc7b8;
  border: 0px solid red;
  height: 336px;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  padding-bottom: 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include break($mobile, true) {
    height: 230px;
    background-image: url("../images/campaign/find-buy-m.png");
    background-size: cover;
    background-position: center;
  }

  .subheadline {
    font-size: 6.25vw;
    line-height: 6.25vw;
    margin-bottom: 10px;
    @include break($mobile, true) {
      font-size: 50px;
      line-height: 42px;
      margin-bottom: 10px;
    }
    @include break($desktop-lg) {
      font-size: 100px;
    }
  }

  .subheadline--small {
    font-size: 2.0625vw;
    line-height: 2.0625vw;
    margin-bottom: 20px;
    @include break($mobile, true) {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    @include break($desktop-lg) {
      font-size: 33px;
      line-height: 33px;
    }
  }

  .cta {
    @include cta-no-border($black, $white, $white, $black, auto);
  }
}

.disp-btyb {
  .headline {
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .subheadline {
    @include break($mobile, true) {
      font-size: 52px !important;
      line-height: 52px !important;
    }
  }

  .cta {
    min-width: 285px !important;
    width: 285px !important;
    padding: 0 !important;
    @include break($mobile, true) {
      font-size: 38px !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      height: 80px !important;
    }
  }
}

@keyframes float {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px) rotate(0deg);
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-25px) rotate(20deg);
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px) rotate(0deg);
  }
}

@keyframes float-scoop {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-40px) rotate(30deg);
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

@keyframes float-spoon {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

.disp-landing-header-2 {
  position: relative;
  background-color: black;
  overflow: hidden;
  height: calc(77vh - 50px);
  width: 100%;
  text-align: center;
  padding-top: 70px;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .subheadline--lg {
    line-height: 70px;
    @include break($mobile, true) {
      line-height: 35px;
    }
  }

  .subheadline {
    a {
      color: white;
      text-decoration: none;
    }

    font-family: "gothic", sans-serif;
    position: relative;
    text-transform: uppercase;
    font-size: 85px;
    color: white;
    margin-bottom: 30px;
    @include break($mobile, true) {
      font-size: 10.625vw;
      margin-bottom: 10px;
    }
  }

  .subheadline--small {
    //font-size: 33px;
    font-size: 2.0625vw;
    margin-bottom: 50px;
    @include break($mobile, true) {
      font-size: 4.0625vw;
      margin-bottom: 20px;
    }
    @include break($desktop-lg) {
      font-size: 33px;
      //font-size: 2.8125VW;
    }
  }

  .subheadline--xsmall {
    //font-size: 22px;
    font-size: 1.375vw;
    margin-bottom: 0;
    @include break($mobile, true) {
      //font-size: 18px;
      font-size: 2.8125vw;
    }
    @include break($desktop-lg) {
      font-size: 22px;
      //font-size: 2.8125VW;
    }
  }

  .disp-landing-header__map-icon {
    @include break($mobile, true) {
      height: 8px;
    }
  }

  .headlines {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .animatable {
      width: 100%;
      top: 0;
      opacity: 0;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      transition: all 0.4s;
      transform: translateY(20px);

      &.show {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @include break($mobile, true) {
    height: 100vh;
    padding-top: 40px;
  }

  .cta-wrapper {
    display: flex;
    justify-content: center;

    .cta {
      @include cta-no-border($white, $black, $black, $white, 190);
    }
  }
}

.disp-landing-header-2__inner {
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.pink-bg {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ice-cream-wrapper {
  position: absolute;
  border: 1px solid red;
  margin-left: 16%;

  .scoop-2 {
    width: 165px;
    height: 165px;
  }
}

.left-mast,
.right-mast {
  //background-image: url('../images/campaign/left-mast.png');
  position: absolute;
}

.left-mast {
  //left: 10%;
  @include break($mobile, true) {
    left: -90px;
    .static-img {
      width: 285px;
    }
  }

  @include break($tablet) {
    left: -25px;
  }
  @include break($desktop-lg) {
    left: 8%;
  }
}

.right-mast {
  //right: 0;
  //left: 60%;

  @include break($mobile, true) {
    left: 70%;
    margin-top: 60px;
    .static-img {
      width: 260px;
    }
  }

  @include break($tablet) {
    right: 0 !important;
  }

  @include break($desktop) {
    left: 60%;
  }
}

.scoop-2 {
  flex: 1;
  width: 170px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  top: auto;
  left: auto;
  transform: translatey(0px);
  animation: float-scoop 6s ease-in-out infinite;
  margin-left: 100px;
  margin-top: 60px;

  img {
    width: 100%;
    height: auto;
  }

  @include break($mobile, true) {
    margin-left: 70px;
    margin-top: 60px;
    width: 60px;
    height: 60px;
  }
}

.cheese-2 {
  flex: 1;
  width: 102px;
  margin-top: 30px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
  transform: translatey(0px);
  animation: float 4s ease-in-out infinite;

  img {
    width: 100%;
    height: auto;
  }

  margin-left: 343px;
  @include break($mobile, true) {
    margin-top: 40px;
    width: 32px;
    height: 38px;
    margin-left: 170px;
  }
}

.disp-blueberry {
  width: 114px;
  margin-left: 353px;
  margin-top: 350px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  //transform: translatey(0px);
  animation: float-blueberry 3s ease-in-out infinite;

  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    display: none;
  }
}

@keyframes float-blueberry {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translateY(0px);
    //opacity: .6;
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translateY(30px) rotate(10deg);
    //opacity: .3;
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translateY(0px);
    //opacity: .6;
  }
}

.disp-blueberry-shadow {
  width: 114px;
  margin-left: 353px;
  margin-top: 350px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  overflow: hidden;
  //transform: translatey(0px);
  animation: float-blueberry-shadow 3s ease-in-out infinite;

  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    display: none;
  }
}

@keyframes float-blueberry-shadow {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translateX(0px);
    opacity: 0.8;
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translateX(-30px) rotate(-10deg);
    opacity: 1;
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translateX(0px);
    //opacity: .6;
    opacity: 0.8;
  }
}

.disp-strawberry {
  width: 250px;
  display: none;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  margin-top: 268px;
  margin-left: 430px;
  overflow: hidden;
  //transform: translatey(0px);
  //animation: float-spoon 8s ease-in-out infinite;
  img {
    width: 100%;
    height: auto;
  }

  @include break($desktop) {
    display: block;
  }
}

.disp-butter {
  width: 240px;
  //display: none;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  margin-top: 509px;
  margin-left: 110px;
  overflow: hidden;
  //transform: translatey(0px);
  //animation: float-spoon 8s ease-in-out infinite;
  img {
    width: 100%;
    height: auto;
  }

  @include break($mobile, true) {
    width: 80px;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    margin-top: 178px;
    margin-left: 40px;
    overflow: hidden;
  }
}

.spoon-2 {
  width: 324px;
  margin-left: 29px;
  margin-top: 31px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  transform: translatey(0px);
  animation: float-spoon 4s ease-in-out infinite;

  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    margin-left: 10px;
    width: 112px;
    height: 256px;
    margin-top: 20px;
  }
}

.spoon-shadow {
  width: 426px;
  margin-top: 328px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  opacity: 0.6;
  transform: translatey(0px);
  animation: spoon-shadow 4s ease-in-out infinite;

  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    margin-left: 1px;
    width: 132px;
    height: 236px;
    margin-top: 130px;
  }
}

@keyframes spoon-shadow {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
    opacity: 0.6;
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translateX(30px);
    opacity: 0.3;
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
    opacity: 0.6;
  }
}

.scoop-shadow {
  width: 183px;
  margin-top: 378px;
  margin-left: 430px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  opacity: 0.5;
  //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
  transform: translatey(0px);
  animation: scoop-shadow 6s ease-in-out infinite;

  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    margin-left: 210px;
    width: 82px;
    height: 206px;
    margin-top: 175px;
  }
}

@keyframes scoop-shadow {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
    opacity: 0.5;
  }
  50% {
    //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translateX(40px);
    opacity: 0.3;
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
    opacity: 0.5;
  }
}

.dairy-banner-inner {
  height: 100%;
}

.dairy-banner {
  height: 120px;
  background-image: url("../images/campaign/tout-bg-desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  @include break($mobile, true) {
    height: auto;
    padding: 30px 0;
    background-image: url("../images/campaign/tout-bg-mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }

  .heading {
    font-size: 33px;
    color: #038f88;
    text-transform: uppercase;
    font-family: "gothic";
    margin-right: 30px;
    font-weight: bold;

    @include break($mobile, true) {
      text-align: left;
      font-size: 21px;
      margin-right: 0;
    }
  }

  .learn-more {
    font-family: "gothic";
    text-transform: uppercase;
    color: #f4cb26;
    font-size: 20px;
    @include break($mobile, true) {
      margin-top: 10px;
      font-size: 16px;
    }

    a {
      color: $black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.disp-main-ice-cream {
  width: 406px;
  margin-top: 26px;
  margin-left: 360px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  transform: translatey(0px);
  //animation: spoon-shadow 4s ease-in-out infinite;
  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    display: none;
  }
}

.disp-blueberry-top {
  width: 68px;
  margin-top: 1px;
  margin-left: 202px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  //transform: translatey(0px);
  //animation: spoon-shadow 4s ease-in-out infinite;
  img {
    width: 100%;
    height: auto;
  }

  //margin-left: 300px;
  @include break($mobile, true) {
    width: 24px;
    margin-top: 0px;
    margin-left: 70px;
  }
}

.disp-wrapper-alt {
  text-align: center;
  margin: 0 auto;
  position: relative;
  max-width: 1440px;
  width: 90%;
}
