.recipes.carousel {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .cta-wrapper {
      align-self: flex-end;
      margin-bottom: 10px;

      .cta {
        width: 197px;
      }
    }

    .heading {
      .headline {
        @include headline-accent-3($white);
        font-size: 25px;
        font-family: "wisdom";
        color: $yellow-ada;
        letter-spacing: 0.18px;
      }

      .subheadline {
        text-transform: uppercase;
        font-size: 92px;
      }
    }
  }
}

@include break($mobile) {
  .recipes.carousel {
    padding-top: 30px;

    .header {
      padding: 0 10px;
      flex-direction: column;
      align-items: center;

      .cta-wrapper {
        align-self: center;
        margin: 3vw 0 6vw 0;

        .cta {
          width: 197px;
        }
      }

      .heading {
        text-align: center;

        .headline {
        }

        .subheadline {
          font-size: 46px;
        }
      }
    }
  }
}
