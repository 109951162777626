.partnership-resources {
  background-color: #f5b324;
  padding-top: 10px;
  padding-bottom: 50px;

  .header-section {
    .heading {
      font-family: gothic, sans-serif;
      font-size: 60px;
      line-height: 53px;
      text-transform: uppercase;

      @include break($tablet) {
        font-size: 72px;
        line-height: 80px;
      }
    }

    .description {
      font-family: gothic, sans-serif;
      font-size: 24px;
      line-height: 26px;
      max-width: 600px;
      margin: auto;
    }
  }

  body#partnership & {
    .featured-recipes {
      @include break($tablet) {
        padding: 80px 0 80px;
      }

      .slide-wrapper-outer {
        overflow: hidden;

        .prev,
        .slick-prev {
          @include prev_button(0, white, false);
        }

        .next,
        .slick-next {
          @include next_button(0, white, false);
        }

        .slick-dots {
          li {
            button {
              &:before {
                color: #ffffff;
                opacity: 1;
              }
            }

            &.slick-active button:before {
              color: #d6d6d6;
            }
          }
        }
      }

      .panels {
        padding: 0 10vw;
        @include break($tablet) {
          padding: 0;
        }

        .photo {
          height: 250px;

          @include break($tablet) {
            height: auto;
            display: flex;
            align-items: center;
          }

          img {
            margin: auto;

            @include break($tablet) {
              max-width: 405px;
              max-height: 405px;
            }
          }
        }

        .details {
          text-align: center;
          background-color: transparent;
          height: 500px;
          display: block;

          @include break($tablet) {
            height: auto;
            text-align: left;
            display: flex;
            justify-content: center;
          }

          @include break($desktop-lg) {
            padding-left: 24px !important;
            padding-right: 24px !important;
          }

          .headline {
            text-transform: none;
            font-size: 38px;
            line-height: 34px;
            text-align: center;
            @include break($tablet) {
              font-size: 48px;
              line-height: 46px;
              text-align: left;
            }
          }

          .description {
            color: black;
            font-size: 18px;
            line-height: 26px;
            text-align: center;

            @include break($tablet) {
              font-size: 24px;
              line-height: 26px;
              text-align: left;
              flex-grow: 0;
            }
          }

          .cta-partnership {
            @include cta-no-border($black, $white, $white, $black, auto);
          }
        }
      }
    }
  }
}
