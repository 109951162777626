.mobile-yes {
  .accordion {
    border-top: 2px solid $yellow;
    margin-bottom: 10%;

    li {
      border-bottom: 1px solid $gray_pale;
      font: 18px/22px $serif;
      padding: 1em 1.3em;
      position: relative;

      .icon {
        height: 18px;
        position: absolute;
        right: 1.3em;
        top: 1em;
        transform: rotate(0deg);
        transition: 0.3s all cubic-bezier(0.49, 1.92, 0.83, 0.67);
        width: 18px;

        $xline-width: 18px;
        $xline-height: 4px;
        $xline-padding: 0px;

        &::before {
          transform: rotateZ(90deg);
        }

        &::before,
        &::after {
          background-color: black;
          content: " ";
          height: $xline-height;
          left: $xline-padding;
          position: absolute;
          top: 7px;
          width: $xline-width;
          z-index: 1;
        }
      }
      .term,
      .label {
        font: 25px/25px $sans;
        text-transform: uppercase;
        max-width: 300px;
      }

      .definition,
      .definition p,
      .details {
        display: none;

        a,
        a:hover {
          text-decoration: none;
          color: $yellow;
        }

        .phone a {
          color: $black-cmab;
        }
      }

      &.open {
        .icon {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        .definition,
        .definition p,
        .details {
          display: block;
        }
      }
    }

    ul li {
      border: 0;
      .label {
        font: 18px $sans;
        color: $olive;
      }
    }
  }
}
